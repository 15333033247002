<template>
  <v-form ref="form" v-model="valid" @submit.prevent="callSubmit()">
    <div class="text-h5 text-primary pt-10 pb-6">
      {{
        props.role === NewUserRole.client ? $t("company-type") : $t("company")
      }}
    </div>
    <v-row class="my-0">
      <v-col class="py-0">
        <v-radio-group
          v-if="props.role === 'client'"
          v-model="data.company_type"
          :rules="[rules.required]"
          inline
        >
          <v-radio
            :label="$t('logistician')"
            value="logistic"
            color="primary"
          ></v-radio>
          <v-radio
            :label="$t('industry')"
            value="industry"
            color="primary"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row class="my-0">
      <v-col class="py-0">
        <v-text-field
          v-model="data.company_name"
          :rules="[rules.required]"
          autocomplete="organization"
          :label="$t('company-name')"
          variant="outlined"
          class="pb-2"
        ></v-text-field>
      </v-col>
      <v-col class="py-0">
        <v-text-field
          v-model="data.company_addendum"
          autocomplete="off"
          :label="$t('company-addendum')"
          variant="outlined"
          class="pb-2"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <div class="text-h5 text-primary pt-10 pb-6">
      {{ $t("headquarters") }}
    </div>
    <v-row class="my-0">
      <v-col cols="6" class="py-0">
        <v-text-field
          v-model="data.headquarters_street"
          autocomplete="street-address"
          :rules="[rules.required]"
          :label="$t('street')"
          variant="outlined"
          class="pb-2"
        ></v-text-field>
      </v-col>
      <v-col cols="6" class="py-0">
        <v-autocomplete
          v-model="data.headquarters_country_id"
          autocomplete="country-name"
          :rules="[rules.required]"
          :label="$t('country')"
          :items="store.getters['app/countries']"
          item-title="title"
          item-value="id"
          variant="outlined"
          class="pb-2"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" class="py-0">
        <v-text-field
          v-model="data.headquarters_zip"
          autocomplete="postal-code"
          :rules="[rules.required, rules.postCodeOnly]"
          :label="$t('zip-code')"
          variant="outlined"
          class="pb-2"
        ></v-text-field>
      </v-col>
      <v-col cols="6" class="py-0">
        <v-text-field
          v-model="data.headquarters_city"
          autocomplete="address-level2"
          :rules="[rules.required]"
          :label="$t('town')"
          variant="outlined"
          class="pb-2"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-checkbox
      v-model="data.different_billing_address"
      :label="$t('different-billing-address')"
      color="primary"
      class="pb-2"
    ></v-checkbox>
    <div v-if="data.different_billing_address">
      <v-row class="my-0">
        <v-col cols="6" class="pt-0">
          <v-text-field
            v-model="data.billing_street"
            autocomplete="street-address"
            :rules="[rules.required]"
            :label="$t('street')"
            variant="outlined"
            class="pb-2"
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="pt-0">
          <v-autocomplete
            v-model="data.billing_country_id"
            autocomplete="country"
            :rules="[rules.required]"
            :label="$t('country')"
            :items="store.getters['app/countries']"
            item-title="title"
            item-value="id"
            variant="outlined"
            class="pb-2"
          ></v-autocomplete>
        </v-col>
        <v-col cols="6" class="py-0">
          <v-text-field
            v-model="data.billing_zip"
            autocomplete="postal-code"
            :rules="[rules.required, rules.postCodeOnly]"
            :label="$t('zip-code')"
            variant="outlined"
            class="pb-2"
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="py-0">
          <v-text-field
            v-model="data.billing_city"
            autocomplete="address-level2"
            :rules="[rules.required]"
            :label="$t('town')"
            variant="outlined"
            class="pb-2"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    <v-divider></v-divider>
    <div class="text-h5 text-primary pt-10 pb-6">
      {{ $t("central-contact-person") }}
    </div>
    <v-row class="my-0">
      <v-col cols="2" class="py-0">
        <v-select
          v-model="data.salutation"
          autocomplete="honorific-prefix"
          :rules="[rules.required]"
          :label="$t('salutation')"
          :items="
            salutationKeys.map(({ key, type }) => ({
              value: type,
              title: $t(key),
            }))
          "
          variant="outlined"
          class="pb-2"
        ></v-select>
      </v-col>
      <v-col cols="5" class="py-0">
        <v-text-field
          v-model="data.firstname"
          autocomplete="given-name"
          :rules="[rules.required]"
          :label="$t('first-name')"
          variant="outlined"
          class="pb-2"
        ></v-text-field>
      </v-col>
      <v-col cols="5" class="py-0">
        <v-text-field
          v-model="data.lastname"
          autocomplete="family-name"
          :rules="[rules.required]"
          :label="$t('last-name')"
          variant="outlined"
          class="pb-2"
        ></v-text-field>
      </v-col>
      <v-col cols="6" class="py-0">
        <v-text-field
          v-model="data.phone"
          autocomplete="tel"
          :rules="[rules.required, rules.phone]"
          :label="$t('phone')"
          variant="outlined"
          class="pb-2"
        ></v-text-field>
      </v-col>
      <v-col cols="6" class="py-0">
        <v-text-field
          v-model="data.email"
          autocomplete="email"
          :rules="[rules.required, rules.email]"
          :label="$t('email')"
          variant="outlined"
          class="pb-2"
        ></v-text-field>
      </v-col>
      <v-col cols="6" class="py-0">
        <v-text-field
          v-model="data.password"
          autocomplete="new-password"
          :label="$t('password')"
          :append-inner-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[
            rules.required,
            rules.numberHas,
            rules.letterHas,
            rules.symbolHas,
            rules.letterLowercaseHas,
            rules.letterUppercaseHas,
            rules.lengthMin(8),
          ]"
          :type="passwordShow ? 'text' : 'password'"
          counter
          variant="outlined"
          class="pb-2"
          @click:append-inner="passwordShow = !passwordShow"
        ></v-text-field>
      </v-col>
      <v-col cols="6" class="py-0">
        <v-text-field
          v-model="data.password_confirmation"
          autocomplete="new-password"
          :label="$t('repeat-password')"
          :append-inner-icon="
            passwordConfirmationShow ? 'mdi-eye' : 'mdi-eye-off'
          "
          :rules="[rules.required, rules.match(data.password)]"
          :type="passwordConfirmationShow ? 'text' : 'password'"
          variant="outlined"
          class="pb-2"
          @click:append-inner="
            passwordConfirmationShow = !passwordConfirmationShow
          "
        ></v-text-field>
      </v-col>
    </v-row>
    <div class="d-flex justify-space-between mt-10">
      <v-btn variant="text" color="primary" size="large" @click="emit('back')">
        {{ $t("button.back") }}
      </v-btn>
      <v-btn type="submit" color="primary" size="large">
        {{ $t("button.next") }}
      </v-btn>
    </div>
  </v-form>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useStore } from "@/store";

import { useRules } from "@/utils/rules";

import { NewUserRole, NewUserObject } from "@/types/auth";
import { VForm } from "vuetify/components";
import { useValidHelper } from "@/utils/validHelper";
import { useMountScroller } from "@/utils/mountScroller";
import { salutationKeys } from "@/utils/other";
import { CountryObject } from "@/types/other";
import { CompanyType } from "@/types/company";

const store = useStore();

const props = defineProps<{
  role: NewUserRole;
  newUserObject: NewUserObject;
}>();

const rules = useRules();

const valid = ref(false);

const data = ref<NewUserObject>(props.newUserObject);

if (props.role === NewUserRole.contractor) {
  data.value.company_type = CompanyType.contractor;
}

const form = ref<VForm>();

useValidHelper(form);
useMountScroller();

const passwordShow = ref(false);
const passwordConfirmationShow = ref(false);

const callSubmit = async () => {
  await form.value?.validate();
  if (valid.value) {
    emit("submit", { data: data.value, tabIndex: 1 });
  }
};

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "submit", data: { data: NewUserObject; tabIndex: number }): void;
  // eslint-disable-next-line no-unused-vars
  (e: "back"): void;
}>();

onMounted(() => {
  if (!data.value.headquarters_country_id) {
    const codeDE = "DE";
    data.value.headquarters_country_id = store.getters["app/countries"].find(
      ({ code }: CountryObject) => code.toUpperCase() === codeDE.toUpperCase(),
    ).id;
  }
});
</script>

<style scoped></style>
