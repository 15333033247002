<template>
  <div class="position-relative">
    <v-text-field
      ref="field"
      prepend-inner-icon="mdi-calendar"
      append-inner-icon="mdi-menu-down"
      variant="outlined"
      readonly
      :value="currentTimeValues.title"
    ></v-text-field>
    <div
      v-if="showMonthsList"
      ref="monthsList"
      class="elevation-5 rounded-lg bpl-months position-absolute bg-white"
    >
      <div class="d-flex justify-space-between align-center">
        <v-btn
          icon="mdi-chevron-left"
          elevation="0"
          :disabled="props.loading"
          variant="plain"
          @click="clickArrow(false)"
        />
        <v-btn
          class="text-body-2 pa-2 font-weight-bold"
          variant="plain"
          elevation="0"
          :disabled="props.loading"
          @click="toggleMonthView"
          >{{
            isMonthsView ? navYear : `${startYear} - ${startYear + 11}`
          }}</v-btn
        >
        <v-btn
          icon="mdi-chevron-right"
          elevation="0"
          :disabled="props.loading"
          variant="plain"
          @click="clickArrow(true)"
        />
      </div>

      <div class="bpl-months-list pa-1">
        <template v-if="isMonthsView">
          <div v-for="item in months" :key="`${navYear}-${item.value}`">
            <v-btn
              :variant="item.active ? 'flat' : 'text'"
              :disabled="props.loading"
              :color="item.active ? ('primary' as any) : undefined"
              class="text-body-2 font-weight-medium rounded w-100"
              @click="clickMonth(navYear, item.value)"
              >{{ item.name }}
            </v-btn>
          </div>
        </template>
        <template v-else>
          <div v-for="item in years" :key="item.value">
            <v-btn
              :variant="item.active ? 'flat' : 'text'"
              :color="item.active ? ('primary' as any) : undefined"
              class="text-body-2 font-weight-medium rounded w-100"
              :disabled="props.loading"
              @click="clickYear(item.value)"
              >{{ item.value }}
            </v-btn>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
import moment from "moment";
import { useI18n } from "vue-i18n";
import { backFormats, useLocFormat } from "@/utils/locFormat";
const { locale } = useI18n();

const props = defineProps<{
  modelValue?: string;
  loading?: boolean;
}>();

const currentTime = computed({
  get() {
    return props.modelValue || "";
  },
  set(value: string) {
    emit("update:modelValue", value);
  },
});

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "update:modelValue", data?: string): void;
}>();

const showMonthsList = ref(false);
const field = ref<any | null>(null);
const monthsList = ref<HTMLDivElement>();

const isMonthsView = ref(true);

const navYear = ref(moment().year());
const startYear = computed(() => Math.trunc(navYear.value / 12) * 12);

const locFormat = useLocFormat();

const currentTimeValues = computed(() => {
  const m = currentTime.value ? moment(currentTime.value) : null;
  return {
    year: m && m.year(),
    month: m && m.month(),
    title: m ? m.locale(locale.value).format(locFormat.value.yMmmm) : "",
  };
});

const months = computed(() =>
  moment()
    .locale(locale.value)
    .localeData()
    .monthsShort()
    .map((name, value) => ({
      name,
      value,
      active:
        navYear.value === currentTimeValues.value.year &&
        value === currentTimeValues.value.month,
    })),
);
const years = computed(() =>
  [...Array(12).keys()].map((i) => {
    const value = startYear.value + i;
    return { value, active: value === currentTimeValues.value.year };
  }),
);

const toggleMonthView = () => {
  const newValue = !isMonthsView.value;
  if (newValue) navYear.value = currentTimeValues.value.year || moment().year();
  isMonthsView.value = newValue;
};
const clickArrow = (isRight: boolean) => {
  if (isMonthsView.value) {
    navYear.value = navYear.value + (isRight ? 1 : -1);
  } else {
    navYear.value = navYear.value + (isRight ? 1 : -1) * 12;
  }
};
const clickMonth = (year: number, month: number) => {
  currentTime.value = moment().year(year).month(month).format(backFormats.yMm);
};
const clickYear = (year: number) => {
  navYear.value = year;
  isMonthsView.value = true;
};

watch(
  () => currentTimeValues.value.year,
  (value) => {
    navYear.value = value || moment().year();
  },
);

watch(showMonthsList, (value) => {
  if (value) clickYear(currentTimeValues.value.year || moment().year());
});

const mouseDownHandler = (e: any) => {
  const inputEl =
    field.value?.$el?.getElementsByClassName("v-input__control")[0];
  const calendarEl = monthsList.value;

  const clickOutside = !(
    calendarEl === e.target ||
    calendarEl?.contains(e.target) ||
    inputEl === e.target ||
    inputEl?.contains(e.target)
  );
  showMonthsList.value = !clickOutside;
};

onMounted(() => {
  document.addEventListener("mousedown", mouseDownHandler);
});

onBeforeUnmount(() => {
  document.removeEventListener("mousedown", mouseDownHandler);
});
</script>

<style scoped>
.bpl-months {
  top: 68px;
  left: 0;
  z-index: 10;
}
.bpl-months-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 4px;
  width: 250px;
}
</style>
