import { CompanyData, CompanyType } from "@/types/company";

import { PaymentTargetType, SalutationType } from "@/types/calculator";
import { RoleObject, RoleType } from "@/types/roles";

export type UserCredentialsObject = {
  email: string;
  password: string;
};

export enum NewUserRole {
  client = "client",
  contractor = "contractor",
}

export enum DepartmentType {
  no = "no",
  invoice = "invoice",
  warning = "warning",
  miscellaneous = "miscellaneous",
}

export enum Languages {
  bulgarian = "bulgarian",
  croatian = "croatian",
  czech = "czech",
  danish = "danish",
  dutch = "dutch",
  english = "english",
  estonian = "estonian",
  finnish = "finnish",
  french = "french",
  german = "german",
  greek = "greek",
  hungarian = "hungarian",
  italian = "italian",
  latvian = "latvian",
  lithuanian = "lithuanian",
  norwegian = "norwegian",
  polish = "polish",
  portuguese = "portuguese",
  romanian = "romanian",
  russian = "russian",
  serbian = "serbian",
  slovak = "slovak",
  slovenian = "slovenian",
  spanish = "spanish",
  swedish = "swedish",
  ukrainian = "ukrainian",
}

export type NewUserObject = {
  company_type?: CompanyType;
  company_name?: string;
  company_addendum?: string;
  headquarters_street?: string;
  headquarters_country_id?: string;
  headquarters_city?: string;
  headquarters_zip?: string;
  different_billing_address: boolean;
  billing_street?: string;
  billing_country_id?: string;
  billing_city?: string;
  billing_zip?: string;
  email?: string;
  password?: string;
  password_confirmation?: string;
  salutation?: SalutationType;
  firstname?: string;
  lastname?: string;
  phone?: string;
  company_vat_id?: string;
  company_tax_id?: string;
  company_payment_target?: PaymentTargetType;
  company_iban?: string;
  company_swift?: string;
  company_bank_name?: string;
  company_bank_code?: string;
  verification_url?: string;
  locale?: string;
};

export type NewUserErrorObject = {
  company_type: string[];
  company_name: string[];
  company_addendum: string[];
  headquarters_street: string[];
  headquarters_country_id: string[];
  headquarters_city: string[];
  headquarters_zip: string[];
  different_billing_address: string[];
  billing_street: string[];
  billing_country_id: string[];
  billing_city: string[];
  billing_zip: string[];
  email: string[];
  password: string[];
  password_confirmation: string[];
  salutation: string[];
  firstname: string[];
  lastname: string[];
  phone: string[];
  company_vat_id: string[];
  company_tax_id: string[];
  company_payment_target: string[];
  company_iban: string[];
  company_swift: string[];
  company_bank_name: string[];
  company_bank_code: string[];
};

export type ForgotObject = { email: string; reset_url: string };

export type ResetObject = {
  email: string;
  token: string;
  password: string;
  password_confirmation: string;
};

export interface TokensObject {
  access_token: string;
  expires_in: number;
  refresh_token: string;
}

export interface CurrentTokensObject extends TokensObject {
  token_time: number;
}

export type ProfileData = {
  id: string;
  firstname: string;
  lastname: string;
  designation?: string;
  department: DepartmentType;
  department_miscellaneous: string;
  salutation: SalutationType;
  languages: Languages[] | null;
  email: string;
  phone: string;
  role: RoleType;
  roles: { data: RoleObject[] };
  company: { data: CompanyData } | null;
  locale: string;
  voucher_recipient: boolean;
  from_role: RoleType | null;
};

export enum ProfileBlockType {
  headquarters,
  contact,
  tax,
  address,
  bank,
  account,
  password,
  email,
}
