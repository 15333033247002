<template>
  <v-row no-gutters>
    <v-col cols="12" class="pa-10">
      <v-sheet class="py-12 px-10 rounded-xl bpl-border-gray">
        <v-form ref="form" v-model="valid" @submit.prevent="saveChanges()">
          <editable-block v-model="edited" :loading="editLoading" submit>
            <template #title>
              <p class="text-h4 text-primary font-weight-light">
                {{ $t("vehicles") }}
              </p>
            </template>
            <template #edit-btn>
              <v-btn
                :disabled="!tableItems.length"
                color="primary"
                @click="edited = true"
                >{{ $t("button.add") }}</v-btn
              >
            </template>
            <template #default="{ readonly }">
              <div class="mt-10">
                <div
                  v-if="edited"
                  class="px-4 pt-8 pb-4 bg-grey-lighten-5 mb-10"
                >
                  <p class="text-h5 text-primary pb-8">
                    {{ $t("vehicle-type") }}
                  </p>
                  <v-row class="my-0">
                    <v-col cols="12" class="py-0">
                      <v-text-field
                        v-model="newVehicleObject.name"
                        :error-messages="errors.name"
                        :rules="[rules.required]"
                        :label="$t('vehicle-type')"
                        :readonly="readonly"
                        :variant="readonly ? 'filled' : 'outlined'"
                        class="pb-2"
                        @update:model-value="errors.name = []"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" class="py-0">
                      <v-radio-group
                        v-model="newVehicleObject.calculation_type"
                        inline
                        hide-details
                        class="mt-2"
                      >
                        <v-radio
                          v-for="(typeData, i) in calcTypes"
                          :key="i"
                          :label="typeData.label"
                          :value="typeData.type"
                          color="primary"
                          :class="{ 'ml-4': !!i }"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="9" class="py-0">
                      <v-text-field
                        v-model="newVehicleObject.value"
                        :error-messages="errors.value"
                        :label="currentCalcType?.label"
                        type="number"
                        hide-spin-buttons
                        :append-inner-icon="currentCalcType?.icon"
                        class="pb-2"
                        :readonly="readonly"
                        :variant="readonly ? 'filled' : 'outlined'"
                        :bg-color="!readonly ? ('white' as any) : undefined"
                        :rules="[rules.floatNumberWithMinusOnly]"
                        @update:model-value="errors.value = []"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2" class="py-0">
                      <v-text-field
                        v-model="newVehicleObject.length"
                        :error-messages="errors.length"
                        :label="$t('length')"
                        type="number"
                        hide-spin-buttons
                        class="pb-2"
                        :readonly="readonly"
                        :variant="readonly ? 'filled' : 'outlined'"
                        :bg-color="!readonly ? ('white' as any) : undefined"
                        :rules="[rules.floatNumberOnly]"
                        @update:model-value="errors.length = []"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2" class="py-0">
                      <v-text-field
                        v-model="newVehicleObject.width"
                        :error-messages="errors.width"
                        :label="$t('width')"
                        type="number"
                        hide-spin-buttons
                        class="pb-2"
                        :readonly="readonly"
                        :variant="readonly ? 'filled' : 'outlined'"
                        :bg-color="!readonly ? ('white' as any) : undefined"
                        :rules="[rules.floatNumberOnly]"
                        @update:model-value="errors.width = []"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2" class="py-0">
                      <v-text-field
                        v-model="newVehicleObject.height"
                        :error-messages="errors.height"
                        :label="$t('height')"
                        type="number"
                        hide-spin-buttons
                        class="pb-2"
                        :readonly="readonly"
                        :variant="readonly ? 'filled' : 'outlined'"
                        :bg-color="!readonly ? ('white' as any) : undefined"
                        :rules="[rules.floatNumberOnly]"
                        @update:model-value="errors.height = []"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" class="py-0">
                      <v-text-field
                        v-model="newVehicleObject.capacity"
                        :error-messages="errors.capacity"
                        :label="$t('capacity')"
                        type="number"
                        hide-spin-buttons
                        append-inner-icon="mdi-weight-kilogram"
                        class="pb-2"
                        :readonly="readonly"
                        :variant="readonly ? 'filled' : 'outlined'"
                        :bg-color="!readonly ? ('white' as any) : undefined"
                        :rules="[rules.floatNumberOnly]"
                        @update:model-value="errors.capacity = []"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" class="py-0">
                      <v-text-field
                        v-model="newVehicleObject.volume"
                        :error-messages="errors.volume"
                        :label="$t('volume')"
                        type="number"
                        hide-spin-buttons
                        class="pb-2"
                        :readonly="readonly"
                        :variant="readonly ? 'filled' : 'outlined'"
                        :bg-color="!readonly ? ('white' as any) : undefined"
                        :rules="[rules.floatNumberOnly]"
                        @update:model-value="errors.volume = []"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="py-0">
                      <v-combobox
                        v-model="prepEquipment"
                        :error-messages="errors.equipment"
                        :label="$t('equipment')"
                        multiple
                        chips
                        :readonly="readonly"
                        :variant="readonly ? 'filled' : 'outlined'"
                        :bg-color="!readonly ? ('white' as any) : undefined"
                        @update:model-value="errors.equipment = []"
                      >
                        <template #chip="{ item }">
                          <v-chip
                            closable
                            @click:close="
                              prepEquipment = prepEquipment.filter(
                                (c) => c !== item.title,
                              )
                            "
                          >
                            {{ item.title }}
                          </v-chip>
                        </template>
                      </v-combobox>
                    </v-col>
                    <v-col cols="3" class="py-0">
                      <v-checkbox
                        v-model="newVehicleObject.ferry"
                        :label="$t('ferry')"
                        color="primary"
                        :disabled="readonly"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="2" class="py-0">
                      <v-checkbox
                        v-model="newVehicleObject.active"
                        :label="$t('active')"
                        color="primary"
                        :disabled="readonly"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </div>
                <v-data-table
                  :headers="headers"
                  :items="tableItems"
                  class="bpl-custom-table-footer elevation-0 bpl-border-gray rounded-lg bpl-table-height-1096"
                  :items-per-page="perPage"
                  :items-per-page-options="[]"
                  :page="page"
                  :loading="
                    !tableItems.length && store.state.app.vehiclesLoading
                  "
                >
                  <template #[`item.equipment`]="{ item }">
                    <div class="d-flex flex-wrap pa-1">
                      <div
                        v-for="(e, i) in item.equipment"
                        :key="i"
                        class="pa-1"
                      >
                        <v-chip>{{ e }}</v-chip>
                      </div>
                    </div>
                  </template>
                  <template #[`item.ferry`]="{ item }">
                    <span v-if="item.ferry">{{ $t("text.yes") }}</span>
                    <span v-else class="text-red-lighten-1">{{
                      $t("text.no")
                    }}</span>
                  </template>
                  <template #[`item.active`]="{ item }">
                    <span v-if="item.active">{{ $t("text.yes") }}</span>
                    <span v-else class="text-red-lighten-1">{{
                      $t("text.no")
                    }}</span>
                  </template>
                  <template #[`item.actions`]="{ item }">
                    <v-menu>
                      <template #activator="{ props: menuProps }">
                        <v-btn
                          icon="mdi-dots-vertical"
                          variant="plain"
                          elevation="0"
                          v-bind="menuProps"
                        ></v-btn>
                      </template>
                      <v-list min-width="230">
                        <v-list-item
                          :disabled="removedId === item.id"
                          @click.stop="deleteVehicle(item.id)"
                        >
                          <v-list-item-title>{{
                            $t("button.delete")
                          }}</v-list-item-title>
                          <template #append>
                            <v-icon
                              icon="mdi-delete"
                              color="grey-darken-2"
                            ></v-icon>
                          </template>
                        </v-list-item>
                        <v-list-item @click="clickEdit(item.id)">
                          <v-list-item-title>{{
                            $t("button.edit")
                          }}</v-list-item-title>
                          <template #append>
                            <v-icon
                              icon="mdi-file-edit"
                              color="grey-darken-2"
                            ></v-icon>
                          </template>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-data-table>
              </div>
            </template>
          </editable-block>
        </v-form>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useStore } from "@/store";
import { useI18n } from "vue-i18n";

import { snackbar } from "@/utils/snackbar";
import { useRules } from "@/utils/rules";
import { useValidHelper } from "@/utils/validHelper";
import { getInitialVehicleErrorObject } from "@/utils/settings";
import { useConfirm } from "@/utils/confirmHelper";
import { useFormat } from "@/utils/other";

import { VForm, VTextField } from "vuetify/components";
import { EditVehicle, Vehicle, VehicleCalcType } from "@/types/settings";

import EditableBlock from "@/components/EditableBlock.vue";

const { t } = useI18n();
const toFormat = useFormat();

const editedId = ref("");

const initialEditVehicle: EditVehicle = {
  active: false,
  value: "",
  calculation_type: VehicleCalcType.fixedPrice,
  length: "",
  width: "",
  height: "",
  capacity: "",
  volume: "",
  equipment: "",
  ferry: false,
  name: "",
};

const calcTypes = computed<
  { type: VehicleCalcType; label: string; icon: string }[]
>(() => [
  {
    type: VehicleCalcType.fixedPrice,
    label: t("fixed-price"),
    icon: "mdi-currency-eur",
  },
  { type: VehicleCalcType.factor, label: t("factor"), icon: "mdi-percent" },
]);

const currentCalcType = computed(() => {
  return calcTypes.value.find(
    ({ type }) => type === newVehicleObject.value.calculation_type,
  );
});

const errors = ref(getInitialVehicleErrorObject());

const newVehicleObject = ref<EditVehicle>({ ...initialEditVehicle });

const rules = useRules();

const valid = ref(false);
const form = ref<VForm>();

const perPage = 20;

const page = ref(1);

useValidHelper(form);

const edited = ref(false);

const editLoading = ref(false);

const removedId = ref<string>();

const deleteConfirm = useConfirm("dialog.delete-item");

const store = useStore();

const editedVehicleById = computed<Vehicle | null>(
  () => store.state.app.vehicles.find((p) => p.id === editedId.value) || null,
);

const clickEdit = (id: string) => {
  editedId.value = id;
  edited.value = true;

  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};
const saveChanges = async () => {
  await form.value?.validate();
  if (valid.value) {
    try {
      editLoading.value = true;

      if (editedId.value) {
        await store.dispatch("app/updateVehicle", {
          id: editedId.value,
          vehicle: newVehicleObject.value,
        });
      } else {
        await store.dispatch("app/addVehicle", newVehicleObject.value);
      }

      edited.value = false;
    } catch (e: any) {
      snackbar(e.message);
      errors.value = { ...getInitialVehicleErrorObject(), ...e.errors };
    } finally {
      editLoading.value = false;
    }
  }
};

const deleteVehicle = async (id: string) => {
  if (await deleteConfirm()) {
    try {
      removedId.value = id;
      await store.dispatch("app/deleteVehicle", id);
    } catch (e: any) {
      snackbar(e.message);
    } finally {
      removedId.value = undefined;
    }
  }
};

const tableItems = computed(() =>
  store.state.app.vehicles.map((v, i) => ({
    tableNumber: i + 1,
    vehicle: v.name,
    value: v.value,
    calcType:
      v.calculation_type === VehicleCalcType.factor
        ? t("factor")
        : t("fixed-price"),
    length: toFormat(v.length),
    width: toFormat(v.width),
    height: toFormat(v.height),
    capacity: toFormat(v.capacity),
    volume: toFormat(v.volume),
    equipment: v.equipment.split(","),
    ferry: v.ferry,
    active: v.active,
    id: v.id,
  })),
);

const prepEquipment = computed({
  get() {
    return newVehicleObject.value.equipment.length
      ? newVehicleObject.value.equipment.split(",")
      : [];
  },
  set(value: string[]) {
    newVehicleObject.value.equipment = value.join(",");
  },
});

const headers = computed(
  () =>
    [
      {
        title: t("table-number"),
        key: "tableNumber",
        width: "50px",
        sortable: false,
      },
      {
        title: t("vehicles"),
        key: "vehicle",
        sortable: false,
      },
      {
        title: t("value"),
        key: "value",
        sortable: false,
      },
      {
        title: t("calculation-type"),
        key: "calcType",
        sortable: false,
      },
      {
        title: t("length"),
        key: "length",
        sortable: false,
      },
      {
        title: t("width"),
        key: "width",
        sortable: false,
      },
      {
        title: t("height"),
        key: "height",
        sortable: false,
      },
      {
        title: t("capacity"),
        key: "capacity",
        sortable: false,
      },
      {
        title: t("volume"),
        key: "volume",
        sortable: false,
      },
      {
        title: t("equipment"),
        key: "equipment",
        width: "20%",
        sortable: false,
      },
      {
        title: t("ferry"),
        key: "ferry",
        sortable: false,
      },
      {
        title: t("active"),
        key: "active",
        sortable: false,
      },
      {
        title: "",
        key: "actions",
        width: "48px",
        align: "end",
        sortable: false,
      },
    ] as any[],
);

watch(edited, (value) => {
  //clear if edited-block was closed
  if (!value) {
    editedId.value = "";
    newVehicleObject.value = { ...initialEditVehicle };
    errors.value = getInitialVehicleErrorObject();
  }
});

watch(
  () => newVehicleObject.value.calculation_type,
  (value) => {
    newVehicleObject.value.value =
      editedVehicleById.value?.calculation_type === value &&
      editedVehicleById.value.value !== null
        ? String(editedVehicleById.value.value)
        : "";
  },
);

watch(editedVehicleById, (value, oldValue) => {
  if (value?.id !== oldValue?.id) {
    newVehicleObject.value = value
      ? {
          active: value.active,
          length: value.length,
          width: value.width,
          height: value.height,
          capacity: value.capacity,
          volume: value.volume,
          equipment: value.equipment || "",
          ferry: value.ferry,
          value: String(value.value),
          calculation_type: value.calculation_type,
          name: value.name,
        }
      : {
          ...initialEditVehicle,
        };
  }
});
</script>

<style></style>
