import { Ref, ref, watch } from "vue";
import { CheckboxGroupValue, GlobCheckbox } from "@/types/other";

export const useCheckboxes = (defChecked: Ref<CheckboxGroupValue[]>) => {
  const globCheckbox = ref<GlobCheckbox>({ checked: false, interim: false });
  const checkedList = ref<CheckboxGroupValue[]>([]);

  watch(
    globCheckbox,
    (value) => {
      if (!value.checked || !value.interim)
        checkedList.value = value.checked ? [...defChecked.value] : [];
    },
    { deep: true }
  );

  watch(checkedList, (value) => {
    const newGlob: GlobCheckbox = {
      checked: !!value.length,
      interim: value.length !== defChecked.value.length && !!value.length,
    };

    if (
      newGlob.checked !== globCheckbox.value.checked ||
      newGlob.interim !== globCheckbox.value.interim
    )
      globCheckbox.value = newGlob;
  });

  return { globCheckbox, checkedList };
};
