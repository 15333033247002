<template>
  <div class="d-flex flex-column small-radio-list">
    <v-tooltip
      v-if="props.title"
      :text="props.tooltip || ''"
      location="bottom"
      offset="-1px"
    >
      <template #activator="{ props: tooltipProps }">
        <div
          v-bind="props.tooltip ? tooltipProps : null"
          :class="{
            'bpl-underlined-text': props.tooltip,
            'small-radio-disabled': props.disabled,
          }"
          class="small-radio-wrap"
        >
          {{ props.title }}
        </div></template
      ></v-tooltip
    >
    <div>
      <v-radio-group
        v-model="radioValue"
        :disabled="props.disabled"
        color="primary"
        hide-details
      >
        <div class="small-checkbox-wrap">
          <v-radio
            :label="$t('no-selection')"
            :value="null"
            hide-details
          ></v-radio>
        </div>
        <v-tooltip
          v-for="(item, j) in prepItems"
          :key="j"
          :text="item.tooltip || ''"
          location="bottom"
          offset="-1px"
        >
          <template #activator="{ props: tooltipProps }">
            <div
              v-bind="item.tooltip ? tooltipProps : null"
              class="small-checkbox-wrap"
            >
              <v-radio
                :label="item.title"
                :value="item.value"
                :class="{ 'bpl-underlined-field': item.tooltip }"
                hide-details
              ></v-radio>
            </div>
          </template>
        </v-tooltip>
      </v-radio-group>
      <show-more-btn
        v-if="
          props.items.length > maxItems + (props.title ? 0 : 1) && !showMore
        "
        :disabled="disabled"
        @click="clickShowMore"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import ShowMoreBtn from "@/components/CheckboxesGroup/ShowMoreBtn.vue";
import { CheckboxData, CheckboxGroupValue } from "@/types/other";

const props = defineProps<{
  modelValue?: CheckboxGroupValue;
  items: CheckboxData[];
  title?: string;
  tooltip?: string;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "update:modelValue", data?: CheckboxGroupValue): void;
}>();

const prepItems = computed(() => [
  ...props.items.slice(
    0,
    showMore.value ? props.items.length : maxItems + (props.title ? 0 : 1)
  ),
]);

const radioValue = computed({
  get() {
    if (props.modelValue === undefined) {
      return null;
    } else {
      return props.modelValue as CheckboxGroupValue;
    }
  },
  set(value: CheckboxGroupValue | null): void {
    if (value === null) {
      emit("update:modelValue");
    } else {
      emit("update:modelValue", value);
    }
  },
});

const clickShowMore = () => {
  showMore.value = true;
};

const showMore = ref(false);

const maxItems = 4;
</script>

<style scoped>
.small-radio-list {
  width: max-content;
}

.small-radio-wrap {
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  height: 40px;
}

.small-radio-disabled {
  opacity: 0.38;
  cursor: default;
}
</style>
