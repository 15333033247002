import moment from "moment/moment";

import { FactorDay } from "@/types/settings";
import { backFormats } from "@/utils/locFormat";

export const checkDisabledDay = (date: string) => {
  const currentDay = moment(date);

  const minDay = moment().startOf("day");
  const maxDay = moment()
    .startOf("day")
    .year(minDay.year() + 3);
  const durationMin = currentDay.diff(minDay);
  const durationMax = maxDay.diff(currentDay);

  return !(durationMin >= 0 && durationMax >= 0);
};

export const startAndEndMoments = (a: string, b: string) => {
  const mA = moment(a);
  const mB = moment(b);
  const bIsAfter = mB.diff(mA) > 0;
  return {
    start: bIsAfter ? mA : mB,
    end: bIsAfter ? mB : mA,
  };
};

export const datesInRange = (
  a: string,
  b: string,
  list: FactorDay[],
  withEnds = false,
) => {
  const { start, end } = startAndEndMoments(a, b);

  return list
    .filter(({ date }) => {
      const m = moment(date);
      if (withEnds) return m.diff(start) >= 0 && end.diff(m) >= 0;
      else return m.diff(start) > 0 && end.diff(m) > 0;
    })
    .map(({ date }) => date);
};

export const addMissedDates = (factorDates: FactorDay[], month: string) => {
  const m = moment(month);
  const res: FactorDay[] = [];
  for (let i = 1; i <= m.daysInMonth(); i++) {
    m.date(i);
    const date = m.format(backFormats.yMmDd);
    const existDate = factorDates.find((f) => f.date === date);
    res.push(existDate || { id: Symbol(), date, value: 1, mf_value: 1 });
  }
  return res;
};

export const reduceWithNewDates = (
  factorDates: FactorDay[],
  newFactorDates: FactorDay[],
) => {
  return factorDates.map(
    (f) => newFactorDates.find((newF) => newF.date === f.date) || f,
  );
};
