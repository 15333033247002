import {
  StatusContext,
  StatusType,
  OfferStatusType,
  OrderStatusType,
  ClientStatusType,
  TransporterStatusType,
} from "@/types/document";

type StatusDataItem = {
  context: StatusContext;
  name: StatusType;
  color: string;
  key: string;
};
export const statusesData: StatusDataItem[] = [
  //offer statuses data
  {
    context: StatusContext.offer,
    name: OfferStatusType.open,
    color: "#29B6F6",
    key: "status-offer.open",
  },
  {
    context: StatusContext.offer,
    name: OfferStatusType.expired,
    color: "#78909C",
    key: "status-offer.expired",
  },
  {
    context: StatusContext.offer,
    name: OfferStatusType.onRequest,
    color: "#FFCA28",
    key: "status-offer.request",
  },
  {
    context: StatusContext.offer,
    name: OfferStatusType.priceOnRequest,
    color: "#FB8C00",
    key: "status-offer.price-on-request",
  },
  //order statuses data
  {
    context: StatusContext.order,
    name: OrderStatusType.upcoming,
    color: "#29B6F6",
    key: "status-order.upcoming",
  },
  {
    context: StatusContext.order,
    name: OrderStatusType.billing,
    color: "#FFCA28",
    key: "status-order.billing",
  },
  {
    context: StatusContext.order,
    name: OrderStatusType.ongoing,
    color: "#1E88E5",
    key: "status-order.ongoing",
  },
  {
    context: StatusContext.order,
    name: OrderStatusType.completed,
    color: "#43A047",
    key: "status-order.completed",
  },
  {
    context: StatusContext.order,
    name: OrderStatusType.outageFreight,
    color: "#F44336",
    key: "status-order.outage-freight",
  },
  {
    context: StatusContext.order,
    name: OrderStatusType.fautfracht,
    color: "#F44336",
    key: "status-order.fautfracht",
  },
  {
    context: StatusContext.order,
    name: OrderStatusType.tripCompleted,
    color: "#7CB342",
    key: "status-order.trip-completed",
  },
  {
    context: StatusContext.order,
    name: OrderStatusType.canceled,
    color: "#F40006",
    key: "status-order.cancel",
  },
  //client statuses data
  {
    context: StatusContext.client,
    name: ClientStatusType.accepted,
    color: "#4CAF50",
    key: "status-client.accepted",
  },
  {
    context: StatusContext.client,
    name: ClientStatusType.blocked,
    color: "#FB8C00",
    key: "status-client.blocked",
  },
  {
    context: StatusContext.client,
    name: ClientStatusType.pending,
    color: "#1982D4",
    key: "status-client.pending",
  },
  {
    context: StatusContext.client,
    name: ClientStatusType.rejected,
    color: "#FF5252",
    key: "status-client.rejected",
  },
  //transporter statuses data
  {
    context: StatusContext.transporter,
    name: TransporterStatusType.accepted,
    color: "#4CAF50",
    key: "status-contractor.accepted",
  },
  {
    context: StatusContext.transporter,
    name: TransporterStatusType.temporarilyBlocked,
    color: "#FB8C00",
    key: "status-contractor.temporarily-blocked",
  },
  {
    context: StatusContext.transporter,
    name: TransporterStatusType.blocked,
    color: "#FB8C00",
    key: "status-contractor.blocked",
  },
  {
    context: StatusContext.transporter,
    name: TransporterStatusType.pending,
    color: "#1982D4",
    key: "status-contractor.pending",
  },
  {
    context: StatusContext.transporter,
    name: TransporterStatusType.rejected,
    color: "#FF5252",
    key: "status-contractor.rejected",
  },
];

export const getStatus = (name: StatusType, context: StatusContext) =>
  statusesData.find((data) => name === data.name && context === data.context) ||
  null;
