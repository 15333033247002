<template>
  <div class="d-flex flex-column align-center text-center">
    <v-sheet width="487" class="mx-auto pa-10 rounded-xl bpl-border-gray">
      <v-alert
        v-if="forgotError"
        :title="$t('notification-request-failed')"
        :text="forgotError"
        color="error"
        variant="tonal"
        class="mb-6"
      ></v-alert>
      <div class="d-flex align-center justify-center">
        <v-img :src="require('@/assets/logo.webp')" width="82" height="82" />
        <h4 class="ml-3 text-h4 text-primary font-weight-light flex-shrink-0">
          {{ $t("password-forgot") }}
        </h4>
      </div>
      <v-form
        ref="form"
        v-model="valid"
        class="mt-6"
        @submit.prevent="callForgotPassword()"
      >
        <v-text-field
          v-model="email"
          :error-messages="errors.email"
          name="email"
          autocomplete="email"
          :label="$t('email')"
          :rules="[rules.required, rules.email]"
          type="email"
          variant="outlined"
          class="pb-2"
          @update:model-value="errors.email = []"
        ></v-text-field>
        <v-btn
          :loading="loading"
          type="submit"
          color="primary"
          size="large"
          block
          class="mt-6"
        >
          {{ $t("button.request-password") }}
        </v-btn>
      </v-form>
    </v-sheet>
    <router-link to="/login">
      <v-btn variant="text" class="mt-2 text-primary">
        {{ $t("button.cancel") }}
      </v-btn>
    </router-link>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

import { useRules } from "@/utils/rules";

import { forgot } from "@/api/auth";
import { ForgotObject } from "@/types/auth";
import { VForm } from "vuetify/components";
import { useValidHelper } from "@/utils/validHelper";

const rules = useRules();

const valid = ref(false);
const form = ref<VForm>();

useValidHelper(form);

const email = ref("");
const forgotError = ref("");
const errors = ref({ email: [], reset_url: [] });
const loading = ref(false);

const callForgotPassword = async () => {
  if (!loading.value) {
    await form.value?.validate();
    if (valid.value) {
      try {
        loading.value = true;
        const data: ForgotObject = {
          email: email.value,
          reset_url: `${window.location.origin}/password/reset`,
        };
        await forgot(data);
        emit("submit", email.value);
      } catch (e: any) {
        forgotError.value = e.message;
        errors.value = { email: [], reset_url: [], ...e.errors };
      } finally {
        loading.value = false;
      }
    }
  }
};

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "submit", data: string): void;
}>();
</script>
