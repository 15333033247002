import { useI18n } from "vue-i18n";

import {
  ExportNotificationErrorType,
  ExportNotificationType,
  LanguageType,
  NotificationType,
  Pagination,
} from "@/types/other";
import { PaymentTargetType, SalutationType } from "@/types/calculator";
import { PointType } from "@/types/document";
import { CompanyType } from "@/types/company";
import { ProfileData } from "@/types/auth";
import { RoleType } from "@/types/roles";
import { RideLoadingStatus, RideUnloadingStatus } from "@/types/rides";

export const getMatches = (array1: any[], array2: any[], inverse = false) =>
  array1.filter((e) => array2.includes(e) === !inverse);

export const calcGlobChecked = (
  checkedList: string[],
  defChecked: string[],
  globCheckedList: string[],
) => {
  const addList = getMatches(checkedList, globCheckedList, true);
  const removeList = getMatches(defChecked, checkedList, true);
  return [
    ...globCheckedList.filter((e) => !removeList.includes(e)),
    ...addList,
  ];
};

export const calcFetchPage = (deleteCount: number, pagData: Pagination) => {
  const resCount = pagData.total - deleteCount;
  const resPage = Math.min(
    pagData.current_page,
    Math.ceil(resCount / pagData.per_page),
  );
  return resPage > 0 ? resPage : 1;
};
export const initialPagData: Pagination = {
  current_page: 1,
  total: 1,
  total_pages: 1,
  per_page: 20,
};

export const joinWithDots = (...names: any[]) =>
  names.filter((n) => n).join(" · ");

type SearchItem = {
  title: string;
  value?: string | string[];
};
export const prepSearchString = (...items: SearchItem[]) =>
  items
    .map(({ title, value }) => ({
      title,
      value:
        typeof value === "string"
          ? value
          : value
          ? value.filter((v) => v).join(",")
          : "",
    }))
    .filter(({ value }) => value)
    .map(({ title, value }) => `${title}:${value}`)
    .join(";");

export type KeyByType<T> = { key: string; type: T };

export const pointKeys: KeyByType<PointType>[] = [
  { key: "calculator-route-type.first", type: PointType.loadingPoint },
  { key: "calculator-route-type.second", type: PointType.unloadingPoint },
  { key: "calculator-route-type.third", type: PointType.palletExchange },
  { key: "calculator-route-type.four", type: PointType.customsStation },
];
export const getPointName = (type: PointType) =>
  (pointKeys.find((d) => d.type === type) as KeyByType<PointType>).key;

export const paymentTargetKeys: KeyByType<PaymentTargetType>[] = [
  { key: "payment-target-items.first", type: PaymentTargetType.days7 },
  { key: "payment-target-items.second", type: PaymentTargetType.days8to14 },
  { key: "payment-target-items.third", type: PaymentTargetType.days15to21 },
];

export const getPaymentTargetName = (type: PaymentTargetType) =>
  (
    paymentTargetKeys.find(
      (d) => d.type === type,
    ) as KeyByType<PaymentTargetType>
  ).key;

export const salutationKeys: KeyByType<SalutationType>[] = [
  { key: "salutation-items.first", type: SalutationType.mr },
  { key: "salutation-items.second", type: SalutationType.mrs },
  { key: "salutation-items.third", type: SalutationType.divers },
];

export const getSalutationName = (type: SalutationType) =>
  (salutationKeys.find((d) => d.type === type) as KeyByType<SalutationType>)
    .key;

export const companyTypeKeys: KeyByType<CompanyType>[] = [
  { key: "logistician", type: CompanyType.logistic },
  { key: "industry", type: CompanyType.industry },
  { key: "contractor", type: CompanyType.contractor },
];

export const getCompanyTypeName = (type: CompanyType) =>
  (companyTypeKeys.find((d) => d.type === type) as KeyByType<CompanyType>).key;

export const languageKeys: KeyByType<LanguageType>[] = [
  { key: "german", type: LanguageType.german },
  { key: "english", type: LanguageType.english },
];

export const getLanguageName = (type: LanguageType) =>
  (languageKeys.find((d) => d.type === type) as KeyByType<LanguageType>).key;

export const rideLoadingStatusesKeys: KeyByType<RideLoadingStatus>[] = [
  { key: "ride.loading-status-wait", type: RideLoadingStatus.wait },
  {
    key: "ride.loading-status-waiting-papers",
    type: RideLoadingStatus.waitingPapers,
  },
  { key: "ride.loading-status-has-pager", type: RideLoadingStatus.hasPager },
  { key: "ride.loading-status-loading", type: RideLoadingStatus.loading },
  { key: "ride.loading-status-loaded", type: RideLoadingStatus.loaded },
];

export const getRideLoadingStatusName = (type: RideLoadingStatus) =>
  (
    rideLoadingStatusesKeys.find(
      (d) => d.type === type,
    ) as KeyByType<RideLoadingStatus>
  ).key;
export const rideUnloadingStatusesKeys: KeyByType<RideUnloadingStatus>[] = [
  { key: "ride.unloading-status-wait", type: RideUnloadingStatus.wait },
  { key: "ride.unloading-status-waiting", type: RideUnloadingStatus.waiting },
  {
    key: "ride.unloading-status-has-pager",
    type: RideUnloadingStatus.hasPager,
  },
  {
    key: "ride.unloading-status-unloading",
    type: RideUnloadingStatus.unloading,
  },
  { key: "ride.unloading-status-unloaded", type: RideUnloadingStatus.unloaded },
];

export const getRideUnloadingStatusName = (type: RideUnloadingStatus) =>
  (
    rideUnloadingStatusesKeys.find(
      (d) => d.type === type,
    ) as KeyByType<RideUnloadingStatus>
  ).key;
export const getUsersByRoles = (
  users: ProfileData[] = [],
  roles: RoleType[] = [],
) => (roles.length ? users.filter(({ role }) => roles.includes(role)) : users);

export const notificationKeys: KeyByType<NotificationType>[] = [
  {
    key: "notification.orders-exported",
    type: ExportNotificationType.ordersCsvExport,
  },
  {
    key: "notification.orders-exported-error",
    type: ExportNotificationErrorType.ordersCsvExportError,
  },
  {
    key: "notification.orders-exported",
    type: ExportNotificationType.ordersPdfExport,
  },
  {
    key: "notification.orders-exported-error",
    type: ExportNotificationErrorType.ordersPdfExportError,
  },
  {
    key: "notification.orders-exported",
    type: ExportNotificationType.documentsTransporterPdfExport,
  },
  {
    key: "notification.orders-exported-error",
    type: ExportNotificationErrorType.documentsTransporterPdfExportError,
  },
  {
    key: "notification.offers-exported",
    type: ExportNotificationType.offersCsvExport,
  },
  {
    key: "notification.offers-exported-error",
    type: ExportNotificationErrorType.offersCsvExportError,
  },
  {
    key: "notification.client-exported",
    type: ExportNotificationType.clientsCsvExport,
  },
  {
    key: "notification.client-exported-error",
    type: ExportNotificationErrorType.clientsCsvExportError,
  },
  {
    key: "notification.transporters-exported",
    type: ExportNotificationType.transportersCsvExport,
  },
  {
    key: "notification.transporters-exported-error",
    type: ExportNotificationErrorType.transportersCsvExportError,
  },
  {
    key: "notification.market-factor-exported",
    type: ExportNotificationType.marketFactorsCsvExport,
  },
  {
    key: "notification.market-factor-exported-error",
    type: ExportNotificationErrorType.marketFactorsCsvExportError,
  },
  {
    key: "notification.postal-code-exported",
    type: ExportNotificationType.countryPointsCsvExport,
  },
  {
    key: "notification.postal-code-exported-error",
    type: ExportNotificationErrorType.countryPointsCsvExportError,
  },
  {
    key: "notification.lzf-exported",
    type: ExportNotificationType.lzfCsvExport,
  },
  {
    key: "notification.lzf-exported-error",
    type: ExportNotificationErrorType.lzfCsvExportError,
  },
  {
    key: "notification.ferry-exported",
    type: ExportNotificationType.ferriesCsvExport,
  },
  {
    key: "notification.ferry-exported-error",
    type: ExportNotificationErrorType.ferriesCsvExportError,
  },
];

export const getNotificationName = (type: NotificationType) =>
  notificationKeys.find((d) => d.type === type)?.key || "";

export const roleKeys: KeyByType<RoleType>[] = [
  {
    key: "administrator",
    type: "admin",
  },
  {
    key: "sub-admin",
    type: "sub-admin",
  },
  {
    key: "bpl-manager",
    type: "bpl-manager",
  },
  {
    key: "bpl-calculation-manager",
    type: "bpl-calculation-manager",
  },
  {
    key: "role.contractor-forwarder",
    type: "forwarder",
  },
  {
    key: "role.contractor-planners",
    type: "planner",
  },
  {
    key: "role.contractor-accounting",
    type: "accounting-contractor",
  },
  {
    key: "role.contractor-driver",
    type: "driver",
  },
  {
    key: "role.contractor-pallets-department-contractor",
    type: "pallet-department-contractor",
  },
  {
    key: "role.client-industry",
    type: "industry",
  },
  {
    key: "role.client-industry-accounting",
    type: "accounting-client",
  },
  {
    key: "role.client-logisticians",
    type: "logistic",
  },
  {
    key: "role.client-logisticians-manager",
    type: "manager",
  },
  {
    key: "role.client-logisticians-pallets-department",
    type: "pallet-department-client",
  },
  {
    key: "role.client-industry-superuser",
    type: "sub-industry",
  },
  {
    key: "role.client-logisticians-superuser",
    type: "sub-logistic",
  },
  {
    key: "role.contractor-superuser",
    type: "sub-forwarder",
  },
];

export const getRoleName = (type: RoleType) =>
  (roleKeys.find((d) => d.type === type) as KeyByType<RoleType>).key;

type RoleByCompany = {
  company: CompanyType | null;
  roles: RoleType[];
};

const rolesOfCompanies: RoleByCompany[] = [
  {
    company: CompanyType.industry,
    roles: ["industry", "sub-industry", "accounting-client", "manager"],
  },
  {
    company: CompanyType.logistic,
    roles: [
      "logistic",
      "sub-logistic",
      "accounting-client",
      "manager",
      "pallet-department-client",
    ],
  },
  {
    company: CompanyType.contractor,
    roles: [
      "forwarder",
      "sub-forwarder",
      "planner",
      "accounting-contractor",
      "driver",
      "pallet-department-contractor",
    ],
  },
  {
    company: null,
    roles: ["admin", "sub-admin", "bpl-manager", "bpl-calculation-manager"],
  },
];

const mainRoles: RoleType[] = ["forwarder", "industry", "logistic", "admin"];

const getRolesByCompany = (type: CompanyType | null) =>
  rolesOfCompanies.find((d) => d.company === type)?.roles as RoleType[];

export const getRolesDetailsByCompany = (
  type: CompanyType | null,
  withMainRoles = false,
) => {
  const roles = getRolesByCompany(type);
  return roleKeys.filter(
    (d) =>
      roles.includes(d.type) && (withMainRoles || !mainRoles.includes(d.type)),
  );
};

export const cleanObject = <T>(obj: T & object): T => {
  const cleanObj = Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      return [key, value === "" ? undefined : value];
    }),
  );
  return cleanObj as T;
};

export const useFormat = () => {
  const { locale } = useI18n();
  return (value?: number | string, acc = -1) => {
    if (value === undefined || value === null) {
      return "";
    }
    const numberValue = typeof value === "number" ? value : Number(value);
    const roundedValue =
      acc < 0 ? numberValue : Number(numberValue.toFixed(acc));

    return roundedValue.toLocaleString(locale.value);
  };
};
export const copyToClipboard = (value?: string) => {
  if (value) navigator.clipboard.writeText(value);
};

export const renameFile = (
  fileRaw: File,
  name: string,
) => {
  const blob = fileRaw.slice(0, fileRaw.size, fileRaw.type);
  return new File([blob], name, { type: fileRaw.type });
};
