<template>
  <div class="d-flex align-center justify-center h-screen">
    <v-progress-circular
      color="primary"
      indeterminate
      size="64"
    ></v-progress-circular>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { verify } from "@/api/auth";

const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const url = route.fullPath;

const callVerifyEmail = async () => {
  try {
    await verify(url.split("/api/v1/")[1]);
    await router.push({
      name: "login",
      query: { success_message: t("notification-email-verify") },
    });
  } catch (e: any) {
    await router.push({
      name: "login",
      query: { error_message: e.message },
    });
  }
};

onMounted(() => {
  callVerifyEmail();
});
</script>
