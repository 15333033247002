import { get, patch, post } from "@/api/index";

import { MetaPagination } from "@/types/other";
import { EditRideObject, RideObject, RideSavedFile } from "@/types/rides";

export const getRides = (params?: {
  include: string;
  page: number;
  search?: string;
  orderBy?: string;
  sortedBy?: string;
}) => get<{ data: RideObject[]; meta: MetaPagination }>("rides", params);

export const getRide = (
  id: string,
  params?: {
    include: string;
  },
) => get<{ data: RideObject }>(`rides/${id}`, params);

export const updateRide = (
  id: string,
  data: EditRideObject,
  params?: { include: string },
) => patch<{ data: RideObject }>(`rides/${id}`, data, params);

export const setRideFile = (file: File) =>
  post<{ data: RideSavedFile }>(`rides/upload`, { file }, true);
