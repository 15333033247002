<template>
  <v-combobox
    v-model="languages"
    :error-messages="props.errorMessages || []"
    :rules="props.rules || []"
    :label="$t('language')"
    :items="languagesList"
    :return-object="false"
    item-value="value"
    item-title="title"
    multiple
    autocomplete="off"
    :readonly="props.readonly"
    :variant="props.readonly ? 'filled' : 'outlined'"
    :bg-color="props.bgColor"
    :loading="props.loading"
    :clearable="props.clearable"
  >
    <template #chip="{ item }">
      <v-chip
        :key="item.value"
        :readonly="props.readonly"
        closable
        @click:close="removeLanguage(item.value)"
      >
        {{ item.title }}
      </v-chip>
    </template>
  </v-combobox>
</template>

<script setup lang="ts">
import { Languages } from "@/types/auth";
import { computed } from "vue";
import { getLanguageKey } from "@/utils/auth";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  modelValue?: Languages[];
  rules?: any[];
  readonly?: boolean;
  loading?: boolean;
  errorMessages?: string[];
  clearable?: boolean;
  bgColor?: string;
}>();

const languages = computed<Languages[]>({
  get() {
    return props.modelValue || [];
  },
  set(value: Languages[]) {
    emit(
      "update:modelValue",
      value.filter((l) => languagesList.value.some(({ value }) => value === l)),
    );
  },
});

const languagesList = computed(() =>
  Object.values(Languages).map((key: Languages) => ({
    value: key,
    title: t(getLanguageKey(key)),
  })),
);

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "update:modelValue", data: Languages[]): void;
}>();

const removeLanguage = (language: Languages) => {
  languages.value = languages.value?.filter((item) => item !== language);
};
</script>

<style scoped></style>
