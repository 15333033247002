import { store } from "@/store";

import { CurrentTokensObject, ProfileData } from "@/types/auth";
import { PrepareResponseObject } from "@/types/calculator";
import { DocumentObject } from "@/types/document";
import { FactorDay } from "@/types/settings";

const localStorageKeyPrefix = "BPL";
const localStorageVersion = "0.1.0";
const versionKey = "VERSION";

const setLocalStorage = (key: string, value: any) => {
  try {
    localStorage.setItem(
      `${localStorageKeyPrefix}_${key}`,
      JSON.stringify(value),
    );
  } catch (e: any) {
    console.error(e.message);
  }
};

const getLocalStorage = <T>(key: string): T | null => {
  const value = localStorage.getItem(`${localStorageKeyPrefix}_${key}`);
  let result = null;
  try {
    result = value ? JSON.parse(value) : value;
  } catch (e: any) {
    console.error(e.message);
  }
  return result;
};

const removeLocalStorage = (key: string): void => {
  localStorage.removeItem(`${localStorageKeyPrefix}_${key}`);
};

type LocConfig = {
  TOKENS: CurrentTokensObject;
  DRAWER_OPENED: boolean;
  CALCULATOR_STEP: number[];
  CURRENT_DOCUMENT_PREPARE: PrepareResponseObject;
  CURRENT_DOCUMENT_SEASON_FACTOR: FactorDay[];
  CURRENT_DOCUMENT: DocumentObject;
  PROFILE: ProfileData;
};

export const getLocValue = <K extends keyof LocConfig>(
  key: K,
): LocConfig[K] | null => getLocalStorage(key);
export const setLocValue = <K extends keyof LocConfig>(
  key: K,
  value: LocConfig[K] | null,
) => setLocalStorage(key, value);

export const syncLocalVersion = async () => {
  const currentVersion = getLocalStorage<string>(versionKey);
  if (currentVersion !== localStorageVersion) {
    Object.keys(localStorage).map((key) => {
      if (key.indexOf(`${localStorageKeyPrefix}_`) === 0) {
        removeLocalStorage(key.slice(`${localStorageKeyPrefix}_`.length));
      }
    });
    setLocalStorage(versionKey, localStorageVersion);
    await store.dispatch("auth/clearAuthData");
  }
};
