type SomeObjectWithId = { id: string };

export const addItem = <T extends SomeObjectWithId>(
  list: T[] = [],
  item: T,
): T[] => {
  return [item, ...list];
};
export const updateItem = <T extends SomeObjectWithId>(
  list: T[] = [],
  item: T,
) => {
  const newList = [...list];
  const index = list.findIndex((i) => i.id === item.id);
  newList.splice(index, 1, item);
  return newList;
};

export const removeItem = <T extends SomeObjectWithId>(
  list: T[] = [],
  id: string,
) => {
  const newList = [...list];
  const index = list.findIndex((i) => i.id === id);
  newList.splice(index, 1);
  return newList;
};
