import { DocumentSavedData } from "@/types/document";
import { Languages } from "@/types/auth";

export enum RideStatus {
  success = "success",
  warning = "warning",
  error = "error",
  transportCompleted = "transport_completed",
}

export enum RideLoadingStatus {
  wait = "wait", //ride.loading-status-wait
  waitingPapers = "waiting-papers", //ride.loading-status-waiting-papers
  hasPager = "has-pager", //ride.loading-status-has-pager
  loading = "loading", //ride.loading-status-loading
  loaded = "loaded", //ride.loading-status-loaded
}

export enum RideUnloadingStatus {
  wait = "wait", //ride.unloading-status-wait
  waiting = "waiting", //ride.unloading-status-waiting
  hasPager = "has-pager", //ride.unloading-status-has-pager
  unloading = "unloading", //ride.unloading-status-unloading
  unloaded = "unloaded", //ride.unloading-status-unloaded
}

export type RideDriverObject = {
  firstname?: string;
  lastname?: string;
  phone?: string;
  languages?: Languages[] | null;
  id?: string;
};

export type RideObject = {
  id: string;
  step: number;
  document: { data: DocumentSavedData };
  status: RideStatus;
  updated_at: string;

  //1 step
  step_1_drivers: RideDriverObject[] | null;
  step_1_gps_link: string;
  step_1_license_plate: string;
  step_1_arrival_time: string;
  step_1_trailer_license_plate: string;
  step_1_invalid_date: boolean;
  //2 step
  step_2_arrival_time: string;
  //3 step
  step_3_arrival_time: string;
  step_3_page: string;
  step_3_status: RideLoadingStatus;
  //4 step
  step_4_leave_point_time: string;
  step_4_scheduled_unloading_time: string;
  step_4_invalid_date: boolean;
  //5 step
  step_5_scheduled_unloading_time: string;
  //6 step
  step_6_arrived_unloading_time: string;
  step_6_status: RideUnloadingStatus;
  step_6_page: string;
  //7 step
  step_7_leave_unloading_time: string;
  step_7_files: { data: RideSavedFile[] };
};

export enum RideFirstBlockType {
  drivers,
  tracking,
  arrival,
}

export enum RideThirdBlockType {
  arrival,
  status,
}

export enum RideFourthBlockType {
  departure,
  arrival,
}

export enum RideSixthBlockType {
  arrival,
  status,
}

export type EditRideObject = {
  step?: number;
  status?: RideStatus;
  //1 step
  step_1_drivers?: RideDriverObject[];
  step_1_gps_link?: string;
  step_1_license_plate?: string;
  step_1_arrival_time?: string;
  step_1_trailer_license_plate?: string;
  step_1_invalid_date?: boolean;
  //2 step
  step_2_arrival_time?: string;
  //3 step
  step_3_arrival_time?: string;
  step_3_status?: RideLoadingStatus;
  step_3_page?: string;
  //4 step
  step_4_leave_point_time?: string;
  step_4_scheduled_unloading_time?: string;
  step_4_invalid_date?: boolean;
  //5 step
  step_5_scheduled_unloading_time?: string;
  //6 step
  step_6_arrived_unloading_time?: string;
  step_6_status?: RideUnloadingStatus;
  step_6_page?: string;
  //7 step
  step_7_leave_unloading_time?: string;
  step_7_files?: string[];
};

export type EditRideErrorObject = {
  //1 step
  step_1_drivers: string[];
  step_1_gps_link: string[];
  step_1_license_plate: string[];
  step_1_arrival_time: string[];
  step_1_trailer_license_plate: string[];
  //2 step
  step_2_arrival_time: string[];
  //3 step
  step_3_arrival_time: string[];
  step_3_page: string[];
  //4 step
  step_4_leave_point_time: string[];
  step_4_scheduled_unloading_time: string[];
  //5 step
  step_5_scheduled_unloading_time: string[];
  //6 step
  step_6_arrived_unloading_time: string[];
  step_6_status: string[];
  step_6_page: string[];
  //7 step
  step_7_leave_unloading_time: string[];
};

export type RideSavedFile = {
  id: string;
  name: string;
  url: string;
  updated_at: string;
};
