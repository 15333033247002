<template>
  <v-row class="my-0 pt-6" :class="{ 'pointer-events-none': props.readonly }">
    <v-col cols="4" class="py-0">
      <v-text-field
        :model-value="documentObject.goods_type"
        :error-messages="errors.goods_type"
        :rules="[rules.required]"
        :label="$t('type-goods')"
        clearable
        class="pb-2"
        :readonly="props.readonly"
        :variant="props.readonly ? 'filled' : 'outlined'"
        @update:model-value="vModalUpdate('goods_type', $event)"
      ></v-text-field>
    </v-col>
    <v-col cols="4" class="py-0">
      <v-text-field
        :model-value="documentObject.goods_weight"
        :error-messages="errors.goods_weight"
        :rules="[rules.required, rules.numberOnly, rules.valueMax(99999, 'kg')]"
        :label="$t('weight-goods')"
        append-inner-icon="mdi-weight-kilogram"
        clearable
        class="pb-2"
        :readonly="props.readonly"
        :variant="props.readonly ? 'filled' : 'outlined'"
        @update:model-value="vModalUpdate('goods_weight', $event)"
      ></v-text-field>
    </v-col>
    <v-col cols="4" class="py-0">
      <v-text-field
        :model-value="documentObject.goods_value"
        :error-messages="errors.goods_value"
        :rules="[rules.required, rules.numberOnly]"
        :label="$t('value-goods')"
        append-inner-icon="mdi-currency-eur"
        clearable
        class="pb-2"
        :readonly="props.readonly"
        :variant="props.readonly ? 'filled' : 'outlined'"
        @update:model-value="vModalUpdate('goods_value', $event)"
      ></v-text-field>
    </v-col>
    <v-col cols="4" class="py-0">
      <v-text-field
        :model-value="documentObject.goods_length"
        :error-messages="errors.goods_length"
        :rules="[
          rules.required,
          rules.floatNumberOnly,
          rules.valueMax(99.99, 'm'),
        ]"
        :label="$t('length')"
        type="number"
        hide-spin-buttons
        clearable
        class="pb-2"
        :readonly="props.readonly"
        :variant="props.readonly ? 'filled' : 'outlined'"
        @update:model-value="vModalUpdate('goods_length', $event)"
      ></v-text-field>
    </v-col>
    <v-col cols="4" class="py-0">
      <v-text-field
        :model-value="documentObject.goods_width"
        :error-messages="errors.goods_width"
        :rules="[
          rules.required,
          rules.floatNumberOnly,
          rules.valueMax(9.99, 'm'),
        ]"
        :label="$t('width')"
        type="number"
        hide-spin-buttons
        clearable
        class="pb-2"
        :readonly="props.readonly"
        :variant="props.readonly ? 'filled' : 'outlined'"
        @update:model-value="vModalUpdate('goods_width', $event)"
      ></v-text-field>
    </v-col>
    <v-col cols="4" class="py-0">
      <v-text-field
        :model-value="documentObject.goods_height"
        :error-messages="errors.goods_height"
        :rules="[
          rules.required,
          rules.floatNumberOnly,
          rules.valueMax(9.99, 'm'),
        ]"
        :label="$t('height')"
        type="number"
        hide-spin-buttons
        clearable
        class="pb-2"
        :readonly="props.readonly"
        :variant="props.readonly ? 'filled' : 'outlined'"
        @update:model-value="vModalUpdate('goods_height', $event)"
      ></v-text-field>
    </v-col>
    <v-col cols="12" class="py-0">
      <v-tooltip text="Lorem Ipsum" location="bottom" offset="-32px">
        <template #activator="{ props: tooltipProps }">
          <div v-bind="tooltipProps" class="mr-6">
            <v-checkbox
              :model-value="documentObject.goods_insurance"
              :error-messages="errors.goods_insurance"
              :label="$t('insurance')"
              color="primary"
              class="bpl-underlined-field"
              :disabled="props.readonly"
              @update:model-value="vModalUpdate('goods_insurance', $event)"
            ></v-checkbox>
          </div>
        </template>
      </v-tooltip>
    </v-col>
    <v-col cols="12" class="pt-0 pb-8">
      <v-alert
        v-if="documentObject.goods_insurance"
        :text="$t('obtain-insurance-text')"
        color="info"
        variant="tonal"
      />
      <v-alert
        v-else
        :text="$t('waived-insurance-text')"
        color="warning"
        variant="tonal"
        icon="mdi-alert-outline"
      />
    </v-col>
    <template v-if="documentObject.goods_insurance">
      <v-col cols="4" class="py-0">
        <v-select
          :model-value="documentObject.goods_product_group"
          :error-messages="errors.goods_product_group"
          autocomplete="off"
          :rules="[rules.required]"
          :label="$t('product-group')"
          :items="goodsProductGroups"
          item-value="value"
          item-title="title"
          class="pb-2"
          :readonly="props.readonly"
          :variant="props.readonly ? 'filled' : 'outlined'"
          @update:model-value="vModalUpdate('goods_product_group', $event)"
        >
        </v-select>
      </v-col>
      <v-col cols="8" class="py-0">
        <div class="d-flex align-center">
          <v-icon icon="mdi-information" color="primary"></v-icon>
          <div class="ml-4">
            <div>{{ $t("product-group-text") }}</div>
            <router-link
              to="/warengruppen"
              target="_blank"
              class="text-primary text-decoration-none"
            >
              {{ $t("product-group-link") }}
            </router-link>
          </div>
        </div>
      </v-col>
    </template>
  </v-row>
</template>

<script setup lang="ts">
import { computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";

import { useRules } from "@/utils/rules";
import {
  getLocalDocumentObject,
  getGoodsProductGroupTitle,
  getInitialDocumentErrorObject,
} from "@/utils/calculator";

import {
  DocumentErrorObject,
  DocumentObject,
  GoodsProductGroup,
} from "@/types/document";

const rules = useRules();
const store = useStore();
const { t } = useI18n();

const props = defineProps<{
  modelValue?: DocumentObject;
  readonly?: boolean;
  errors?: DocumentErrorObject;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", data?: DocumentObject): void;
  (e: "update:errors", data: DocumentErrorObject): void;
}>();

const documentObject = computed(
  () => props.modelValue || getLocalDocumentObject(),
);

const errors = computed<DocumentErrorObject>({
  get() {
    return props.errors || getInitialDocumentErrorObject();
  },
  set(value?: DocumentErrorObject): void {
    emit("update:errors", value || getInitialDocumentErrorObject());
  },
});

const goodsProductGroups = computed(() => {
  return Object.values(GoodsProductGroup).map((key: GoodsProductGroup) => {
    const titleObject = getGoodsProductGroupTitle(key);

    return {
      value: key,
      title: `${t(titleObject.key)} ${titleObject.number}`,
    };
  });
});

const currentVehicle = computed(() =>
  store.state.app.vehicles.find(
    ({ code }) => code === documentObject.value.transport_type_id,
  ),
);

const priceOnRequestOverpriced = computed(() => {
  const maxKgValue = 49;
  const goodsWeight = Number(documentObject.value.goods_weight);
  const goodsValue = Number(documentObject.value.goods_value);
  if (goodsWeight && goodsValue) {
    const kgValue = goodsValue / goodsWeight;
    if (kgValue > maxKgValue) {
      return true;
    }
  }
  return false;
});

const priceOnRequestOverweight = computed(() => {
  const maxWeight = 24500;
  const goodsWeight = Number(documentObject.value.goods_weight);
  if (goodsWeight > maxWeight) {
    return true;
  }

  if (currentVehicle.value) {
    const vehicleCapacity = Number(currentVehicle.value.capacity);
    if (goodsWeight > vehicleCapacity) {
      return true;
    }
  }

  return false;
});

const priceOnRequestOversize = computed(() => {
  if (currentVehicle.value) {
    const goodsLength = Number(documentObject.value.goods_length);
    const vehicleLength = Number(currentVehicle.value.length);
    if (goodsLength > vehicleLength) {
      return true;
    }

    const goodsWidth = Number(documentObject.value.goods_width);
    const vehicleWidth = Number(currentVehicle.value.width);
    if (goodsWidth > vehicleWidth) {
      return true;
    }

    const goodsHeight = Number(documentObject.value.goods_height);
    const vehicleHeight = Number(currentVehicle.value.height);
    if (goodsHeight > vehicleHeight) {
      return true;
    }
  }

  return false;
});

const vModalUpdate = (field: keyof DocumentObject, value: any) => {
  errors.value[field as keyof DocumentErrorObject] = [];
  emit("update:modelValue", {
    ...documentObject.value,
    [field]: value,
  });
};

watch(
  priceOnRequestOverpriced,
  (value) => {
    store.commit("calculator/setPriceOnRequestOverpriced", value);
  },
  { immediate: true },
);

watch(priceOnRequestOverweight, (value) => {
  store.commit("calculator/setPriceOnRequestOverweight", value);
});

watch(priceOnRequestOversize, (value) => {
  store.commit("calculator/setPriceOnRequestOversize", value);
});
</script>

<style scoped></style>
