<template>
  <v-form ref="formRef" v-model="valid" @submit.prevent="saveChanges">
    <template v-if="isBplAdmin || isTransporter">
      <v-alert
        v-if="props.editable"
        :text="$t('alert.ride-step1-required')"
        color="warning"
        variant="tonal"
        class="my-8"
        icon="mdi-alert-outline"
      />
    </template>
    <editable-block
      v-model="isEditable"
      :loading="loading"
      submit
      @cancel="cancelChanges"
    >
      <template #title>
        <p class="text-h5 text-primary py-2">
          {{ $t("ride.estimated-time") }}
        </p>
      </template>
      <template #edit-btn="{ edit }">
        <v-btn
          v-if="props.editable && (isBplAdmin || isTransporter)"
          variant="text"
          elevation="0"
          color="primary"
          icon="mdi-file-edit-outline"
          @click="edit"
        />
      </template>
      <template #default="{ readonly }">
        <v-row class="my-0 pt-4" :class="{ 'pointer-events-none': readonly }">
          <v-col cols="12" class="py-0">
            <calendar-field
              v-model="editRide.step_2_arrival_time"
              :title="$t('ride.scheduled-arrival-loading-point')"
              class="pb-2"
              :chip-format="locFormat.dDMmYHhMmUhr"
              :readonly="readonly"
              :rules="[rules.required]"
              :error-messages="errors.step_2_arrival_time"
              @update:model-value="errors.step_2_arrival_time = []"
            />
          </v-col>
        </v-row>
      </template>
    </editable-block>
  </v-form>
  <div
    v-if="props.editable && (isBplAdmin || isTransporter)"
    class="d-flex justify-end"
  >
    <v-btn
      type="button"
      color="primary"
      size="large"
      :disabled="isEditable"
      :loading="manuallyLoading"
      @click="switchManually"
    >
      {{ $t("button.switch-manually") }} (DEBUG)
    </v-btn>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, ref, watch } from "vue";

import { updateRide } from "@/api/rides";

import { access } from "@/utils/access";
import { useValidHelper } from "@/utils/validHelper";
import { useMountScroller } from "@/utils/mountScroller";
import { getInitialEditRideErrorObject } from "@/utils/rides";
import { snackbar } from "@/utils/snackbar";
import { useRules } from "@/utils/rules";
import { useLocFormat } from "@/utils/locFormat";

import { VForm } from "vuetify/components";
import { EditRideObject, RideObject } from "@/types/rides";

import CalendarField from "@/components/CalendarField.vue";
import EditableBlock from "@/components/EditableBlock.vue";

const include = "document.carrier.users";

const rules = useRules();
const locFormat = useLocFormat();

const isEditable = ref(false);

const props = defineProps<{
  ride: RideObject;
  editable?: boolean;
}>();

const editRide = ref<EditRideObject>({}); //step_2_arrival_time
const errors = ref(getInitialEditRideErrorObject());

useMountScroller();

const valid = ref(false);
const formRef = ref<VForm>();
const loading = ref(false);

const manuallyLoading = ref(false);

useValidHelper(formRef);

const syncEdits = (value: RideObject) => {
  editRide.value.step_2_arrival_time = value.step_2_arrival_time;
  errors.value.step_2_arrival_time = [];
};

const cancelChanges = () => {
  nextTick(() => {
    syncEdits(props.ride);
  });
};

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "updateRide", data: RideObject): void;
}>();

const saveChanges = async () => {
  try {
    loading.value = true;

    const { data } = await updateRide(props.ride.id, editRide.value, {
      include,
    });
    isEditable.value = false;
    emit("updateRide", data);
  } catch (e: any) {
    errors.value = {
      ...getInitialEditRideErrorObject(),
      ...e.errors,
    };
  } finally {
    loading.value = false;
  }
};

const isBplAdmin = computed(() =>
  access.someRoles(["admin", "sub-admin", "bpl-manager"]),
);

const isTransporter = computed(() =>
  access.someRoles([
    "forwarder",
    "sub-forwarder",
    "planner",
    "accounting-contractor",
    "driver",
    "pallet-department-contractor",
  ]),
);

const switchManually = async () => {
  await formRef.value?.validate();
  if (valid.value) {
    try {
      manuallyLoading.value = true;
      const { data } = await updateRide(
        props.ride.id,
        {
          step: 3,
        },
        {
          include,
        },
      );

      emit("updateRide", data);
    } catch (e: any) {
      snackbar(e.message);
    } finally {
      manuallyLoading.value = false;
    }
  }
};

watch(
  () => props.ride,
  (value) => {
    syncEdits(value);
  },
  { immediate: true },
);
</script>

<style scoped></style>
