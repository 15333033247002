import { del, get, post, put } from "@/api";

import {
  CountryPointObject,
  DeliveryFactor,
  EditDeliveryFactor,
  EditFactorDay,
  EditFeature,
  EditPallet,
  EditSettingsObject,
  EditVehicle,
  FactorDay,
  FerryObject,
  MarketFactor,
  Pallet,
  SettingsObject,
  Vehicle,
} from "@/types/settings";
import { MetaPagination } from "@/types/other";
import { FeatureObject } from "@/types/settings";

export const getSeasonFactors = (month: string) =>
  get<{ data: FactorDay[] }>(`settings/season_factors/${month}`);

export const setSeasonFactor = (
  value: EditFactorDay,
  startDate: string,
  endDate: string,
) =>
  post<{ data: FactorDay[] }>(
    `settings/season_factors/${startDate}/${endDate}`,
    value,
  );

export const getPallets = () => get<{ data: Pallet[] }>(`settings/pallets`);

export const updatePallet = (id: string, pallet: EditPallet) =>
  put<{ data: Pallet }>(`settings/pallets/${id}`, pallet);

export const getVehicles = () =>
  get<{ data: Vehicle[] }>(`settings/transport_types`);

export const updateVehicle = (id: string, transportType: EditVehicle) =>
  put<{ data: Vehicle }>(`settings/transport_types/${id}`, transportType);

export const createVehicle = (transportType: EditVehicle) =>
  post<{ data: Vehicle }>(`settings/transport_types`, transportType);

export const removeVehicle = (id: string) =>
  del(`settings/transport_types/${id}`);

export const getMarketFactors = () =>
  get<{ data: MarketFactor[] }>(`settings/market_factors`);

export const setMarketFactors = (csv: File) =>
  post(`settings/market_factors`, { csv }, true);

export const setDeliveryFactors = (csv: File) =>
  post(`settings/lzf`, { csv }, true);

export const getSettings = () => get<{ data: SettingsObject }>(`settings`);

export const updateSettings = (settings: EditSettingsObject) =>
  post<{ data: SettingsObject }>(`settings`, settings);

export const getCountryPoints = (params?: {
  page: number;
  search: string;
  searchJoin?: string;
  orderBy?: string;
  sortedBy?: string;
}) =>
  get<{ data: CountryPointObject[]; meta: MetaPagination }>(
    `settings/country_points`,
    params,
  );

export const setCountryPoints = (csv: File) =>
  post(`settings/country_points`, { csv }, true);

export const getFerry = (params?: {
  page: number;
  search: string;
  searchJoin?: string;
  orderBy?: string;
  sortedBy?: string;
}) =>
  get<{ data: FerryObject[]; meta: MetaPagination }>(`settings/ferry`, params);

export const setFerry = (csv: File) => post(`settings/ferry`, { csv }, true);

export const getSpecialFeatures = () =>
  get<{ data: FeatureObject[] }>(`settings/special_features`);

export const updateSpecialFeature = (id: string, feature: EditFeature) =>
  put<{ data: FeatureObject }>(`settings/special_features/${id}`, feature);

export const getDeliveryFactors = (params?: {
  page: number;
  search: string;
  searchJoin?: string;
}) =>
  get<{ data: DeliveryFactor[]; meta: MetaPagination }>(`settings/lzf`, params);

export const updateDeliveryFactor = (id: string, feature: EditDeliveryFactor) =>
  put<{ data: DeliveryFactor }>(`settings/lzf/${id}`, feature);
