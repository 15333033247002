<template>
  <v-row no-gutters>
    <v-col cols="8" class="pa-10">
      <v-sheet class="pb-12 pt-11 px-10 rounded-xl bpl-border-gray">
        <div class="d-flex justify-space-between align-center">
          <div class="d-flex align-center">
            <v-btn
              icon="mdi-keyboard-backspace"
              variant="plain"
              :to="{ name: 'marketplace' }"
              class="mr-4"
            />
            <template v-if="documentObject">
              <base-chip
                v-if="documentObject.express"
                color="#4CAF50"
                class="text-capitalize py-1 px-3 mr-4"
              >
                {{ $t("express") }}
              </base-chip>
              <router-link
                v-if="isBplAdmin"
                :to="{ name: 'order', params: { id: documentObject.id } }"
                class="text-decoration-none"
              >
                <base-chip
                  color="#C2FFDB"
                  border-color="#27AE60"
                  background-color="#27AE60"
                  class="text-capitalize py-1 px-3"
                >
                  <span>{{ $t("order") }}</span>
                  <v-icon
                    icon="mdi-open-in-new"
                    color="#C2FFDB"
                    class="ml-1"
                  ></v-icon>
                </base-chip>
              </router-link>
            </template>
          </div>
        </div>
        <template v-if="documentObject">
          <div class="mt-7">
            <p class="text-h5 text-primary py-2">
              {{ $t("route-data") }}
            </p>
            <div class="pt-6">
              <v-chip>
                <span class="text-grey-darken-2">
                  {{ $t("vignette-avoidance") }}:
                  <span class="text-grey-darken-4">
                    {{
                      documentObject.vignette ? $t("text.yes") : $t("text.no")
                    }}
                  </span>
                </span>
              </v-chip>
              <v-chip class="ml-4">
                <span class="text-grey-darken-2">
                  {{ $t("duration") }}:
                  <span class="text-grey-darken-4">
                    {{ formatDuration }}
                  </span>
                </span>
              </v-chip>
              <v-chip v-if="calcData" class="ml-4">
                <span class="text-grey-darken-2">
                  {{ $t("route-data") }}:
                  <span class="text-grey-darken-4">
                    {{ calcData.distance }} km
                  </span>
                </span>
              </v-chip>
            </div>
            <div v-if="metaPoints" class="pointer-events-none">
              <route-point-marketplace
                v-for="(point, index) in metaPoints"
                :key="point.id"
                :point="point"
                :document="documentObject"
                :index="index"
                :is-last="index === metaPoints.length - 1"
              />
            </div>
          </div>
          <v-divider />
          <p class="text-h5 text-primary mt-10 mb-8">
            {{ $t("offer-data") }}
          </p>
          <div>
            <v-chip>
              <span class="text-grey-darken-2">
                {{ $t("date-created") }}:
                <span class="text-grey-darken-4">
                  {{ createdAt }}
                </span>
              </span>
            </v-chip>
            <v-chip v-if="calcData" class="ml-4">
              <span class="text-grey-darken-2">
                {{ $t("number-drivers") }}:
                <span class="text-grey-darken-4">
                  {{ calcData.drivers }}
                </span>
              </span>
            </v-chip>
            <v-chip class="ml-4">
              <span class="text-grey-darken-2">
                {{ $t("pallet-exchange") }}:
                <span class="text-grey-darken-4">
                  {{ documentObject.pallet.data.name }}
                </span>
              </span>
            </v-chip>
          </div>
          <v-row class="my-0 pt-8 pointer-events-none">
            <v-col
              :cols="!documentObject.unloading_time_end ? 12 : 6"
              class="py-0"
            >
              <calendar-field
                v-model="documentObject.loading_time_start"
                :title="$t('loading-time')"
                class="pb-2"
                readonly
              />
            </v-col>
            <v-col
              v-if="!!documentObject.loading_time_end"
              cols="6"
              class="bpl-hyphen-col py-0"
            >
              <calendar-field
                v-model="documentObject.loading_time_end"
                :title="$t('loading-time')"
                class="pb-2"
                readonly
              />
            </v-col>
            <v-col
              :cols="!documentObject.unloading_time_end ? 12 : 6"
              class="py-0"
            >
              <calendar-field
                v-model="documentObject.unloading_time_start"
                :title="$t('discharge-time')"
                class="pb-2"
                readonly
              />
            </v-col>
            <v-col
              v-if="documentObject.unloading_time_end"
              cols="6"
              class="bpl-hyphen-col py-0"
            >
              <calendar-field
                v-model="documentObject.unloading_time_end"
                :title="$t('discharge-time')"
                class="pb-2"
                readonly
              />
            </v-col>
            <v-col cols="6" class="py-0">
              <fake-input
                :label="$t('vehicle-type')"
                class="pb-2"
                :value="documentObject.vehicle.data.name"
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <v-combobox
                variant="outlined"
                readonly
                :label="$t('equipment')"
                :model-value="
                  documentObject.vehicle.data.equipment?.split(',') || []
                "
                multiple
                chips
              />
            </v-col>
            <v-col v-if="spCalcList.length" cols="12" class="py-0">
              <v-combobox
                variant="outlined"
                readonly
                :label="$t('special-features')"
                :model-value="spCalcList"
                multiple
                chips
              >
                <template #chip="{ item }">
                  <v-chip>
                    <span class="text-grey-darken-2">
                      {{ item.title ? `${item.title}:` : "" }}
                      <span class="text-grey-darken-4">
                        {{ item.value }}
                      </span>
                    </span>
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
          <v-divider />
          <p class="text-h5 text-primary mt-10">
            {{ $t("goods") }}
          </p>
          <v-row class="my-0 pt-8">
            <v-col cols="4" class="py-0">
              <fake-input
                :label="$t('type-goods')"
                class="pb-2"
                :value="documentObject.goods_type"
              />
            </v-col>
            <v-col cols="4" class="py-0">
              <fake-input
                :label="$t('value-goods')"
                class="pb-2"
                :value="formatGoodsValue"
                append-inner-icon="mdi-currency-eur"
              />
            </v-col>
            <v-col cols="4" class="py-0">
              <fake-input
                :label="$t('weight-goods')"
                class="pb-2"
                :value="formatGoodsWeight"
                append-inner-icon="mdi-weight-kilogram"
              />
            </v-col>
            <v-col cols="4" class="py-0">
              <fake-input
                :label="$t('length')"
                class="pb-2"
                :value="formatGoodsLength"
              />
            </v-col>
            <v-col cols="4" class="py-0">
              <fake-input
                :label="$t('width')"
                class="pb-2"
                :value="formatGoodsWidth"
              />
            </v-col>
            <v-col cols="4" class="py-0">
              <fake-input
                :label="$t('height')"
                class="pb-2"
                :value="formatGoodsHeight"
              />
            </v-col>
            <template v-if="!isTransporter">
              <v-col cols="12" class="py-0">
                <v-tooltip text="Lorem Ipsum" location="bottom" offset="-32px">
                  <template #activator="{ props: tooltipProps }">
                    <div v-bind="tooltipProps" class="d-inline-block">
                      <v-checkbox
                        v-model="documentObject.goods_insurance"
                        disabled
                        :label="$t('insurance')"
                        color="primary"
                        class="bpl-underlined-field"
                      ></v-checkbox>
                    </div>
                  </template>
                </v-tooltip>
              </v-col>
              <v-col cols="12" class="pt-0 pb-8">
                <v-alert
                  v-if="documentObject.goods_insurance"
                  :text="$t('obtain-insurance-text')"
                  color="info"
                  variant="tonal"
                />
                <v-alert
                  v-else
                  :text="$t('waived-insurance-text')"
                  color="warning"
                  variant="tonal"
                  icon="mdi-alert-outline"
                />
              </v-col>
              <template v-if="documentObject.goods_insurance">
                <v-col cols="4" class="py-0">
                  <fake-input
                    :label="$t('product-group')"
                    class="pb-2"
                    :value="goodsProductGroupTitle"
                  />
                </v-col>
                <v-col cols="8" class="py-0">
                  <div class="d-flex align-center">
                    <v-icon icon="mdi-information" color="primary"></v-icon>
                    <div class="ml-4">
                      <div>{{ $t("product-group-text") }}</div>
                      <router-link
                        to="/warengruppen"
                        target="_blank"
                        class="text-primary text-decoration-none"
                      >
                        {{ $t("product-group-link") }}
                      </router-link>
                    </div>
                  </div>
                </v-col>
              </template>
            </template>
          </v-row>
          <v-divider />
          <p class="text-h5 text-primary mt-10">
            {{ $t("payment-target") }}
          </p>
          <v-row class="my-0 pt-8">
            <v-col cols="4" class="py-0">
              <fake-input
                :label="$t('payment-target')"
                class="pb-2"
                :value="paymentData"
              />
            </v-col>
          </v-row>
          <v-divider />
        </template>
        <app-loader v-else-if="loading" />
      </v-sheet>
    </v-col>
    <v-col cols="4" class="pa-10 pl-0">
      <v-sheet class="py-12 px-10 rounded-xl bpl-border-gray">
        <p class="text-h5 text-primary mb-8">
          {{ $t("order-amount") }}
        </p>
        <app-loader v-if="loading" height="76px" />
        <div v-else>
          <p class="text-h4 font-weight-bold text-primary mb-2">
            {{ formatTransporterPrice }} €
          </p>
          <p class="text-subtitle-1">
            {{ $t("text.calculator-vat") }}
          </p>
        </div>
        <v-btn
          v-if="
            access.someRoles([
              'forwarder',
              'sub-forwarder',
              'planner',
              'accounting-contractor',
              'pallet-department-contractor',
            ])
          "
          color="primary"
          size="large"
          :loading="loading"
          :disabled="loading"
          class="mt-8"
          @click="confirmDocument()"
        >
          {{ $t("button.accept-order") }}
        </v-btn>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import moment from "moment";
import { useStore } from "@/store";

import { getMarketplace, confirmMarketplace } from "@/api/marketplace";

import { snackbar } from "@/utils/snackbar";
import { getPaymentTargetName, useFormat } from "@/utils/other";
import { useLocFormat } from "@/utils/locFormat";
import {
  getInitialDocumentCalcObject,
  getGoodsProductGroupTitle,
} from "@/utils/calculator";
import { useCalcList } from "@/utils/calcList";
import { access } from "@/utils/access";
import { useConfirm } from "@/utils/confirmHelper";

import { DocumentCalcObject } from "@/types/calculator";
import { MarketplaceData } from "@/types/marketplace";

import CalendarField from "@/components/CalendarField.vue";
import FakeInput from "@/components/FakeInput.vue";
import AppLoader from "@/components/AppLoader.vue";
import BaseChip from "@/components/BaseChip.vue";
import RoutePointMarketplace from "@/components/MarketplaceView/RoutePointMarketplace.vue";

const { t, locale } = useI18n();
const router = useRouter();
const store = useStore();

const locFormat = useLocFormat();
const confirmModal = useConfirm("dialog.document-accept-order", {
  text: "dialog.document-accept-order-text",
});

const props = defineProps<{
  id: string;
}>();

const include = "vehicle,points.country,pallet";

const documentObject = ref<MarketplaceData>();
const loading = ref(true);

const calcData = computed(() => {
  let result = getInitialDocumentCalcObject();
  try {
    if (documentObject.value)
      result = JSON.parse(documentObject.value.calc) as DocumentCalcObject;
  } catch (e: any) {
    console.error(e.message);
  }
  return result;
});

const palletCode = computed(() => documentObject.value?.pallet.data.code);
const toFormat = useFormat();
const { spCalcList } = useCalcList(calcData, palletCode);

const formatDuration = computed(() =>
  calcData.value
    ? moment
        .duration(calcData.value?.duration, "hours")
        .locale(locale.value)
        .humanize({ h: 48, d: 7, w: 4 })
    : "",
);

const createdAt = computed(() =>
  documentObject.value
    ? moment(documentObject.value?.created_at).format(locFormat.value.yMmDd)
    : null,
);

const paymentData = computed(() => {
  const value = documentObject.value?.payment_target;
  return value ? t(getPaymentTargetName(value)) : "";
});

const formatTransporterPrice = computed(() =>
  toFormat(documentObject.value?.transporter_price || 0, 2),
);

const metaParse = (value?: string) => {
  const defMeta =
    '{ "neutral_shipping": {"active": false }, "another_branch": {"active": false } }';
  try {
    return JSON.parse(value || defMeta);
  } catch (e: any) {
    snackbar(e.message);
  }
};

const metaPoints = computed(() =>
  (documentObject.value?.points.data || []).map((item) => ({
    ...item,
    company_name: item.company_name || "",
    meta: metaParse(item.meta),
  })),
);

const isBplAdmin = computed(() =>
  access.someRoles([
    "admin",
    "sub-admin",
    "bpl-manager",
    "bpl-calculation-manager",
  ]),
);

const isTransporter = computed(() =>
  access.someRoles([
    "forwarder",
    "sub-forwarder",
    "planner",
    "accounting-contractor",
    "driver",
    "pallet-department-contractor",
  ]),
);

const userLocale = computed(() => store.state.auth.profile?.locale);

const goodsProductGroupTitle = computed(() => {
  const titleObject = getGoodsProductGroupTitle(
    documentObject.value?.goods_product_group || undefined,
  );
  return `${t(titleObject.key)} ${titleObject.number}`;
});

const formatGoodsValue = computed(() =>
  toFormat(documentObject.value?.goods_value),
);
const formatGoodsWeight = computed(() =>
  toFormat(documentObject.value?.goods_weight),
);
const formatGoodsLength = computed(() =>
  toFormat(documentObject.value?.goods_length),
);
const formatGoodsWidth = computed(() =>
  toFormat(documentObject.value?.goods_width),
);
const formatGoodsHeight = computed(() =>
  toFormat(documentObject.value?.goods_height),
);

const backToList = async () => {
  await router.push({
    name: "marketplace",
  });
};

const fetchDocument = async (id: string) => {
  try {
    loading.value = true;

    const { data } = await getMarketplace(id, {
      include,
    });
    documentObject.value = data;
  } catch (e: any) {
    snackbar(e.message);
    await backToList();
  } finally {
    loading.value = false;
  }
};

const confirmDocument = async () => {
  if ((await confirmModal()) && documentObject.value?.id) {
    loading.value = true;
    try {
      const { data } = await confirmMarketplace(documentObject.value.id);
      await router.push({
        name: "order",
        params: { id: data.id },
      });
    } catch (e: any) {
      snackbar(e.message);
    } finally {
      loading.value = false;
    }
  }
};

watch(
  userLocale,
  () => {
    fetchDocument(props.id);
  },
  { immediate: true },
);
</script>

<style scoped></style>
