import { get, post } from "@/api";
import {
  LocObjectWrap,
  CountryObjectRaw,
  DataBaseNotification,
  ExportNotificationType,
  ExportResponse,
} from "@/types/other";

export const getLocs = () => get<{ data: LocObjectWrap[] }>("localizations");

export const getCountries = () =>
  get<{ data: CountryObjectRaw[] }>("countries");

export const getNotifications = () =>
  get<{ data: DataBaseNotification[] }>("notifications");

export const markAsReadNotifications = () => get("notifications/markAsRead");

export const exportSome = (ids: string[], type: ExportNotificationType) =>
  post<ExportResponse>(`export`, { ids, type });
