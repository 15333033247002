import { EditRideErrorObject } from "@/types/rides";

export const getInitialEditRideErrorObject = (): EditRideErrorObject => ({
  //1 step
  step_1_drivers: [],
  step_1_license_plate: [],
  step_1_gps_link: [],
  step_1_arrival_time: [],
  step_1_trailer_license_plate: [],
  //2 step
  step_2_arrival_time: [],
  //3 step
  step_3_arrival_time: [],
  step_3_page: [],
  //4 step
  step_4_leave_point_time: [],
  step_4_scheduled_unloading_time: [],
  //5 step
  step_5_scheduled_unloading_time: [],
  //6 step
  step_6_arrived_unloading_time: [],
  step_6_status: [],
  step_6_page: [],
  //7 step
  step_7_leave_unloading_time: []
});
