<template>
  <v-row no-gutters>
    <v-col cols="12" class="pa-10">
      <v-sheet class="py-12 px-10 rounded-xl bpl-border-gray">
        <div class="d-flex justify-space-between align-center">
          <p class="text-h4 text-primary font-weight-light">
            {{ $t("rides") }}
          </p>
        </div>
        <div class="mt-10 bpl-border-gray rounded-lg">
          <v-row no-gutters class="align-center px-4 py-3">
            <v-col cols="4"></v-col>
            <v-col cols="4">
              <v-text-field
                v-model="globSearch"
                :label="$t('search')"
                variant="outlined"
                append-inner-icon="mdi-magnify"
                hide-details
                clearable
              />
            </v-col>
            <v-col cols="4" class="text-right">
              <v-btn
                elevation="0"
                variant="text"
                class="text-capitalize text-grey-darken-2"
                :class="{ 'text-primary': isAdvancedSearch }"
                :append-icon="!isFormOpened ? 'mdi-menu-down' : 'mdi-menu-up'"
                @click="isFormOpened = !isFormOpened"
                >{{ $t("advanced-search-settings") }}</v-btn
              >
            </v-col>
          </v-row>
          <div v-if="isFormOpened" class="bg-grey-lighten-5 px-4 pt-8">
            <v-row class="my-0">
              <v-col cols="4" class="py-0">
                <v-text-field
                  v-model="searchObject.number"
                  :label="$t('transport-order-number')"
                  class="pb-2"
                  variant="outlined"
                  append-inner-icon="mdi-magnify"
                  bg-color="white"
                  clearable
                >
                </v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-text-field
                  v-model="searchObject.loadingData"
                  :label="`${t('loading-point')} / ${t('loading-time')}`"
                  class="pb-2"
                  variant="outlined"
                  append-inner-icon="mdi-magnify"
                  bg-color="white"
                  clearable
                >
                </v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-text-field
                  v-model="searchObject.unloadingData"
                  :label="`${t('unloading-point')} / ${t('discharge-time')}`"
                  class="pb-2"
                  variant="outlined"
                  append-inner-icon="mdi-magnify"
                  bg-color="white"
                  clearable
                >
                </v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-text-field
                  v-model="searchObject.transporter"
                  :label="$t('transporter')"
                  class="pb-2"
                  variant="outlined"
                  append-inner-icon="mdi-magnify"
                  bg-color="white"
                  clearable
                >
                </v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-text-field
                  v-model="searchObject.planner"
                  :label="$t('planner')"
                  class="pb-2"
                  variant="outlined"
                  append-inner-icon="mdi-magnify"
                  bg-color="white"
                  clearable
                >
                </v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-select
                  v-model="searchObject.status"
                  autocomplete="off"
                  clearable
                  :label="$t('status')"
                  :items="
                    Object.values(RideStatus).map((status) => ({
                      value: status,
                      title: $t(status),
                    }))
                  "
                  variant="outlined"
                  class="pb-2"
                  bg-color="white"
                >
                </v-select>
              </v-col>
            </v-row>
          </div>
          <v-data-table-server
            :headers="headers.filter((h) => !h.hidden)"
            :items="prepRides"
            class="bpl-custom-table-footer elevation-0 border-0 align-self-start bpl-table-height-1096"
            :items-per-page="pagData.per_page"
            :page="pagData.current_page"
            :items-length="pagData.total"
            :items-per-page-options="[]"
            :loading="loading"
            @click:row="clickRow"
            @update:options="
              fetchRides($event.page, finalSearchString, $event.sortBy[0])
            "
          >
            <template #[`item.status`]="{ item }">
              <div class="d-flex justify-center">
                <ride-status-point :status="item.status" />
              </div>
            </template>
          </v-data-table-server>
        </div>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import moment from "moment";
import { useI18n } from "vue-i18n";

import { getRides } from "@/api/rides";

import { snackbar } from "@/utils/snackbar";
import { initialPagData, joinWithDots, prepSearchString } from "@/utils/other";
import { useWatchDebounceHelper } from "@/utils/watchDebounceHelper";
import { useLocFormat } from "@/utils/locFormat";

import { SortData } from "@/types/other";

import { RideObject, RideStatus } from "@/types/rides";
import { useStore } from "@/store";
import RideStatusPoint from "@/components/RideStatusPoint.vue";
import { useRouter } from "vue-router";

const router = useRouter();
const { t } = useI18n();

const store = useStore();

const include =
  "document.loadingPoint.country,document.unloadingPoint.country,document.contact,document.carrier,document.carrierContact";

const rides = ref<RideObject[]>([]);
const sortData = ref<SortData>();
const pagData = ref(initialPagData);

const isFormOpened = ref(false);

const loading = ref(false);

const headers = computed(
  () =>
    [
      {
        title: t("transport-order-number"),
        key: "number",
        sortable: true,
      },
      {
        title: `${t("loading-point")} / ${t("loading-time")}`,
        key: "loadingData",
        sortable: true,
      },
      {
        title: `${t("unloading-point")} / ${t("discharge-time")}`,
        key: "unloadingData",
        sortable: true,
      },
      {
        title: t("transporter"),
        key: "transporter",
        sortable: true,
      },
      {
        title: t("planner"),
        key: "planner",
        sortable: true,
      },
      { title: t("status"), key: "status", sortable: true, width: "82px" },
    ] as any[],
);

const fetchRides = async (page: number, search: string, sort?: SortData) => {
  if (!loading.value) {
    try {
      loading.value = true;
      const {
        data,
        meta: { pagination },
      } = await getRides({
        include,
        page,
        search,
        ...(!isGlobSearch.value ? { searchJoin: "and" } : {}),
        ...(sort ? { orderBy: sort.key, sortedBy: sort.order } : {}),
      });
      rides.value = data;
      pagData.value = pagination;
    } catch (e: any) {
      snackbar(e.message);
    } finally {
      sortData.value = sort;
      loading.value = false;
    }
  }
};

const globSearch = ref("");

const isGlobSearch = computed(() => !!globSearch.value);

const isAdvancedSearch = computed(
  () => !isGlobSearch.value && !!finalSearchString.value,
);

type RidesSearchObject = {
  number?: string;
  loadingData?: string;
  unloadingData?: string;
  transporter?: string;
  planner?: string;
  status?: RideStatus;
};

const searchObject = ref<RidesSearchObject>({});

watch(
  searchObject,
  (value) => {
    if (Object.keys(value).length) globSearch.value = "";
  },
  { deep: true },
);

watch(globSearch, (value) => {
  if (value) {
    searchObject.value = {};
  }
});

const userLocale = computed(() => store.state.auth.profile?.locale);

watch(userLocale, () => {
  fetchRides(
    pagData.value.current_page,
    finalSearchString.value,
    sortData.value,
  );
});

const finalSearchString = computed(() =>
  isGlobSearch.value
    ? globSearch.value
    : prepSearchString(
        {
          title: "number",
          value: searchObject.value?.number || "",
        },
        {
          title: "loadingData",
          value: searchObject.value?.loadingData || "",
        },
        {
          title: "unloadingData",
          value: searchObject.value?.unloadingData || "",
        },
        {
          title: "transporter",
          value: searchObject.value?.transporter || "",
        },
        {
          title: "planner",
          value: searchObject.value?.planner || "",
        },
        {
          title: "status",
          value: searchObject.value?.status || "",
        },
      ),
);

useWatchDebounceHelper<string>(finalSearchString, 400, (value) => {
  if (globSearch.value) isFormOpened.value = false;
  fetchRides(1, value, sortData.value);
});

const clickRow = (e: any, data: { item: any }) => {
  router.push({
    name: "ride",
    params: { id: data.item.id },
  });
};

const prepRides = computed(() =>
  rides.value.map(({ document: { data: documentData }, id, status }) => {
    const {
      carrierContact,
      carrier,
      number,
      loadingPoint,
      unloadingPoint,
      loading_time_end,
      loading_time_start,
      unloading_time_end,
      unloading_time_start,
    } = documentData;

    const mLoadingStart = moment(loading_time_start);
    const loadingStart = mLoadingStart.isValid()
      ? mLoadingStart.format(locFormat.value.dDMmYWithDots)
      : "";

    const mLoadingEnd = moment(loading_time_end);
    const loadingEnd = mLoadingEnd.isValid()
      ? mLoadingEnd.format(locFormat.value.dDMmYWithDots)
      : "";
    const mUnloadingStart = moment(unloading_time_start);
    const unloadingStart = mUnloadingStart.isValid()
      ? mUnloadingStart.format(locFormat.value.dDMmYWithDots)
      : "";

    const mUnloadingEnd = moment(unloading_time_end);
    const unloadingEnd = mUnloadingEnd.isValid()
      ? mUnloadingEnd.format(locFormat.value.dDMmYWithDots)
      : "";
    return {
      number: `TA${number}`,
      loadingData: joinWithDots(
        loadingPoint?.data.country.data.name,
        loadingPoint?.data.postcode,
        loadingPoint?.data.city,
        `${loadingStart} ${loadingEnd && "-"} ${loadingEnd}`,
      ),
      unloadingData: joinWithDots(
        unloadingPoint?.data.country.data.name,
        unloadingPoint?.data.postcode,
        unloadingPoint?.data.city,
        `${unloadingStart} ${unloadingEnd && "-"} ${unloadingEnd}`,
      ),
      transporter: carrier
        ? `${carrier?.data.name} ${carrier?.data.addendum}`
        : "",
      planner: joinWithDots(
        carrierContact?.data.firstname,
        carrierContact?.data.lastname,
      ),
      status,
      id,
    };
  }),
);

const locFormat = useLocFormat();
</script>

<style></style>
