<template>
  <v-row class="my-0 py-6">
    <v-col cols="4" class="py-0">
      <radio-col
        :model-value="documentObject.adr"
        :disabled="props.readonly"
        :items="specialFeaturesItems.adr"
        :title="$t(getFeatureKey(FeatureType.adr))"
        tooltip="Lorem Ipsum"
        @update:model-value="vModalUpdate('adr', $event)"
      />
    </v-col>
    <v-col cols="4" class="py-0">
      <radio-col
        :model-value="documentObject.languageSkills"
        :disabled="props.readonly"
        :items="specialFeaturesItems.languageSkills"
        :title="$t(getFeatureKey(FeatureType.languageSkills))"
        @update:model-value="vModalUpdate('languageSkills', $event)"
      />
    </v-col>
    <v-col cols="4" class="d-flex flex-column py-0">
      <checkbox-col
        :model-value="documentObject.other"
        :title="$t('other')"
        :disabled="props.readonly"
        :items="specialFeaturesItems.other"
        @update:model-value="vModalUpdate('other', $event)"
      />
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { computed, watch } from "vue";
import { useStore } from "@/store";

import { getLocalDocumentObject } from "@/utils/calculator";
import { getFeatureKey } from "@/utils/features";

import { DocumentObject, DocumentErrorObject } from "@/types/document";
import { FeatureType, FeatureCalcType } from "@/types/settings";
import { CheckboxData } from "@/types/other";

import RadioCol from "@/components/CheckboxesGroup/RadioCol.vue";
import CheckboxCol from "@/components/CheckboxesGroup/CheckboxCol.vue";

const store = useStore();

const props = defineProps<{
  modelValue?: DocumentObject;
  readonly?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", data?: DocumentObject): void;
  (e: "update:errors", data: DocumentErrorObject): void;
}>();

const documentObject = computed(
  () => props.modelValue || getLocalDocumentObject(),
);

const specialFeaturesItems = computed(() => {
  const filterType = (filter: FeatureType): CheckboxData[] =>
    store.state.app.features
      .filter(({ active, type }) => active && type === filter)
      .map(({ name, code }) => ({ value: code, title: name }));

  const adr = filterType(FeatureType.adr);
  const languageSkills = filterType(FeatureType.languageSkills);
  const other = filterType(FeatureType.other).map((item) => ({
    ...item,
    tooltip: "Lorem Ipsum",
  }));
  return {
    adr,
    languageSkills,
    other,
  };
});

const priceOnRequestFeatures = computed(() =>
  store.state.calculator.calc.arrBE.some(
    ({ calculation_type }) =>
      calculation_type === FeatureCalcType.priceOnRequest,
  ),
);

const vModalUpdate = (field: keyof DocumentObject, value: any) => {
  emit("update:modelValue", {
    ...documentObject.value,
    [field]: value,
  });
};

watch(
  priceOnRequestFeatures,
  (value) => {
    store.commit("calculator/setPriceOnRequestFeatures", value);
  },
  { immediate: true },
);
</script>

<style scoped></style>
