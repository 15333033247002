<template>
  <company-view :id="props.id" is-transporter />
</template>

<script setup lang="ts">
import CompanyView from "@/components/CompaniesView/CompanyView.vue";

const props = defineProps<{
  id: string;
}>();
</script>

<style scoped></style>
