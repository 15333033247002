<template>
  <auth-wrapper>
    <v-sheet width="487" class="mx-auto py-12 px-10 rounded-xl bpl-border-gray">
      <v-alert
        v-if="successMessage"
        :title="successMessage"
        type="success"
        variant="tonal"
        class="mb-6"
      ></v-alert>
      <v-alert
        v-if="loginError"
        :title="$t('notification-request-failed')"
        :text="loginError"
        color="error"
        variant="tonal"
        class="mb-6"
      ></v-alert>
      <div class="d-flex justify-center">
        <logo-full></logo-full>
      </div>
      <v-form
        ref="form"
        v-model="valid"
        class="mt-6"
        @submit.prevent="callLogin()"
      >
        <v-text-field
          v-model="data.email"
          :error-messages="errors.email"
          autofocus
          name="email"
          autocomplete="email"
          :label="$t('email')"
          :rules="[rules.required, rules.email]"
          type="email"
          variant="outlined"
          class="pb-2"
          @update:model-value="errors.email = []"
        ></v-text-field>
        <v-text-field
          v-model="data.password"
          :error-messages="errors.password"
          name="current-password"
          autocomplete="current-password"
          :label="$t('password')"
          :append-inner-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required]"
          :type="passwordShow ? 'text' : 'password'"
          variant="outlined"
          class="pb-2"
          @update:model-value="errors.password = []"
          @click:append-inner="passwordShow = !passwordShow"
        ></v-text-field>
        <router-link to="/password/forgot" class="text-primary text-body-2">
          {{ $t("password-forgot") }}
        </router-link>
        <v-btn
          :loading="loading"
          type="submit"
          color="primary"
          size="large"
          block
          class="mt-14"
        >
          {{ $t("button.login") }}
        </v-btn>
      </v-form>
      <div class="mt-6 text-body-2 text-center">
        <span>
          {{ $t("registration-noaccount") }}
        </span>
        {{ " " }}
        <router-link to="/register" class="text-primary">
          {{ $t("button.register-now") }}
        </router-link>
      </div>
    </v-sheet>
  </auth-wrapper>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "@/store";
import { VForm } from "vuetify/components";

import LogoFull from "@/components/Auth/Registration/LogoFull.vue";
import AuthWrapper from "@/components/Auth/AuthWrapper.vue";

import { useRules } from "@/utils/rules";
import { getInitialUserCredentialsObject } from "@/utils/auth";

import { useValidHelper } from "@/utils/validHelper";
import { access } from "@/utils/access";

const router = useRouter();
const route = useRoute();
const store = useStore();

const rules = useRules();

const valid = ref(false);
const form = ref<VForm>();
const data = ref(getInitialUserCredentialsObject());

useValidHelper(form);

const passwordShow = ref(false);
const loginError = ref(route.query.error_message?.toString() || "");
const errors = ref({
  email: [],
  password: [],
});

const redirectUrl = route.query.redirect_url?.toString();
const successMessage = ref(route.query.success_message?.toString() || "");
const loading = ref(false);

const callLogin = async () => {
  if (!loading.value) {
    await router.replace({ query: undefined });
    await form.value?.validate();
    if (valid.value) {
      loading.value = true;
      try {
        await store.dispatch("auth/login", data.value);
        await router.push(
          redirectUrl && redirectUrl !== "/"
            ? {
                path: redirectUrl,
              }
            : { name: access.routeByRole(access.getRole()) }
        );
        loginError.value = "";
      } catch (e: any) {
        successMessage.value = "";
        loginError.value = e.message;
        errors.value = {
          email: [],
          password: [],
          ...e.errors,
        };
      } finally {
        loading.value = false;
      }
    }
  }
};
</script>
