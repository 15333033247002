import { post, get } from "@/api";

import { PrepareResponseObject } from "@/types/calculator";
import { PrepareObject } from "@/types/document";
import { FactorDay } from "@/types/settings";

export const prepare = (data: PrepareObject) =>
  post<PrepareResponseObject>("documents/prepare", data);

export const getSeasonFactor = (startDate: string, endDate: string) => {
  return get<{ data: FactorDay[] }>(
    `settings/season_factors/${startDate}/${endDate}`,
  );
};
