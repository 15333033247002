<template>
  <div
    class="d-flex align-start pl-3"
    :class="{ 'bg-grey-lighten-5': index % 2 !== 0 }"
  >
    <div class="text-h6 pt-12">{{ index + 1 }}</div>
    <v-row class="my-0 px-6 pt-8">
      <!-- all pallets -->
      <v-col cols="6" class="py-0">
        <fake-input
          :label="pointData.label"
          class="pb-2"
          :value="pointData.name"
        />
      </v-col>
      <!-- pallet exchange -->
      <template v-if="point.type === PointType.palletExchange">
        <v-col cols="6" class="py-0">
          <v-text-field
            v-model="point.company_name"
            :label="$t('company-name')"
            :rules="[rules.required]"
            :readonly="props.readonly"
            :variant="props.readonly ? 'filled' : 'outlined'"
            :bg-color="!props.readonly ? ('white' as any) : undefined"
            class="pb-2"
          />
        </v-col>
        <v-col cols="6" class="py-0">
          <v-text-field
            v-model="point.street"
            :label="$t('street')"
            :rules="[rules.required]"
            :readonly="props.readonly"
            :variant="props.readonly ? 'filled' : 'outlined'"
            :bg-color="!props.readonly ? ('white' as any) : undefined"
            class="pb-2"
          />
        </v-col>
        <v-col cols="6" class="py-0">
          <v-text-field
            v-model="point.house"
            :label="$t('house-number')"
            :rules="[rules.required]"
            :readonly="props.readonly"
            :variant="props.readonly ? 'filled' : 'outlined'"
            :bg-color="!props.readonly ? ('white' as any) : undefined"
            class="pb-2"
          />
        </v-col>
      </template>
      <!-- customs station -->
      <template v-if="point.type === PointType.customsStation">
        <v-col cols="6" class="py-0">
          <v-text-field
            v-model="point.agent_lastname"
            :label="$t('customs-agent.last-name')"
            :rules="[rules.required]"
            :readonly="props.readonly"
            :variant="props.readonly ? 'filled' : 'outlined'"
            :bg-color="!props.readonly ? ('white' as any) : undefined"
            class="pb-2"
          />
        </v-col>
        <v-col cols="4" class="py-0">
          <v-text-field
            v-model="point.agent_firstname"
            :label="$t('customs-agent.first-name')"
            :rules="[rules.required]"
            :readonly="props.readonly"
            :variant="props.readonly ? 'filled' : 'outlined'"
            :bg-color="!props.readonly ? ('white' as any) : undefined"
            class="pb-2"
          />
        </v-col>
        <v-col cols="4" class="py-0">
          <v-text-field
            v-model="point.street"
            :label="$t('street')"
            :rules="[rules.required]"
            :readonly="props.readonly"
            :variant="props.readonly ? 'filled' : 'outlined'"
            :bg-color="!props.readonly ? ('white' as any) : undefined"
            class="pb-2"
          />
        </v-col>
        <v-col cols="4" class="py-0">
          <v-text-field
            v-model="point.house"
            :label="$t('house-number')"
            :rules="[rules.required]"
            :readonly="props.readonly"
            :variant="props.readonly ? 'filled' : 'outlined'"
            :bg-color="!props.readonly ? ('white' as any) : undefined"
            class="pb-2"
          />
        </v-col>
      </template>
      <!-- loading or unloading point  -->
      <template
        v-if="
          point.type === PointType.loadingPoint ||
          point.type === PointType.unloadingPoint
        "
      >
        <v-col cols="6" class="py-0">
          <v-text-field
            v-model="point.company_name"
            :label="$t('company-name')"
            :rules="[rules.required]"
            :readonly="props.readonly"
            :variant="props.readonly ? 'filled' : 'outlined'"
            :bg-color="!props.readonly ? ('white' as any) : undefined"
            class="pb-2"
          />
        </v-col>
        <v-col cols="6" class="py-0">
          <v-text-field
            v-model="point.street"
            :label="$t('street')"
            :rules="[rules.required]"
            :readonly="props.readonly"
            :variant="props.readonly ? 'filled' : 'outlined'"
            :bg-color="!props.readonly ? ('white' as any) : undefined"
            class="pb-2"
          />
        </v-col>
        <v-col cols="3" class="py-0">
          <v-text-field
            v-model="point.house"
            :label="$t('house-number')"
            :rules="[rules.required]"
            :readonly="props.readonly"
            :variant="props.readonly ? 'filled' : 'outlined'"
            :bg-color="!props.readonly ? ('white' as any) : undefined"
            class="pb-2"
          />
        </v-col>
        <!-- loading point  -->
        <template v-if="point.type === PointType.loadingPoint">
          <v-col cols="3" class="py-0">
            <v-text-field
              v-model="point.meta.reference"
              :label="$t('loading-reference')"
              :readonly="props.readonly"
              :variant="props.readonly ? 'filled' : 'outlined'"
              :bg-color="!props.readonly ? ('white' as any) : undefined"
              class="pb-2"
              :class="{
                'point-warning-show': showWarning,
              }"
            />
          </v-col>
        </template>
        <!-- unloading point  -->
        <template v-else>
          <v-col cols="3" class="py-0">
            <v-text-field
              v-model="point.meta.reference"
              :label="$t('discharge-reference')"
              :readonly="props.readonly"
              :variant="props.readonly ? 'filled' : 'outlined'"
              :bg-color="!props.readonly ? ('white' as any) : undefined"
              class="pb-2"
              :class="{
                'point-warning-show': showWarning,
              }"
            />
          </v-col>
        </template>
        <!-- not last unloading point -->
        <template v-if="!isLast">
          <v-col cols="4" class="py-0">
            <v-select
              v-model="point.meta.pallet_type"
              :items="palletTypesList"
              item-title="name"
              item-value="code"
              :label="$t('pallet-type')"
              :rules="[rules.required]"
              :readonly="palletSelectorReadonly"
              :variant="palletSelectorReadonly ? 'filled' : 'outlined'"
              :bg-color="!palletSelectorReadonly ? ('white' as any) : undefined"
              :class="{
                'pointer-events-none': palletSelectorReadonly,
              }"
              class="pb-2"
            />
          </v-col>
          <v-col cols="4" class="py-0">
            <v-text-field
              v-model="point.meta.number_of_pallets"
              :label="$t('number-of-pallets')"
              :rules="[rules.required, rules.numberOnly]"
              :readonly="props.readonly"
              :variant="props.readonly ? 'filled' : 'outlined'"
              :bg-color="!props.readonly ? ('white' as any) : undefined"
              class="pb-2"
            />
          </v-col>
          <v-col cols="4" class="py-0">
            <v-text-field
              v-model="point.meta.weight_goods"
              :label="$t('weight-goods')"
              append-inner-icon="mdi-weight-kilogram"
              :rules="[rules.required, rules.numberOnly]"
              :readonly="props.readonly"
              :variant="props.readonly ? 'filled' : 'outlined'"
              :bg-color="!props.readonly ? ('white' as any) : undefined"
              class="pb-2"
            />
          </v-col>
          <v-col cols="12" class="py-0">
            <v-select
              v-model="point.meta.pallet_exchange"
              item-value="code"
              item-title="name"
              :items="palletExchangeList"
              :label="$t('pallet-exchange')"
              :rules="[rules.required]"
              :readonly="props.readonly || palletExchangeBlocked"
              :variant="
                props.readonly || palletExchangeBlocked ? 'filled' : 'outlined'
              "
              :bg-color="
                !(props.readonly || palletExchangeBlocked)
                  ? ('white' as any)
                  : undefined
              "
              class="pb-2"
            />
          </v-col>
          <template v-if="point.meta.pallet_type === PalletCustomTypes.other">
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="point.meta.pallet_type_other"
                :label="$t('pallet-type-other')"
                :rules="[rules.required]"
                :readonly="props.readonly"
                :variant="props.readonly ? 'filled' : 'outlined'"
                :bg-color="!props.readonly ? ('white' as any) : undefined"
                class="pb-2"
              />
            </v-col>
          </template>
        </template>
        <!-- last unloading point -->
        <template v-else>
          <v-col cols="4" class="py-0">
            <v-select
              v-model="point.meta.pallet_type"
              :items="palletTypesList"
              item-title="name"
              item-value="code"
              :label="$t('pallet-type')"
              :rules="[rules.required]"
              :readonly="palletSelectorReadonly"
              :variant="palletSelectorReadonly ? 'filled' : 'outlined'"
              :bg-color="!palletSelectorReadonly ? ('white' as any) : undefined"
              :class="{
                'pointer-events-none': palletSelectorReadonly,
              }"
              class="pb-2"
            />
          </v-col>
          <v-col cols="4" class="py-0">
            <v-text-field
              v-model="point.meta.number_of_pallets"
              :label="$t('number-of-pallets')"
              :rules="[rules.required, rules.numberOnly]"
              :readonly="props.readonly"
              :variant="props.readonly ? 'filled' : 'outlined'"
              :bg-color="!props.readonly ? ('white' as any) : undefined"
              class="pb-2"
            />
          </v-col>
          <v-col cols="4" class="py-0">
            <v-text-field
              v-model="point.meta.weight_goods"
              :label="$t('weight-goods')"
              append-inner-icon="mdi-weight-kilogram"
              :rules="[rules.required, rules.numberOnly]"
              :readonly="props.readonly"
              :variant="props.readonly ? 'filled' : 'outlined'"
              :bg-color="!props.readonly ? ('white' as any) : undefined"
              class="pb-2"
            />
          </v-col>
          <template v-if="point.meta.pallet_type === PalletCustomTypes.other">
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="point.meta.pallet_type_other"
                :label="$t('pallet-type-other')"
                :rules="[rules.required]"
                :readonly="props.readonly"
                :variant="props.readonly ? 'filled' : 'outlined'"
                :bg-color="!props.readonly ? ('white' as any) : undefined"
                class="pb-2"
              />
            </v-col>
          </template>
          <v-col cols="12" class="py-0">
            <v-select
              v-model="point.meta.pallet_exchange"
              item-value="code"
              item-title="name"
              :items="palletExchangeList"
              :label="$t('pallet-exchange')"
              :rules="[rules.required]"
              :readonly="props.readonly"
              :variant="props.readonly ? 'filled' : 'outlined'"
              :bg-color="!props.readonly ? ('white' as any) : undefined"
              class="pb-2"
            />
          </v-col>
          <!-- return to another branch is selected -->
          <template
            v-if="
              point.meta.pallet_exchange ===
              PalletExchange.returnToAnotherBranch
            "
          >
            <v-col cols="4" class="py-0">
              <v-text-field
                v-model="point.meta.another_branch.company_name"
                :label="$t('company-name')"
                :rules="[rules.required]"
                :readonly="props.readonly"
                :variant="props.readonly ? 'filled' : 'outlined'"
                :bg-color="!props.readonly ? ('white' as any) : undefined"
                class="pb-2"
              />
            </v-col>
            <v-col cols="4" class="py-0">
              <v-text-field
                v-model="point.meta.another_branch.street"
                :label="$t('street')"
                :rules="[rules.required]"
                :readonly="props.readonly"
                :variant="props.readonly ? 'filled' : 'outlined'"
                :bg-color="!props.readonly ? ('white' as any) : undefined"
                class="pb-2"
              />
            </v-col>
            <v-col cols="4" class="py-0">
              <v-text-field
                v-model="point.meta.another_branch.house"
                :label="$t('house-number')"
                :rules="[rules.required]"
                :readonly="props.readonly"
                :variant="props.readonly ? 'filled' : 'outlined'"
                :bg-color="!props.readonly ? ('white' as any) : undefined"
                class="pb-2"
              />
            </v-col>
            <v-col cols="4" class="py-0">
              <v-autocomplete
                v-model="point.meta.another_branch.country_code"
                :label="$t('country')"
                :rules="[rules.required]"
                :items="store.getters['app/countries']"
                item-title="title"
                item-value="code"
                :readonly="props.readonly"
                :variant="props.readonly ? 'filled' : 'outlined'"
                :bg-color="!props.readonly ? ('white' as any) : undefined"
                class="pb-2"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4" class="py-0">
              <v-text-field
                v-model="point.meta.another_branch.zip"
                :label="$t('zip-code')"
                :rules="[rules.required, rules.postCodeOnly]"
                :readonly="props.readonly"
                :variant="props.readonly ? 'filled' : 'outlined'"
                :bg-color="!props.readonly ? ('white' as any) : undefined"
                class="pb-2"
              />
            </v-col>
            <v-col cols="4" class="py-0">
              <v-text-field
                v-model="point.meta.another_branch.city"
                :label="$t('location')"
                :rules="[rules.required]"
                :readonly="props.readonly"
                :variant="props.readonly ? 'filled' : 'outlined'"
                :bg-color="!props.readonly ? ('white' as any) : undefined"
                class="pb-2"
              />
            </v-col>
          </template>
          <!-- return to loading point is selected -->
          <template
            v-if="
              point.meta.pallet_exchange === PalletExchange.returnToLoadingPoint
            "
          >
            <v-col cols="12" class="py-0">
              <v-select
                v-model="point.meta.return_to_loading_point"
                item-value="id"
                item-title="name"
                :items="pointsList"
                :label="$t('destination-return')"
                :rules="[rules.required]"
                :readonly="props.readonly"
                :variant="props.readonly ? 'filled' : 'outlined'"
                :bg-color="!props.readonly ? ('white' as any) : undefined"
                class="pb-2"
              />
            </v-col>
          </template>
        </template>
        <v-col cols="12" class="py-0">
          <v-textarea
            v-model="point.meta.other_comments"
            :label="$t('other-comments')"
            :counter="1000"
            :rules="[rules.lengthMax(1000)]"
            :readonly="props.readonly"
            :variant="props.readonly ? 'filled' : 'outlined'"
            :bg-color="!props.readonly ? ('white' as any) : undefined"
            no-resize
            auto-grow
            class="pb-2"
          />
        </v-col>
        <v-col cols="12" class="py-0 d-flex justify-space-between align-center">
          <v-tooltip
            v-if="!isTransporter || point.meta.neutral_shipping.active"
            text="Lorem Ipsum"
            location="bottom"
            offset="-32px"
          >
            <template #activator="{ props: tooltipProps }">
              <div v-bind="tooltipProps" class="d-inline-block">
                <v-checkbox
                  v-model="point.meta.neutral_shipping.active"
                  :label="$t('neutral-shipping')"
                  :disabled="props.readonly"
                  color="primary"
                  hide-details
                  class="bpl-underlined-field"
                ></v-checkbox>
              </div>
            </template>
          </v-tooltip>
          <div
            v-if="point.discharged"
            :class="{ 'opacity-65': props.readonly }"
          >
            <v-icon icon="mdi-information" color="primary" />
            <span class="text-primary ml-2">
              {{ $t("loading-unloading-driver") }}
            </span>
          </div>
        </v-col>
        <!-- neutral_shipping active -->
        <template v-if="point.meta.neutral_shipping.active">
          <v-col cols="4" class="py-0">
            <v-text-field
              v-model="point.meta.neutral_shipping.company_name"
              :label="$t('company-name')"
              :rules="[rules.required]"
              :readonly="props.readonly"
              :variant="props.readonly ? 'filled' : 'outlined'"
              :bg-color="!props.readonly ? ('white' as any) : undefined"
              class="pb-2"
            />
          </v-col>
          <v-col cols="4" class="py-0">
            <v-text-field
              v-model="point.meta.neutral_shipping.street"
              :label="$t('street')"
              :rules="[rules.required]"
              :readonly="props.readonly"
              :variant="props.readonly ? 'filled' : 'outlined'"
              :bg-color="!props.readonly ? ('white' as any) : undefined"
              class="pb-2"
            />
          </v-col>
          <v-col cols="4" class="py-0">
            <v-text-field
              v-model="point.meta.neutral_shipping.house"
              :label="$t('house-number')"
              :rules="[rules.required]"
              :readonly="props.readonly"
              :variant="props.readonly ? 'filled' : 'outlined'"
              :bg-color="!props.readonly ? ('white' as any) : undefined"
              class="pb-2"
            />
          </v-col>
          <v-col cols="4" class="py-0">
            <v-autocomplete
              v-model="point.meta.neutral_shipping.country_code"
              :label="$t('country')"
              :rules="[rules.required]"
              :items="store.getters['app/countries']"
              item-title="title"
              item-value="code"
              :readonly="props.readonly"
              :variant="props.readonly ? 'filled' : 'outlined'"
              :bg-color="!props.readonly ? ('white' as any) : undefined"
              class="pb-2"
            ></v-autocomplete>
          </v-col>
          <v-col cols="4" class="py-0">
            <v-text-field
              v-model="point.meta.neutral_shipping.zip"
              :label="$t('zip-code')"
              :rules="[rules.required, rules.postCodeOnly]"
              :readonly="props.readonly"
              :variant="props.readonly ? 'filled' : 'outlined'"
              :bg-color="!props.readonly ? ('white' as any) : undefined"
              class="pb-2"
            />
          </v-col>
          <v-col cols="4" class="py-0">
            <v-text-field
              v-model="point.meta.neutral_shipping.city"
              :label="$t('location')"
              :rules="[rules.required]"
              :readonly="props.readonly"
              :variant="props.readonly ? 'filled' : 'outlined'"
              :bg-color="!props.readonly ? ('white' as any) : undefined"
              class="pb-2"
            />
          </v-col>
        </template>
      </template>
    </v-row>
  </div>
</template>

<script setup lang="ts">
import { computed, watch, onMounted } from "vue";
import { useStore } from "@/store";
import { useI18n } from "vue-i18n";

import { useRules } from "@/utils/rules";
import { getPointName, joinWithDots } from "@/utils/other";
import { access } from "@/utils/access";

import {
  PalletExchange,
  EditPointObject,
  PointType,
  DocumentSavedData,
} from "@/types/document";

import FakeInput from "@/components/FakeInput.vue";

enum PalletCustomTypes {
  no = "no",
  other = "other",
}

const props = defineProps<{
  modelValue: EditPointObject;
  document: DocumentSavedData;
  emptyWarning: boolean;
  readonly: boolean;
  index: number;
  isLast: boolean;
}>();

const store = useStore();
const rules = useRules();
const { t } = useI18n();

const point = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const palletExchangeList = computed(() => {
  // loading point
  if (point.value.type === PointType.loadingPoint) {
    // pallet type no selected in document
    if (!palletIsSelected.value) {
      return [
        { code: PalletExchange.noExchange, name: t(PalletExchange.noExchange) },
      ];
    }
    // pallet type selected in document
    else {
      return [
        { code: PalletExchange.exchange, name: t(PalletExchange.exchange) },
        { code: PalletExchange.noExchange, name: t(PalletExchange.noExchange) },
      ];
    }
  }
  if (point.value.type === PointType.unloadingPoint) {
    // not last unloading point
    if (!props.isLast) {
      // pallet type no selected in document
      if (!palletIsSelected.value) {
        return [
          {
            code: PalletExchange.noExchange,
            name: t(PalletExchange.noExchange),
          },
          {
            code: PalletExchange.palletSlipDpl,
            name: t(PalletExchange.palletSlipDpl),
          },
          {
            code: PalletExchange.palletNoteUnloadingPoint,
            name: t(PalletExchange.palletNoteUnloadingPoint),
          },
        ];
      }
      // pallet type selected in document
      else {
        return [
          {
            code: PalletExchange.noExchange,
            name: t(PalletExchange.noExchange),
          },
          {
            code: PalletExchange.exchange,
            name: t(PalletExchange.exchange),
          },
          {
            code: PalletExchange.palletSlipDpl,
            name: t(PalletExchange.palletSlipDpl),
          },
          {
            code: PalletExchange.palletNoteUnloadingPoint,
            name: t(PalletExchange.palletNoteUnloadingPoint),
          },
        ];
      }
    }
    // last unloading point
    else {
      // pallet type no selected in document
      if (!palletIsSelected.value) {
        return [
          {
            code: PalletExchange.noExchange,
            name: t(PalletExchange.noExchange),
          },
          {
            code: PalletExchange.palletNoteUnloadingPoint,
            name: t(PalletExchange.palletNoteUnloadingPoint),
          },
          {
            code: PalletExchange.palletSlipDpl,
            name: t(PalletExchange.palletSlipDpl),
          },
        ];
      }
      // pallet type selected in document
      else {
        return [
          {
            code: PalletExchange.noExchange,
            name: t(PalletExchange.noExchange),
          },
          { code: PalletExchange.exchange, name: t(PalletExchange.exchange) },
          {
            code: PalletExchange.returnToLoadingPoint,
            name: t(PalletExchange.returnToLoadingPoint),
          },
          {
            code: PalletExchange.returnToAnotherBranch,
            name: t(PalletExchange.returnToAnotherBranch),
          },
          {
            code: PalletExchange.palletSlipDpl,
            name: t(PalletExchange.palletSlipDpl),
          },
          {
            code: PalletExchange.palletNoteUnloadingPoint,
            name: t(PalletExchange.palletNoteUnloadingPoint),
          },
        ];
      }
    }
  }
  // other point types
  else {
    return [];
  }
});

const palletTypesList = computed(() => [
  ...store.state.app.pallets.filter(
    ({ code }) => code !== PalletCustomTypes.no,
  ),
  { code: PalletCustomTypes.other, name: t(PalletCustomTypes.other) },
]);

const pointData = computed(() => {
  return {
    name: joinWithDots(
      point.value.country.data.name,
      point.value.postcode,
      point.value.city,
    ),
    label: point.value.type ? t(getPointName(point.value.type)) : "",
  };
});

const pointsList = computed(() =>
  props.document.points.data.slice(0, -1).map((point, index) => {
    return {
      id: point.id,
      name: `${index + 1}: ${point.type ? t(getPointName(point.type)) : ""}
      ${joinWithDots(
        point.country.data.code.toUpperCase(),
        point.postcode,
        point.city,
      )}`,
    };
  }),
);

const palletIsSelected = computed(
  () => props.document.pallet.data.code !== PalletCustomTypes.no,
);

const palletSelectorReadonly = computed(
  () => palletIsSelected.value || props.readonly,
);

const palletExchangeBlocked = computed(
  () => point.value.type === PointType.loadingPoint && !palletIsSelected.value,
);

const showWarning = computed(
  () => props.emptyWarning && !point.value.meta.reference,
);

const isTransporter = computed(() =>
  access.someRoles([
    "forwarder",
    "sub-forwarder",
    "planner",
    "accounting-contractor",
    "driver",
    "pallet-department-contractor",
  ]),
);

const emit = defineEmits<{
  (e: "update:modelValue", data: EditPointObject): void;
}>();

watch(
  point,
  (value) => {
    if (
      palletIsSelected.value &&
      point.value.meta.pallet_type !== props.document.pallet.data.code
    ) {
      point.value.meta.pallet_type = props.document.pallet.data.code;
    }
    if (
      palletExchangeBlocked.value &&
      point.value.meta.pallet_exchange !== PalletExchange.noExchange
    ) {
      point.value.meta.pallet_exchange = PalletExchange.noExchange;
    }
    if (value.meta.pallet_type !== PalletCustomTypes.other) {
      value.meta.pallet_type_other = undefined;
    }

    if (value.meta.pallet_exchange !== PalletExchange.returnToLoadingPoint) {
      value.meta.return_to_loading_point = undefined;
    }

    if (value.meta.pallet_exchange === PalletExchange.returnToAnotherBranch) {
      value.meta.another_branch.active = true;
    } else {
      value.meta.another_branch.active = false;
    }
  },
  { deep: true },
);

onMounted(() => {
  if (palletIsSelected.value) {
    point.value.meta.pallet_type = props.document.pallet.data.code;
  }
  if (palletExchangeBlocked.value) {
    point.value.meta.pallet_exchange = PalletExchange.noExchange;
  }
});
</script>

<style scoped>
.opacity-65 {
  opacity: 0.65;
}

.point-warning-show :deep() .v-field__outline,
.point-warning-show :deep() .v-field-label {
  color: rgb(var(--v-theme-warning)) !important;
}
</style>
