<template>
  <v-form
    ref="departureForm"
    v-model="departureValid"
    @submit.prevent="saveChanges(RideFourthBlockType.departure)"
  >
    <v-alert
      v-if="props.editable"
      :text="$t('alert.ride-step1-required')"
      color="warning"
      variant="tonal"
      class="my-8"
      icon="mdi-alert-outline"
    />
    <v-alert
      v-if="isInvalidDate"
      :text="$t('alert.expected-arrival-differs-unloading')"
      color="error"
      variant="tonal"
      class="my-8"
      icon="mdi-alert-octagon-outline"
    />
    <editable-block
      v-model="editableBlocks"
      :hide-actions="!isEditableBlocks"
      :name="RideFourthBlockType.departure"
      :loading="departureLoading"
      submit
      @cancel="cancelChanges"
    >
      <template #title>
        <p class="text-h5 text-primary py-2">
          {{ $t("actual-departure") }}
        </p>
      </template>
      <template #edit-btn="{ edit }">
        <v-btn
          v-if="editable"
          variant="text"
          elevation="0"
          color="primary"
          icon="mdi-file-edit-outline"
          @click="edit"
        />
      </template>
      <template #default="{ readonly }">
        <v-row class="my-0 pt-4" :class="{ 'pointer-events-none': readonly }">
          <v-col cols="12" class="py-0">
            <calendar-field
              v-model="editRideDeparture.step_4_leave_point_time"
              :title="$t('leave-loading-point')"
              class="pb-2"
              :chip-format="locFormat.dDMmYHhMmUhr"
              :readonly="readonly"
              :error-messages="errors.step_4_leave_point_time"
              :rules="[rules.required]"
              @update:model-value="errors.step_4_leave_point_time = []"
            />
          </v-col>
        </v-row>
      </template>
    </editable-block>
  </v-form>
  <v-form
    v-if="isBplAdmin || isTransporter"
    ref="arrivalForm"
    v-model="arrivalValid"
    @submit.prevent="saveChanges(RideFourthBlockType.arrival)"
  >
    <editable-block
      v-model="editableBlocks"
      :hide-actions="!isEditableBlocks"
      :name="RideFourthBlockType.arrival"
      :loading="arrivalLoading"
      submit
      @cancel="cancelChanges"
    >
      <template #title>
        <p class="text-h5 text-primary py-2">
          {{ $t("estimated-arrival") }}
        </p>
      </template>
      <template #edit-btn="{ edit }">
        <v-btn
          v-if="editable"
          variant="text"
          elevation="0"
          color="primary"
          icon="mdi-file-edit-outline"
          @click="edit"
        />
      </template>
      <template #default="{ readonly }">
        <v-row class="my-0 pt-4" :class="{ 'pointer-events-none': readonly }">
          <v-col cols="12" class="py-0">
            <calendar-field
              v-model="editRideArrival.step_4_scheduled_unloading_time"
              :title="$t('scheduled-unloading-point')"
              class="pb-2"
              :chip-format="locFormat.dDMmYHhMmUhr"
              :readonly="readonly"
              :error-messages="
                errors.step_4_scheduled_unloading_time?.length
                  ? errors.step_4_scheduled_unloading_time
                  : calendarErrors
              "
              :rules="[rules.required]"
              @update:model-value="errors.step_4_scheduled_unloading_time = []"
            />
          </v-col>
        </v-row>
      </template>
    </editable-block>
  </v-form>
  <div v-if="isBplAdmin || isTransporter" class="d-flex justify-end">
    <v-btn
      v-if="!isSaved"
      :loading="loading"
      type="button"
      color="primary"
      size="large"
      @click="saveAllChanges"
    >
      {{ $t("button.save-and-send") }}
    </v-btn>
    <v-btn
      v-else-if="isBplAdmin && isInvalidDateSaved"
      type="button"
      color="primary"
      size="large"
      :disabled="!!editableBlocks.length"
      :loading="manuallyLoading"
      @click="switchManually"
    >
      {{ $t("button.switch-manually") }}
    </v-btn>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import moment from "moment";

import { updateRide } from "@/api/rides";

import { useRules } from "@/utils/rules";
import { useLocFormat } from "@/utils/locFormat";
import { getInitialEditRideErrorObject } from "@/utils/rides";
import { useValidHelper } from "@/utils/validHelper";
import { access } from "@/utils/access";
import { useMountScroller } from "@/utils/mountScroller";
import { snackbar } from "@/utils/snackbar";

import { EditRideObject, RideFourthBlockType, RideObject } from "@/types/rides";

import { VForm } from "vuetify/components";
import CalendarField from "@/components/CalendarField.vue";
import EditableBlock from "@/components/EditableBlock.vue";

const { t } = useI18n();
const locFormat = useLocFormat();

const include = "document.carrier.users";

const rules = useRules();

const editableBlocks = ref<RideFourthBlockType[]>([]);

const props = defineProps<{
  ride: RideObject;
  editable?: boolean;
}>();

const editRideDeparture = ref<EditRideObject>({}); //step_4_leave_point_time
const editRideArrival = ref<EditRideObject>({}); //step_4_scheduled_unloading_time

const getDatesDiff = (time: string, locTime: string) => {
  const timeMoment = moment(time);
  const locTimeMoment = moment(locTime);
  return locTimeMoment.diff(timeMoment, "minutes");
};

const isInvalidDate = computed(() => {
  if (editRideArrival.value.step_4_scheduled_unloading_time)
    return (
      getDatesDiff(
        props.ride.document.data.unloading_time_start,
        editRideArrival.value.step_4_scheduled_unloading_time,
      ) >= 1
    );
  return false;
});

const isInvalidDateSaved = computed(() => {
  return props.ride.step_4_invalid_date;
});

const calendarErrors = computed(() => {
  if (
    editRideArrival.value.step_4_scheduled_unloading_time &&
    getDatesDiff(
      props.ride.document.data.unloading_time_start,
      editRideArrival.value.step_4_scheduled_unloading_time,
    ) < 0
  ) {
    return [t("ride.order-agreed-time")];
  }
  return [];
});

const loading = ref(false);

const manuallyLoading = ref(false);

useMountScroller();

//departure
const departureValid = ref(false);
const departureForm = ref<VForm>();
const departureLoading = ref(false);

useValidHelper(departureForm);

//arrival
const arrivalValid = ref(false);
const arrivalForm = ref<VForm>();
const arrivalLoading = ref(false);

useValidHelper(arrivalForm);

const errors = ref(getInitialEditRideErrorObject());

const syncEdits = (value: RideObject) => {
  //departure sync
  if (!editableBlocks.value.includes(RideFourthBlockType.departure)) {
    editRideDeparture.value.step_4_leave_point_time =
      value.step_4_leave_point_time;
    errors.value.step_4_leave_point_time = [];
  }
  //arrival sync
  if (!editableBlocks.value.includes(RideFourthBlockType.arrival)) {
    editRideArrival.value.step_4_scheduled_unloading_time =
      value.step_4_scheduled_unloading_time;
    errors.value.step_4_scheduled_unloading_time = [];
  }
};

const cancelChanges = () => {
  nextTick(() => {
    syncEdits(props.ride);
  });
};

const closeBlock = (name: RideFourthBlockType) => {
  editableBlocks.value = editableBlocks.value.filter(
    (blockName) => blockName !== name,
  );
};

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "updateRide", data: RideObject): void;
}>();

const validAll = async () => {
  await Promise.all([
    departureForm.value?.validate(),
    arrivalForm.value?.validate(),
  ]);

  return departureValid.value && arrivalValid.value;
};
const saveAllChanges = async () => {
  if (await validAll()) {
    try {
      loading.value = true;
      const response = await updateRide(
        props.ride.id,
        {
          ...editRideDeparture.value,
          ...editRideArrival.value,
          step_4_invalid_date: isInvalidDate.value,
          ...(!isInvalidDate.value
            ? {
                step: 5,
                step_5_scheduled_unloading_time:
                  editRideArrival.value.step_4_scheduled_unloading_time,
              }
            : {}),
        },
        {
          include,
        },
      );

      emit("updateRide", response.data);
    } catch (e: any) {
      errors.value = {
        ...getInitialEditRideErrorObject(),
        ...e.errors,
      };
    } finally {
      loading.value = false;
    }
  }
};
const switchManually = async () => {
  if (await validAll()) {
    try {
      manuallyLoading.value = true;
      const response = await updateRide(
        props.ride.id,
        {
          step: 5,
          step_4_invalid_date: false,
          step_5_scheduled_unloading_time:
            editRideArrival.value.step_4_scheduled_unloading_time,
        },
        {
          include,
        },
      );

      emit("updateRide", response.data);
    } catch (e: any) {
      snackbar(e.message);
    } finally {
      manuallyLoading.value = false;
    }
  }
};
const saveChanges = async (name: RideFourthBlockType) => {
  let response: { data: RideObject } | undefined;
  let error: any = {};

  switch (name) {
    case RideFourthBlockType.departure:
      await departureForm.value?.validate();
      if (departureValid.value) {
        try {
          departureLoading.value = true;
          response = await updateRide(props.ride.id, editRideDeparture.value, {
            include,
          });
          closeBlock(RideFourthBlockType.departure);
        } catch (e: any) {
          error = e;
        } finally {
          departureLoading.value = false;
        }
      }
      break;
    case RideFourthBlockType.arrival:
      await arrivalForm.value?.validate();
      if (arrivalValid.value) {
        try {
          arrivalLoading.value = true;
          response = await updateRide(props.ride.id, editRideArrival.value, {
            include,
          });
          closeBlock(RideFourthBlockType.arrival);
        } catch (e: any) {
          error = e;
        } finally {
          arrivalLoading.value = false;
        }
      }
      break;
  }
  if (error?.errors) {
    errors.value = {
      ...getInitialEditRideErrorObject(),
      ...error.errors,
    };
  }
  if (response?.data) emit("updateRide", response.data);
};

const isBplAdmin = computed(() =>
  access.someRoles(["admin", "sub-admin", "bpl-manager"]),
);

const isTransporter = computed(() =>
  access.someRoles([
    "forwarder",
    "sub-forwarder",
    "planner",
    "accounting-contractor",
    "driver",
    "pallet-department-contractor",
  ]),
);

const isSaved = computed(() => !!props.ride.step_4_leave_point_time);

const isEditableBlocks = computed(
  () => isBplAdmin.value && isSaved.value && isInvalidDateSaved.value,
);

const isEditable = computed(() => {
  if (isBplAdmin.value) {
    return (!isSaved.value || isInvalidDateSaved.value) && props.editable;
  } else if (isTransporter.value) {
    return !isSaved.value && props.editable;
  }
  return false;
});

watch(
  () => props.ride,
  (value) => {
    syncEdits(value);
  },
  { immediate: true },
);

watch(
  () => !isEditableBlocks.value && isEditable.value,
  (value) => {
    if (value) {
      editableBlocks.value = Object.values(
        RideFourthBlockType,
      ) as RideFourthBlockType[];
    } else {
      editableBlocks.value = [];
    }
  },
  { immediate: true },
);
</script>

<style scoped></style>
