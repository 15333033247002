import { Module } from "vuex";

import { State } from "@/store";

import { getLocValue, setLocValue } from "@/utils/localStorage";
import {
  getInitialDocumentObject,
  getLocalDocumentObject,
  getInitialPrepareObject,
  getInitialDocumentCalcObject,
} from "@/utils/calculator";

import { prepare, getSeasonFactor } from "@/api/calculator";

import {
  PrepareResponseObject,
  DocumentCalcObject,
  PriceOnRequest,
} from "@/types/calculator";
import { DocumentObject, PrepareObject } from "@/types/document";
import { FactorDay } from "@/types/settings";

export interface CalculatorState {
  readySteps: number[];
  prepare: PrepareResponseObject;
  prepareLoading: boolean;
  seasonFactor: FactorDay[];
  seasonFactorLoading: boolean;
  document: DocumentObject;
  calc: DocumentCalcObject;
  priceOnRequest: PriceOnRequest;
}

export type CalculatorModule = Module<CalculatorState, State>;

export const calculator: CalculatorModule = {
  namespaced: true,
  state: () => ({
    readySteps: getLocValue("CALCULATOR_STEP") || [0],
    prepare:
      getLocValue("CURRENT_DOCUMENT_PREPARE") || getInitialPrepareObject(),
    prepareLoading: false,
    seasonFactor: getLocValue("CURRENT_DOCUMENT_SEASON_FACTOR") || [],
    seasonFactorLoading: false,
    document: getLocalDocumentObject(),
    calc: getInitialDocumentCalcObject(),
    priceOnRequest: {
      overpriced: false,
      overweight: false,
      oversize: false,
      features: false,
    },
  }),
  mutations: {
    addStep(state, value: number) {
      const readySteps = Array.from(new Set([...state.readySteps, value]));
      state.readySteps = readySteps;
      setLocValue("CALCULATOR_STEP", readySteps);
    },
    setPrepare(state, value: PrepareResponseObject) {
      state.prepare = value;
      setLocValue("CURRENT_DOCUMENT_PREPARE", value);
    },

    setPrepareLoading(state, value: boolean) {
      state.prepareLoading = value;
    },
    setSeasonFactorLocal(state) {
      const seasonFactor = state.seasonFactor;
      if (seasonFactor) {
        setLocValue("CURRENT_DOCUMENT_SEASON_FACTOR", seasonFactor);
      }
    },
    setSeasonFactor(state, value: FactorDay[]) {
      state.seasonFactor = value;
    },
    setSeasonFactorLoading(state, value: boolean) {
      state.seasonFactorLoading = value;
    },
    setDocumentLocal(state, value: DocumentObject) {
      state.document = value;
      setLocValue("CURRENT_DOCUMENT", value);
    },
    setDocument(state, value: DocumentObject) {
      state.document = value;
    },
    setCalc(state, value: DocumentCalcObject) {
      state.calc = value;
    },
    setPriceOnRequestOverpriced(state, value: boolean) {
      state.priceOnRequest.overpriced = value;
    },
    setPriceOnRequestOverweight(state, value: boolean) {
      state.priceOnRequest.overweight = value;
    },
    setPriceOnRequestOversize(state, value: boolean) {
      state.priceOnRequest.oversize = value;
    },
    setPriceOnRequestFeatures(state, value: boolean) {
      state.priceOnRequest.features = value;
    },
    refreshDocument(state) {
      state.document = getLocalDocumentObject();
    },
    clearDocument(state) {
      //reset calculator step
      const readySteps = [0];
      state.readySteps = readySteps;
      setLocValue("CALCULATOR_STEP", readySteps);
      // clear document
      const cleanDocument = getInitialDocumentObject();
      state.document = cleanDocument;
      setLocValue("CURRENT_DOCUMENT", cleanDocument);
      // clear prepare data
      const cleanPrepare = getInitialPrepareObject();
      state.prepare = cleanPrepare;
      setLocValue("CURRENT_DOCUMENT_PREPARE", cleanPrepare);
      //reset price on request data
      state.priceOnRequest = {
        overpriced: false,
        overweight: false,
        oversize: false,
        features: false,
      };
    },
  },
  actions: {
    async fetchPrepare(context, value: PrepareObject) {
      context.commit("setPrepareLoading", true);
      const prepareData = await prepare(value);
      context.commit("setPrepare", prepareData);
      context.commit("setPrepareLoading", false);
    },
    async fetchSeasonFactor(
      context,
      { start, end }: { start: string; end: string },
    ) {
      context.commit("setSeasonFactorLoading", true);
      const { data } = await getSeasonFactor(start, end);
      context.commit("setSeasonFactor", data);
      context.commit("setSeasonFactorLoading", false);
    },
  },
};
