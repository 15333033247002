import { store } from "@/store";
import { i18n } from "@/main";

import { getLocValue } from "@/utils/localStorage";
import { CurrentTokensObject, TokensObject } from "@/types/auth";
import { makeFormData } from "@/utils/settings";

const urlPrefix = "/api/v1/";

let currentRefresh: null | Promise<string> = null;

const refresh = async (currentTokens: CurrentTokensObject): Promise<string> => {
  try {
    const newTokens = await post<TokensObject>(
      "clients/web/refresh",
      { ...currentTokens },
      false,
      false
    );

    if (newTokens.access_token) {
      store.commit("auth/setTokens", newTokens);
      return Promise.resolve(newTokens.access_token);
    } else {
      return Promise.resolve(currentTokens.access_token);
    }
  } catch (error) {
    const expires = currentTokens.expires_in + currentTokens.token_time;
    const time = new Date().getTime();
    if (expires <= time) {
      await store.dispatch("auth/clearAuthData");
      return Promise.reject(error);
    } else {
      return Promise.resolve(currentTokens.access_token);
    }
  }
};

const getToken = async (): Promise<string> => {
  const currentTokens = store.state.auth.tokens;
  if (currentTokens) {
    const expires =
      Math.round(currentTokens.expires_in / 1.5) + currentTokens.token_time;
    const time = new Date().getTime();
    if (expires > time) {
      return Promise.resolve(currentTokens.access_token);
    } else {
      if (!currentRefresh) {
        currentRefresh = refresh(currentTokens);
      }
      return Promise.resolve(currentRefresh);
    }
  } else {
    return Promise.resolve("");
  }
};

enum MethodType {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  PATCH = "PATCH",
  DELETE = "DELETE",
}

const baseFetch = async <T>(
  method: MethodType,
  url: string,
  body: any = null,
  auth = true,
  isFile = false
): Promise<T> => {
  const token = auth ? await getToken() : "";
  currentRefresh = null;
  const loc = getLocValue("PROFILE")?.locale || i18n.global.locale.value;

  const response = await fetch(`${urlPrefix}${url}`, {
    method,
    headers: {
      accept: "application/json",
      ...(!isFile ? { "Content-Type": "application/json" } : {}),
      "Accept-Language": loc,
      Authorization: `Bearer ${token}`,
    },
    body: body ? (isFile ? makeFormData(body) : JSON.stringify(body)) : null,
  });

  if (response.status === 401) {
    await store.dispatch("auth/clearAuthData");
    return Promise.reject(response);
  }

  const jsonResponse = response.status === 204 ? null : await response.json();

  if (response.ok) {
    return jsonResponse;
  } else {
    throw jsonResponse;
  }
};

const prepParams = (url: string, params?: object) =>
  `${url}${params ? `?${new URLSearchParams(Object.entries(params))}` : ""}`;

export const get = <T>(url: string, params?: object) =>
  baseFetch<T>(MethodType.GET, prepParams(url, params));

export const post = <T>(url: string, body?: any, isFile = false, auth = true) =>
  baseFetch<T>(MethodType.POST, url, body, auth, isFile);

export const put = <T>(url: string, body: any, params?: object) =>
  baseFetch<T>(MethodType.PUT, prepParams(url, params), body);

export const patch = <T>(url: string, body: any, params?: object) =>
  baseFetch<T>(MethodType.PATCH, prepParams(url, params), body);

export const del = <T>(url: string, body?: any) =>
  baseFetch<T>(MethodType.DELETE, url, body);
