// vue
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store, storeKey } from "./store";

// vuetify
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import { createVuetify, ThemeDefinition } from "vuetify";
import { loadFonts } from "./plugins/webfontloader";

// sockets
import socketsConnect from "./plugins/socketsConnect";

// i18n
import { createVueI18nAdapter } from "vuetify/locale/adapters/vue-i18n";
import { createI18n, useI18n } from "vue-i18n";
// Translations provided by Vuetify
import { en, de } from "vuetify/locale";
// Custom translations provided by i18n
import customEn from "./locales/en.json";
import customDe from "./locales/de.json";

const mergedEn = { $vuetify: { ...en }, ...customEn };
const mergedDe = { $vuetify: { ...de }, ...customDe };

// v-calendar
import { setupCalendar } from "v-calendar";
import "v-calendar/dist/style.css";
import { getLocValue } from "@/utils/localStorage";

// moment-timezone, set global moment timezone
import moment from "moment-timezone";
moment.tz.setDefault("Europe/Berlin");

const customLightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    "background-primary": "#F5F4F8",
    "background-secondary": "#F9F9FB",
    primary: "#1982D4",
    error: "#FF5252",
  },
};

export const i18n = createI18n({
  legacy: false, // Vuetify does not support the legacy mode of vue-i18n
  locale: getLocValue("PROFILE")?.locale || "de",
  fallbackLocale: "en",
  messages: { en: mergedEn, de: mergedDe },
});

const vuetify = createVuetify({
  theme: {
    defaultTheme: "customLightTheme",
    themes: {
      customLightTheme,
    },
  },
  locale: {
    adapter: createVueI18nAdapter({ i18n, useI18n }),
  },
  components: {},
});

loadFonts();

createApp(App)
  .use(router)
  .use(store, storeKey)
  .use(i18n)
  .use(vuetify)
  .use(setupCalendar, {})
  .use(socketsConnect)
  .mount("#app");
