import { del, get, post, put } from "@/api";
import { NewCompanyUserObject } from "@/types/company";
import { ProfileData } from "@/types/auth";

export const createEmployee = (
  companyId: string,
  newUser: NewCompanyUserObject
) => post<{ data: ProfileData }>(`companies/${companyId}/users`, newUser);

export const removeEmployee = (companyId: string, id: string) =>
  del(`companies/${companyId}/users/${id}`);

export const getUsers = () => get<{ data: ProfileData[] }>(`users`);

export const createUser = (newUser: NewCompanyUserObject) =>
  post<{ data: ProfileData }>(`users`, newUser);

export const updateUser = (
  id: string,
  newUserData: NewCompanyUserObject,
  params?: { include: string }
) => put<{ data: ProfileData }>(`users/${id}`, newUserData, params);

export const removeUser = (id: string) => del(`users/${id}`);
