<template>
  <v-form ref="formRef" v-model="valid">
    <v-alert
      v-if="props.editable"
      :text="$t('alert.ride-step1-required')"
      color="warning"
      variant="tonal"
      class="my-8"
      icon="mdi-alert-outline"
    />
    <v-alert
      v-if="fileError"
      :text="$t('alert.document-upload-refused')"
      color="error"
      variant="tonal"
      class="my-8"
      icon="mdi-alert-octagon-outline"
      closable
    ></v-alert>
    <v-alert
      v-if="fileSuccess"
      :text="$t('alert.document-upload-completed')"
      color="success"
      variant="tonal"
      class="mb-6"
      icon="mdi-check-circle"
      closable
    ></v-alert>
    <p class="text-h5 text-primary pt-2 pb-6">
      {{ $t("transport-completed") }}
    </p>
    <calendar-field
      v-model="editRide.step_7_leave_unloading_time"
      :title="$t('transport-completed')"
      class="pb-2"
      :class="{ 'pointer-events-none': !props.editable }"
      :readonly="props.editable"
      :chip-format="locFormat.dDMmYHhMmUhr"
      :error-messages="errors.step_7_leave_unloading_time"
      :rules="[rules.required]"
      @update:model-value="errors.step_7_leave_unloading_time = []"
    />
    <ride-files-view
      v-if="isBplAdmin || isTransporter"
      :readonly="isTransporter && !props.editable"
      :ride="props.ride"
      @upload-error="fileError = $event"
      @upload-success="fileSuccess = $event"
      @update-ride="emit('updateRide', $event)"
    />
  </v-form>
  <div
    v-if="(isTransporter || isBplAdmin) && props.editable"
    class="d-flex justify-end mt-8"
  >
    <v-btn
      :loading="loading"
      color="primary"
      size="large"
      type="button"
      @click="saveAllChanges()"
    >
      {{ $t("button.save-and-send") }}
    </v-btn>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";

import { updateRide } from "@/api/rides";

import { useRules } from "@/utils/rules";
import { useLocFormat } from "@/utils/locFormat";
import { getInitialEditRideErrorObject } from "@/utils/rides";
import { useValidHelper } from "@/utils/validHelper";
import { useMountScroller } from "@/utils/mountScroller";
import { access } from "@/utils/access";

import { VForm } from "vuetify/components";
import { EditRideObject, RideObject } from "@/types/rides";

import RideFilesView from "@/components/RideView/RideSeventh/RideFilesView.vue";
import CalendarField from "@/components/CalendarField.vue";

const include = "document.carrier.users";

const rules = useRules();
const locFormat = useLocFormat();

const props = defineProps<{
  ride: RideObject;
  editable?: boolean;
}>();

const editRide = ref<EditRideObject>({}); //step_7_leave_unloading_time

useMountScroller();

//arrival
const valid = ref(false);
const formRef = ref<VForm>();
const loading = ref(false);

useValidHelper(formRef);

const errors = ref(getInitialEditRideErrorObject());

const fileError = ref(false);
const fileSuccess = ref(false);

const syncEdits = (value: RideObject) => {
  editRide.value.step_7_leave_unloading_time =
    value.step_7_leave_unloading_time;

  errors.value.step_7_leave_unloading_time = [];
};

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "updateRide", data: RideObject): void;
}>();

const saveAllChanges = async () => {
  await formRef.value?.validate();
  if (valid.value) {
    try {
      loading.value = true;
      const { data } = await updateRide(
        props.ride.id,
        { ...editRide.value, step: 8 },
        {
          include,
        },
      );

      emit("updateRide", data);
    } catch (e: any) {
      console.log(e);
      errors.value = {
        ...getInitialEditRideErrorObject(),
        ...e.errors,
      };
    } finally {
      loading.value = false;
    }
  }
};

const isBplAdmin = computed(() =>
  access.someRoles(["admin", "sub-admin", "bpl-manager"]),
);

const isTransporter = computed(() =>
  access.someRoles([
    "forwarder",
    "sub-forwarder",
    "planner",
    "accounting-contractor",
    "driver",
    "pallet-department-contractor",
  ]),
);

watch(
  () => props.ride,
  (value) => {
    syncEdits(value);
  },
  { immediate: true },
);
</script>

<style scoped></style>
