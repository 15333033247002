<template>
  <v-tooltip :text="props.tooltip || ''" location="bottom" offset="-1px">
    <template #activator="{ props: tooltipProps }">
      <div
        v-bind="props.tooltip ? tooltipProps : null"
        class="small-checkbox-wrap"
      >
        <v-checkbox
          v-model="isChecked"
          :value="props.name || undefined"
          :label="props.label || ''"
          color="primary"
          hide-details
          :disabled="props.disabled"
          :class="{ 'bpl-underlined-field': props.tooltip }"
          :true-icon="props.minus ? 'mdi-minus-box' : 'mdi-checkbox-marked'"
        ></v-checkbox>
      </div>
    </template>
  </v-tooltip>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { CheckboxGroupValue } from "@/types/other";

const props = defineProps<{
  modelValue: boolean | CheckboxGroupValue[];
  name?: CheckboxGroupValue;
  label?: string;
  tooltip?: string;
  disabled?: boolean;
  minus?: boolean;
}>();

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "update:modelValue", data: boolean | CheckboxGroupValue[]): void;
}>();

const isChecked = computed({
  get() {
    return props.modelValue;
  },
  set(value: boolean | CheckboxGroupValue[]): void {
    emit("update:modelValue", value);
  },
});
</script>

<style scoped>
.small-checkbox-wrap {
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  height: 40px;
}
</style>
