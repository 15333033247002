<template>
  <v-form ref="form" v-model="valid" @submit.prevent="callSubmit()">
    <div class="pb-2 pt-10 d-flex justify-space-between align-center">
      <p class="text-h5 text-primary">
        {{ $t("company") }}
      </p>
      <v-btn
        v-if="isBplAdmin"
        :to="{
          name: 'clients',
          query: {
            add: 'true',
          },
        }"
        color="primary"
      >
        {{ $t("button.register-company") }}
      </v-btn>
    </div>
    <company-block v-model="documentObject" />
    <div class="d-flex justify-space-between mt-10">
      <v-btn
        variant="text"
        color="primary"
        size="large"
        @click="emit('step', 1)"
      >
        {{ $t("button.back") }}
      </v-btn>
      <v-btn type="submit" size="large" color="primary">
        {{ $t("button.next") }}
      </v-btn>
    </div>
  </v-form>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "@/store";

import { useValidHelper } from "@/utils/validHelper";
import { useMountScroller } from "@/utils/mountScroller";
import { getLocalDocumentObject } from "@/utils/calculator";
import { access } from "@/utils/access";

import { VForm } from "vuetify/components";
import { DocumentObject, DocumentType } from "@/types/document";

import CompanyBlock from "@/components/CalculatorView/Blocks/CompanyBlock.vue";

const store = useStore();
const form = ref<VForm>();

useValidHelper(form);
useMountScroller();

const documentObject = ref<DocumentObject>(getLocalDocumentObject());

const valid = ref(false);

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "step", data: number): void;
}>();

const isBplAdmin = computed(() =>
  access.someRoles([
    "admin",
    "sub-admin",
    "bpl-manager",
    "bpl-calculation-manager",
  ]),
);

const isPriceOnRequest = computed(
  () =>
    !!store.state.calculator.document.manual ||
    !!store.state.calculator.priceOnRequest.features ||
    !!store.state.calculator.prepare.ferries.price_on_request ||
    !!store.state.calculator.calc.priceOnRequestOverpriced ||
    !!store.state.calculator.calc.priceOnRequestOverweight ||
    !!store.state.calculator.calc.priceOnRequestOversize ||
    !!store.state.calculator.calc.priceOnRequestTime,
);

const callSubmit = async () => {
  await form.value?.validate();
  if (valid.value) {
    store.commit("calculator/setDocumentLocal", documentObject.value);
    store.commit("calculator/addStep", 3);
    emit("step", 3);
  }
};

watch(
  isPriceOnRequest,
  (value) => {
    documentObject.value.type = value ? DocumentType.offer : DocumentType.order;
    documentObject.value.price_on_request = value;
  },
  { immediate: true },
);

watch(
  documentObject,
  (value) => {
    store.commit("calculator/setDocument", value);
  },
  { deep: true },
);

onMounted(() => {
  store.commit("calculator/refreshDocument");
});
</script>

<style scoped></style>
