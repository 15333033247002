import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { store } from "@/store";
import HomeView from "@/views/HomeView.vue";
import ImprintPage from "@/views/ImprintPage.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import TermsPage from "@/views/TermsPage.vue";
import ClassificationProductGroups from "@/views/ClassificationProductGroups.vue";
import AuthLogin from "@/views/AuthLogin.vue";
import AuthRegistration from "@/views/AuthRegistration.vue";
import AuthPasswordForgot from "@/views/AuthPasswordForgot.vue";
import AuthPasswordReset from "@/views/AuthPasswordReset.vue";
import AuthEmailVerify from "@/views/AuthEmailVerify.vue";
import DashboardView from "@/views/DashboardView.vue";
import CalculatorView from "@/views/DashboardView/CalculatorView.vue";
import OffersView from "@/views/DashboardView/OffersView.vue";
import SeasonFactorView from "@/views/DashboardView/SeasonFactorView.vue";
import EmployeesView from "@/views/DashboardView/EmployeesView.vue";
import PalletsView from "@/views/DashboardView/PalletsView.vue";
import VehiclesView from "@/views/DashboardView/VehiclesView.vue";
import RidesView from "@/views/DashboardView/RidesView.vue";
import RideView from "@/views/DashboardView/RidesView/RideView.vue";
import TestView from "@/views/DashboardView/TestView.vue";
import MarketFactorView from "@/views/DashboardView/MarketFactorView.vue";
import OrdersView from "@/views/DashboardView/OrdersView.vue";
import OrderView from "@/views/DashboardView/OrdersView/OrderView.vue";
import PostalCodeView from "@/views/DashboardView/PostalCodeView.vue";
import FerryView from "@/views/DashboardView/FerryView.vue";
import OfferView from "@/views/DashboardView/OffersView/OfferView.vue";
import { access } from "@/utils/access";
import ProfileSettingsView from "@/views/DashboardView/ProfileSettingsView.vue";
import ClientsView from "@/views/DashboardView/ClientsView.vue";
import TransportersView from "@/views/DashboardView/TransportersView.vue";
import ClientView from "@/views/DashboardView/ClientsView/ClientView.vue";
import TransporterView from "@/views/DashboardView/TransportersView/TransporterView.vue";
import FeaturesView from "@/views/DashboardView/FeaturesView.vue";
import GenSettingsView from "@/views/DashboardView/GenSettingsView.vue";
import DeliveryFactorView from "@/views/DashboardView/DeliveryFactorView.vue";
import MarketplacesView from "@/views/DashboardView/MarketplacesView.vue";
import MarketplaceView from "@/views/DashboardView/MarketplacesView/MarketplaceView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/imprint",
    name: "imprint",
    component: ImprintPage,
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
  },
  {
    path: "/terms",
    name: "terms",
    component: TermsPage,
  },
  {
    path: "/warengruppen",
    name: "warengruppen",
    component: ClassificationProductGroups,
  },
  {
    path: "/login",
    name: "login",
    component: AuthLogin,
    meta: {
      guest: true,
    },
  },
  {
    path: "/register",
    name: "register",
    component: AuthRegistration,
    meta: {
      guest: true,
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardView,
    meta: {
      auth: true,
    },
    redirect: { name: "calculator" },
    children: [
      {
        path: "",
        name: "calculator",
        component: CalculatorView,
        meta: {
          roles: [
            "admin",
            "sub-admin",
            "bpl-manager",
            "bpl-calculation-manager",
            "industry",
            "sub-industry",
            "manager",
            "logistic",
            "sub-logistic",
            "pallet-department-client",
          ],
        },
      },
      {
        path: "marketplace",
        name: "marketplace",
        component: MarketplacesView,
        meta: {
          roles: [
            "admin",
            "sub-admin",
            "bpl-manager",
            "forwarder",
            "sub-forwarder",
            "planner",
            // "accounting-contractor",
            "pallet-department-contractor",
          ],
        },
      },
      {
        path: "marketplace/:id",
        name: "marketplace-item",
        component: MarketplaceView,
        meta: {
          roles: [
            "admin",
            "sub-admin",
            "bpl-manager",
            "forwarder",
            "sub-forwarder",
            "planner",
            "pallet-department-contractor",
          ],
        },
        props: true,
      },
      {
        path: "offers",
        name: "offers",
        component: OffersView,
        meta: {
          roles: [
            "admin",
            "sub-admin",
            "bpl-manager",
            "industry",
            "sub-industry",
            "accounting-client",
            "manager",
            "logistic",
            "sub-logistic",
            "pallet-department-client",
          ],
        },
      },
      {
        path: "offers/:id",
        name: "offer",
        component: OfferView,
        meta: {
          roles: [
            "admin",
            "sub-admin",
            "bpl-manager",
            "industry",
            "sub-industry",
            "accounting-client",
            "manager",
            "logistic",
            "sub-logistic",
            "pallet-department-client",
          ],
        },
        props: true,
      },
      {
        path: "orders",
        name: "orders",
        component: OrdersView,
        meta: {
          roles: [
            "admin",
            "sub-admin",
            "bpl-manager",
            "forwarder",
            "sub-forwarder",
            "planner",
            "accounting-contractor",
            "pallet-department-contractor",
            "industry",
            "sub-industry",
            "accounting-client",
            "manager",
            "logistic",
            "sub-logistic",
            "pallet-department-client",
          ],
        },
      },
      {
        path: "orders/:id",
        name: "order",
        component: OrderView,
        meta: {
          roles: [
            "admin",
            "sub-admin",
            "bpl-manager",
            "forwarder",
            "sub-forwarder",
            "planner",
            "accounting-contractor",
            "pallet-department-contractor",
            "industry",
            "sub-industry",
            "accounting-client",
            "manager",
            "logistic",
            "sub-logistic",
            "pallet-department-client",
          ],
        },
        props: true,
      },
      {
        path: "clients",
        name: "clients",
        component: ClientsView,
        meta: {
          roles: ["admin", "sub-admin", "bpl-manager"],
        },
      },
      {
        path: "clients/:id",
        name: "client",
        component: ClientView,
        meta: {
          roles: ["admin", "sub-admin", "bpl-manager"],
        },
        props: true,
      },
      {
        path: "transporters",
        name: "transporters",
        component: TransportersView,
        meta: {
          roles: ["admin", "sub-admin", "bpl-manager"],
        },
      },
      {
        path: "transporters/:id",
        name: "transporter",
        component: TransporterView,
        meta: {
          roles: ["admin", "sub-admin", "bpl-manager"],
        },
        props: true,
      },
      {
        path: "rides",
        name: "rides",
        component: RidesView,
        meta: {
          roles: [
            "admin",
            "sub-admin",
            "bpl-manager",
            "forwarder",
            "sub-forwarder",
            "planner",
            "accounting-contractor",
            "driver",
            "pallet-department-contractor",
            "industry",
            "sub-industry",
            "accounting-client",
            "manager",
            "logistic",
            "sub-logistic",
            "pallet-department-client",
          ],
        },
      },
      {
        path: "rides/:id",
        name: "ride",
        component: RideView,
        meta: {
          roles: [
            "admin",
            "sub-admin",
            "bpl-manager",
            "forwarder",
            "sub-forwarder",
            "planner",
            "accounting-contractor",
            "driver",
            "pallet-department-contractor",
            "industry",
            "sub-industry",
            "accounting-client",
            "manager",
            "logistic",
            "sub-logistic",
            "pallet-department-client",
          ],
        },
        props: true,
      },
      {
        path: "employees",
        name: "employees",
        component: EmployeesView,
        meta: {
          roles: [
            "admin",
            "sub-admin",
            "forwarder",
            "sub-forwarder",
            "industry",
            "sub-industry",
            "logistic",
            "sub-logistic",
          ],
        },
      },
      {
        path: "profile-settings",
        name: "profile-settings",
        component: ProfileSettingsView,
        meta: {
          roles: [
            "admin",
            "sub-admin",
            "bpl-manager",
            "bpl-calculation-manager",
            "forwarder",
            "sub-forwarder",
            "planner",
            "accounting-contractor",
            "driver",
            "pallet-department-contractor",
            "industry",
            "sub-industry",
            "accounting-client",
            "manager",
            "logistic",
            "sub-logistic",
            "pallet-department-client",
          ],
        },
      },
      //system settings
      {
        path: "season-factor",
        name: "season-factor",
        component: SeasonFactorView,
        meta: {
          roles: ["admin", "sub-admin"],
        },
      },
      {
        path: "pallets",
        name: "pallets",
        component: PalletsView,
        meta: {
          roles: ["admin", "sub-admin"],
        },
      },
      {
        path: "vehicles",
        name: "vehicles",
        component: VehiclesView,
        meta: {
          roles: ["admin", "sub-admin"],
        },
      },
      {
        path: "general",
        name: "general",
        component: GenSettingsView,
        meta: {
          roles: ["admin", "sub-admin"],
        },
      },
      {
        path: "features",
        name: "features",
        component: FeaturesView,
        meta: {
          roles: ["admin", "sub-admin"],
        },
      },
      {
        path: "market-factor",
        name: "market-factor",
        component: MarketFactorView,
        meta: {
          roles: ["admin", "sub-admin"],
        },
      },
      {
        path: "delivery-factor",
        name: "delivery-factor",
        component: DeliveryFactorView,
        meta: {
          roles: ["admin", "sub-admin"],
        },
      },
      {
        path: "postal-code",
        name: "postal-code",
        component: PostalCodeView,
        meta: {
          roles: ["admin", "sub-admin"],
        },
      },
      {
        path: "ferry",
        name: "ferry",
        component: FerryView,
        meta: {
          roles: ["admin", "sub-admin"],
        },
      },
      //test
      ...(process.env.NODE_ENV === "development"
        ? [
            {
              path: "test",
              name: "test",
              component: TestView,
            },
          ]
        : []),
    ],
  },
  //auth
  {
    path: "/password/forgot",
    name: "password-forgot",
    component: AuthPasswordForgot,
    meta: {
      guest: true,
    },
  },
  {
    path: "/password/reset",
    name: "password-reset",
    component: AuthPasswordReset,
    meta: {
      guest: true,
    },
  },
  {
    path: "/email/verify",
    name: "email-verify",
    component: AuthEmailVerify,
    meta: {
      guest: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from) => {
  const isAuth = store.getters["auth/isAuth"];
  if (isAuth) {
    if (to.meta.guest || (to.meta.roles && !access.someRoles(to.meta.roles))) {
      const routeName = access.routeByRole(access.getRole());
      return from.name && !from.meta.guest ? false : { name: routeName };
    }
  } else {
    if (to.meta.auth) {
      return {
        name: "login",
        query: {
          redirect_url: to.path,
        },
      };
    }
  }
});

router.afterEach((to, from) => {
  if (from.name && from.name !== to.name) {
    window.scrollTo({
      top: 0,
    });
  }
});

export default router;
