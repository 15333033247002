<template>
  <v-form
    ref="arrivalForm"
    v-model="arrivalValid"
    @submit.prevent="saveChanges(RideThirdBlockType.arrival)"
  >
    <v-alert
      v-if="(isBplAdmin || isTransporter) && props.editable"
      :text="$t('alert.ride-step1-required')"
      color="warning"
      variant="tonal"
      class="my-8"
      icon="mdi-alert-outline"
    />
    <editable-block
      v-model="editableBlocks"
      :hide-actions="!isEditableBlocks"
      :name="RideThirdBlockType.arrival"
      :loading="arrivalLoading"
      submit
      @cancel="cancelChanges"
    >
      <template #title>
        <p class="text-h5 text-primary py-2">
          {{ $t("actual-arrival") }}
        </p>
      </template>
      <template #edit-btn="{ edit }">
        <v-btn
          v-if="editable"
          variant="text"
          elevation="0"
          color="primary"
          icon="mdi-file-edit-outline"
          @click="edit"
        />
      </template>
      <template #default="{ readonly }">
        <v-row
          class="my-0 pt-4"
          :class="{
            'pointer-events-none': readonly || (isSaved && isTransporter),
          }"
        >
          <v-col cols="12" class="py-0">
            <calendar-field
              v-model="editRideArrival.step_3_arrival_time"
              :title="$t('arrived-loading-point')"
              class="pb-2"
              :chip-format="locFormat.dDMmYHhMmUhr"
              :readonly="readonly || (isSaved && isTransporter)"
              :error-messages="errors.step_3_arrival_time"
              :rules="[rules.required]"
              @update:model-value="errors.step_3_arrival_time = []"
            />
          </v-col>
        </v-row>
      </template>
    </editable-block>
  </v-form>

  <v-form
    ref="statusForm"
    v-model="statusValid"
    @submit.prevent="saveChanges(RideThirdBlockType.status)"
  >
    <editable-block
      v-model="editableBlocks"
      :hide-actions="!isEditableBlocks"
      :name="RideThirdBlockType.status"
      :loading="statusLoading"
      submit
      @cancel="cancelChanges"
    >
      <template #title>
        <p class="text-h5 text-primary py-2">
          {{ $t("charging-status") }}
        </p>
      </template>
      <template #edit-btn="{ edit }">
        <v-btn
          v-if="editable"
          variant="text"
          elevation="0"
          color="primary"
          icon="mdi-file-edit-outline"
          @click="edit"
        />
      </template>
      <template #default="{ readonly }">
        <v-row class="my-0 pt-4">
          <v-col
            :cols="!isPagerNumberVisible ? 12 : 6"
            class="py-0"
            :class="{ 'pointer-events-none': readonly }"
          >
            <v-select
              v-model="editRideStatus.step_3_status"
              :rules="[rules.required]"
              :label="$t('status')"
              :items="
                rideLoadingStatusesKeys.map(({ key, type }) => ({
                  value: type,
                  title: $t(key),
                }))
              "
              :variant="readonly ? 'filled' : 'outlined'"
              class="pb-2"
            ></v-select>
          </v-col>
          <v-col
            v-if="isPagerNumberVisible"
            cols="6"
            class="py-0"
            :class="{ 'pointer-events-none': readonly }"
          >
            <v-text-field
              v-model="editRideStatus.step_3_page"
              :rules="[rules.lengthMin(5), rules.pageNumber]"
              :label="$t('pager-number')"
              :readonly="readonly || (!!props.ride.step_3_page && !isBplAdmin)"
              :variant="
                readonly || (!!props.ride.step_3_page && !isBplAdmin)
                  ? 'filled'
                  : 'outlined'
              "
              class="pb-2"
              :error-messages="errors.step_3_page"
              @update:model-value="errors.step_3_page = []"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-alert
          v-if="props.editable && (isBplAdmin || isTransporter) && isSaved && readonly"
          :text="$t('alert.ride-step3-status')"
          color="info"
          variant="tonal"
          class="my-8"
        />
      </template>
    </editable-block>
  </v-form>

  <div
    v-if="props.editable && (isTransporter || isBplAdmin)"
    class="d-flex justify-end"
  >
    <template v-if="isTransporter"
      ><v-btn
        v-if="isBplAdmin || isTransporterEditable"
        :loading="loading"
        type="button"
        color="primary"
        size="large"
        :disabled="isBplAdmin && !!editableBlocks.length"
        @click="saveAllChanges"
      >
        {{ $t("button.save-and-send") }}
      </v-btn>
      <v-btn
        v-if="!isTransporterEditable"
        type="button"
        color="primary"
        size="large"
        @click="isTransporterEditable = true"
      >
        {{ $t("button.update-status") }}
      </v-btn></template
    >
    <v-btn
      v-else
      type="button"
      color="primary"
      size="large"
      :disabled="!!editableBlocks.length"
      :loading="manuallyLoading"
      @click="switchManually"
    >
      {{ $t("button.switch-manually") }}
    </v-btn>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, ref, watch } from "vue";

import { updateRide } from "@/api/rides";

import { useRules } from "@/utils/rules";
import { useLocFormat } from "@/utils/locFormat";
import { getInitialEditRideErrorObject } from "@/utils/rides";
import { useValidHelper } from "@/utils/validHelper";
import { access } from "@/utils/access";
import { useMountScroller } from "@/utils/mountScroller";
import { rideLoadingStatusesKeys } from "@/utils/other";
import { snackbar } from "@/utils/snackbar";

import { VForm } from "vuetify/components";
import {
  EditRideObject,
  RideThirdBlockType,
  RideObject,
  RideLoadingStatus,
} from "@/types/rides";

import CalendarField from "@/components/CalendarField.vue";
import EditableBlock from "@/components/EditableBlock.vue";

const include = "document.carrier.users";

const rules = useRules();
const locFormat = useLocFormat();

const editableBlocks = ref<RideThirdBlockType[]>([]);

const props = defineProps<{
  ride: RideObject;
  editable?: boolean;
}>();

const editRideArrival = ref<EditRideObject>({}); //step_3_arrival_time
const editRideStatus = ref<EditRideObject>({}); //step_3_status, step_3_page

const loading = ref(false);

useMountScroller();

//status
const statusValid = ref(false);
const statusForm = ref<VForm>();
const statusLoading = ref(false);

useValidHelper(statusForm);

//arrival
const arrivalValid = ref(false);
const arrivalForm = ref<VForm>();
const arrivalLoading = ref(false);

useValidHelper(arrivalForm);

const errors = ref(getInitialEditRideErrorObject());

const manuallyLoading = ref(false);

const syncEdits = (value: RideObject) => {
  //arrival sync
  if (!editableBlocks.value.includes(RideThirdBlockType.arrival)) {
    editRideArrival.value.step_3_arrival_time = value.step_3_arrival_time;

    errors.value.step_3_arrival_time = [];
  }
  //status sync
  if (!editableBlocks.value.includes(RideThirdBlockType.status)) {
    editRideStatus.value.step_3_status = value.step_3_status;
    editRideStatus.value.step_3_page = value.step_3_page;

    errors.value.step_3_page = [];
  }
};

const cancelChanges = () => {
  nextTick(() => {
    syncEdits(props.ride);
  });
};

const closeBlock = (name: RideThirdBlockType) => {
  editableBlocks.value = editableBlocks.value.filter(
    (blockName) => blockName !== name,
  );
};

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "updateRide", data: RideObject): void;
}>();

const validAll = async () => {
  await Promise.all([
    arrivalForm.value?.validate(),
    statusForm.value?.validate(),
  ]);

  return arrivalValid.value && statusValid.value;
};

const finStatusData = computed(() => {
  return {
    ...editRideStatus.value,
    step_3_page: editRideStatus.value.step_3_page || undefined,
  } as EditRideObject;
});
const saveAllChanges = async () => {
  if (await validAll()) {
    try {
      loading.value = true;
      const response = await updateRide(
        props.ride.id,
        {
          ...editRideArrival.value,
          ...finStatusData.value,
          step:
            finStatusData.value.step_3_status === RideLoadingStatus.loaded
              ? 4
              : undefined,
        },
        {
          include,
        },
      );

      emit("updateRide", response.data);
      isTransporterEditable.value = false;
    } catch (e: any) {
      errors.value = {
        ...getInitialEditRideErrorObject(),
        ...e.errors,
      };
    } finally {
      loading.value = false;
    }
  }
};

const switchManually = async () => {
  if (await validAll()) {
    try {
      manuallyLoading.value = true;
      const response = await updateRide(
        props.ride.id,
        {
          step: 4,
        },
        {
          include,
        },
      );

      emit("updateRide", response.data);
    } catch (e: any) {
      snackbar(e.message);
    } finally {
      manuallyLoading.value = false;
    }
  }
};
const saveChanges = async (name: RideThirdBlockType) => {
  let response: { data: RideObject } | undefined;
  let error: any = {};

  switch (name) {
    case RideThirdBlockType.arrival:
      await arrivalForm.value?.validate();
      if (arrivalValid.value) {
        try {
          arrivalLoading.value = true;
          response = await updateRide(props.ride.id, editRideArrival.value, {
            include,
          });
          closeBlock(RideThirdBlockType.arrival);
        } catch (e: any) {
          error = e;
        } finally {
          arrivalLoading.value = false;
        }
      }
      break;
    case RideThirdBlockType.status:
      await statusForm.value?.validate();
      if (statusValid.value) {
        try {
          statusLoading.value = true;
          response = await updateRide(props.ride.id, finStatusData.value, {
            include,
          });
          closeBlock(RideThirdBlockType.status);
        } catch (e: any) {
          error = e;
        } finally {
          statusLoading.value = false;
        }
      }
      break;
  }
  if (error?.errors) {
    errors.value = {
      ...getInitialEditRideErrorObject(),
      ...error.errors,
    };
  }
  if (response?.data) emit("updateRide", response.data);
};

const isPagerNumberVisible = computed(
  () =>
    !!props.ride.step_3_page ||
    editRideStatus.value.step_3_status === RideLoadingStatus.hasPager,
);

const isBplAdmin = computed(() =>
  access.someRoles(["admin", "sub-admin", "bpl-manager"]),
);

const isTransporter = computed(() =>
  access.someRoles([
    "forwarder",
    "sub-forwarder",
    "planner",
    "accounting-contractor",
    "driver",
    "pallet-department-contractor",
  ]),
);

const isSaved = computed(() => !!props.ride.step_3_status);

const isTransporterEditable = ref(!isSaved.value);

const isEditableBlocks = computed(() => isBplAdmin.value && props.editable);

const isEditable = computed(() => {
  if (isBplAdmin.value) {
    return props.editable;
  } else if (isTransporter.value) {
    return isTransporterEditable.value && props.editable;
  }
  return false;
});

watch(
  () => props.ride,
  (value) => {
    syncEdits(value);
  },
  { immediate: true },
);

watch(
  () => !isEditableBlocks.value && isEditable.value,
  (value) => {
    if (value) {
      editableBlocks.value = Object.values(
        RideThirdBlockType,
      ) as RideThirdBlockType[];
    } else {
      editableBlocks.value = [];
    }
  },
  { immediate: true },
);
</script>

<style scoped></style>
