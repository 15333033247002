<template>
  <div class="pt-6" :class="{ 'pointer-events-none': props.readonly }">
    <async-company-selector
      v-if="isBplAdmin"
      :model-value="documentObject.company_id"
      :rules="[rules.required]"
      :title="$t(`company-name`)"
      :readonly="props.readonly"
      class="pb-2"
      :type="CompanyQueryType.customers"
      @changed="adminCompanyObject = $event"
      @update:model-value="vModalUpdate('company_id', $event)"
    />
    <template v-if="companyData">
      <v-row class="my-0">
        <v-col class="py-0" cols="6">
          <fake-input
            class="pb-2"
            :value="companyData.name"
            :label="$t('company-name')"
          ></fake-input
        ></v-col>
        <v-col class="py-0" cols="6">
          <fake-input
            class="pb-2"
            :value="companyData.addendum"
            :label="$t('company-addendum')"
          ></fake-input
        ></v-col>
        <v-col class="py-0" cols="6">
          <fake-input
            class="pb-2"
            :value="companyData.headquarters_address.data.street"
            :label="$t('street')"
          ></fake-input
        ></v-col>
        <v-col class="py-0" cols="6">
          <fake-input
            class="pb-2"
            :value="companyData.headquarters_address.data.zip"
            :label="$t('zip-code')"
          ></fake-input
        ></v-col>
        <v-col class="py-0" cols="6">
          <fake-input
            class="pb-2"
            :value="companyData.headquarters_address.data.city"
            :label="$t('town')"
          ></fake-input
        ></v-col>
        <v-col class="py-0" cols="6">
          <fake-input
            class="pb-2"
            :value="companyData.headquarters_address.data.country.data.name"
            :label="$t('country')"
          ></fake-input>
        </v-col>
      </v-row>
      <v-divider />
      <p class="text-h5 text-primary pb-2 pt-12">
        {{ $t("administrator") }}
      </p>
      <v-row class="my-0 pt-6">
        <v-col class="py-0">
          <v-select
            :model-value="documentObject.user_id"
            :error-messages="errors.user_id"
            :label="$t('dispatcher')"
            :items="companyProfiles"
            :rules="[rules.required]"
            :readonly="props.readonly"
            :variant="props.readonly ? 'filled' : 'outlined'"
            class="pb-2"
            @update:model-value="vModalUpdate('user_id', $event)"
          ></v-select>
        </v-col>
      </v-row>
      <div>
        <p v-if="isNoSelection" class="text-h5 text-primary pt-4">
          {{ $t("contact") }}
        </p>
        <v-row v-if="contactProfile" class="my-0 pt-6">
          <v-col cols="6" class="py-0">
            <fake-input
              class="pb-2"
              :value="salutationLabel"
              :label="$t('salutation')"
            ></fake-input>
          </v-col>
          <v-col cols="6" class="py-0">
            <fake-input
              class="pb-2"
              :value="contactProfile?.email"
              :label="$t('email')"
            ></fake-input>
          </v-col>
          <v-col cols="6" class="py-0">
            <fake-input
              class="pb-2"
              :value="contactProfile?.firstname"
              :label="$t('name')"
            ></fake-input>
          </v-col>
          <v-col cols="6" class="py-0">
            <fake-input
              class="pb-2"
              :value="contactProfile?.phone"
              :label="$t('phone')"
            ></fake-input>
          </v-col>
          <v-col cols="6" class="py-0">
            <fake-input
              class="pb-2"
              :value="contactProfile?.lastname"
              :label="$t('last-name')"
            ></fake-input>
          </v-col>
        </v-row>
      </div>
      <div class="pt-6">
        <p class="text-h5 text-primary">
          {{ $t("different-billing-address") }}
        </p>
        <v-row class="my-0 pt-6">
          <v-col cols="6" class="py-0">
            <fake-input
              class="pb-2"
              :value="companyData.billing_address?.data.street"
              :label="$t('street')"
            ></fake-input>
          </v-col>
          <v-col cols="6" class="py-0">
            <fake-input
              class="pb-2"
              :value="companyData.billing_address?.data.zip"
              :label="$t('zip-code')"
            ></fake-input>
          </v-col>
          <v-col cols="6" class="py-0">
            <fake-input
              class="pb-2"
              :value="companyData.billing_address?.data.city"
              :label="$t('location')"
            ></fake-input>
          </v-col>
          <v-col cols="6" class="py-0">
            <fake-input
              class="pb-2"
              :value="companyData.billing_address?.data.country.data.name"
              :label="$t('country')"
            ></fake-input>
          </v-col>
        </v-row>
      </div>
    </template>
    <app-loader v-else-if="!(isBplAdmin && !documentObject.company_id)" />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";

import { useRules } from "@/utils/rules";
import {
  getLocalDocumentObject,
  getInitialDocumentErrorObject,
} from "@/utils/calculator";
import { access } from "@/utils/access";

import { CompanyData } from "@/types/company";
import { DocumentErrorObject, DocumentObject } from "@/types/document";
import { ProfileData } from "@/types/auth";
import { CompanyQueryType } from "@/types/company";

import FakeInput from "@/components/FakeInput.vue";
import { getSalutationName } from "@/utils/other";
import AsyncCompanySelector from "@/components/AsyncCompanySelector.vue";
import AppLoader from "@/components/AppLoader.vue";

const rules = useRules();
const store = useStore();
const { t } = useI18n();

const props = defineProps<{
  modelValue?: DocumentObject;
  readonly?: boolean;
  errors?: DocumentErrorObject;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", data?: DocumentObject): void;
  (e: "update:errors", data: DocumentErrorObject): void;
}>();

const documentObject = computed(
  () => props.modelValue || getLocalDocumentObject(),
);

const errors = computed<DocumentErrorObject>({
  get() {
    return props.errors || getInitialDocumentErrorObject();
  },
  set(value?: DocumentErrorObject): void {
    emit("update:errors", value || getInitialDocumentErrorObject());
  },
});

const adminCompanyObject = ref<CompanyData>();

const companyData = computed<CompanyData | null>(
  () =>
    (isBplAdmin.value
      ? adminCompanyObject.value
      : store.getters["auth/company"]) || null,
);

const profileId = computed<string | undefined>(
  () => store.state.auth.profile?.id,
);

const companyProfiles = computed(() => {
  return (
    companyData.value?.users?.data.map((user) => ({
      title:
        user.id === profileId.value
          ? t("no-selection")
          : `${user.firstname} ${user.lastname}`,
      value: user.id,
    })) || []
  );
});

const isNoSelection = computed(
  () => !isBplAdmin.value && documentObject.value.user_id === profileId.value,
);

const contactProfile = computed(
  () =>
    (isNoSelection.value
      ? store.state.auth.profile
      : (companyData.value?.users?.data || []).find(
          ({ id }: ProfileData) => id === documentObject.value.user_id,
        )) || null,
);

const salutationLabel = computed(() => {
  const value = contactProfile.value?.salutation;
  return value ? t(getSalutationName(value)) : "";
});

const isBplAdmin = computed(() =>
  access.someRoles([
    "admin",
    "sub-admin",
    "bpl-manager",
    "bpl-calculation-manager",
  ]),
);

const vModalUpdate = (field: keyof DocumentObject, value: any) => {
  errors.value[field as keyof DocumentErrorObject] = [];
  emit("update:modelValue", {
    ...documentObject.value,
    [field]: value,
  });
};

watch(adminCompanyObject, (value) => {
  if (
    !value?.users.data.some(({ id }) => id === documentObject.value.user_id)
  ) {
    vModalUpdate("user_id", undefined);
  }
});

watch(
  () => ({
    company: documentObject.value.company_id,
    user: documentObject.value.user_id,
  }),
  (value) => {
    const companyId = companyData.value?.id;
    const contact = companyData.value?.contact?.data?.id;

    if (!value.company && companyId) {
      vModalUpdate("company_id", companyId);
    }
    if (!value.user && contact) {
      vModalUpdate("user_id", contact);
    }
  },
);

onMounted(() => {
  if (!isBplAdmin.value && !documentObject.value.user_id) {
    vModalUpdate("user_id", profileId.value);
  }
});
</script>

<style scoped></style>
