import { FeatureType } from "@/types/settings";

export const featuresGroups: { name: FeatureType; key: string }[] = [
  { name: FeatureType.adr, key: "adr" },
  { name: FeatureType.languageSkills, key: "language-skills" },
  { name: FeatureType.other, key: "other" },
  { name: FeatureType.driving_skills, key: "driving-skills" },
];

export const getFeatureKey = (name: FeatureType) =>
  featuresGroups.find((f) => f.name === name)?.key as string;
