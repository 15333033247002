<template>
  <v-row no-gutters>
    <v-col cols="12" class="pa-10">
      <v-sheet class="pb-12 pt-11 px-10 rounded-xl bpl-border-gray">
        <v-form ref="form" v-model="valid" @submit.prevent="saveChanges()">
          <editable-block v-model="edited" :loading="editLoading" submit>
            <template #title>
              <p class="text-h4 text-primary font-weight-light py-1">
                {{ $t("land-delivery-factor") }}
              </p>
            </template>
            <template #header-content>
              <div class="mr-2">
                <export-loader
                  v-if="isExported || exportLoading"
                  :tooltip="$t('loader.download-prepared')"
                />
              </div>
            </template>
            <template #actions>
              <v-menu :key="isDataReady.toString()">
                <template #activator="{ props: menuProps }">
                  <v-btn
                    class="ml-2"
                    icon="mdi-dots-vertical"
                    variant="plain"
                    elevation="0"
                    v-bind="menuProps"
                  ></v-btn>
                </template>
                <v-list min-width="230">
                  <v-list-item
                    :disabled="uploadLoading"
                    @click.stop="csvInput && csvInput.click()"
                  >
                    <v-list-item-title>{{
                      $t("button.upload-csv")
                    }}</v-list-item-title>
                    <template #append>
                      <v-icon icon="mdi-upload" color="grey-darken-2"></v-icon>
                    </template>
                    <input
                      :key="isDataReady.toString()"
                      ref="csvInput"
                      type="file"
                      accept="text/csv"
                      class="d-none"
                      name="csv"
                      @input="csvInputHandler"
                    />
                  </v-list-item>
                  <v-list-item @click="exportCsv()">
                    <v-list-item-title>{{
                      $t("button.csv-export")
                    }}</v-list-item-title>
                    <template #append>
                      <v-icon
                        icon="mdi-file-table"
                        color="grey-darken-2"
                      ></v-icon>
                    </template>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template #default="{ readonly }">
              <div class="mt-9">
                <div
                  v-if="edited"
                  class="px-4 pt-8 pb-4 bg-grey-lighten-5 mb-10"
                >
                  <p class="text-h5 text-primary pb-8">
                    {{ $t("edit-land-delivery-factor") }}
                  </p>
                  <v-row class="my-0">
                    <v-col cols="4" class="py-0">
                      <fake-input
                        class="pb-2"
                        :value="
                          deliveryFactorById?.loadingPointCountry.data.code.toUpperCase()
                        "
                        :label="$t('loading-point-country-code')"
                      ></fake-input>
                    </v-col>
                    <v-col cols="4" class="py-0">
                      <fake-input
                        class="pb-2"
                        :value="
                          deliveryFactorById?.unloadingPointCountry.data.code.toUpperCase()
                        "
                        :label="$t('unloading-point-country-code')"
                      ></fake-input>
                    </v-col>
                    <v-col cols="4" class="py-0">
                      <v-text-field
                        v-model="editDeliveryFactor.client_factor"
                        :error-messages="errors.client_factor"
                        :label="$t('client-factor')"
                        type="number"
                        hide-spin-buttons
                        append-inner-icon="mdi-percent"
                        class="pb-2"
                        :readonly="readonly"
                        :variant="readonly ? 'filled' : 'outlined'"
                        :bg-color="!readonly ? ('white' as any) : undefined"
                        :rules="[rules.floatNumberOnly]"
                        @update:model-value="errors.client_factor = []"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2" class="py-0">
                      <v-checkbox
                        v-model="editDeliveryFactor.active"
                        :label="$t('active')"
                        color="primary"
                        :disabled="readonly"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </div>
                <div class="bpl-border-gray rounded-lg">
                  <v-row no-gutters class="px-4 py-3">
                    <v-col cols="3">
                      <v-autocomplete
                        v-model="countries"
                        :items="store.getters['app/countries']"
                        item-title="title"
                        item-value="id"
                        clearable
                        multiple
                        variant="outlined"
                        hide-details
                        class="bpl-no-selection"
                      >
                        <template #prepend-inner>
                          <p class="text-no-wrap">
                            {{ $t("loading-point-country") }}
                            {{
                              countries.length ? `(${countries.length})` : ""
                            }}
                          </p></template
                        >
                        <template #item="{ props, item }">
                          <v-list-item v-bind="props">
                            <template #prepend>
                              <v-checkbox-btn
                                v-model="countries"
                                :value="item.value"
                                color="primary"
                                @click.stop
                              >
                              </v-checkbox-btn>
                            </template>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-data-table-server
                    :headers="headers"
                    :items="tableItems"
                    class="bpl-custom-table-footer elevation-0 rounded-lg bpl-table-height-1096"
                    :items-per-page="pagData.per_page"
                    :page="pagData.current_page"
                    :items-length="pagData.total"
                    :items-per-page-options="[]"
                    :loading="loading"
                    @update:options="
                      fetchDeliveryFactors(
                        $event.page,
                        finalSearchString,
                        $event.sortBy[0],
                      )
                    "
                  >
                    <template #[`item.active`]="{ item }">
                      <span v-if="item.active">{{ $t("text.yes") }}</span>
                      <span v-else class="text-red-lighten-1">{{
                        $t("text.no")
                      }}</span>
                    </template>
                    <template #[`item.actions`]="{ item }">
                      <v-menu>
                        <template #activator="{ props: menuProps }">
                          <v-btn
                            icon="mdi-dots-vertical"
                            variant="plain"
                            elevation="0"
                            v-bind="menuProps"
                          ></v-btn>
                        </template>
                        <v-list min-width="230">
                          <v-list-item @click="clickEdit(item.id)">
                            <v-list-item-title>{{
                              $t("button.edit")
                            }}</v-list-item-title>
                            <template #append>
                              <v-icon
                                icon="mdi-file-edit"
                                color="grey-darken-2"
                              ></v-icon>
                            </template>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </template>
                  </v-data-table-server>
                </div>
              </div>
            </template> </editable-block
        ></v-form> </v-sheet></v-col
  ></v-row>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useStore } from "@/store";
import { useI18n } from "vue-i18n";

import {
  getDeliveryFactors,
  setDeliveryFactors,
  updateDeliveryFactor,
} from "@/api/settings";

import { snackbar } from "@/utils/snackbar";
import { useRules } from "@/utils/rules";
import { useValidHelper } from "@/utils/validHelper";
import { getInitialDeliveryFactorErrorObject } from "@/utils/settings";
import { initialPagData, prepSearchString, useFormat } from "@/utils/other";
import { useWatchDebounceHelper } from "@/utils/watchDebounceHelper";

import { VForm } from "vuetify/components";
import { ExportNotificationType, SortData } from "@/types/other";
import {
  EditDeliveryFactor,
  DeliveryFactor,
  DeliveryFactorErrorObject,
} from "@/types/settings";

import EditableBlock from "@/components/EditableBlock.vue";
import FakeInput from "@/components/FakeInput.vue";
import ExportLoader from "@/components/ExportLoader.vue";

const { t } = useI18n();
const toFormat = useFormat();

const sortData = ref<SortData>();

const pagData = ref(initialPagData);

const deliveryFactorId = ref("");

const initialEditDeliveryFactor: EditDeliveryFactor = {
  active: false,
  client_factor: "",
};

const deliveryFactors = ref<DeliveryFactor[]>([]);

const errors = ref<DeliveryFactorErrorObject>(
  getInitialDeliveryFactorErrorObject(),
);
const store = useStore();

const countries = ref<string[]>([]);

const editDeliveryFactor = ref<EditDeliveryFactor>({
  ...initialEditDeliveryFactor,
});

const rules = useRules();

const valid = ref(false);
const form = ref<VForm>();

useValidHelper(form);

const edited = ref(false);

const loading = ref(false);
const editLoading = ref(false);

const uploadLoading = ref(false);

const csvInput = ref<HTMLInputElement>();

const exportLoading = ref(false);

const csvInputHandler = async (e: any) => {
  const file: File = e.target.files[0];
  if (file) {
    try {
      uploadLoading.value = true;
      await setDeliveryFactors(file);
      deliveryFactors.value = [];
      await fetchDeliveryFactors(1, finalSearchString.value, sortData.value);
    } catch (e: any) {
      snackbar(e.message);
    } finally {
      uploadLoading.value = false;
    }
  }
};

const exportCsv = async () => {
  try {
    exportLoading.value = true;
    await store.dispatch("app/exportFiles", {
      type: ExportNotificationType.lzfCsvExport,
      ids: [],
    });
  } catch (e: any) {
    snackbar(e.message);
  } finally {
    exportLoading.value = false;
  }
};

const isExported = computed(() =>
  store.state.app.exportsQueue.some(
    (e) => e.type === ExportNotificationType.lzfCsvExport,
  ),
);

const deliveryFactorById = computed<DeliveryFactor | null>(
  () =>
    deliveryFactors.value.find((p) => p.id === deliveryFactorId.value) || null,
);

const clickEdit = (id: string) => {
  deliveryFactorId.value = id;
  edited.value = true;

  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};
const saveChanges = async () => {
  await form.value?.validate();
  if (valid.value) {
    try {
      editLoading.value = true;
      const { data } = await updateDeliveryFactor(
        deliveryFactorId.value,
        editDeliveryFactor.value,
      );
      deliveryFactors.value = deliveryFactors.value.map((d) =>
        d.id === data.id ? data : d,
      );
      edited.value = false;
    } catch (e: any) {
      snackbar(e.message);
      errors.value = { ...getInitialDeliveryFactorErrorObject(), ...e.errors };
    } finally {
      editLoading.value = false;
    }
  }
};

const finalSearchString = computed(() =>
  prepSearchString({
    title: "loading_point_country.code",
    value: countries.value
      .map((id) => store.getters["app/getCountryById"](id)?.code)
      .filter((code) => code),
  }),
);

const isDataReady = computed(() => !!deliveryFactors.value.length);

useWatchDebounceHelper<string>(finalSearchString, 400, (value) => {
  fetchDeliveryFactors(1, value, sortData.value);
});

const fetchDeliveryFactors = async (
  page: number,
  search: string,
  sort?: SortData,
) => {
  if (!loading.value) {
    try {
      loading.value = true;
      const {
        data,
        meta: { pagination },
      } = await getDeliveryFactors({
        page,
        search,
        searchJoin: "or",
        ...(sort ? { orderBy: sort.key, sortedBy: sort.order } : {}),
      });
      deliveryFactors.value = data;
      pagData.value = pagination;

      //close edit when list is changed
      edited.value = false;
    } catch (e: any) {
      snackbar(e.message);
    } finally {
      sortData.value = sort;
      loading.value = false;
    }
  }
};

const tableItems = computed(() =>
  deliveryFactors.value.map((f) => ({
    loadingCountry: f.loadingPointCountry.data.code.toUpperCase(),
    unloadingCountry: f.unloadingPointCountry.data.code.toUpperCase(),
    client_factor: toFormat(f.client_factor),
    active: f.active,
    id: f.id,
  })),
);

const headers = computed(
  () =>
    [
      {
        title: t("loading-point-country-code"),
        key: "loadingCountry",
        sortable: false,
      },
      {
        title: t("unloading-point-country-code"),
        key: "unloadingCountry",
        sortable: false,
      },
      {
        title: t("client-factor"),
        key: "client_factor",
        sortable: true,
      },
      {
        title: t("active"),
        key: "active",
        sortable: true,
      },
      {
        title: "",
        key: "actions",
        width: "48px",
        align: "end",
        sortable: false,
      },
    ] as any[],
);

watch(edited, (value) => {
  if (!value) {
    deliveryFactorId.value = "";
    editDeliveryFactor.value = { ...initialEditDeliveryFactor };
  }
});

watch(deliveryFactorById, (value, oldValue) => {
  if (value?.id !== oldValue?.id) {
    editDeliveryFactor.value = value
      ? {
          active: value.active,
          client_factor: String(value.client_factor),
        }
      : {
          ...initialEditDeliveryFactor,
        };
  }
});
</script>

<style></style>
