<template>
  <v-row class="my-0 py-6" :class="{ 'pointer-events-none': props.readonly }">
    <v-col cols="12" class="py-0">
      <v-select
        :model-value="documentObject.transport_type_id"
        :error-messages="errors.transport_type_id"
        autocomplete="off"
        :rules="[rules.required]"
        :label="$t('vehicle-type')"
        :items="transportTypes"
        item-value="code"
        item-title="name"
        :readonly="props.readonly"
        :variant="props.readonly ? 'filled' : 'outlined'"
        class="pb-2"
        @update:model-value="vModalUpdate('transport_type_id', $event)"
      ></v-select>
    </v-col>
    <v-col cols="12" class="py-0">
      <v-tooltip text="Lorem Ipsum" location="bottom">
        <template #activator="{ props: tooltipProps }">
          <span
            class="bpl-underlined-text text-subtitle-1"
            v-bind="tooltipProps"
          />
          {{ $t("equipment") }}
        </template>
      </v-tooltip>
      <v-chip
        v-for="(item, i) in currentEquipment"
        :key="i"
        size="default"
        class="ml-4"
      >
        {{ item }}
      </v-chip>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";

import { useRules } from "@/utils/rules";
import {
  getLocalDocumentObject,
  getInitialDocumentErrorObject,
} from "@/utils/calculator";
import { access } from "@/utils/access";

import { DocumentErrorObject, DocumentObject } from "@/types/document";

const rules = useRules();
const store = useStore();
const { t } = useI18n();

const props = defineProps<{
  modelValue?: DocumentObject;
  readonly?: boolean;
  errors?: DocumentErrorObject;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", data?: DocumentObject): void;
  (e: "update:errors", data: DocumentErrorObject): void;
}>();

const documentObject = computed(
  () => props.modelValue || getLocalDocumentObject(),
);

const errors = computed<DocumentErrorObject>({
  get() {
    return props.errors || getInitialDocumentErrorObject();
  },
  set(value?: DocumentErrorObject): void {
    emit("update:errors", value || getInitialDocumentErrorObject());
  },
});

const needFerry = computed(() => {
  const fullStationsList = [
    documentObject.value.firstStationData,
    ...documentObject.value.stationsList,
    documentObject.value.lastStationData,
  ];

  return fullStationsList.some(({ country_id }) => {
    const country = store.getters["app/getCountryById"](country_id);
    return country && country.ferry > 0;
  });
});

const isBplAdmin = computed(() =>
  access.someRoles([
    "admin",
    "sub-admin",
    "bpl-manager",
    "bpl-calculation-manager",
  ]),
);

const transportTypes = computed(() => {
  return store.state.app.vehicles.filter(
    ({ active, ferry }) =>
      isBplAdmin.value || (active && (!needFerry.value || ferry)),
  );
});

const currentVehicle = computed(() =>
  store.state.app.vehicles.find(
    ({ code }) => code === documentObject.value.transport_type_id,
  ),
);

const currentEquipment = computed(
  () =>
    currentVehicle.value?.equipment.split(",") || [t("text.default-equipment")],
);

const priceOnRequestOverweight = computed(() => {
  const maxWeight = 24500;
  const goodsWeight = Number(documentObject.value.goods_weight);
  if (goodsWeight > maxWeight) {
    return true;
  }

  if (currentVehicle.value) {
    const vehicleCapacity = Number(currentVehicle.value.capacity);
    if (goodsWeight > vehicleCapacity) {
      return true;
    }
  }

  return false;
});

const priceOnRequestOversize = computed(() => {
  if (currentVehicle.value) {
    const goodsLength = Number(documentObject.value.goods_length);
    const vehicleLength = Number(currentVehicle.value.length);
    if (goodsLength > vehicleLength) {
      return true;
    }

    const goodsWidth = Number(documentObject.value.goods_width);
    const vehicleWidth = Number(currentVehicle.value.width);
    if (goodsWidth > vehicleWidth) {
      return true;
    }

    const goodsHeight = Number(documentObject.value.goods_height);
    const vehicleHeight = Number(currentVehicle.value.height);
    if (goodsHeight > vehicleHeight) {
      return true;
    }
  }

  return false;
});

const vModalUpdate = (field: keyof DocumentObject, value: any) => {
  errors.value[field as keyof DocumentErrorObject] = [];
  emit("update:modelValue", {
    ...documentObject.value,
    [field]: value,
  });
};

watch(
  currentVehicle,
  () => {
    store.commit(
      "calculator/setPriceOnRequestOverweight",
      priceOnRequestOverweight.value,
    );
    store.commit(
      "calculator/setPriceOnRequestOversize",
      priceOnRequestOversize.value,
    );
  },
  { immediate: true },
);
</script>

<style scoped></style>
