<template>
  <div class="d-flex pt-2 pb-6 align-center justify-space-between">
    <p class="text-h5 text-primary">
      {{ $t("documents-for-journey") }}
    </p>
    <router-link to="#" class="bpl-download-files-btn text-primary">
      {{ $t("button.download-receipts") }}
    </router-link>
  </div>
  <ride-file-input
    v-if="!props.readonly"
    class="mb-7"
    :disabled="uploadLoading"
    @uploaded="filesInputHandler"
    @error="emit('uploadError', true)"
  />
  <div v-if="locFiles.length">
    <v-row class="my-0">
      <v-col v-for="(fileData, i) in locFiles" :key="i" cols="4">
        <ride-file-item
          :name="fileData.name"
          :file-data="fileData.data"
          :loading="!fileData.data"
          :remove-loading="removeId === fileData.data?.id"
          :readonly="uploadLoading || props.readonly"
          @remove="removeFile(fileData.data?.id)" /></v-col
    ></v-row>
  </div>
  <div
    v-else-if="props.readonly"
    class="text-body-1 text-grey-darken-1 text-center py-8"
  >
    {{ $t("documents-ride-empty-list") }}
  </div>
</template>

<script setup lang="ts">
import { RideObject, RideSavedFile } from "@/types/rides";
import RideFileItem from "@/components/RideView/RideSeventh/RideFileItem.vue";
import RideFileInput from "@/components/RideView/RideSeventh/RideFileInput.vue";
import { ref, watch } from "vue";
import { setRideFile, updateRide } from "@/api/rides";
import { renameFile } from "@/utils/other";
import { snackbar } from "@/utils/snackbar";

const include = "document.carrier.users";

type LocRideFile = {
  name: string;
  data?: RideSavedFile;
};

const props = defineProps<{
  ride: RideObject;
  readonly?: boolean;
}>();

const uploadLoading = ref(false);
const removeId = ref("");
const locFiles = ref<LocRideFile[]>([]);

const filesInputHandler = async (files: File[]) => {
  let withError = false;

  uploadLoading.value = true;

  emit("uploadError", false);
  emit("uploadSuccess", false);

  for (const fileRaw of files) {
    const file = renameFile(
      fileRaw,
      `TA${props.ride.document.data.number}_${fileRaw.name}`,
    );
    locFiles.value.unshift({ name: file.name });
    try {
      const { data } = await setRideFile(file);
      locFiles.value[0].data = data;
    } catch (e: any) {
      withError = true;
      locFiles.value = locFiles.value.slice(1, locFiles.value.length);
    }
  }

  emit("uploadError", withError);
  emit("uploadSuccess", !withError);

  const filesIds = locFiles.value
    .map((item) => item.data?.id)
    .filter((id): id is string => !!id);

  const { data } = await updateRide(
    props.ride.id,
    {
      step_7_files: filesIds,
    },
    {
      include,
    },
  );

  emit("updateRide", data);

  uploadLoading.value = false;
};

const removeFile = async (id?: string) => {
  if (id) {
    const filesIds = locFiles.value
      .map(({ data }) => data?.id)
      .filter((arrId) => arrId && arrId !== id) as string[];

    try {
      removeId.value = id;
      const { data } = await updateRide(
        props.ride.id,
        {
          step_7_files: filesIds,
        },
        {
          include,
        },
      );

      emit("updateRide", data);
    } catch (e: any) {
      snackbar(e.message);
    } finally {
      removeId.value = "";
    }
  }
};

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "uploadError", data: boolean): void;
  (e: "uploadSuccess", data: boolean): void;
  (e: "updateRide", data: RideObject): void;
}>();

watch(
  () => props.ride.step_7_files.data,
  (files) => {
    locFiles.value = files.map((data) => ({ name: data.name, data }));
  },
  { immediate: true },
);
</script>

<style scoped>
.bpl-download-files-btn {
  text-decoration: underline;
  text-decoration-color: inherit;
  text-underline-offset: 2px;
}
</style>
