import { useI18n } from "vue-i18n";

import { broadcast } from "@/utils/events";
import { useAsyncModal } from "@/utils/asyncModalHelper";

const confirmModal = useAsyncModal();

export const confirmId = confirmModal.id;

export const useConfirm = (
  title = "",
  options?: {
    text?: string | false;
    yes?: string | false;
    no?: string | false;
  }
) => {
  const { t } = useI18n();

  return async () => {
    broadcast("SetConfirm", {
      title: title ? t(title) : "",
      text: options?.text ? t(options?.text) : "",
      yes: options?.yes === false ? "" : t(options?.yes || "text.yes"),
      no: options?.no === false ? "" : t(options?.no || "text.no"),
    });

    return await confirmModal.callback();
  };
};
