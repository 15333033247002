<template>
  <v-row no-gutters>
    <v-col cols="12" class="mb-10">
      <v-sheet class="py-12 px-10 rounded-xl bpl-border-gray">
        <div class="position-relative">
          <p class="text-h5 text-primary mb-8">
            {{ $t("route-data") }}
          </p>
          <v-row class="my-0">
            <v-col cols="12" class="py-0">
              <fake-input
                class="pb-2"
                :value="`${formatDistance} km`"
                :label="$t('distance')"
              ></fake-input>
            </v-col>
            <v-col cols="12" class="py-0">
              <fake-input
                class="pb-2"
                :value="formatDuration"
                :label="$t('duration')"
              ></fake-input>
            </v-col>
          </v-row>
          <p class="text-h5 text-primary mb-8 mt-4">
            {{ $t("current-costs") }}
          </p>
          <fake-input
            class="pb-2"
            :value="formatBasePrice"
            :label="$t('base-price')"
            append-inner-icon="mdi-currency-eur"
          ></fake-input>
          <div class="text-subtitle-1 text-grey-darken-2 pb-2">
            <template v-for="item in calcList || []" :key="item.mane">
              <div
                v-if="item.calculation_type === FeatureCalcType.priceOnRequest"
                class="d-flex justify-space-between py-3 text-error"
              >
                <span>{{ item.name }}:</span>
                <span>{{ $t("price-on-request") }}</span>
              </div>
              <div v-else class="d-flex justify-space-between py-3">
                <span>{{ item.name }}:</span>
                <span>{{ item.cost }} €</span>
              </div>
            </template>
            <div
              v-if="isManual"
              class="d-flex justify-space-between py-3 text-error"
            >
              <span>{{ $t("manual-offer") }}:</span>
              <span>{{ $t("price-on-request") }}</span>
            </div>
          </div>
          <p class="text-h5 text-primary mt-4">
            {{ $t("total") }}
          </p>
          <v-alert
            v-if="isManual"
            :text="$t('notifications.manual-calculation-offer')"
            color="info"
            variant="tonal"
            class="mt-8"
          />
          <v-alert
            v-else-if="isPriceOnRequest"
            :text="$t('notifications.price-on-request')"
            color="info"
            variant="tonal"
            class="mt-8"
          />
          <template v-else>
            <p class="text-h4 font-weight-bold text-primary mb-2">
              {{ formatTotalPrice }} €
            </p>
            <p class="text-subtitle-1">
              {{ $t("text.calculator-vat") }}
            </p>
          </template>
          <app-loader
            v-show="loading"
            height="100%"
            class="rote-data-loader position-absolute w-100 bg-white"
          />
        </div>
      </v-sheet>
    </v-col>
    <async-modal :id="priceAdjustmentModal.id" v-slot="{ resolve, autofocus }">
      <v-card max-width="660" class="px-10 py-12">
        <p class="text-h4 font-weight-light text-primary text-center">
          {{ $t("dialog.price-adjustment-title") }}
        </p>
        <div class="mt-4 text-grey-darken-2 text-center">
          {{ $t("dialog.price-adjustment-text") }}
        </div>
        <v-btn
          :ref="autofocus"
          color="primary"
          size="large"
          class="mb-4 mt-10"
          @click="resolve(ResolveType.confirm)"
        >
          {{ $t("button.ok") }}
        </v-btn>
      </v-card>
    </async-modal>
  </v-row>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted, onUnmounted } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
import moment from "moment";

import { backFormats } from "@/utils/locFormat";
import { calcDocumentDate } from "@/utils/calc";
import { useCalcList } from "@/utils/calcList";
import { useAsyncModal, ResolveType } from "@/utils/asyncModalHelper";
import { getInitialDocumentCalcObject } from "@/utils/calculator";
import { useFormat } from "@/utils/other";

import {
  Vehicle,
  Pallet,
  FeatureObject,
  FeatureCalcType,
} from "@/types/settings";

import FakeInput from "@/components/FakeInput.vue";
import AppLoader from "@/components/AppLoader.vue";
import AsyncModal from "@/components/AsyncModal.vue";

const { locale } = useI18n();
const store = useStore();
const priceAdjustmentModal = useAsyncModal();

const intervalID = ref();
const currentDate = ref(moment().format(backFormats.yMmDdHhMmSs));

const isManual = computed(() => !!store.state.calculator.document.manual);

const priceOnRequestFerry = computed(
  () => store.state.calculator.prepare.ferries.price_on_request,
);

const priceOnRequestOverpriced = computed(
  () => store.state.calculator.priceOnRequest.overpriced,
);

const priceOnRequestOverweight = computed(
  () => store.state.calculator.priceOnRequest.overweight,
);

const priceOnRequestOversize = computed(
  () => store.state.calculator.priceOnRequest.oversize,
);

const isPriceOnRequest = computed(
  () =>
    isManual.value ||
    priceOnRequestFerry.value ||
    priceOnRequestOverpriced.value ||
    priceOnRequestOverweight.value ||
    priceOnRequestOversize.value ||
    store.state.calculator.priceOnRequest.features ||
    !!store.state.calculator.calc.priceOnRequestTime,
);

const loading = computed(
  () =>
    store.state.calculator.seasonFactorLoading ||
    store.state.calculator.prepareLoading ||
    !store.state.app.settings ||
    (store.state.app.palletsLoading && !store.state.app.pallets.length) ||
    (store.state.app.vehiclesLoading && !store.state.app.vehicles.length) ||
    (store.state.app.featuresLoading && !store.state.app.features.length),
);

const calcData = computed(() => {
  if (loading.value) {
    return getInitialDocumentCalcObject();
  }
  const loadingTimeStart = store.state.calculator.document.loading_time_start;
  const loadingTimeEnd = store.state.calculator.document.loading_time_end;
  const dischargeTimeStart =
    store.state.calculator.document.unloading_time_start;
  const dischargeTimeEnd = store.state.calculator.document.unloading_time_end;
  const seasonFactor = store.state.calculator.seasonFactor;
  const {
    distance,
    ferries,
    plz,
    lzf: { client_factor },
    market_factor,
    market_factor: market_factor_24,
  } = store.state.calculator.prepare;

  const boatCost = ferries.price_on_request ? 0 : ferries.boat;
  const railCost = ferries.price_on_request ? 0 : ferries.rail;

  const {
    base_price_multiplier,
    commission_normal,
    commission_express_1,
    commission_express_2,
    commission_express_3,
    commission_express_4,
  } = store.state.app.settings || {
    commission_normal: 0.2,
    commission_express_1: 0.2,
    commission_express_2: 0.2,
    commission_express_3: 0.2,
    commission_express_4: 0.2,
    base_price_multiplier: 0.2,
  };

  const commissions = {
    commission_normal,
    commission_express_1,
    commission_express_2,
    commission_express_3,
    commission_express_4,
  };

  const points = store.state.calculator.document.stationsList.length;

  const fullStationsList = [
    store.state.calculator.document.firstStationData,
    ...store.state.calculator.document.stationsList,
    store.state.calculator.document.lastStationData,
  ];

  const forwarderDischargedPoints = fullStationsList.filter(
    ({ discharged }) => discharged,
  ).length;

  const ftCode = store.state.calculator.document.transport_type_id;
  const transportTypes: Vehicle[] = store.state.app.vehicles;
  const ft = transportTypes.find(({ code }) => code === ftCode) || {
    id: "",
    active: false,
    calculation_type: 1,
    length: "",
    width: "",
    height: "",
    capacity: "",
    volume: "",
    equipment: "",
    ferry: false,
    name: "",
    code: "",
    value: 0,
  };

  const { adr, languageSkills, other } = store.state.calculator.document;

  const specialFeatureCodes = [...other];
  if (adr) {
    specialFeatureCodes.push(adr);
  }
  if (languageSkills) {
    specialFeatureCodes.push(languageSkills);
  }

  const specialFeatures = specialFeatureCodes.reduce(
    (filtered: FeatureObject[], currCode) => {
      const item = store.state.app.features.find(
        ({ code }) => code === currCode,
      );
      if (item) {
        filtered.push(item);
      }
      return filtered;
    },
    [],
  );

  const palletCode = store.state.calculator.document.pallet_exchange_id;
  const palletTypes: Pallet[] = store.state.app.pallets;
  const pt = palletTypes.find(({ code }) => code === palletCode)?.price || 0;

  return calcDocumentDate({
    dist: distance,
    PLZ: plz,
    S: seasonFactor,
    commissions,
    LZFK: client_factor,
    T: market_factor,
    TE: market_factor_24,
    BPM: base_price_multiplier,
    points: points,
    fDPoints: forwarderDischargedPoints,
    loadingDateStart: loadingTimeStart,
    loadingDateEnd: loadingTimeEnd,
    unloadingDateStart: dischargeTimeStart,
    unloadingDateEnd: dischargeTimeEnd,
    currentDate: currentDate.value,
    dateFormat: backFormats.yMmDdHhMmSs,
    rawFT: ft,
    rawBE: specialFeatures,
    PT: pt,
    boatCost,
    railCost,
  });
});

const palletCode = computed(
  () => store.state.calculator.document.pallet_exchange_id,
);

const storeCalcData = computed(() => store.state.calculator.calc);

const toFormat = useFormat();
const { calcList } = useCalcList(storeCalcData, palletCode);

const formatDuration = computed(() =>
  moment
    .duration(calcData.value.duration, "hours")
    .locale(locale.value)
    .humanize({ h: 48, d: 7, w: 4 }),
);

const formatDistance = computed(() => toFormat(calcData.value.distance, 2));
const formatBasePrice = computed(() => toFormat(calcData.value.basePrice, 2));
const formatTotalPrice = computed(() => toFormat(calcData.value.totalPrice, 2));

watch(
  [
    calcData,
    priceOnRequestOverpriced,
    priceOnRequestOverweight,
    priceOnRequestOversize,
    priceOnRequestFerry,
  ],
  ([newDocumentCost, newOverpriced, newOverweight, newOversize, newFerry]) => {
    const AVERAGE_SPEED_EUROPE = 70;
    const MAX_TIME = 35;
    const MAX_DISTANCE = AVERAGE_SPEED_EUROPE * MAX_TIME;

    store.commit("calculator/setCalc", {
      ...newDocumentCost,
      priceOnRequestOverpriced: newOverpriced,
      priceOnRequestOverweight: newOverweight,
      priceOnRequestOversize: newOversize,
      priceOnRequestFerry: newFerry,
      priceOnRequestTime: newDocumentCost.distance > MAX_DISTANCE,
    });
  },
  { immediate: true },
);

watch(
  [() => calcData.value.expCost, currentDate],
  ([newExpCost, newCurrentDate], [oldExpCost, oldCurrentDate]) => {
    if (newExpCost !== oldExpCost && newCurrentDate !== oldCurrentDate) {
      priceAdjustmentModal.callback();
    }
  },
);

onMounted(() => {
  intervalID.value = setInterval(() => {
    currentDate.value = moment().format(backFormats.yMmDdHhMmSs);
  }, 60000);
});

onUnmounted(() => {
  clearInterval(intervalID.value);
});
</script>

<style scoped>
.rote-data-loader {
  left: 0;
  top: 0;
}
</style>
