<template>
  <v-form ref="formRef" v-model="valid" @submit.prevent="saveChanges()">
    <v-alert
      v-if="props.editable"
      :text="$t('alert.ride-step1-required')"
      color="warning"
      variant="tonal"
      class="my-8"
      icon="mdi-alert-outline"
    />
    <editable-block
      v-model="isEditable"
      :loading="loading"
      submit
      @cancel="cancelChanges"
    >
      <template #title>
        <p class="text-h5 text-primary py-2">
          {{ $t("estimated-arrival") }}
        </p>
      </template>
      <template #edit-btn="{ edit }">
        <v-btn
          v-if="editable"
          variant="text"
          elevation="0"
          color="primary"
          icon="mdi-file-edit-outline"
          @click="edit"
        />
      </template>
      <template #default="{ readonly }">
        <v-row class="my-0 pt-4" :class="{ 'pointer-events-none': readonly }">
          <v-col cols="12" class="py-0">
            <calendar-field
              v-model="editRide.step_5_scheduled_unloading_time"
              :title="$t('scheduled-unloading-point')"
              class="pb-2"
              :chip-format="locFormat.dDMmYHhMmUhr"
              :readonly="readonly"
              :error-messages="
                errors.step_5_scheduled_unloading_time?.length
                  ? errors.step_5_scheduled_unloading_time
                  : calendarErrors
              "
              :rules="[rules.required]"
              @update:model-value="errors.step_5_scheduled_unloading_time = []"
            />
          </v-col>
        </v-row>
      </template>
    </editable-block>
  </v-form>
  <div v-if="isBplAdmin && props.editable" class="d-flex justify-end">
    <v-btn
      type="button"
      color="primary"
      size="large"
      :disabled="isEditable"
      :loading="manuallyLoading"
      @click="switchManually"
    >
      {{ $t("button.switch-manually") }}
    </v-btn>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, ref, watch } from "vue";
import moment from "moment";
import { useI18n } from "vue-i18n";

import { updateRide } from "@/api/rides";

import { useRules } from "@/utils/rules";
import { useLocFormat } from "@/utils/locFormat";
import { getInitialEditRideErrorObject } from "@/utils/rides";
import { useValidHelper } from "@/utils/validHelper";
import { access } from "@/utils/access";
import { useMountScroller } from "@/utils/mountScroller";
import { snackbar } from "@/utils/snackbar";

import { VForm } from "vuetify/components";
import { EditRideObject, RideObject } from "@/types/rides";
import CalendarField from "@/components/CalendarField.vue";
import EditableBlock from "@/components/EditableBlock.vue";

const { t } = useI18n();
const rules = useRules();
const locFormat = useLocFormat();

const include = "document.carrier.users";

const isEditable = ref(false);

const props = defineProps<{
  ride: RideObject;
  editable?: boolean;
}>();

const editRide = ref<EditRideObject>({}); //step_5_scheduled_unloading_time

const getDatesDiff = (time: string, locTime: string) => {
  const timeMoment = moment(time);
  const locTimeMoment = moment(locTime);
  return locTimeMoment.diff(timeMoment, "minutes");
};

const calendarErrors = computed(() => {
  if (
    editRide.value.step_5_scheduled_unloading_time &&
    getDatesDiff(
      props.ride.step_4_leave_point_time,
      editRide.value.step_5_scheduled_unloading_time,
    ) < 0
  ) {
    return [t("ride.order-agreed-time")];
  }
  return [];
});

useMountScroller();

const valid = ref(false);
const formRef = ref<VForm>();
const loading = ref(false);
useValidHelper(formRef);

const manuallyLoading = ref(false);

const errors = ref(getInitialEditRideErrorObject());

const syncEdits = (value: RideObject) => {
  editRide.value.step_5_scheduled_unloading_time =
    value.step_5_scheduled_unloading_time;
  errors.value.step_5_scheduled_unloading_time = [];
};

const cancelChanges = () => {
  nextTick(() => {
    syncEdits(props.ride);
  });
};

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "updateRide", data: RideObject): void;
}>();

const switchManually = async () => {
  await formRef.value?.validate();
  if (valid.value) {
    try {
      manuallyLoading.value = true;
      const response = await updateRide(
        props.ride.id,
        {
          step: 6,
        },
        {
          include,
        },
      );

      emit("updateRide", response.data);
    } catch (e: any) {
      snackbar(e.message);
    } finally {
      manuallyLoading.value = false;
    }
  }
};
const saveChanges = async () => {
  await formRef.value?.validate();
  if (valid.value) {
    try {
      loading.value = true;
      const { data } = await updateRide(props.ride.id, editRide.value, {
        include,
      });
      emit("updateRide", data);
      isEditable.value = false;
    } catch (e: any) {
      errors.value = {
        ...getInitialEditRideErrorObject(),
        ...e.errors,
      };
    } finally {
      loading.value = false;
    }
  }
};

const isBplAdmin = computed(() =>
  access.someRoles(["admin", "sub-admin", "bpl-manager"]),
);

watch(
  () => props.ride,
  (value) => {
    syncEdits(value);
  },
  { immediate: true },
);
</script>

<style scoped></style>
