<template>
  <v-snackbar
    v-model="snackbar"
    position="fixed"
    location="top"
    :color="color"
    :timeout="delay"
  >
    <template #default>
      <div class="d-flex align-center">
        <v-icon :icon="icon" class="mr-4"></v-icon>
        <span>{{ data.text }}</span>
      </div>
    </template>

    <template #actions>
      <v-btn icon="mdi-close" @click="snackbar = false" />
    </template>
  </v-snackbar>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useStore } from "@/store";
import { SnackbarData, SnackbarType } from "@/types/other";

const delay = 4000;

const initialData: SnackbarData = { type: SnackbarType.error, text: "" };

const store = useStore();

const timeout = ref<number>();

const snackbar = computed({
  get() {
    return !!store.state.app.snackbar;
  },
  set(newValue: boolean): void {
    if (!newValue) store.commit("app/setSnackbar", null);
  },
});

const data = ref(initialData);

const color = computed(() => {
  switch (data.value.type) {
    case SnackbarType.error:
      return "red-accent-2";
    case SnackbarType.warning:
      return "orange-darken-1";
    default:
      return "green-lighten-1";
  }
});
const icon = computed(() => {
  switch (data.value.type) {
    case SnackbarType.error:
      return "mdi-alert-outline";
    case SnackbarType.warning:
      return "mdi-alert-octagon-outline";
    default:
      return "mdi-check-circle-outline";
  }
});

watch(
  () => store.state.app.snackbar,
  (value: SnackbarData | null) => {
    clearTimeout(timeout.value);
    if (value) {
      data.value = value;
    } else {
      timeout.value = setTimeout(() => {
        data.value = initialData;
      }, delay);
    }
  },
);
</script>

<style scoped></style>
