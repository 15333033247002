<template>
  <div
    class="fill-height d-flex flex-column justify-space-between align-center py-12"
  >
    <div></div>
    <div class="d-flex flex-column justify-center align-end">
      <div
        class="w-locale my-2 px-10 text-body-2 text-grey-darken-2 cursor-pointer"
      >
        <span>{{ $t("language") }}</span>
        <v-icon
          class="ml-2"
          icon="mdi-menu-down"
          color="grey-darken-2"
        ></v-icon>
        <locale-menu />
      </div>
      <v-sheet
        width="auto"
        :max-width="maxWidth || '1010'"
        class="mx-auto px-10 py-12 rounded-xl bpl-border-gray"
      >
        <logo-full></logo-full>
        <div class="text-h4 text-primary font-weight-light mt-10">
          {{ props.title }}
        </div>
        <div v-if="locale === 'de'" class="mt-10">
          <slot name="de"></slot>
        </div>
        <div v-else class="mt-10">
          <slot name="en"></slot>
        </div>
      </v-sheet>
    </div>
    <auth-footer />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

import LocaleMenu from "@/components/LocaleMenu.vue";
import LogoFull from "@/components/Auth/Registration/LogoFull.vue";
import AuthFooter from "@/components/Auth/AuthFooter.vue";

const props = defineProps<{
  title?: string;
  maxWidth?: string;
}>();

const { locale } = useI18n();
</script>

<style scoped>
.w-locale {
  min-width: 175px;
}
</style>
