<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <v-layout>
    <v-app-bar elevation="0" class="bpl-border-gray position-fixed">
      <v-btn
        class="ml-1"
        icon="mdi-menu"
        @click="isDrawerOpened = !isDrawerOpened"
      />
      <div class="ml-4">
        <v-img :src="require('../assets/logo.webp')" width="50" />
      </div>
      <div class="ml-4 text-h6">Brennpunkt Logistik</div>
      <v-spacer />
      <v-menu :close-on-content-click="false">
        <template #activator="{ props: menuProps }">
          <v-btn icon v-bind="menuProps">
            <v-badge
              v-if="haveUnread"
              dot
              bordered
              offset-y="4"
              color="primary"
            >
              <v-icon icon="mdi-bell" color="grey-darken-2"></v-icon>
            </v-badge>
            <v-icon v-else icon="mdi-bell" color="grey-darken-2"></v-icon>
          </v-btn>
        </template>
        <v-card v-if="notifications.length" rounded="lg" elevation="5">
          <div class="text-subtitle-1 font-weight-medium py-3 px-4">
            {{ $t("notifications.title") }}
          </div>
          <v-list width="383" height="343" class="py-0">
            <template
              v-for="(
                { read_at, created_at, title, path, url }, i
              ) in notifications"
              :key="i"
            >
              <v-list-item
                :to="path"
                :href="url"
                target="_blank"
                class="notifications d-flex justify-space-between py-3 px-4 text-medium-emphasis text-body-2"
                :class="{
                  'bg-grey-lighten-4': !read_at,
                }"
              >
                <v-list-item-title class="mr-5 text-wrap">
                  {{ title }}
                </v-list-item-title>
                <template #append>
                  <span class="text-no-wrap text-12">{{ created_at }}</span>
                </template>
              </v-list-item>
              <v-divider class="bg-grey-lighten-3"></v-divider>
            </template>
          </v-list>
          <v-btn variant="text" class="w-100" @click="cleanNotifications()">
            <span class="text-body-2 text-primary">{{
              $t("notifications.delete")
            }}</span>
          </v-btn>
        </v-card>
        <v-card
          v-else
          rounded="lg"
          elevation="5"
          min-width="383"
          height="431"
          class="d-flex justify-center align-center"
        >
          <span class="text-subtitle-1 text-disabled">
            {{ $t("notifications.empty") }}
          </span>
        </v-card>
      </v-menu>
      <v-menu :close-on-content-click="false">
        <template #activator="{ props: menuProps }">
          <v-btn
            class="ml-3 mr-4"
            color="primary"
            icon="mdi-account"
            variant="elevated"
            v-bind="menuProps"
          ></v-btn>
        </template>
        <v-list rounded="lg" elevation="5" min-width="230">
          <v-list-item class="cursor-pointer">
            <v-list-item-title>{{ $t("change-password") }}</v-list-item-title>
            <template #append>
              <v-icon icon="mdi-key" color="grey-darken-2"></v-icon>
            </template>
          </v-list-item>
          <v-list-item class="cursor-pointer">
            <v-list-item-title>{{ $t("language") }}</v-list-item-title>
            <template #append>
              <v-icon icon="mdi-menu-down" color="grey-darken-2"></v-icon>
            </template>
            <locale-menu />
          </v-list-item>
          <v-list-item to="/imprint" target="_blank">
            <v-list-item-title>{{ $t("imprint") }}</v-list-item-title>
            <template #append>
              <v-icon icon="mdi-open-in-new" color="grey-darken-2"></v-icon>
            </template>
          </v-list-item>
          <v-list-item to="/privacy-policy" target="_blank">
            <v-list-item-title>{{ $t("privacy-policy") }}</v-list-item-title>
            <template #append>
              <v-icon icon="mdi-open-in-new" color="grey-darken-2"></v-icon>
            </template>
          </v-list-item>
          <v-list-item to="/terms" target="_blank">
            <v-list-item-title>{{ $t("terms-conditions") }}</v-list-item-title>
            <template #append>
              <v-icon icon="mdi-open-in-new" color="grey-darken-2"></v-icon>
            </template>
          </v-list-item>

          <v-list-item @click="store.dispatch('auth/logout')">
            <v-list-item-title>{{ $t("logout") }}</v-list-item-title>
            <template #append>
              <v-icon icon="mdi-logout" color="grey-darken-2"></v-icon>
            </template>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
      :rail="!opened"
      class="bpl-drawer position-fixed"
      rail-width="58"
      touchless
      permanent
      @mouseenter="isDrawerHovered = true"
      @mouseleave="isDrawerHovered = false"
    >
      <v-list
        v-model:opened="panels"
        :lines="false"
        nav
        class="pa-0"
        :rounded="false"
      >
        <template v-for="(item, i) in drawerItems" :key="i">
          <v-list-item
            v-if="item.route"
            class="ma-0 rounded-0 bpl-drawer-icon-wrap bg-transparent"
            :class="{ 'bg-primary': item.value === selectedTab }"
            :to="{ name: item.route }"
            :active="false"
          >
            <template #prepend>
              <v-icon
                :icon="item.icon"
                :color="
                  item.value === selectedTab
                    ? 'light-blue-lighten-5'
                    : 'grey-darken-2'
                "
                size="20"
                class="drawer-icon"
              ></v-icon>
            </template>

            <v-list-item-title
              :class="{
                'text-light-blue-lighten-5': item.value === selectedTab,
                'text-grey-darken-2': item.value !== selectedTab,
              }"
              v-text="$t(item.value)"
            ></v-list-item-title>
          </v-list-item>

          <v-list-group
            v-else-if="item.children?.length"
            class="drawer-group"
            :class="{ 'drawer-group-active': item.value === selectedTab }"
            :value="item.value"
            disabled
          >
            <template #activator="{ props: groupProps }">
              <v-list-item
                v-bind="groupProps"
                :title="$t(item.value)"
                class="ma-0 rounded-0 bpl-drawer-icon-wrap bg-transparent text-grey-darken-2"
                :class="{ 'bg-primary': item.value === selectedTab }"
              >
                <template #prepend>
                  <v-icon
                    :icon="item.icon"
                    :color="
                      item.value === selectedTab
                        ? 'light-blue-lighten-5'
                        : 'grey-darken-2'
                    "
                    size="20"
                    class="drawer-icon"
                  ></v-icon>
                </template>
              </v-list-item>
            </template>

            <v-list-item
              v-for="(subItem, j) in item.children"
              :key="j"
              class="ma-0 rounded-0 bpl-drawer-icon-wrap bg-transparent"
              :class="{
                'bg-blue-lighten-4': subItem.value === selectedSubTab,
              }"
              :active="false"
              :to="{ name: subItem.route }"
            >
              <template #prepend>
                <v-icon
                  icon="mdi-circle-medium"
                  :color="
                    subItem.value === selectedSubTab
                      ? 'primary'
                      : 'grey-darken-2'
                  "
                  size="20"
                  class="drawer-icon"
                ></v-icon>
              </template>

              <v-list-item-title
                :class="{
                  'text-primary': subItem.value === selectedSubTab,
                  'text-grey-darken-2': subItem.value !== selectedSubTab,
                }"
                v-text="$t(subItem.value)"
              ></v-list-item-title>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-main class="dashboard-view-main">
      <router-view />
    </v-main>
  </v-layout>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";

import moment from "moment";

import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
import { useRouter } from "vue-router";

import { RouteRecordName, useRoute } from "vue-router";

import LocaleMenu from "@/components/LocaleMenu.vue";

import { access } from "@/utils/access";
import { snackbar } from "@/utils/snackbar";

import { getNotificationName } from "@/utils/other";
import {
  DataBaseNotification,
  NotificationType,
  ExportNotificationType,
  ExportNotificationErrorType,
} from "@/types/other";

const { t } = useI18n();
const store = useStore();
const { locale } = useI18n();
const router = useRouter();
const route = useRoute();

const exportTypes: NotificationType[] = [
  ...Object.values(ExportNotificationType),
  ...Object.values(ExportNotificationErrorType),
];

const isDrawerOpened = computed({
  get() {
    return store.state.app.drawerOpened;
  },
  set(value: boolean) {
    store.commit("app/setDrawerOpened", value);
  },
});
const isDrawerHovered = ref(false);

const panelsCache = ref<string[]>([]);

const panels = computed({
  get() {
    return opened.value ? panelsCache.value : [];
  },
  set(value: string[]) {
    panelsCache.value = value;
  },
});

const opened = computed(() => isDrawerOpened.value || isDrawerHovered.value);

const accessNavigation = (items: DrawerItem[]): DrawerItem[] =>
  items
    .map((d) => {
      if (d.children) {
        const filteredChildren = d.children.filter((c) => {
          const routeData = router.getRoutes().find((r) => r.name === c.route);
          return (
            !routeData?.meta.roles || access.someRoles(routeData.meta.roles)
          );
        });
        return { ...d, children: filteredChildren };
      } else return d;
    })
    .filter((d) => {
      if (d.children && !d.children.length) {
        return false;
      } else {
        const routeData = router.getRoutes().find((r) => r.name === d.route);
        return !routeData?.meta.roles || access.someRoles(routeData.meta.roles);
      }
    });

const drawerItems = computed(() =>
  accessNavigation([
    { value: "calculator", icon: "mdi-calculator", route: "calculator" },
    {
      value: "marketplace",
      icon: "mdi-hand-coin",
      route: "marketplace",
      routes: ["marketplace-item"],
    },
    {
      value: "offer",
      icon: "mdi-text-box",
      route: "offers",
      routes: ["offer"],
    },
    {
      value: "order",
      icon: "mdi-clipboard-check",
      route: "orders",
      routes: ["order"],
    },
    {
      value: "clients",
      icon: "mdi-account-multiple",
      route: "clients",
      routes: ["client"],
    },
    {
      value: "transporters",
      icon: "mdi-truck",
      route: "transporters",
      routes: ["transporter"],
    },
    {
      value: "rides",
      icon: "mdi-road-variant",
      route: "rides",
      routes: ["ride"],
    },
    { value: "employees", icon: "mdi-account-edit", route: "employees" },
    {
      value: "profile-settings",
      icon: "mdi-account-cog",
      route: "profile-settings",
    },
    {
      value: "system-settings",
      icon: "mdi-cog",
      children: [
        {
          value: "general",
          route: "general",
        },
        {
          value: "vehicles",
          route: "vehicles",
        },
        {
          value: "special-features",
          route: "features",
        },
        {
          value: "market-factor",
          route: "market-factor",
        },
        {
          value: "land-delivery-factor",
          route: "delivery-factor",
        },
        {
          value: "postal-code",
          route: "postal-code",
        },
        {
          value: "ferry",
          route: "ferry",
        },
        {
          value: "pallets",
          route: "pallets",
        },
        {
          value: "season-factor",
          route: "season-factor",
        },
      ],
    },
  ]),
);

const selectedSubTab = computed(
  () =>
    drawerItems.value
      .map((i) => i.children || [])
      .flat()
      .find(
        (item) =>
          item.route === route.name ||
          item.routes?.includes(route.name as RouteRecordName),
      )?.value,
);

const selectedTab = computed(
  () =>
    drawerItems.value.find(
      (item) =>
        item.route === route.name ||
        item.routes?.includes(route.name as RouteRecordName) ||
        item.children?.some((c) => c.value === selectedSubTab.value),
    )?.value,
);

const notifications = computed(() =>
  store.state.app.notifications.map((item: DataBaseNotification) => {
    const created_at = moment
      .duration(moment(item.created_at).diff(moment()))
      .locale(locale.value)
      .humanize({ h: 48, d: 7, w: 4 });

    const read_at = item.read_at;
    switch (true) {
      case exportTypes.includes(item.data.event): {
        const key = getNotificationName(item.data.event);
        return {
          read_at,
          created_at,
          title: key && t(key),
          url: item.data.url,
          path: undefined,
        };
      }
      default: {
        return {
          read_at,
          created_at,
          title: item.data.text,
          url: undefined,
          path: item.data.url
            ? { path: new URL(item.data.url).pathname }
            : undefined,
        };
      }
    }
  }),
);

const haveUnread = computed(() =>
  store.state.app.notifications.some(({ read_at }) => read_at === null),
);

const cleanNotifications = async () => {
  try {
    await store.dispatch("app/makeMarkAsReadNotifications");
    store.commit("app/setNotifications", []);
  } catch (e: any) {
    snackbar(e.message);
  }
};

watch(opened, (value) => {
  if (value && selectedSubTab.value) {
    const tableValue = drawerItems.value.find(
      (item) =>
        item.children?.some(
          (subItem) => subItem.value === selectedSubTab.value,
        ),
    );
    if (tableValue) {
      panels.value = [tableValue.value];
    }
  } else panels.value = [];
});

watch(
  () => route.name,
  () => {
    if (selectedSubTab.value) {
      panels.value = [selectedTab.value as string];
    }
  },
);

interface DrawerItemInner {
  value: string;
  route: RouteRecordName;
  routes?: RouteRecordName[];
}

interface DrawerItem extends Omit<DrawerItemInner, "route"> {
  children?: DrawerItemInner[];
  route?: RouteRecordName;
  icon: string;
}
</script>

<style>
.dashboard-view-main {
  min-height: calc(100vh - 64px);
}
.bpl-drawer-icon-wrap,
.drawer-group .bpl-drawer-icon-wrap {
  padding-left: 18px !important;
}
.drawer-icon,
.drawer-group .v-list-group__header .v-icon {
  opacity: 1 !important;
}
.drawer-group-active .v-list-group__header .v-icon {
  color: #e1f5fe;
}
.cursor-pointer {
  cursor: pointer;
}

.bpl-drawer {
  border-color: #eeeeee;
}

.text-12 {
  font-size: 12px;
}

.notifications > .v-list-item__append {
  align-self: baseline;
}

.bpl-underline-hover:hover {
  text-decoration-line: underline;
  cursor: pointer;
}
</style>
