export enum LanguageType {
  german = 1,
  english,
}

export type SortData = {
  key: string;
  order: string;
};

export type Pagination = {
  total: number;
  per_page: number;
  current_page: number;
  total_pages: number;
};

export type MetaPagination = {
  pagination: Pagination;
};

export type CountryObjectRaw = {
  id: string;
  name: string;
  code: string;
  ferry: number;
};

export type CountryObject = CountryObjectRaw & {
  title: string;
};

type LocObject = { code: string; default_name: string; locale_name: string };

export type LocObjectWrap = { id: string; language: LocObject };

export enum SnackbarType {
  warning = "warning",
  error = "error",
  success = "success",
}

export type SnackbarData = {
  text: string;
  type: SnackbarType;
};

export type ConfirmData = {
  title: string;
  text: string;
  yes: string;
  no: string;
};

export type CheckboxGroupValue = any | number;

export type GlobCheckbox = {
  checked: boolean;
  interim: boolean;
};

export type CheckboxData = {
  value: CheckboxGroupValue;
  title: string;
  tooltip?: string;
};

export type DateValue = number | string;

export type RangeValue = {
  start?: DateValue;
  end?: DateValue;
};

export enum ExportNotificationType {
  ordersCsvExport = "orders-csv-export",
  offersCsvExport = "offers-csv-export",
  clientsCsvExport = "clients-csv-export",
  transportersCsvExport = "transporters-csv-export",
  ordersPdfExport = "orders-pdf-export",
  documentsTransporterPdfExport = "documents-transporter-pdf-export",
  marketFactorsCsvExport = "market-factors-csv-export",
  countryPointsCsvExport = "country-points-csv-export",
  lzfCsvExport = "lzf-csv-export",
  ferriesCsvExport = "ferries-csv-export",
}

export enum ExportNotificationErrorType {
  ordersCsvExportError = "orders-csv-export-error",
  offersCsvExportError = "offers-csv-export-error",
  clientsCsvExportError = "clients-csv-export-error",
  transportersCsvExportError = "transporters-csv-export-error",
  ordersPdfExportError = "orders-pdf-export-error",
  documentsTransporterPdfExportError = "documents-transporter-pdf-export-error",
  marketFactorsCsvExportError = "market-factors-csv-export-error",
  countryPointsCsvExportError = "country-points-csv-export-error",
  lzfCsvExportError = "lzf-csv-export-error",
  ferriesCsvExportError = "ferries-csv-export-error",
}

export enum UserNotificationType {
  userRegistered = "user-registered-event",
  userRegisteredManual = "user-registered-manual-event",
  companyAccepted = "company-accepted-event",
  companyBlocked = "company-blocked-event",
  documentPriceOnRequest = "document-price-on-request-event",
}

export type NotificationType =
  | ExportNotificationType
  | ExportNotificationErrorType
  | UserNotificationType;

type NotificationData = {
  event: NotificationType;
  uuid: string;
  url: string;
  text?: string;
};

export type NotificationObject = {
  id: string;
  type: string;
  data: NotificationData;
};

export type DataBaseNotification = NotificationObject & {
  created_at: string;
  read_at: string | null;
};

export type ExportQueue = {
  type: ExportNotificationType;
  uuid: string;
  ids: string[];
};

export type ExportResponse = { uuid: string };

export type ProgressTab = string | { title: string; subtitle: string };
