<template>
  <div class="d-flex align-center">
    <p
      class="text-h6"
      :class="{
        'text-grey-darken-4': !readonly,
        'text-grey-lighten-1': readonly,
      }"
    >
      {{ props.title }}
    </p>
    <v-btn
      class="ml-2"
      icon="mdi-delete"
      variant="plain"
      :disabled="props.readonly"
      @click="driver = {}"
    />
  </div>
  <v-row class="my-0 pt-4">
    <v-col cols="6" class="py-0">
      <v-text-field
        v-model="driver.firstname"
        :rules="[]"
        :label="$t('first-name')"
        :readonly="props.readonly"
        :variant="props.readonly ? 'filled' : 'outlined'"
        class="pb-2"
      ></v-text-field>
    </v-col>
    <v-col cols="6" class="py-0">
      <v-text-field
        v-model="driver.lastname"
        :rules="[]"
        :label="$t('last-name')"
        :readonly="props.readonly"
        :variant="props.readonly ? 'filled' : 'outlined'"
        class="pb-2"
      ></v-text-field>
    </v-col>
    <v-col cols="6" class="py-0">
      <v-text-field
        v-model="driver.phone"
        :rules="[]"
        :label="$t('phone')"
        :readonly="props.readonly"
        :variant="props.readonly ? 'filled' : 'outlined'"
        class="pb-2"
      ></v-text-field>
    </v-col>
    <v-col cols="6" class="py-0">
      <language-selector
        v-if="driver.languages"
        v-model="driver.languages"
        :readonly="readonly"
        :bg-color="!readonly ? ('white' as any) : undefined"
        class="pb-2"
      /> </v-col
  ></v-row>
</template>

<script setup lang="ts">
import { RideDriverObject } from "@/types/rides";
import { computed } from "vue";
import LanguageSelector from "@/components/LanguageSelector.vue";

const props = defineProps<{
  modelValue: RideDriverObject;
  readonly: boolean;
  title: string;
}>();

const driver = computed<RideDriverObject>({
  get() {
    return props.modelValue || {};
  },
  set(value: RideDriverObject) {
    emit("update:modelValue", value || {});
  },
});

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "update:modelValue", data: RideDriverObject): void;
}>();
</script>

<style scoped></style>
