import { CountryObjectRaw } from "@/types/other";

export type FactorDay = {
  id: string | symbol;
  date: string;
  value: number | null;
  mf_value: number | null;
};

export type EditFactorDay = {
  value?: number;
  mf_value?: number;
};

export type Pallet = {
  id: string;
  name: string;
  code: string;
  price: number;
  price_accounting: number;
  active: boolean;
};

export type EditPallet = {
  price?: string;
  price_accounting?: string;
  active: boolean;
};

export type PalletErrorObject = {
  price: string[];
  price_accounting: string[];
};

export enum VehicleCalcType {
  fixedPrice = 1,
  factor,
}

export type Vehicle = {
  id: string;
  active: boolean;
  calculation_type: VehicleCalcType;
  length: string;
  width: string;
  height: string;
  capacity: string;
  volume: string;
  equipment: string;
  ferry: boolean;
  name: string;
  code: string;
  value: number | null;
};

export type EditVehicle = {
  active: boolean;
  value: string;
  length: string;
  width: string;
  height: string;
  capacity: string;
  volume: string;
  equipment: string;
  ferry: boolean;
  calculation_type: Vehicle["calculation_type"];
  name: string;
};

export type VehicleErrorObject = {
  value: string[];
  length: string[];
  width: string[];
  height: string[];
  capacity: string[];
  volume: string[];
  equipment: string[];
  name: string[];
};

export type MarketFactor = {
  id: string;
  percent: number;
  value: number;
};

export type SettingsObject = {
  base_price_multiplier: number;
  commission_normal: number;
  commission_express_1: number;
  commission_express_2: number;
  commission_express_3: number;
  commission_express_4: number;
  credit_limit: number;
  discount: number;
  market_factor: number;
  surcharge: number;
  working_time_start: string;
  working_time_end: string;
};

export type EditSettingsObject = {
  base_price_multiplier?: number;
  commission_normal?: number;
  commission_express_1?: number;
  commission_express_2?: number;
  commission_express_3?: number;
  commission_express_4?: number;
  credit_limit?: number;
  discount?: number;
  market_factor?: number;
  surcharge?: number;
  working_time_start?: string;
  working_time_end?: string;
};

export type EditSettingsErrorObject = {
  working_time_start: string[];
  working_time_end: string[];
};

export type CountryPointObject = {
  id: string;
  active: boolean;
  country: { data: CountryObjectRaw };
  ferry: number;
  input: number;
  output: number;
  postcode: number;
};

export type FerryObject = {
  id: string;
  type: string;
  price: number;
  toCountry: { data: CountryObjectRaw };
  fromCountry: { data: CountryObjectRaw };
};

export enum FeatureType {
  adr = "adr",
  languageSkills = "language_skills",
  other = "other",
  driving_skills = "driving_skills",
}

export enum FeatureCalcType {
  fixedPrice = 1,
  factor,
  priceOnRequest,
}

export type FeatureObject = {
  calculation_type: FeatureCalcType;
  name: string;
  code: string;
  id: string;
  active: boolean;
  type: FeatureType;
  value: number;
};

export type FeatureCostObject = FeatureObject & { cost: number };

export type EditFeature = {
  calculation_type: FeatureObject["calculation_type"];
  active: boolean;
  value: string;
};

export type FeatureErrorObject = {};

export type DeliveryFactor = {
  id: string;
  client_factor: number;
  active: boolean;
  loadingPointCountry: {
    data: CountryObjectRaw;
  };
  unloadingPointCountry: {
    data: CountryObjectRaw;
  };
};

export type EditDeliveryFactor = {
  client_factor: string;
  active: boolean;
};

export type DeliveryFactorErrorObject = {
  client_factor: string[];
};
