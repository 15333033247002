<template>
  <v-row no-gutters>
    <v-col :cols="isWithSidebar ? 8 : 12" class="pa-10">
      <v-sheet class="py-12 px-10 rounded-xl bpl-border-gray">
        <progress-tabs
          :tabs="tabs"
          :tab-index="lastStep"
          :current-tab-index="tabIndex"
          @select="setTabIndex"
        />
        <calc-first v-if="tabIndex === 0" @step="setTabIndex" />
        <calc-second v-if="tabIndex === 1" @step="setTabIndex" />
        <calc-third v-if="tabIndex === 2" @step="setTabIndex" />
        <calc-summary
          v-if="tabIndex === 3"
          @step="setTabIndex"
          @created="onCreated"
        />
      </v-sheet>
    </v-col>
    <v-col v-if="isWithSidebar" cols="4" class="pa-10 pl-0">
      <calc-sidebar />
    </v-col>
    <async-modal
      v-if="documentObject"
      :id="createdDialogModal.id"
      v-slot="{ cancel, autofocus }"
    >
      <v-card class="px-10 py-12" max-width="660">
        <p class="text-h4 text-primary font-weight-light text-center">
          {{ title }}
        </p>
        <p class="mt-4 text-grey-darken-2 text-center">
          {{ text }}
        </p>
        <v-btn
          :ref="autofocus"
          size="x-large"
          color="primary"
          class="mt-10"
          :to="openLink"
        >
          {{ open }}
        </v-btn>
        <v-btn
          size="x-large"
          color="primary"
          variant="outlined"
          class="mt-4"
          :to="{ name: 'calculator' }"
          @click="cancel()"
        >
          {{ create }}
        </v-btn>
      </v-card>
    </async-modal>
  </v-row>
</template>

<script setup lang="ts">
import { ref, computed, nextTick } from "vue";
import { useStore } from "@/store";
import { useI18n } from "vue-i18n";

import { useAsyncModal } from "@/utils/asyncModalHelper";

import { DocumentSavedData } from "@/types/document";

import ProgressTabs from "@/components/ProgressTabs.vue";
import CalcFirst from "@/components/CalculatorView/CalcFirst.vue";
import CalcSecond from "@/components/CalculatorView/CalcSecond.vue";
import CalcThird from "@/components/CalculatorView/CalcThird.vue";
import CalcSummary from "@/components/CalculatorView/CalcSummary.vue";
import CalcSidebar from "@/components/CalculatorView/CalcSidebar.vue";
import AsyncModal from "@/components/AsyncModal.vue";

const store = useStore();
const { t } = useI18n();
const createdDialogModal = useAsyncModal();

const tabs = ["route-data", "offer-data", "company-data", "summary"];

const tabIndex = ref(Math.max(...store.state.calculator.readySteps));
const documentObject = ref<DocumentSavedData>();
const count = ref("1");

const isWithSidebar = computed(() => [1, 2, 3].includes(tabIndex.value));
const lastStep = computed(() => Math.max(...store.state.calculator.readySteps));

const isMany = computed(() => Number(count.value) > 1);

const title = computed(() => {
  if (isMany.value) {
    if (documentObject.value?.manual) {
      return t("dialog.manual-offer");
    } else if (documentObject.value?.price_on_request) {
      return t("dialog.offers-price-request-warning");
    } else {
      return t("dialog.orders-created-title");
    }
  } else {
    if (documentObject.value?.manual) {
      return t("dialog.manual-offer");
    } else if (documentObject.value?.price_on_request) {
      return t("dialog.offer-price-request-warning");
    } else {
      return t("calculator-accept-order-title");
    }
  }
});

const text = computed(() => {
  if (isMany.value) {
    if (documentObject.value?.manual) {
      return t("dialog.manual-offer-text");
    } else if (documentObject.value?.price_on_request) {
      return t("dialog.offers-price-request-warning-text");
    } else {
      return t("dialog.orders-created-text");
    }
  } else {
    if (documentObject.value?.manual) {
      return t("dialog.manual-offer-text");
    } else if (documentObject.value?.price_on_request) {
      return t("dialog.offer-price-request-warning-text");
    } else {
      return t("calculator-accept-order-description");
    }
  }
});

const open = computed(() => {
  if (isMany.value) {
    return documentObject.value?.price_on_request
      ? t("button.view-offers")
      : t("button.view-orders");
  } else {
    return documentObject.value?.price_on_request
      ? t("button.open-offer")
      : t("button.open-order");
  }
});

const openLink = computed(() => {
  if (isMany.value) {
    return {
      name: documentObject.value?.price_on_request ? "offers" : "orders",
    };
  } else {
    return {
      name: documentObject.value?.price_on_request ? "offer" : "order",
      params: { id: documentObject.value?.id || "" },
    };
  }
});

const create = computed(() => {
  return documentObject.value?.price_on_request
    ? t("button.create-another-offer")
    : t("button.create-another-order");
});

type Created = {
  document: DocumentSavedData;
  count: string;
};
const onCreated = async ({
  document: newDocument,
  count: newCount,
}: Created) => {
  documentObject.value = newDocument;
  count.value = newCount;
  setTabIndex(0);
  store.commit("calculator/clearDocument");
  await nextTick();
  await createdDialogModal.callback();
};

const setTabIndex = (data: number) => {
  tabIndex.value = data;
};
</script>
