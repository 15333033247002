import { nextTick, onMounted } from "vue";

export const useMountScroller = () => {
  onMounted(() => {
    nextTick(() => {
      window.scrollTo({
        top: 0,
      });
    });
  });
};
