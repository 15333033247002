import { broadcast, subscribe } from "@/utils/events";

export enum ResolveType {
  confirm = 1,
}

type AsyncModal<T> = {
  id: symbol;
  callback: () => Promise<T | 0>;
};

export const useAsyncModal = <
  T extends number = ResolveType
>(): AsyncModal<T> => {
  const asyncModalId = Symbol();

  return {
    id: asyncModalId,
    callback: () => {
      broadcast("SetAsyncModal", { value: true, id: asyncModalId });
      return new Promise((resolve) => {
        subscribe("ResolveAsyncModal", ({ id, value }) => {
          if (id === asyncModalId) {
            resolve(value);
          }
        });
      });
    },
  };
};
