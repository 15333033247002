<template>
  <auth-wrapper>
    <div class="d-flex flex-column justify-center align-end">
      <accept-message v-if="messageEmail !== ''" @click="messageEmail = ''" />
      <forgot-password v-else @submit="(email) => (messageEmail = email)" />
    </div>
  </auth-wrapper>
</template>

<script setup lang="ts">
import { ref } from "vue";

import AcceptMessage from "@/components/Auth/ForgotPassword/AcceptMessage.vue";
import ForgotPassword from "@/components/Auth/ForgotPassword/ForgotPassword.vue";
import AuthWrapper from "@/components/Auth/AuthWrapper.vue";

const messageEmail = ref("");
</script>
