<template>
  <div class="d-flex align-center">
    <div
      class="d-flex align-center pa-3 overflow-hidden w-100"
      :class="{
        'bg-blue-lighten-5': !props.loading,
        'bg-blue-lighten-4': props.loading,
        'bpl-ride-file-item-active': !props.loading && !props.removeLoading,
      }"
      @click="downloadFile"
    >
      <div class="bpl-ride-file-item-icon">
        <v-progress-circular
          v-if="props.loading || downloadLoading"
          indeterminate
          color="primary"
          size="24"
          width="2"
        />
        <v-icon v-else icon="mdi-download" color="primary"></v-icon>
      </div>
      <span class="text-body-2 ml-2 bpl-ride-file-item-text">{{
        props.name
      }}</span>
      <span
        v-if="prepData"
        class="text-caption ml-2 flex-1-0 text-right text-grey-darken-2"
        >{{ prepData }}</span
      >
    </div>
    <div class="bpl-ride-file-item-close flex-1-0">
      <v-btn
        v-if="!props.loading"
        :loading="props.removeLoading"
        icon="mdi-close"
        variant="plain"
        :disabled="props.readonly"
        @click="emit('remove')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { RideSavedFile } from "@/types/rides";
import { exportFileByUrl } from "@/utils/exportFiles";
import { computed, ref } from "vue";
import { useLocFormat } from "@/utils/locFormat";
import moment from "moment";
const locFormat = useLocFormat();

const props = defineProps<{
  loading: boolean;
  removeLoading: boolean;
  readonly: boolean;
  name: string;
  fileData?: RideSavedFile;
}>();

const downloadLoading = ref(false);

const downloadFile = () => {
  if (props.fileData?.url) {
    downloadLoading.value = true;
    exportFileByUrl(
      props.fileData.url,
      () => {
        downloadLoading.value = false;
      },
      props.fileData.name,
    );
  }
};

const prepData = computed(
  () =>
    props.fileData &&
    moment(props.fileData.updated_at).format(locFormat.value.dDMmYWithDots),
);

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "remove"): void;
}>();
</script>

<style scoped>
.bpl-ride-file-item-active {
  cursor: pointer;
}

.bpl-ride-file-item-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bpl-ride-file-item-icon {
  width: 24px;
}

.bpl-ride-file-item-close {
  width: 48px;
}
</style>
