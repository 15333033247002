<template>
  <v-form ref="form" v-model="valid" @submit.prevent="callSubmit()">
    <v-expansion-panels v-model="panels" class="pt-10 bpl-panels" multiple>
      <v-expansion-panel
        :disabled="isFirstPanelEdited"
        elevation="0"
        class="ma-0"
      >
        <template #title>
          <span class="text-h4 text-primary font-weight-light">
            {{ $t("your-calculation") }}
          </span>
        </template>
        <template #text>
          <template
            v-if="
              store.state.app.vehicles.length &&
              store.state.app.pallets.length &&
              store.getters['app/countries'].length
            "
          >
            <editable-block
              v-model="editableBlocks"
              class="pt-10"
              :name="CalcBlockType.arrivalTime"
              :disabled="datesLoading"
              @save="saveChanges"
              @cancel="cancelChanges"
            >
              <template #title>
                <p class="text-h5 text-primary py-2">
                  {{ $t("arrival-time") }}
                </p>
              </template>
              <template #edit-btn="{ edit }">
                <v-btn
                  variant="text"
                  elevation="0"
                  color="primary"
                  icon="mdi-file-edit-outline"
                  @click="edit"
                />
              </template>
              <template #default="{ readonly }">
                <arrival-time-block
                  v-model="documentObject"
                  :readonly="readonly"
                  :form="form"
                  @loading="updateDatesLoading"
                />
              </template>
            </editable-block>
            <v-divider />
            <editable-block
              v-model="editableBlocks"
              class="pt-10"
              :name="CalcBlockType.routeData"
              :loading="routesLoading"
              @save="saveChanges"
              @cancel="cancelChanges"
            >
              <template #title>
                <p class="text-h5 text-primary py-2">
                  {{ $t("route-overview") }}
                </p>
              </template>
              <template #edit-btn="{ edit }">
                <v-btn
                  variant="text"
                  elevation="0"
                  color="primary"
                  icon="mdi-file-edit-outline"
                  @click="edit"
                />
              </template>
              <template #default="{ readonly }">
                <route-data-block
                  v-model="documentObject"
                  :readonly="readonly"
                  :errors-raw="errorsRaw"
                  :errors="errors"
                  @update:errors="errors = $event"
                />
              </template>
            </editable-block>
            <v-divider />
            <editable-block
              v-model="editableBlocks"
              class="pt-10"
              :name="CalcBlockType.transport"
              @save="saveChanges"
              @cancel="cancelChanges"
            >
              <template #title>
                <p class="text-h5 text-primary py-2">
                  {{ $t("transportation") }}
                </p>
              </template>
              <template #edit-btn="{ edit }">
                <v-btn
                  variant="text"
                  elevation="0"
                  color="primary"
                  icon="mdi-file-edit-outline"
                  @click="edit"
                />
              </template>
              <template #default="{ readonly }">
                <transport-block
                  v-model="documentObject"
                  :readonly="readonly"
                  :errors="errors"
                  @update:errors="errors = $event"
                />
              </template>
            </editable-block>
            <v-divider />
            <editable-block
              v-model="editableBlocks"
              class="pt-10"
              :name="CalcBlockType.goods"
              @save="saveChanges"
              @cancel="cancelChanges"
            >
              <template #title>
                <p class="text-h5 text-primary py-2">
                  {{ $t("goods") }}
                </p>
              </template>
              <template #edit-btn="{ edit }">
                <v-btn
                  variant="text"
                  elevation="0"
                  color="primary"
                  icon="mdi-file-edit-outline"
                  @click="edit"
                />
              </template>
              <template #default="{ readonly }">
                <goods-block
                  v-model="documentObject"
                  :readonly="readonly"
                  :errors="errors"
                  @update:errors="errors = $event"
                />
              </template>
            </editable-block>
            <v-divider />
            <editable-block
              v-model="editableBlocks"
              :name="CalcBlockType.features"
              class="pt-10"
              @save="saveChanges"
              @cancel="cancelChanges"
            >
              <template #title>
                <p class="text-h5 text-primary py-2">
                  {{ $t("special-features") }}
                </p>
              </template>
              <template #edit-btn="{ edit }">
                <v-btn
                  variant="text"
                  elevation="0"
                  color="primary"
                  icon="mdi-file-edit-outline"
                  @click="edit"
                />
              </template>
              <template #default="{ readonly }">
                <features-block v-model="documentObject" :readonly="readonly" />
              </template>
            </editable-block>
            <v-divider />
            <editable-block
              v-model="editableBlocks"
              :name="CalcBlockType.other"
              class="pt-10"
              @save="saveChanges"
              @cancel="cancelChanges"
            >
              <template #title>
                <p class="text-h5 text-primary py-2">
                  {{ $t("other") }}
                </p>
              </template>
              <template #edit-btn="{ edit }">
                <v-btn
                  variant="text"
                  elevation="0"
                  color="primary"
                  icon="mdi-file-edit-outline"
                  @click="edit"
                />
              </template>
              <template #default="{ readonly }">
                <other-block
                  v-model="documentObject"
                  :readonly="readonly"
                  :errors="errors"
                  @update:errors="errors = $event"
                />
              </template>
            </editable-block>
            <v-divider /></template
          ><app-loader v-else />
        </template>
      </v-expansion-panel>
      <v-expansion-panel
        :disabled="isSecondPanelEdited"
        elevation="0"
        class="pt-10 ma-0"
      >
        <template #title>
          <span class="text-h4 text-primary font-weight-light">
            {{ $t("your-contact-details") }}
          </span>
        </template>
        <template #text>
          <editable-block
            v-model="editableBlocks"
            :name="CalcBlockType.company"
            class="pt-10"
            @save="saveChanges"
            @cancel="cancelChanges"
          >
            <template #title>
              <p class="text-h5 text-primary py-2">
                {{ $t("company") }}
              </p>
            </template>
            <template #edit-btn="{ edit }">
              <v-btn
                variant="text"
                elevation="0"
                color="primary"
                icon="mdi-file-edit-outline"
                @click="edit"
              />
            </template>
            <template #default="{ readonly }">
              <company-block
                v-model="documentObject"
                :readonly="readonly"
                :errors="errors"
                @update:errors="errors = $event"
              />
            </template>
          </editable-block>
        </template>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class="d-flex justify-space-between pt-6">
      <v-checkbox
        v-model="checkPrivacyPolicy"
        :rules="[rules.required]"
        color="primary"
      >
        <template #label>
          <span>
            {{ $t("text.check-privacy-policy") }}
            <router-link
              :to="{ name: 'privacy-policy' }"
              target="_blank"
              class="text-primary"
            >
              {{ $t("button.privacy-policy") }}
            </router-link>
            *
          </span>
        </template>
      </v-checkbox>
      <v-checkbox
        v-model="checkTermsConditions"
        :rules="[rules.required]"
        color="primary"
      >
        <template #label>
          <span>
            {{ $t("text.check-terms-conditions") }}
            <router-link
              :to="{ name: 'terms' }"
              target="_blank"
              class="text-primary"
            >
              {{ $t("button.privacy-agb") }}
            </router-link>
            *
          </span>
        </template>
      </v-checkbox>
    </div>
    <div class="d-flex justify-space-between mt-8">
      <v-btn
        variant="text"
        color="primary"
        size="large"
        @click="emit('step', 2)"
      >
        {{ $t("button.back") }}
      </v-btn>
      <v-btn
        type="submit"
        size="large"
        color="primary"
        :disabled="somethingLoaded"
        :loading="loading"
      >
        {{
          isPriceOnRequest
            ? $t("button.create-offer")
            : $t("button.create-order")
        }}
      </v-btn>
    </div>
  </v-form>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";

import { createDocument } from "@/api/documents";

import { useValidHelper, isValidCheck } from "@/utils/validHelper";
import { useRules } from "@/utils/rules";
import { useMountScroller } from "@/utils/mountScroller";
import {
  getInitialDocumentErrorObject,
  getLocalDocumentObject,
} from "@/utils/calculator";
import { snackbar } from "@/utils/snackbar";

import { CalcBlockType, StationObject } from "@/types/calculator";
import {
  DocumentType,
  DocumentErrorObject,
  DocumentObject,
  DocumentSavedData,
} from "@/types/document";

import { VForm } from "vuetify/components";

import AppLoader from "@/components/AppLoader.vue";
import EditableBlock from "@/components/EditableBlock.vue";
import ArrivalTimeBlock from "@/components/CalculatorView/Blocks/ArrivalTimeBlock.vue";
import RouteDataBlock from "@/components/CalculatorView/Blocks/RouteDataBlock.vue";
import TransportBlock from "@/components/CalculatorView/Blocks/TransportBlock.vue";
import GoodsBlock from "@/components/CalculatorView/Blocks/GoodsBlock.vue";
import FeaturesBlock from "@/components/CalculatorView/Blocks/FeaturesBlock.vue";
import OtherBlock from "@/components/CalculatorView/Blocks/OtherBlock.vue";
import CompanyBlock from "@/components/CalculatorView/Blocks/CompanyBlock.vue";

const rules = useRules();
const store = useStore();
const { t } = useI18n();

const form = ref<VForm>();
useValidHelper(form);
useMountScroller();

const documentObject = ref<DocumentObject>(getLocalDocumentObject());

const valid = ref(false);
const editableBlocks = ref<CalcBlockType[]>([]);
const panels = ref([0]);
const loading = ref(false);
const routesLoading = ref(false);
const datesLoading = ref(false);
const errors = ref<DocumentErrorObject>(getInitialDocumentErrorObject());
const errorsRaw = ref<{ [K: string]: string[] }>({});
const checkPrivacyPolicy = ref(false);
const checkTermsConditions = ref(false);

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "step", data: number): void;
  // eslint-disable-next-line no-unused-vars
  (e: "created", data: { document: DocumentSavedData; count: string }): void;
}>();

const fullStationsList = computed<StationObject[]>(() => [
  documentObject.value.firstStationData,
  ...documentObject.value.stationsList,
  documentObject.value.lastStationData,
]);

const isFirstPanelEdited = computed(() => {
  const firstPanelBlockTypes: CalcBlockType[] = [
    CalcBlockType.arrivalTime,
    CalcBlockType.routeData,
    CalcBlockType.transport,
    CalcBlockType.goods,
    CalcBlockType.features,
    CalcBlockType.other,
  ];
  return editableBlocks.value.some((item) =>
    firstPanelBlockTypes.includes(item),
  );
});

const isSecondPanelEdited = computed(() => {
  const secondPanelBlockTypes = [CalcBlockType.company];
  return editableBlocks.value.some((item) =>
    secondPanelBlockTypes.includes(item),
  );
});

const somethingLoaded = computed(
  () => datesLoading.value || routesLoading.value,
);

const isPriceOnRequest = computed(
  () =>
    !!store.state.calculator.document.manual ||
    !!store.state.calculator.priceOnRequest.features ||
    !!store.state.calculator.prepare.ferries.price_on_request ||
    !!store.state.calculator.calc.priceOnRequestOverpriced ||
    !!store.state.calculator.calc.priceOnRequestOverweight ||
    !!store.state.calculator.calc.priceOnRequestOversize ||
    !!store.state.calculator.calc.priceOnRequestTime,
);

const closeBlock = (name: CalcBlockType) => {
  editableBlocks.value = editableBlocks.value.filter(
    (blockName) => blockName !== name,
  );
};

const saveChanges = async ({
  name,
  inputs,
}: {
  name: CalcBlockType;
  inputs: (string | number)[];
}) => {
  const isValid = await isValidCheck(inputs, form.value);

  const newDocumentData = reduceDocuments(
    getLocalDocumentObject(),
    documentObject.value,
    name,
  );

  if (isValid) {
    if (name === CalcBlockType.routeData) {
      try {
        routesLoading.value = true;

        await store.dispatch("calculator/fetchPrepare", {
          vignette: documentObject.value.vignette,
          optimal_route: documentObject.value.optimal_route,
          points: fullStationsList.value,
        });
        store.commit("calculator/setDocumentLocal", newDocumentData);
        closeBlock(name);
      } catch (e: any) {
        snackbar(e.message);
        errorsRaw.value = e.errors;
      } finally {
        routesLoading.value = false;
      }
    } else {
      store.commit("calculator/setDocumentLocal", newDocumentData);
      if (name === CalcBlockType.arrivalTime) {
        store.commit("calculator/setSeasonFactorLocal");
      }
      closeBlock(name);
    }
  }
};

const reduceDocuments = (
  acceptDocument: DocumentObject,
  donorDocument: DocumentObject,
  name: CalcBlockType,
) => {
  switch (name) {
    case CalcBlockType.arrivalTime:
      acceptDocument.loading_time_start = donorDocument.loading_time_start;
      acceptDocument.loading_time_end = donorDocument.loading_time_end;
      acceptDocument.unloading_time_start = donorDocument.unloading_time_start;
      acceptDocument.unloading_time_end = donorDocument.unloading_time_end;

      break;
    case CalcBlockType.routeData:
      acceptDocument.type = donorDocument.type;
      acceptDocument.vignette = donorDocument.vignette;
      acceptDocument.manual = donorDocument.manual;
      acceptDocument.optimal_route = donorDocument.optimal_route;
      acceptDocument.document_count = donorDocument.document_count;

      acceptDocument.firstStationData = donorDocument.firstStationData;
      acceptDocument.lastStationData = donorDocument.lastStationData;
      acceptDocument.stationsList = donorDocument.stationsList;
      acceptDocument.price_on_request = donorDocument.price_on_request;

      break;
    case CalcBlockType.transport:
      acceptDocument.transport_type_id = donorDocument.transport_type_id;
      break;
    case CalcBlockType.goods:
      acceptDocument.type = donorDocument.type;
      acceptDocument.goods_type = donorDocument.goods_type;
      acceptDocument.goods_weight = donorDocument.goods_weight;
      acceptDocument.goods_value = donorDocument.goods_value;
      acceptDocument.goods_length = donorDocument.goods_length;
      acceptDocument.goods_width = donorDocument.goods_width;
      acceptDocument.goods_height = donorDocument.goods_height;
      acceptDocument.goods_insurance = donorDocument.goods_insurance;
      acceptDocument.goods_product_group = donorDocument.goods_product_group;

      acceptDocument.price_on_request = donorDocument.price_on_request;
      break;
    case CalcBlockType.features:
      acceptDocument.type = donorDocument.type;
      acceptDocument.adr = donorDocument.adr;
      acceptDocument.languageSkills = donorDocument.languageSkills;
      acceptDocument.other = donorDocument.other;
      acceptDocument.price_on_request = donorDocument.price_on_request;
      break;
    case CalcBlockType.other:
      acceptDocument.pallet_exchange_id = donorDocument.pallet_exchange_id;
      break;
    case CalcBlockType.company:
      acceptDocument.company_id = donorDocument.company_id;
      acceptDocument.user_id = donorDocument.user_id;
      break;
    default:
  }
  return acceptDocument;
};

const cancelChanges = (name: CalcBlockType) => {
  documentObject.value = reduceDocuments(
    documentObject.value,
    getLocalDocumentObject(),
    name,
  );
};

const callSubmit = async () => {
  await form.value?.validate();
  switch (true) {
    case !!editableBlocks.value.length:
      snackbar(t("error-message.editable-must-be-saved"));
      break;
    case somethingLoaded.value:
      break;
    case !valid.value:
      break;
    default:
      try {
        loading.value = true;
        const vehicleTypeId = store.state.app.vehicles.find(
          ({ code }) =>
            code === store.state.calculator.document.transport_type_id,
        )?.id;
        const palletExchangeId = store.state.app.pallets.find(
          ({ code }) =>
            code === store.state.calculator.document.pallet_exchange_id,
        )?.id;

        const storePrepare = store.state.calculator.prepare;
        const storeCalc = store.state.calculator.calc;

        const localDocumentObject: DocumentObject = {
          ...store.state.calculator.document,
          transport_type_id: vehicleTypeId,
          pallet_exchange_id: palletExchangeId,
          points_ids: storePrepare.points_ids,
          distance: storePrepare.distance,
          total_price: storeCalc.totalPrice,
          transporter_price: storeCalc.transporterPrice,
          calc: JSON.stringify(storeCalc),
          optimal_loading_time: storeCalc.optimalLoadingTime,
        };

        const { data } = await createDocument(localDocumentObject);

        emit("created", {
          document: data,
          count: localDocumentObject.document_count,
        });
      } catch (e: any) {
        snackbar(e.message);
        errors.value = { ...getInitialDocumentErrorObject(), ...e.errors };
      } finally {
        loading.value = false;
      }
  }
};

const updateDatesLoading = (value: boolean) => {
  datesLoading.value = value;
};

watch(
  fullStationsList,
  () => {
    errorsRaw.value = {};
  },
  { deep: true },
);

watch(
  isPriceOnRequest,
  (value) => {
    documentObject.value.type = value ? DocumentType.offer : DocumentType.order;
    documentObject.value.price_on_request = value;
  },
  { immediate: true },
);

watch(
  documentObject,
  (value) => {
    store.commit("calculator/setDocument", value);
  },
  { deep: true },
);
</script>

<style scoped></style>
