<template>
  <div class="bpl-border-gray rounded-t-lg">
    <v-row no-gutters class="px-6 pt-6 ma-0 padding-bottom-2">
      <v-col cols="2">
        <month-selector v-model="currentMonth" :loading="loading" />
      </v-col>
      <v-col class="pt-3 pl-4">
        <v-chip
          v-if="rangeString"
          color="primary"
          variant="flat"
          closable
          @click:close="selectRange = { start: null, end: null }"
        >
          <span class="pr-1">{{ rangeString }}</span>
        </v-chip>
      </v-col>
      <v-col class="pt-4 text-grey-darken-2">
        <span
          v-if="!loading && disabledDays.length === prepFactorData?.length"
          >{{ $t("text.calendar-period-no-edit") }}</span
        >
      </v-col>
      <v-col cols="4" class="d-flex justify-end">
        <v-text-field
          ref="factorInput"
          v-model="seasonFactor"
          :label="
            !props.isMarket ? $t('season-factor') : $t('replacement-factor')
          "
          class="mr-6"
          :readonly="isFactorReadonly"
          :variant="isFactorReadonly ? 'filled' : 'outlined'"
          :class="{
            'pointer-events-none': isFactorReadonly,
          }"
          @focus="$event.target.select()"
          @keydown.enter="saveFactor"
        >
          <template #append-inner>
            <v-btn
              icon="mdi-send"
              elevation="0"
              class="bg-transparent"
              :loading="factorLoading"
              @click="saveFactor"
            />
          </template>
        </v-text-field>
        <v-btn
          icon="mdi-chevron-left"
          variant="plain"
          elevation="0"
          :disabled="loading"
          @click="clickArrow(false)"
        ></v-btn>
        <v-btn
          icon="mdi-chevron-right"
          variant="plain"
          elevation="0"
          :disabled="loading"
          @click="clickArrow(true)"
        ></v-btn>
      </v-col>
    </v-row>
    <div class="dates-list">
      <div class="dates-list-header bg-grey-lighten-4">
        <div
          v-for="day in daysOfWeek"
          :key="day"
          class="dates-list-item text-center py-3 font-weight-medium text-subtitle-2"
        >
          {{ day }}
        </div>
      </div>
      <div v-if="prepFactorData" class="dates-list-content">
        <button
          v-for="i in monthRangeEdges.start"
          :key="i"
          class="dates-list-item dates-list-day-btn"
          disabled
        >
          &nbsp;
        </button>
        <button
          v-for="(dayData, i) in prepFactorData"
          :key="dayData.date"
          class="dates-list-item dates-list-day-btn text-center"
          :class="{
            'dates-list-day-btn-selected': selectedDays.includes(dayData.date),
            'dates-list-day-btn-selected-text':
              selectRange.end && selectedDays.includes(dayData.date),
            'dates-list-day-btn-selected-start':
              selectRange.start === dayData.date,
            'dates-list-day-btn-selected-end': selectRange.end === dayData.date,
            'dates-list-day-btn-highlighted': highlightedDays.includes(
              dayData.date,
            ),
            'dates-list-day-btn-hovered': dayData.date === hoveredDay,
          }"
          :disabled="disabledDays.includes(dayData.date)"
          @mouseenter="hoveredDay = dayData.date"
          @mouseleave="hoveredDay = null"
          @click="clickDay(dayData.date)"
        >
          <span
            class="font-weight-regular text-subtitle-2 text-grey-darken-1"
            >{{ i + 1 }}</span
          >
          <span class="font-weight-black text-h6 dates-list-day-value">{{
            dayData.value
          }}</span>
        </button>
        <button
          v-for="i in monthRangeEdges.end"
          :key="i"
          class="dates-list-item dates-list-day-btn"
          disabled
        >
          &nbsp;
        </button>
      </div>
      <app-loader v-else height="100%" />
    </div>
  </div>
</template>

<script setup lang="ts">
import MonthSelector from "@/components/FactorCalendar/MonthSelector.vue";
import { computed, ref, watch } from "vue";
const { locale } = useI18n();
import moment from "moment";
import { useI18n } from "vue-i18n";
import AppLoader from "@/components/AppLoader.vue";
import { snackbar } from "@/utils/snackbar";
import {
  addMissedDates,
  checkDisabledDay,
  datesInRange,
  reduceWithNewDates,
  startAndEndMoments,
} from "@/utils/seasonFactor";
import { VTextField } from "vuetify/components";
import { getSeasonFactors, setSeasonFactor } from "@/api/settings";
import { useNumberHelper } from "@/utils/numberHelper";
import { FactorDay } from "@/types/settings";
import { backFormats, useLocFormat } from "@/utils/locFormat";

const props = defineProps<{
  isMarket?: boolean;
}>();

const maxDecimals = !props.isMarket ? 3 : 4;

const factorData = ref<FactorDay[] | null>(null);

const prepFactorData = computed(
  () =>
    factorData.value?.map((dayData) => {
      const value = !props.isMarket ? dayData.value : dayData.mf_value;
      return {
        date: dayData.date,
        value: (value !== null ? value : 1).toLocaleString(locale.value, {
          minimumFractionDigits: 1,
          maximumFractionDigits: maxDecimals,
        }),
      };
    }),
);

const hoveredDay = ref<string | null>(null);

const selectRange = ref<{ start: string | null; end: string | null }>({
  start: null,
  end: null,
});

const currentMonth = ref(moment().format(backFormats.yMm));

const seasonFactor = useNumberHelper(
  maxDecimals,
  0,
  !props.isMarket ? 100 : 10,
);

const factorInput = ref<VTextField>();

const factorLoading = ref(false);

const locFormat = useLocFormat();

const daysOfWeek = computed(() => {
  const list = moment().locale(locale.value).localeData().weekdaysShort();
  return [...list.slice(1), list[0]];
});

const loading = computed(() => !factorData.value);

const isFactorReadonly = computed(
  () => !(selectRange.value.start && selectRange.value.end),
);

const locDateFormat = (d: string | null) =>
  d ? moment(d).format(locFormat.value.yMmDd) : "";

const rangeString = computed(() => {
  const { start, end } = selectRange.value;
  const resStart = locDateFormat(start);
  const resEnd = locDateFormat(end);
  if (resStart === resEnd) return resStart || "";
  else return [resStart, resEnd || ""].join(" – ");
});

const clickDay = (date: string) => {
  if (!selectRange.value.start || selectRange.value.end)
    selectRange.value = { start: date, end: null };
  else {
    const { start, end } = startAndEndMoments(selectRange.value.start, date);

    selectRange.value = {
      start: start.format(backFormats.yMmDd),
      end: end.format(backFormats.yMmDd),
    };
  }
};

const saveFactor = async () => {
  if (
    seasonFactor.value &&
    selectRange.value.start &&
    selectRange.value.end &&
    !factorLoading.value
  ) {
    try {
      factorLoading.value = true;
      const newFactorData = await setSeasonFactor(
        !props.isMarket
          ? { value: Number(seasonFactor.value) }
          : { mf_value: Number(seasonFactor.value) },
        selectRange.value.start,
        selectRange.value.end,
      );
      factorData.value = reduceWithNewDates(
        factorData.value || [],
        newFactorData.data,
      );
      seasonFactor.value = "";
      factorInput.value?.blur();
    } catch (e: any) {
      snackbar(e.message);
    } finally {
      factorLoading.value = false;
    }
  }
};

const highlightedDays = computed<string[]>(() => {
  if (
    factorData.value &&
    selectRange.value.start &&
    !selectRange.value.end &&
    hoveredDay.value
  )
    return datesInRange(
      selectRange.value.start,
      hoveredDay.value as string,
      factorData.value as FactorDay[],
    );
  else return [];
});

const selectedDays = computed<string[]>(() => {
  if (factorData.value && selectRange.value.start) {
    if (selectRange.value.end)
      return datesInRange(
        selectRange.value.start,
        selectRange.value.end,
        factorData.value as FactorDay[],
        true,
      );
    else return [selectRange.value.start];
  } else return [];
});

const disabledDays = computed<string[]>(
  () =>
    factorData.value
      ?.filter(({ date }) => checkDisabledDay(date))
      .map(({ date }) => date) || [],
);

const clickArrow = (isRight: boolean) => {
  const m = moment(currentMonth.value);
  if (isRight) m.add(1, "month");
  else m.subtract(1, "month");
  currentMonth.value = m.format(backFormats.yMm);
};

const monthRangeEdges = computed(() => {
  const m = moment(currentMonth.value);
  const daysInMonth = m.daysInMonth();
  const start = (m.date(1).day() + 6) % 7;
  const end = 42 - daysInMonth - start;

  return {
    start,
    end,
  };
});

const fetchWeeksList = async (month: string) => {
  try {
    const seasonFactors = await getSeasonFactors(month);

    factorData.value = addMissedDates(seasonFactors.data, month);
  } catch (e: any) {
    snackbar(e.message);
  }
};

watch(
  currentMonth,
  (value) => {
    factorData.value = null;
    fetchWeeksList(value);
  },
  { immediate: true },
);

watch(selectRange, ({ start, end }) => {
  if (start && end) factorInput.value?.focus();
});
</script>

<style scoped>
.dates-list {
  height: 515px;
}

.dates-list-header {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  height: 47px;
}

.dates-list-content {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.dates-list-item {
  position: relative;
}

.dates-list-item:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-left: #eeeeee 1px solid;
  border-bottom: #eeeeee 1px solid;
  pointer-events: none;
}

.dates-list-item:nth-last-child(7n):after,
.dates-list-day-btn-selected + .dates-list-day-btn-selected:after {
  border-left: none;
}

.dates-list-item:nth-child(n + 36):after {
  border-bottom: none;
}

.dates-list-day-btn {
  height: 78px;
  display: grid;
  align-content: center;
  outline: none;
}

.dates-list-day-btn:disabled {
  pointer-events: none;
}

.dates-list-day-btn:disabled .dates-list-day-value {
  opacity: 0.37;
}

.dates-list-day-btn-hovered,
.dates-list-day-btn-highlighted {
  background-color: #e3f2fd;
}

.dates-list-day-btn-selected {
  background-color: #e3f2fd;
}

.dates-list-day-btn-selected-text * {
  color: #1982d4 !important;
}

.dates-list-day-btn-selected-start {
  background-color: #bbdefb;
}

.dates-list-day-btn-selected-end {
  background-color: #bbdefb;
}

.dates-list-day-btn-selected.dates-list-day-btn-hovered {
  background-color: #90caf9;
}

.padding-bottom-2 {
  padding-bottom: 2px;
}
</style>
