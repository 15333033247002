import { computed, watch, App } from "vue";

import Pusher from "pusher-js";
import Echo from "laravel-echo";

import { broadcast } from "@/utils/events";

import { NotificationObject } from "@/types/other";

export default {
  install: (app: App) => {
    const store = app.config.globalProperties.$store;
    const sockets = Object(null);
    try {
      const socketsLeave = (chanel: string) => {
        sockets.Echo.leave(chanel);
      };

      const socketsConnect = (id: string, token: string) => {
        sockets.Pusher = Pusher;
        sockets.Echo = new Echo({
          broadcaster: "pusher",
          key: process.env.VUE_APP_ECHO_KEY,
          wsHost: process.env.VUE_APP_ECHO_WS_HOST,
          wssPort: process.env.VUE_APP_ECHO_WSS_PORT,
          cluster: process.env.VUE_APP_ECHO_CLUSTER,
          forceTLS: true,
          authEndpoint: process.env.VUE_APP_ECHO_AUTH_ENDPOINT,
          auth: {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
          enabledTransports: ["ws", "wss"],
        });
        socketsLeave(`user.${id}`);
        sockets.Echo.private(`user.${id}`).listenToAll(
          (e: string, data: NotificationObject) => {
            store.commit("app/addNotification", data);
            broadcast("Notification", data);
          }
        );
      };

      const auth = computed(() => store.state.auth);

      watch(
        auth,
        (value, oldValue) => {
          if (oldValue?.profile?.id && sockets.Echo) {
            socketsLeave(`user.${oldValue.profile?.id}`);
          }
          if (value?.profile?.id && value?.tokens?.access_token) {
            socketsConnect(value.profile.id, value.tokens.access_token);
          }
        },
        { immediate: true, deep: true }
      );
    } catch (e: any) {
      console.error(e.message);
    }
  },
};
