import { getLocValue } from "@/utils/localStorage";

import { PrepareResponseObject, DocumentCalcObject } from "@/types/calculator";
import {
  DocumentType,
  PointType,
  DocumentObject,
  DocumentErrorObject,
  GoodsProductGroup,
} from "@/types/document";

export const getInitialDocumentObject = (): DocumentObject => ({
  type: DocumentType.order,
  vignette: false,
  optimal_route: false,
  points_ids: [],
  goods_insurance: false,
  // row fields
  adr: null,
  languageSkills: null,
  other: [],
  firstStationData: {
    id: Symbol(),
    type: PointType.loadingPoint,
    country_id: null,
    zip: null,
    discharged: false,
  },
  stationsList: [],
  lastStationData: {
    id: Symbol(),
    type: PointType.unloadingPoint,
    country_id: null,
    zip: null,
    discharged: false,
  },
  document_count: "1",
});

export const getInitialDocumentErrorObject = (): DocumentErrorObject => ({
  loading_time_start: [],
  loading_time_end: [],
  unloading_time_start: [],
  unloading_time_end: [],
  vignette: [],
  optimal_route: [],
  points_ids: [],
  transport_type_id: [],
  reference_number: [],
  goods_type: [],
  goods_weight: [],
  goods_value: [],
  goods_length: [],
  goods_width: [],
  goods_height: [],
  goods_insurance: [],
  goods_product_group: [],
  pallet_exchange_id: [],
  company_id: [],
  user_id: [],
  total_price: [],
  transporter_price: [],
  document_count: [],
});

export const getLocalDocumentObject = () => {
  const documentObject =
    getLocValue("CURRENT_DOCUMENT") || getInitialDocumentObject();
  documentObject.firstStationData.id = Symbol();
  documentObject.lastStationData.id = Symbol();
  documentObject.stationsList = documentObject.stationsList.map((s) => ({
    ...s,
    id: Symbol(),
  }));
  return documentObject;
};

export const getInitialPrepareObject = (): PrepareResponseObject => ({
  distance: 0,
  ferries: {
    boat: 0,
    rail: 0,
    price_on_request: false,
  },
  lzf: {
    client_factor: 1,
  },
  market_factor: 1,
  plz: 1,
  points_ids: [],
});

export const getInitialDocumentCalcObject = (): DocumentCalcObject => ({
  distance: 0,
  duration: 0,
  drivers: 0,
  expCost: 0,
  arrBE: [],
  bltCost: 0,
  eltCost: 0,
  ptCost: 0,
  faCost: 0,
  nzLoadCost: 0,
  nzUpCost: 0,
  boatCost: 0,
  railCost: 0,
  basePrice: 0,
  totalPrice: 0,
  transporterPrice: 0,
  minDuration: 0,
  optimalLoadingTime: "",
});

export const getGoodsProductGroupTitle = (key?: GoodsProductGroup) => {
  const rome = {
    0: { key: "product-group-item", number: "I" },
    1: { key: "product-group-item", number: "II" },
    2: { key: "product-group-item", number: "III" },
    3: { key: "product-group-item", number: "IV" },
    4: { key: "product-group-other", number: "" },
  };

  return key ? rome[key] : { key: "", number: "" };
};
