<template>
  <v-row ref="datesBlock" class="my-0 py-6">
    <v-col
      :cols="!props.readonly ? (!flexStart ? 8 : 4) : !flexStart ? 12 : 6"
      class="py-0"
    >
      <calendar-field
        :model-value="documentObject.loading_time_start"
        :error-messages="errors.loading_time_start"
        :title="$t('loading-time')"
        :rules="[
          rules.required,
          rules.mustAfter(minDateLoadingStart, locFormat.dDMmYHhMmUhr),
        ]"
        class="pb-2"
        :info="!flexStart && !props.readonly"
        :min="minDateLoadingStart"
        :readonly="props.readonly"
        :loading="datesLoading"
        @update:model-value="vModalUpdate('loading_time_start', $event)"
      />
    </v-col>
    <v-col
      v-if="flexStart"
      :cols="!props.readonly ? 4 : 6"
      class="bpl-hyphen-col py-0"
    >
      <calendar-field
        :model-value="documentObject.loading_time_end"
        :error-messages="errors.loading_time_end"
        :title="$t('loading-time')"
        :rules="[
          rules.required,
          rules.mustAfter(minDateLoadingEnd, locFormat.dDMmYHhMmUhr),
        ]"
        class="pb-2"
        :info="!props.readonly"
        :min="minDateLoadingStart"
        :readonly="props.readonly"
        :loading="datesLoading"
        @update:model-value="vModalUpdate('loading_time_end', $event)"
      />
    </v-col>
    <v-col v-if="!props.readonly" cols="4" class="py-0 pl-12">
      <v-tooltip text="Lorem Ipsum" location="bottom" offset="-32px">
        <template #activator="{ props: tooltipProps }">
          <div v-bind="tooltipProps" class="d-inline-block">
            <v-checkbox
              v-model="flexStart"
              :label="$t('flexible-arrival-time')"
              color="primary"
              class="bpl-underlined-field"
            ></v-checkbox></div></template
      ></v-tooltip>
    </v-col>
    <v-col
      :cols="!props.readonly ? (!flexEnd ? 8 : 4) : !flexEnd ? 12 : 6"
      class="py-0"
    >
      <calendar-field
        :model-value="documentObject.unloading_time_start"
        :error-messages="errors.unloading_time_start"
        :title="$t('discharge-time')"
        :rules="[
          rules.required,
          rules.mustAfter(minDateDischargeStart, locFormat.dDMmYHhMmUhr),
        ]"
        class="pb-2"
        :info="!flexEnd && !props.readonly"
        :min="minDateLoadingStart"
        :readonly="props.readonly"
        :loading="datesLoading"
        @update:model-value="vModalUpdate('unloading_time_start', $event)"
      />
    </v-col>
    <v-col
      v-if="flexEnd"
      :cols="!props.readonly ? 4 : 6"
      class="bpl-hyphen-col py-0"
    >
      <calendar-field
        :model-value="documentObject.unloading_time_end"
        :error-messages="errors.unloading_time_end"
        :title="$t('discharge-time')"
        :rules="[
          rules.required,
          rules.mustAfter(minDateDischargeEnd, locFormat.dDMmYHhMmUhr),
        ]"
        class="pb-2"
        :info="!props.readonly"
        :min="minDateLoadingStart"
        :readonly="props.readonly"
        :loading="datesLoading"
        @update:model-value="vModalUpdate('unloading_time_end', $event)"
      />
    </v-col>
    <v-col v-if="!props.readonly" cols="4" class="py-0 pl-12">
      <v-tooltip text="Lorem Ipsum" location="bottom" offset="-32px">
        <template #activator="{ props: tooltipProps }">
          <div v-bind="tooltipProps" class="d-inline-block">
            <v-checkbox
              v-model="flexEnd"
              :label="$t('flexible-arrival-time')"
              color="primary"
              class="bpl-underlined-field"
            ></v-checkbox></div></template
      ></v-tooltip>
    </v-col>
    <v-col cols="12" class="py-0">
      <v-tooltip text="Lorem Ipsum" location="bottom">
        <template #activator="{ props: tooltipProps }">
          <span
            class="bpl-underlined-text text-subtitle-1"
            v-bind="tooltipProps"
          >
            {{ $t("number-drivers") }}
          </span>
        </template></v-tooltip
      >
      <v-chip size="default" class="ml-4">
        {{ store.state.calculator.calc.drivers }}
      </v-chip>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { ref, watch, computed, nextTick, onMounted, onUnmounted } from "vue";
import { useStore } from "@/store";
import moment from "moment";

import { isValidCheck, getAllInputs } from "@/utils/validHelper";
import { useRules } from "@/utils/rules";
import {
  getInitialDocumentErrorObject,
  getLocalDocumentObject,
} from "@/utils/calculator";
import { snackbar } from "@/utils/snackbar";
import { backFormats, useLocFormat } from "@/utils/locFormat";

import { DocumentErrorObject, DocumentObject } from "@/types/document";
import { VForm } from "vuetify/components";

import CalendarField from "@/components/CalendarField.vue";

const rules = useRules();
const store = useStore();
const locFormat = useLocFormat();

const props = defineProps<{
  modelValue?: DocumentObject;
  readonly?: boolean;
  form: VForm | undefined;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", data?: DocumentObject): void;
  (e: "loading", data: boolean): void;
}>();

const documentObject = computed(
  () => props.modelValue || getLocalDocumentObject(),
);

const flexStart = ref(false);
const flexEnd = ref(false);
const datesLoading = ref(false);
const errors = ref<DocumentErrorObject>(getInitialDocumentErrorObject());
const thisMoment = ref(moment().format(backFormats.yMmDdHhMmSs));
const datesBlock = ref();
const intervalID = ref();

const minDateLoadingStart = computed(() => {
  const minProcessingHorus = 4;
  const workingTimeStart =
    (store.state.app.settings?.working_time_start || "00:00") + ":00";
  const workingTimeEnd =
    (store.state.app.settings?.working_time_end || "23:59") + ":00";

  const getNextWorkDay = (date: string, processingHorus: number) => {
    const mDate = moment(date, backFormats.yMmDdHhMmSs);
    mDate.add(1, "days");

    if (mDate.day() === 6) {
      mDate.add(2, "days");
    } else if (mDate.day() === 0) {
      mDate.add(1, "days");
    }

    const mResultDate = moment(
      `${mDate.format(backFormats.yMmDd)} ${workingTimeStart}`,
      backFormats.yMmDdHhMmSs,
    ).add(processingHorus, "hours");

    return mResultDate.format(backFormats.yMmDdHhMmSs);
  };

  if (
    [0, 6].includes(moment(thisMoment.value, backFormats.yMmDdHhMmSs).day())
  ) {
    return getNextWorkDay(thisMoment.value, minProcessingHorus);
  }

  const mThisMomentNoTime = moment(thisMoment.value, backFormats.yMmDd).format(
    backFormats.yMmDd,
  );
  const mWorkingTimeStart = moment(
    `${mThisMomentNoTime} ${workingTimeStart}`,
    backFormats.yMmDdHhMmSs,
  );
  const mWorkingTimeEnd = moment(
    `${mThisMomentNoTime} ${workingTimeEnd}`,
    backFormats.yMmDdHhMmSs,
  );

  const mProcessingStart = moment(thisMoment.value, backFormats.yMmDdHhMmSs);
  const mProcessingEnd = moment(thisMoment.value, backFormats.yMmDdHhMmSs).add(
    minProcessingHorus,
    "hours",
  );
  // this moment is not work time
  if (!mProcessingStart.isBetween(mWorkingTimeStart, mWorkingTimeEnd)) {
    //it's too late now
    if (mProcessingStart.isAfter(mWorkingTimeEnd)) {
      return getNextWorkDay(thisMoment.value, minProcessingHorus);
    }
    //it's too early now
    else {
      const mResultDate = moment(
        mWorkingTimeStart.format(backFormats.yMmDdHhMmSs),
        backFormats.yMmDdHhMmSs,
      ).add(minProcessingHorus, "hours");
      return mResultDate.format(backFormats.yMmDdHhMmSs);
    }
  }
  // this moment + minProcessingHorus is not work time
  else if (!mProcessingEnd.isBetween(mWorkingTimeStart, mWorkingTimeEnd)) {
    const preProcessingHorus = mWorkingTimeEnd.diff(
      mProcessingStart,
      "hours",
      true,
    );

    return getNextWorkDay(
      thisMoment.value,
      minProcessingHorus - preProcessingHorus,
    );
  }
  // this is work time
  else {
    return mProcessingEnd.format(backFormats.yMmDdHhMmSs);
  }
});

const routeMinEnd = computed(() => {
  if (documentObject.value.loading_time_start) {
    return moment(
      documentObject.value.loading_time_start,
      backFormats.yMmDdHhMmSs,
    )
      .add(store.state.calculator.calc.minDuration, "hours")
      .format(backFormats.yMmDdHhMmSs);
  } else {
    return 0;
  }
});

const minDateLoadingEnd = computed(
  () => documentObject.value.loading_time_start || minDateLoadingStart.value,
);

const minDateDischargeStart = computed(
  () =>
    routeMinEnd.value ||
    documentObject.value.loading_time_end ||
    minDateLoadingEnd.value,
);

const minDateDischargeEnd = computed(
  () =>
    documentObject.value.unloading_time_start || minDateDischargeStart.value,
);

const checkDatesValidate = async () => {
  await nextTick();
  const inputs = getAllInputs(datesBlock.value);
  return await isValidCheck(inputs, props.form);
};

const fetchSeasonFactor = async (start: string, end: string) => {
  if (await checkDatesValidate()) {
    try {
      datesLoading.value = true;
      errors.value.loading_time_start = [];
      errors.value.loading_time_end = [];
      errors.value.unloading_time_start = [];
      errors.value.unloading_time_end = [];
      await store.dispatch("calculator/fetchSeasonFactor", {
        start,
        end,
      });
    } catch (e: any) {
      snackbar(e.message);
      errors.value.loading_time_start = e.errors.loading_date || [];
      errors.value.loading_time_end = e.errors.loading_date || [];
      errors.value.unloading_time_start = e.errors.unloading_date || [];
      errors.value.unloading_time_end = e.errors.unloading_end || [];
    } finally {
      datesLoading.value = false;
    }
  }
};

const vModalUpdate = (field: keyof DocumentObject, value: any) => {
  errors.value[field as keyof DocumentErrorObject] = [];
  checkDatesValidate();
  emit("update:modelValue", {
    ...documentObject.value,
    [field]: value,
  });
};

watch(
  () => ({ start: flexStart.value, end: flexEnd.value }),
  ({ start, end }) => {
    if (!start) documentObject.value.loading_time_end = undefined;
    if (!end) documentObject.value.unloading_time_end = undefined;
  },
);

watch(
  () => props.readonly,
  () => {
    flexStart.value = !!documentObject.value.loading_time_end;
    flexEnd.value = !!documentObject.value.unloading_time_end;
  },
  { immediate: true },
);

watch(
  () => ({
    start: documentObject.value.loading_time_start,
    end:
      documentObject.value.unloading_time_end ||
      documentObject.value.unloading_time_start,
  }),
  ({ start, end }, old) => {
    if (start && end && (start !== old.start || end !== old.end)) {
      fetchSeasonFactor(start, end);
    }
  },
);

watch(datesLoading, (value) => emit("loading", value));

onMounted(() => {
  intervalID.value = setInterval(() => {
    thisMoment.value = moment().format(backFormats.yMmDdHhMmSs);
  }, 30000);
});

onUnmounted(() => {
  clearInterval(intervalID.value);
});
</script>

<style scoped></style>
