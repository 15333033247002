<template>
  <v-form ref="form" v-model="valid" @submit.prevent="callSubmit()">
    <v-row class="my-0 pt-10">
      <v-col class="py-0">
        <v-text-field
          v-model="data.company_vat_id"
          autocomplete="off"
          :rules="[rules.required]"
          :label="$t('vat')"
          variant="outlined"
          class="pb-2"
        ></v-text-field>
      </v-col>
      <v-col class="py-0">
        <v-text-field
          v-model="data.company_tax_id"
          autocomplete="off"
          :rules="[rules.required]"
          :label="$t('tax')"
          variant="outlined"
          class="pb-2"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="props.role === 'client'" class="my-0">
      <v-col class="py-0">
        <v-select
          v-model="data.company_payment_target"
          autocomplete="off"
          :rules="[rules.required]"
          :label="$t('payment-target')"
          :items="
            paymentTargetKeys.map(({ type, key }) => ({
              value: type,
              title: $t(key),
            }))
          "
          :hint="$t('text.payment-target-info')"
          persistent-hint
          variant="outlined"
          class="pb-2"
        ></v-select>
      </v-col>
    </v-row>
    <template v-if="props.role !== 'client'">
      <v-divider></v-divider>
      <div class="text-h5 text-primary pt-10 pb-6">
        {{ $t("bank-details") }}
      </div>
      <v-row class="my-0">
        <v-col cols="6" class="py-0">
          <v-text-field
            v-model="data.company_iban"
            autocomplete="off"
            :rules="[rules.iban]"
            :label="$t('iban')"
            variant="outlined"
            class="pb-2"
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="py-0">
          <v-text-field
            v-model="data.company_swift"
            autocomplete="off"
            :label="$t('swift')"
            variant="outlined"
            class="pb-2"
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="py-0">
          <v-text-field
            v-model="data.company_bank_name"
            autocomplete="off"
            :label="$t('bank-name')"
            variant="outlined"
            class="pb-2"
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="py-0">
          <v-text-field
            v-model="data.company_bank_code"
            autocomplete="off"
            :label="$t('bank-code')"
            variant="outlined"
            class="pb-2"
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <div class="d-flex justify-space-between mt-10">
      <v-btn variant="text" color="primary" size="large" @click="emit('back')">
        {{ $t("button.back") }}
      </v-btn>
      <v-btn type="submit" color="primary" size="large">
        {{ $t("button.next") }}
      </v-btn>
    </div>
  </v-form>
</template>

<script setup lang="ts">
import { ref } from "vue";

import { useRules } from "@/utils/rules";

import { NewUserRole, NewUserObject } from "@/types/auth";
import { VForm } from "vuetify/components";
import { useValidHelper } from "@/utils/validHelper";
import { useMountScroller } from "@/utils/mountScroller";
import { paymentTargetKeys } from "@/utils/other";

const props = defineProps<{
  role: NewUserRole;
  newUserObject: NewUserObject;
}>();

const rules = useRules();

const valid = ref(false);

const data = ref<NewUserObject>(props.newUserObject);

const form = ref<VForm>();

useValidHelper(form);
useMountScroller();

const callSubmit = async () => {
  await form.value?.validate();
  if (valid.value) {
    emit("submit", { data: data.value, tabIndex: 2 });
  }
};

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "submit", data: { data: NewUserObject; tabIndex: number }): void;
  // eslint-disable-next-line no-unused-vars
  (e: "back"): void;
}>();
</script>

<style scoped></style>
