import { get, post } from "@/api";
import {
  NewUserObject,
  UserCredentialsObject,
  ForgotObject,
  ResetObject,
  ProfileData,
} from "@/types/auth";
import { TokensObject } from "@/types/auth";

export const login = ({ email, password }: UserCredentialsObject) =>
  post<TokensObject>(
    "clients/web/login",
    {
      email,
      password,
    },
    false,
    false,
  );

export const logout = () => post("logout");

export const manualRegister = (data: NewUserObject) =>
  post("register/manual", data);

export const register = (data: NewUserObject) =>
  post("register", data, false, false);

export const forgot = (data: ForgotObject) =>
  post("password/forgot", data, false, false);

export const reset = (data: ResetObject) =>
  post("password/reset", data, false, false);

export const verify = (url: string) => post(url, null, false, false);

export const getProfile = (params?: { include: string }) =>
  get<{ data: ProfileData }>("profile", params);
