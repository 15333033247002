import { Module } from "vuex";

import { State } from "@/store";

import { login, logout, getProfile } from "@/api/auth";

import { getLocValue, setLocValue } from "@/utils/localStorage";

import {
  UserCredentialsObject,
  ProfileData,
  TokensObject,
  CurrentTokensObject,
} from "@/types/auth";
import { CompanyData, NewCompanyUserObject } from "@/types/company";
import { PermissionType, RoleType } from "@/types/roles";
import { createEmployee, removeEmployee, updateUser } from "@/api/users";
import { addItem, removeItem, updateItem } from "@/utils/listHelper";

type UpdateUser = { id: string; newUserData: NewCompanyUserObject };
export interface AuthState {
  tokens: CurrentTokensObject | null;
  profile: ProfileData | null;
}

export type AuthModule = Module<AuthState, State>;

const include =
  "company.contact,company.users,company.billing_address,company.headquarters_address,roles";

export const auth: AuthModule = {
  namespaced: true,
  state: () => ({
    tokens: getLocValue("TOKENS"),
    profile: getLocValue("PROFILE"),
  }),
  mutations: {
    setTokens(state, value: null | TokensObject) {
      if (value !== null) {
        const token_time = new Date().getTime();
        const expires_in = value.expires_in * 1000;
        const currentTokens: CurrentTokensObject = {
          ...value,
          expires_in,
          token_time,
        };
        state.tokens = currentTokens;
        setLocValue("TOKENS", currentTokens);
      } else {
        state.tokens = value;
        setLocValue("TOKENS", value);
      }
    },
    setProfile(state, value: ProfileData | null) {
      state.profile = value;
      setLocValue("PROFILE", value);
    },
    setEmployees(state, value: ProfileData[]) {
      if (state.profile?.company) {
        const newProfile = {
          ...state.profile,
          company: {
            data: {
              ...state.profile.company.data,
              users: {
                data: value,
              },
            },
          },
        };

        state.profile = newProfile;
        setLocValue("PROFILE", newProfile);
      }
    },
  },
  actions: {
    async login(context, credentials: UserCredentialsObject) {
      const tokens = await login(credentials);
      if (tokens.access_token) {
        context.commit("setTokens", tokens);
        await context.dispatch("fetchProfile");
      }
    },
    clearAuthData(context) {
      context.commit("setTokens", null);
      context.commit("setProfile", null);
      const path = window.location.pathname;
      if (!path.includes("/login")) {
        window.location.replace(`/login?redirect_url=${path}`);
      }
    },
    async logout(context) {
      try {
        await logout();
      } finally {
        await context.dispatch("clearAuthData");
      }
    },
    async fetchProfile(context) {
      const { data } = await getProfile({ include });
      if (data) context.commit("setProfile", data);
    },
    async updateProfile(context, newUser: UpdateUser) {
      const { data } = await updateUser(newUser.id, newUser.newUserData, {
        include,
      });
      if (data) context.commit("setProfile", data);
    },
    async addEmployee(
      context,
      {
        companyId,
        userData,
      }: { companyId: string; userData: NewCompanyUserObject },
    ) {
      const { data } = await createEmployee(companyId, userData);
      context.commit(
        "setEmployees",
        addItem<ProfileData>(
          context.state.profile?.company?.data.users.data,
          data,
        ),
      );
    },
    async removeEmployee(
      context,
      { companyId, userId }: { companyId: string; userId: string },
    ) {
      await removeEmployee(companyId, userId);
      context.commit(
        "setEmployees",
        removeItem<ProfileData>(
          context.state.profile?.company?.data.users.data,
          userId,
        ),
      );
    },
    updateEmployee(context, userData: ProfileData) {
      context.commit(
        "setEmployees",
        updateItem<ProfileData>(
          context.state.profile?.company?.data.users.data,
          userData,
        ),
      );
    },
  },
  getters: {
    isAuth: (state): boolean => !!state.profile,
    company: (state): CompanyData | null =>
      state.profile?.company?.data || null,
    roles: (state): RoleType[] =>
      state.profile?.roles.data.map(({ name }) => name) || [],
    permissions: (state): PermissionType[] =>
      state.profile?.roles.data
        .map(({ permissions }) => permissions.data.map(({ name }) => name))
        .flat() || [],
  },
};
