import { ComputedRef, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";

import { useFormat } from "@/utils/other";
import { getFeatureKey } from "@/utils/features";

import { DocumentCalcObject } from "@/types/calculator";
import { Pallet, FeatureCalcType, FeatureType } from "@/types/settings";

export const useCalcList = (
  documentCost: ComputedRef<DocumentCalcObject>,
  palletCode: ComputedRef<string | undefined>,
) => {
  const { t } = useI18n();
  const store = useStore();
  const toFormat = useFormat();

  type CalcList = {
    name: string;
    cost: string;
    type?: FeatureType;
    calculation_type?: FeatureCalcType;
    sp: boolean;
  };

  const specialFeatures = computed(
    () =>
      documentCost.value.arrBE?.map((item) => {
        const name =
          store.state.app.features.find(
            ({ type, code }) => type === item.type && code === item.code,
          )?.name || item.name;
        return { ...item, name, sp: true };
      }, []) || [],
  );

  const spCalcList = computed(() => {
    return Object.entries(
      specialFeatures.value.reduce(
        (calc, { type, name }) => {
          if (type) {
            if (!calc[type]) calc[type] = [];
            calc[type].push(name);
          }
          return calc;
        },
        {} as { [key in FeatureType]: string[] },
      ),
    ).map(([key, value]) => ({
      title: t(getFeatureKey(key as FeatureType)),
      value: value.join(", "),
    }));
  });

  const calcList = computed<CalcList[]>(() => {
    const palletTypes: Pallet[] = store.state.app.pallets;
    const ptName =
      palletTypes.find(({ code }) => code === palletCode.value)?.name ||
      palletCode.value ||
      "";

    const result = [
      {
        name: t("express-surcharge"),
        cost: documentCost.value.expCost,
        calculation_type: FeatureCalcType.fixedPrice,
        sp: false,
      },
      {
        name: t("night-surcharge-loading"),
        cost: documentCost.value.nzLoadCost,
        calculation_type: FeatureCalcType.fixedPrice,
        sp: false,
      },
      {
        name: t("night-surcharge-unloading"),
        cost: documentCost.value.nzUpCost,
        calculation_type: FeatureCalcType.fixedPrice,
        sp: false,
      },
      {
        name: t("weekend-surcharge-loading"),
        cost: documentCost.value.bltCost,
        calculation_type: FeatureCalcType.fixedPrice,
        sp: false,
      },
      {
        name: t("weekend-surcharge-unloading"),
        cost: documentCost.value.eltCost,
        calculation_type: FeatureCalcType.fixedPrice,
        sp: false,
      },
      {
        name: t("second-driver-surcharge"),
        cost: documentCost.value.faCost,
        calculation_type: FeatureCalcType.fixedPrice,
        sp: false,
      },
      {
        name: t("ferry"),
        cost: documentCost.value.boatCost,
        calculation_type: FeatureCalcType.fixedPrice,
        sp: false,
      },
      {
        name: t("rails"),
        cost: documentCost.value.railCost,
        calculation_type: FeatureCalcType.fixedPrice,
        sp: false,
      },
      ...specialFeatures.value,
      {
        name: ptName,
        cost: documentCost.value.ptCost,
        calculation_type: FeatureCalcType.fixedPrice,
        sp: false,
      },
      {
        name: t("value-goods"),
        cost: 0,
        calculation_type: documentCost.value.priceOnRequestOverpriced
          ? FeatureCalcType.priceOnRequest
          : FeatureCalcType.fixedPrice,
        sp: false,
      },

      {
        name: t("weight-goods"),
        cost: 0,
        calculation_type: documentCost.value.priceOnRequestOverweight
          ? FeatureCalcType.priceOnRequest
          : FeatureCalcType.fixedPrice,
        sp: false,
      },
      {
        name: t("oversize"),
        cost: 0,
        calculation_type: documentCost.value.priceOnRequestOversize
          ? FeatureCalcType.priceOnRequest
          : FeatureCalcType.fixedPrice,
        sp: false,
      },

      {
        name: t("ferry"),
        cost: 0,
        calculation_type: documentCost.value.priceOnRequestFerry
          ? FeatureCalcType.priceOnRequest
          : FeatureCalcType.fixedPrice,
        sp: false,
      },
      {
        name: t("travel-time"),
        cost: 0,
        calculation_type: documentCost.value.priceOnRequestTime
          ? FeatureCalcType.priceOnRequest
          : FeatureCalcType.fixedPrice,
        sp: false,
      },
    ];

    return result
      .filter(
        (item) =>
          (item.cost && item.cost > 0) ||
          item.calculation_type === FeatureCalcType.priceOnRequest,
      )
      .map((item) => {
        return { ...item, cost: toFormat(item.cost, 2) };
      });
  });

  return { calcList, spCalcList };
};
