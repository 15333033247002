<template>
  <v-row no-gutters>
    <v-col cols="12" class="pa-10">
      <v-sheet class="py-12 px-10 rounded-xl bpl-border-gray">
        <p class="text-h4 text-primary font-weight-light">
          {{ $t("your-employees") }}
        </p>
        <employees-block
          v-for="roleData in roles"
          :key="roleData.type"
          :role="roleData.type"
          :title="$t(roleData.key)"
          :company-roles="roles"
        />
        <employees-block
          v-for="roleData in adminRoles"
          :key="roleData.type"
          :role="roleData.type"
          :title="$t(roleData.key)"
          :admins="adminUsers"
          :loading="loading"
          @add="addAdmin"
          @update="updateAdmin"
          @remove="removeAdmin"
        />
      </v-sheet> </v-col
  ></v-row>
</template>

<script setup lang="ts">
import EmployeesBlock from "@/components/EmployeesView/EmployeesBlock.vue";
import { computed, onMounted, ref } from "vue";
import { access } from "@/utils/access";
import { getUsers } from "@/api/users";
import { snackbar } from "@/utils/snackbar";
import { ProfileData } from "@/types/auth";
import { useStore } from "@/store";
import { getRolesDetailsByCompany } from "@/utils/other";
import { addItem, removeItem, updateItem } from "@/utils/listHelper";

const store = useStore();

const profile = computed(() => store.state.auth.profile);

const companyType = computed(
  () =>
    profile.value?.company?.data?.type || (profile.value ? null : undefined),
);

const roles = computed(() =>
  companyType.value ? getRolesDetailsByCompany(companyType.value) : [],
);

const adminRoles = computed(() =>
  companyType.value === null
    ? getRolesDetailsByCompany(null).filter((d) => d.type !== "admin")
    : [],
);

const adminUsers = ref<ProfileData[]>([]);
const loading = ref(true);

const isBplAdminUser = computed(() => access.someRoles(["admin", "sub-admin"]));

const fetchAdmins = async () => {
  try {
    loading.value = true;
    const { data } = await getUsers();
    adminUsers.value = data;
  } catch (e: any) {
    snackbar(e.message);
  } finally {
    loading.value = false;
  }
};

const addAdmin = (user: ProfileData) => {
  adminUsers.value = addItem<ProfileData>(adminUsers.value, user);
};

const removeAdmin = (id: string) => {
  adminUsers.value = removeItem<ProfileData>(adminUsers.value, id);
};

const updateAdmin = (updatedUser: ProfileData) => {
  adminUsers.value = updateItem<ProfileData>(adminUsers.value, updatedUser);
};

onMounted(() => {
  if (isBplAdminUser.value) fetchAdmins();
});
</script>

<style scoped></style>
