import { saveAs } from "file-saver";
import { snackbar } from "@/utils/snackbar";

export const exportFileByUrl = (
  url: string,
  callback: () => void,
  customFilename?: string,
) => {
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      let filename = customFilename;
      if (!filename) {
        const pathname = new URL(url).pathname;
        filename = pathname.slice(pathname.lastIndexOf("/") + 1);
      }

      saveAs(blob, filename);
      callback();
    })
    .catch((e: any) => {
      callback();
      snackbar(e.message);
    });
};
