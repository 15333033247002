<template>
  <async-modal :id="confirmId" v-slot="{ resolve, cancel, autofocus }">
    <v-card v-if="confirmData" class="px-10 py-12">
      <p
        v-if="confirmData.title"
        class="text-h4 font-weight-light text-primary text-center"
      >
        {{ confirmData.title }}
      </p>
      <p v-if="confirmData.text" class="mt-4 text-grey-darken-2 text-center">
        {{ confirmData.text }}
      </p>
      <v-btn
        v-if="confirmData.yes"
        :ref="autofocus"
        variant="outlined"
        color="primary"
        size="large"
        class="mb-4 mt-10"
        @click="resolve(ResolveType.confirm)"
      >
        {{ confirmData.yes }}
      </v-btn>
      <v-btn
        v-if="confirmData.no"
        variant="outlined"
        color="primary"
        size="large"
        class="bg-light-blue-lighten-5"
        @click="cancel()"
      >
        {{ confirmData.no }}
      </v-btn>
    </v-card>
  </async-modal>
</template>

<script setup lang="ts">
import { ref } from "vue";

import { subscribe } from "@/utils/events";
import { confirmId } from "@/utils/confirmHelper";
import { ResolveType } from "@/utils/asyncModalHelper";

import { ConfirmData } from "@/types/other";

import AsyncModal from "@/components/AsyncModal.vue";

const confirmData = ref<ConfirmData>();

subscribe("SetConfirm", (value) => (confirmData.value = value));
</script>

<style scoped></style>
