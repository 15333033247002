<template>
  <v-row no-gutters>
    <v-col cols="12" class="pa-10">
      <v-sheet class="py-12 px-10 rounded-xl bpl-border-gray">
        <div class="d-flex justify-space-between align-center">
          <p class="text-h4 text-primary font-weight-light">
            {{ props.isOffer ? $t("offer") : $t("order") }}
          </p>
          <v-btn
            v-if="props.isOffer"
            color="primary"
            :to="{ name: 'calculator' }"
            >{{ $t("button.create-offer") }}</v-btn
          >
        </div>
        <div class="mt-10 bpl-border-gray rounded-lg">
          <v-row no-gutters class="align-center px-4 py-3">
            <v-col cols="4">
              <template v-if="!props.isOffer">
                <template v-if="isBplAdmin">
                  <v-btn
                    :disabled="!checkedList.length"
                    color="primary"
                    @click="exportPdf(checkedList)"
                  >
                    {{ $t("button.pdf-export-ab") }}
                    {{ checkedList.length ? ` (${checkedList.length})` : "" }}
                  </v-btn>
                  <v-btn
                    class="ml-2"
                    :disabled="!checkedList.length"
                    color="primary"
                    @click="exportPdfTa(checkedList)"
                  >
                    {{ $t("button.pdf-export-ta") }}
                    {{ checkedList.length ? ` (${checkedList.length})` : "" }}
                  </v-btn>
                </template>
                <template v-else>
                  <v-btn
                    :disabled="!checkedList.length"
                    color="primary"
                    @click="exportPdf(checkedList)"
                  >
                    {{ $t("button.pdf-export") }}
                    {{ checkedList.length ? ` (${checkedList.length})` : "" }}
                  </v-btn>
                </template>
              </template>
              <v-menu v-if="!isTransporter">
                <template #activator="{ props: menuProps }">
                  <v-btn
                    :class="{ 'mx-2': !props.isOffer }"
                    icon="mdi-dots-vertical"
                    variant="plain"
                    elevation="0"
                    v-bind="menuProps"
                    :disabled="!checkedList.length"
                  ></v-btn>
                </template>
                <v-list min-width="230">
                  <v-list-item @click="exportCsv(checkedList)">
                    <v-list-item-title>
                      {{ $t("button.csv-export") }}
                    </v-list-item-title>
                    <template #append>
                      <v-icon
                        icon="mdi-file-table"
                        color="grey-darken-2"
                      ></v-icon>
                    </template>
                  </v-list-item>
                  <v-list-item
                    v-if="props.isOffer"
                    :disabled="!!removedIds.length || !checkedList.length"
                    @click="deleteDocuments(checkedList)"
                  >
                    <v-list-item-title>
                      {{ $t("button.delete") }}
                    </v-list-item-title>
                    <template #append>
                      <v-icon icon="mdi-delete" color="grey-darken-2"></v-icon>
                    </template>
                  </v-list-item>
                </v-list>
              </v-menu>
              <export-loader
                v-if="isExported || exportLoading"
                :tooltip="$t('loader.download-prepared')"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="globSearch"
                :label="$t('search')"
                variant="outlined"
                append-inner-icon="mdi-magnify"
                hide-details
                clearable
            /></v-col>
            <v-col cols="4" class="text-right">
              <v-btn
                elevation="0"
                variant="text"
                class="text-capitalize text-grey-darken-2"
                :class="{ 'text-primary': isAdvancedSearch }"
                :append-icon="!isFormOpened ? 'mdi-menu-down' : 'mdi-menu-up'"
                @click="isFormOpened = !isFormOpened"
                >{{ $t("advanced-search-settings") }}</v-btn
              >
            </v-col>
          </v-row>
          <div v-if="isFormOpened" class="bg-grey-lighten-5 px-4 pt-8">
            <v-row class="my-0">
              <v-col cols="4" class="py-0">
                <v-text-field
                  v-model="searchObject.number"
                  :label="$t('text.offer-search.number')"
                  class="pb-2"
                  variant="outlined"
                  append-inner-icon="mdi-magnify"
                  bg-color="white"
                  clearable
                >
                </v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-text-field
                  v-model="searchObject.firstname"
                  :label="$t('first-name')"
                  class="pb-2"
                  variant="outlined"
                  append-inner-icon="mdi-magnify"
                  bg-color="white"
                  clearable
                >
                </v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-text-field
                  v-model="searchObject.lastname"
                  :label="$t('last-name')"
                  class="pb-2"
                  variant="outlined"
                  append-inner-icon="mdi-magnify"
                  bg-color="white"
                  clearable
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  v-model="searchObject.loadingPostcode"
                  :label="$t('text.offer-search.loading')"
                  class="pb-2"
                  variant="outlined"
                  append-inner-icon="mdi-magnify"
                  bg-color="white"
                  clearable
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  v-model="searchObject.unloadingPostcode"
                  :label="$t('text.offer-search.unloading')"
                  class="pb-2"
                  variant="outlined"
                  append-inner-icon="mdi-magnify"
                  bg-color="white"
                  clearable
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <calendar-field
                  v-model="searchObject.loadingDate"
                  :title="$t('text.offer-search.period')"
                  class="pb-2"
                  range
                  clearable
                  bg-color="white"
                  hidden-time
                  :chip-format="locFormat.dDMmY"
                />
              </v-col>
              <v-col :cols="isOffer ? 6 : 3" class="py-0">
                <v-select
                  v-model="searchObject.status"
                  :label="$t('status')"
                  :items="statuses"
                  class="pb-2"
                  variant="outlined"
                  bg-color="white"
                  clearable
                >
                </v-select>
              </v-col>
              <v-col v-if="!isOffer" cols="3" class="py-0">
                <v-checkbox
                  v-model="searchObject.specialDepartment"
                  :label="$t('text.check-special-department')"
                  color="primary"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
          </div>
          <v-data-table-server
            :headers="headers.filter((h) => !h.hidden)"
            :items="prepDocuments"
            class="bpl-custom-table-footer elevation-0 border-0 align-self-start bpl-table-height-1096"
            :items-per-page="pagData.per_page"
            :page="pagData.current_page"
            :items-length="pagData.total"
            :items-per-page-options="[]"
            :loading="loading"
            @click:row="clickRow"
            @update:options="
              fetchDocuments($event.page, finalSearchString, $event.sortBy[0])
            "
          >
            <template #[`header.checkbox`]>
              <v-checkbox-btn
                v-model="globCheckbox.checked"
                :true-icon="
                  globCheckbox.interim ? 'mdi-minus-box' : 'mdi-checkbox-marked'
                "
                color="primary"
                :disabled="!prepDocuments.length"
              ></v-checkbox-btn>
            </template>
            <template #[`item.checkbox`]="{ item }">
              <v-checkbox-btn
                v-model="checkedList"
                :value="item.id"
                color="primary"
                @click.stop
              ></v-checkbox-btn>
            </template>
            <template #[`item.status`]="{ item }">
              <div class="d-flex align-center">
                <status-chip
                  :status="{
                    name: item.status,
                    context: props.isOffer
                      ? StatusContext.offer
                      : StatusContext.order,
                  }"
                />
                <v-tooltip
                  v-if="isBplAdmin && item.special_department"
                  :text="$t('status-order.special-department')"
                  location="top"
                  offset="2px"
                >
                  <template #activator="{ props: tooltipProps }">
                    <v-icon
                      v-bind="tooltipProps"
                      color="warning"
                      icon="mdi-alert-outline"
                      class="ml-2"
                    ></v-icon>
                  </template>
                </v-tooltip>
              </div>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-menu :key="loading.toString()">
                <template #activator="{ props: menuProps }">
                  <v-btn
                    class="ml-3"
                    icon="mdi-dots-vertical"
                    variant="plain"
                    elevation="0"
                    v-bind="menuProps"
                  ></v-btn>
                </template>
                <v-list min-width="230">
                  <template v-if="!props.isOffer">
                    <template v-if="isBplAdmin">
                      <v-list-item @click="exportPdf([item.id])">
                        <v-list-item-title>
                          {{ $t("button.pdf-export-ab") }}
                        </v-list-item-title>
                        <template #append>
                          <v-icon
                            icon="mdi-file-export"
                            color="grey-darken-2"
                          ></v-icon>
                        </template>
                      </v-list-item>
                      <v-list-item @click="exportPdfTa([item.id])">
                        <v-list-item-title>
                          {{ $t("button.pdf-export-ta") }}
                        </v-list-item-title>
                        <template #append>
                          <v-icon
                            icon="mdi-file-export"
                            color="grey-darken-2"
                          ></v-icon>
                        </template>
                      </v-list-item>
                    </template>
                    <template v-else>
                      <v-list-item @click="exportPdf([item.id])">
                        <v-list-item-title>
                          {{ $t("button.pdf-export") }}
                        </v-list-item-title>
                        <template #append>
                          <v-icon
                            icon="mdi-file-export"
                            color="grey-darken-2"
                          ></v-icon>
                        </template>
                      </v-list-item>
                    </template>
                  </template>
                  <v-list-item
                    v-if="!isTransporter"
                    @click="exportCsv([item.id])"
                  >
                    <v-list-item-title>{{
                      $t("button.csv-export")
                    }}</v-list-item-title>
                    <template #append>
                      <v-icon
                        icon="mdi-file-table"
                        color="grey-darken-2"
                      ></v-icon>
                    </template>
                  </v-list-item>
                  <v-list-item
                    v-if="props.isOffer"
                    :disabled="removedIds.includes(item.id)"
                    @click.stop="deleteDocuments([item.id])"
                  >
                    <v-list-item-title>{{
                      $t("button.delete")
                    }}</v-list-item-title>
                    <template #append>
                      <v-icon icon="mdi-delete" color="grey-darken-2"></v-icon>
                    </template>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table-server>
          <v-footer
            v-if="globCheckedList.length > checkedList.length"
            app
            color="grey-darken-2 py-4 px-10"
            class="position-fixed"
          >
            <v-row no-gutters>
              <v-col cols="4" class="d-flex align-center">
                <span>
                  {{ globCheckedList.length }}
                  {{ $t("text.offer-select-all") }}</span
                >
              </v-col>
              <v-col cols="4" class="text-center">
                <template v-if="!props.isOffer">
                  <template v-if="isBplAdmin">
                    <v-btn
                      variant="text"
                      color="primary"
                      class="bg-white"
                      @click="exportPdf(globCheckedList)"
                    >
                      {{ $t("button.pdf-export-ab") }}
                    </v-btn>
                    <v-btn
                      variant="text"
                      color="primary"
                      class="bg-white"
                      :class="{ 'ml-2': isBplAdmin }"
                      @click="exportPdfTa(globCheckedList)"
                    >
                      {{ $t("button.pdf-export-ta") }}
                    </v-btn>
                  </template>
                  <template v-else>
                    <v-btn
                      variant="text"
                      color="primary"
                      class="bg-white"
                      @click="exportPdf(globCheckedList)"
                    >
                      {{ $t("button.pdf-export") }}
                    </v-btn>
                  </template>
                </template>
                <v-btn
                  v-if="!isTransporter"
                  variant="text"
                  color="primary"
                  class="bg-white ml-2"
                  @click="exportCsv(globCheckedList)"
                  >{{ $t("button.csv-export") }}</v-btn
                >
                <v-btn
                  v-if="props.isOffer"
                  variant="text"
                  color="red-darken-3"
                  class="bg-red-lighten-4 ml-2"
                  :disabled="globCheckedList.length === removedIds.length"
                  @click="deleteDocuments(globCheckedList)"
                  >{{ $t("button.delete-all") }}</v-btn
                >
              </v-col>
              <v-col cols="4" class="text-right">
                <v-btn variant="text" @click="deselectAll">{{
                  $t("button.deselect-all")
                }}</v-btn>
              </v-col>
            </v-row>
          </v-footer>
        </div>
      </v-sheet>
    </v-col>
  </v-row>
  <async-modal
    v-if="createdId"
    :id="createdDialogModal.id"
    v-slot="{ cancel, autofocus }"
  >
    <v-card class="px-10 py-12" max-width="660">
      <p class="text-h4 text-primary font-weight-light text-center">
        {{ $t("dialog.orders-created-price-on-request-title") }}
      </p>
      <p class="mt-4 text-grey-darken-2 text-center">
        {{ $t("dialog.orders-created-price-on-request-text") }}
      </p>
      <v-btn
        :ref="autofocus"
        size="x-large"
        color="primary"
        class="mt-10"
        :to="{
          name: 'order',
          params: { id: createdId },
        }"
      >
        {{ $t("button.open-order") }}
      </v-btn>
      <v-btn
        size="x-large"
        color="primary"
        variant="outlined"
        class="mt-4"
        :to="{ name: 'calculator' }"
        @click="cancel()"
      >
        {{ $t("button.create-another-offer") }}
      </v-btn>
    </v-card>
  </async-modal>
  <async-modal
    v-if="wrongTypeId"
    :id="wrongTypeModal.id"
    v-slot="{ cancel, autofocus }"
  >
    <v-card class="px-10 py-12" max-width="660">
      <p class="text-h4 text-primary font-weight-light">
        {{ $t("dialog.document-type-changed-title") }}
      </p>
      <p class="mt-4 text-grey-darken-2 text-center">
        {{ $t("dialog.document-type-changed-text") }}
      </p>
      <v-btn
        :ref="autofocus"
        :to="{
          name: props.isOffer ? 'order' : 'offer',
          params: { id: wrongTypeId },
        }"
        size="x-large"
        color="primary"
        class="mt-10"
      >
        {{ props.isOffer ? $t("button.open-order") : $t("button.open-offer") }}
      </v-btn>
      <v-btn
        size="x-large"
        color="primary"
        variant="outlined"
        class="mt-4"
        @click="cancel()"
      >
        {{ $t("button.close") }}
      </v-btn>
    </v-card>
  </async-modal>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref, watch } from "vue";
import moment from "moment";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";
import { useI18n } from "vue-i18n";

import { getDocuments, removeDocuments } from "@/api/documents";

import { snackbar } from "@/utils/snackbar";
import { useCheckboxes } from "@/utils/checkboxHelper";
import {
  calcFetchPage,
  calcGlobChecked,
  getMatches,
  initialPagData,
  joinWithDots,
  prepSearchString,
} from "@/utils/other";
import { statusesData } from "@/utils/statuses";
import { useWatchDebounceHelper } from "@/utils/watchDebounceHelper";
import { backFormats, useLocFormat } from "@/utils/locFormat";
import { access } from "@/utils/access";
import { useConfirm } from "@/utils/confirmHelper";

import {
  DocumentSavedData,
  DocumentType,
  StatusContext,
} from "@/types/document";
import { RangeValue, ExportNotificationType, SortData } from "@/types/other";

import CalendarField from "@/components/CalendarField.vue";
import StatusChip from "@/components/StatusChip.vue";
import ExportLoader from "@/components/ExportLoader.vue";
import { useAsyncModal } from "@/utils/asyncModalHelper";
import AsyncModal from "@/components/AsyncModal.vue";

const router = useRouter();
const route = useRoute();
const store = useStore();
const { t } = useI18n();
const locFormat = useLocFormat();

const props = defineProps<{
  isOffer?: boolean;
}>();

const filter =
  "id;name;number;firstname;lastname;postcode;city;status;pagination;data";

const documents = ref<DocumentSavedData[]>([]);
const sortData = ref<SortData>();
const pagData = ref(initialPagData);

const isFormOpened = ref(false);

const loading = ref(false);
const exportLoading = ref(false);
const removedIds = ref<string[]>([]);

const deleteConfirm = useConfirm("dialog.delete-item");

const createdDialogModal = useAsyncModal();
const wrongTypeModal = useAsyncModal();

const createdId = ref();
const wrongTypeId = ref();

const statuses = computed(() =>
  statusesData
    .filter(
      ({ context }) => props.isOffer === (context === StatusContext.offer),
    )
    .map(({ key, name }) => ({ title: t(key), value: name })),
);

const headers = computed(
  () =>
    [
      {
        title: "",
        key: "checkbox",
        width: "40px",
        sortable: false,
      },
      { title: t("loading-date"), key: "loadingDate", sortable: false },
      {
        title: props.isOffer ? t("offer-number") : t("order-number"),
        key: "number",
        sortable: true,
      },
      {
        title: t("company-name"),
        key: "companyName",
        sortable: true,
        hidden: !isBplAdmin.value,
      },
      {
        title: t("contact"),
        key: "contact",
        sortable: true,
        hidden: isTransporter,
      },
      { title: t("loading-point"), key: "loadingPoint", sortable: true },
      { title: t("unloading-point"), key: "unloadingPoint", sortable: true },
      { title: t("status"), key: "status", sortable: true },
      {
        title: "",
        key: "actions",
        width: "48px",
        align: "end",
        sortable: false,
      },
    ] as any[],
);

const fetchDocuments = async (
  page: number,
  search: string,
  sort?: SortData,
) => {
  if (!loading.value) {
    try {
      loading.value = true;
      const {
        data,
        meta: { pagination },
      } = await getDocuments({
        type: props.isOffer ? DocumentType.offer : DocumentType.order,
        include: include.value,
        filter,
        page,
        search,
        ...(!isGlobSearch.value ? { searchJoin: "and" } : {}),
        ...(sort ? { orderBy: sort.key, sortedBy: sort.order } : {}),
      });
      documents.value = data;
      pagData.value = pagination;
    } catch (e: any) {
      snackbar(e.message);
    } finally {
      sortData.value = sort;
      loading.value = false;
    }
  }
};

const globSearch = ref("");

const isGlobSearch = computed(() => !!globSearch.value);

const isAdvancedSearch = computed(
  () => !isGlobSearch.value && !!finalSearchString.value,
);

type DocumentsSearchObject = {
  number?: string;
  postcode?: string;
  loadingPostcode?: string;
  unloadingPostcode?: string;
  status?: DocumentSavedData["status"];
  firstname?: string;
  lastname?: string;
  loadingDate?: RangeValue;
  specialDepartment?: boolean;
};

const searchObject = ref<DocumentsSearchObject>({});

watch(
  searchObject,
  (value) => {
    if (Object.keys(value).length) globSearch.value = "";
  },
  { deep: true },
);

watch(globSearch, (value) => {
  if (value) {
    searchObject.value = {};
  }
});

const finalSearchString = computed(() =>
  isGlobSearch.value
    ? globSearch.value
    : prepSearchString(
        {
          title: "number",
          value: searchObject.value?.number || "",
        },
        {
          title: "loadingPoint.postcode",
          value: searchObject.value?.loadingPostcode || "",
        },
        {
          title: "unloadingPoint.postcode",
          value: searchObject.value?.unloadingPostcode || "",
        },
        {
          title: "status",
          value: searchObject.value?.status || "",
        },
        {
          title: "user.firstname",
          value: searchObject.value?.firstname || "",
        },
        {
          title: "user.lastname",
          value: searchObject.value?.lastname || "",
        },
        {
          title: "loading_time",
          value: searchObject.value.loadingDate?.end
            ? [
                moment(searchObject.value.loadingDate.start).format(
                  backFormats.yMmDdStart,
                ),
                moment(searchObject.value.loadingDate.end).format(
                  backFormats.yMmDdEnd,
                ),
              ].join(",")
            : "",
        },
        {
          title: "special_department",
          value: searchObject.value?.specialDepartment ? "true" : "",
        },
      ),
);

useWatchDebounceHelper<string>(finalSearchString, 400, (value) => {
  if (globSearch.value) isFormOpened.value = false;
  fetchDocuments(1, value, sortData.value);
});

const include = computed(
  () =>
    `loadingPoint.country,unloadingPoint.country${
      isTransporter.value ? "" : ",company,contact"
    }`,
);

const deleteDocuments = async (ids: string[]) => {
  if (await deleteConfirm()) {
    try {
      removedIds.value = ids;
      if (props.isOffer) await removeDocuments(ids);

      const fetchPage = calcFetchPage(ids.length, pagData.value);

      await fetchDocuments(fetchPage, finalSearchString.value, sortData.value);
      globCheckedList.value = globCheckedList.value.filter(
        (id) => !removedIds.value.includes(id),
      );
    } catch (e: any) {
      snackbar(e.message);
    } finally {
      removedIds.value = [];
    }
  }
};

const clickRow = (e: any, data: { item: any }) => {
  router.push({
    name: props.isOffer ? "offer" : "order",
    params: { id: data.item.id },
  });
};

const prepDocuments = computed(() =>
  documents.value.map(
    ({
      contact,
      number,
      id,
      company,
      loadingPoint,
      loading_time_start,
      loading_time_end,
      unloadingPoint,
      status,
      special_department,
    }) => {
      const mLoadingStart = moment(loading_time_start);
      const loadingStart = mLoadingStart.isValid()
        ? mLoadingStart.format(locFormat.value.dDMmYWithDots)
        : "";

      const mLoadingEnd = moment(loading_time_end);
      const loadingEnd = mLoadingEnd.isValid()
        ? mLoadingEnd.format(locFormat.value.dDMmYWithDots)
        : "";

      return {
        loadingDate: `${loadingStart} ${loadingEnd && "-"} ${loadingEnd}`,
        number: `${documentPrefix.value}${number}`,
        contact: joinWithDots(contact?.data.firstname, contact?.data.lastname),
        companyName: company?.data.name,
        loadingPoint: joinWithDots(
          loadingPoint?.data.country.data.name,
          loadingPoint?.data.postcode,
          loadingPoint?.data.city,
        ),
        unloadingPoint: joinWithDots(
          unloadingPoint?.data.country.data.name,
          unloadingPoint?.data.postcode,
          unloadingPoint?.data.city,
        ),
        status,
        special_department,
        id,
      };
    },
  ),
);

const defChecked = computed(() => prepDocuments.value.map(({ id }) => id));

const { globCheckbox, checkedList } = useCheckboxes(defChecked);

const globCheckedList = ref(<string[]>[]);

const deselectAll = () => {
  globCheckedList.value = [];
  checkedList.value = [];
};

const exportCsv = async (ids: string[]) => {
  try {
    exportLoading.value = true;
    const type: ExportNotificationType = props.isOffer
      ? ExportNotificationType.offersCsvExport
      : ExportNotificationType.ordersCsvExport;
    await store.dispatch("app/exportFiles", { type, ids });
  } catch (e: any) {
    snackbar(e.message);
  } finally {
    exportLoading.value = false;
  }
};

const exportPdf = async (ids: string[]) => {
  try {
    exportLoading.value = true;
    await store.dispatch("app/exportFiles", {
      type: ExportNotificationType.ordersPdfExport,
      ids,
    });
  } catch (e: any) {
    snackbar(e.message);
  } finally {
    exportLoading.value = false;
  }
};

const exportPdfTa = async (ids: string[]) => {
  try {
    exportLoading.value = true;
    await store.dispatch("app/exportFiles", {
      type: ExportNotificationType.documentsTransporterPdfExport,
      ids,
    });
  } catch (e: any) {
    snackbar(e.message);
  } finally {
    exportLoading.value = false;
  }
};

const isExported = computed(() =>
  store.state.app.exportsQueue.some((e) =>
    (props.isOffer
      ? [ExportNotificationType.offersCsvExport]
      : [
          ExportNotificationType.ordersCsvExport,
          ExportNotificationType.ordersPdfExport,
          ExportNotificationType.documentsTransporterPdfExport,
        ]
    ).includes(e.type),
  ),
);

const isBplAdmin = computed(() =>
  access.someRoles([
    "admin",
    "sub-admin",
    "bpl-manager",
    "bpl-calculation-manager",
  ]),
);

const isTransporter = computed(() =>
  access.someRoles([
    "forwarder",
    "sub-forwarder",
    "planner",
    "accounting-contractor",
    "driver",
    "pallet-department-contractor",
  ]),
);

const documentPrefix = computed(() => {
  if (props.isOffer) {
    return "";
  } else {
    return isTransporter.value ? "TA" : "AB";
  }
});

watch(defChecked, (value) => {
  checkedList.value = getMatches(value, globCheckedList.value);
});

watch(checkedList, (value) => {
  globCheckedList.value = calcGlobChecked(
    value,
    defChecked.value,
    globCheckedList.value,
  );
});

const openCreatedModal = async () => {
  await nextTick();
  await createdDialogModal.callback();
};
const openWrongTypeModal = async () => {
  await nextTick();
  await wrongTypeModal.callback();
};

onMounted(() => {
  if (route.query) {
    if (route.query.created_id) {
      createdId.value = route.query.created_id;
      openCreatedModal();
    } else if (route.query.wrong_type_id) {
      wrongTypeId.value = route.query.wrong_type_id;
      openWrongTypeModal();
    }

    router.replace({ query: undefined });
  }
});
</script>

<style></style>
