<template>
  <v-row no-gutters>
    <v-col cols="12" class="pa-10">
      <v-sheet class="pb-12 pt-11 px-10 rounded-xl bpl-border-gray">
        <div class="d-flex justify-space-between align-center">
          <div class="d-flex align-center">
            <v-btn
              icon="mdi-keyboard-backspace"
              variant="plain"
              :to="{ name: 'rides' }"
              class="mr-4"
            />
            <template v-if="rideObject">
              <p class="text-h4 text-primary font-weight-light py-1">
                {{ `TA${rideObject.document.data.number}` }}
              </p>
              <ride-status-point :status="rideObject.status" class="ml-4" />
              <router-link
                :to="{
                  name: 'order',
                  params: { id: rideObject.document.data.id },
                }"
                class="ml-4 text-decoration-none"
              >
                <base-chip
                  color="#C2E9FF"
                  border-color="#1982D4"
                  background-color="#1982D4"
                  class="text-capitalize py-1 px-3"
                >
                  <span>{{ $t("order") }}</span>
                  <v-icon
                    icon="mdi-open-in-new"
                    color="#C2E9FF"
                    class="ml-1"
                  ></v-icon>
                </base-chip>
              </router-link>
            </template>
          </div>
        </div>
        <template v-if="rideObject">
          <progress-tabs
            :tabs="tabs"
            :tab-index="lastStep"
            :current-tab-index="tabIndex"
            class="mt-10"
            @select="setTabIndex"
          />
          <ride-first
            v-if="tabIndex === 0"
            :ride="rideObject"
            :editable="lastStep === 0"
            @update-ride="updateRide"
          />
          <ride-second
            v-else-if="tabIndex === 1"
            :ride="rideObject"
            :editable="lastStep === 1"
            @update-ride="updateRide"
          />
          <ride-third
            v-else-if="tabIndex === 2"
            :ride="rideObject"
            :editable="lastStep === 2"
            @update-ride="updateRide"
          />
          <ride-fourth
            v-else-if="tabIndex === 3"
            :ride="rideObject"
            :editable="lastStep === 3"
            @update-ride="updateRide"
          />
          <ride-fifth
            v-else-if="tabIndex === 4"
            :ride="rideObject"
            :editable="lastStep === 4"
            @update-ride="updateRide"
          />
          <ride-sixth
            v-else-if="tabIndex === 5"
            :ride="rideObject"
            :editable="lastStep === 5"
            @update-ride="updateRide"
          />
          <ride-seventh
            v-else-if="tabIndex === 6"
            :ride="rideObject"
            :editable="lastStep === 6"
            @update-ride="updateRide"
          />
          <ride-eighth
            v-else-if="tabIndex === 7"
            :ride="rideObject"
            :editable="lastStep === 7"
            @update-ride="updateRide"
          />
        </template>
        <app-loader v-else-if="loading" />
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import { useStore } from "@/store";
import { computed, ref, watch } from "vue";
import { RideObject } from "@/types/rides";
import { snackbar } from "@/utils/snackbar";
import { getRide } from "@/api/rides";
import AppLoader from "@/components/AppLoader.vue";
import RideStatusPoint from "@/components/RideStatusPoint.vue";
import BaseChip from "@/components/BaseChip.vue";
import ProgressTabs from "@/components/ProgressTabs.vue";
import RideFirst from "@/components/RideView/RideFirst.vue";
import { ProgressTab, SnackbarType } from "@/types/other";
import RideSecond from "@/components/RideView/RideSecond.vue";
import RideThird from "@/components/RideView/RideThird.vue";
import RideFourth from "@/components/RideView/RideFourth.vue";
import RideFifth from "@/components/RideView/RideFifth.vue";
import RideSixth from "@/components/RideView/RideSixth.vue";
import RideSeventh from "@/components/RideView/RideSeventh.vue";
import RideEighth from "@/components/RideView/RideEighth.vue";
import { useI18n } from "vue-i18n";

const router = useRouter();
const store = useStore();
const { t } = useI18n();

const include = "document.carrier.users";

const props = defineProps<{
  id: string;
}>();

const tabs: ProgressTab[] = [
  { title: "ride.start", subtitle: "loading-point" },
  { title: "ride.estimated-time", subtitle: "loading-point" },
  { title: "ride.loading-point", subtitle: "loading-point" },
  { title: "ride.departure", subtitle: "loading-point" },
  { title: "ride.estimated-time", subtitle: "unloading-point" },
  { title: "ride.unloading-point", subtitle: "unloading-point" },
  { title: "ride.completion", subtitle: "unloading-point" },
  { title: "ride.summary", subtitle: "overview-trip" },
];

const tabIndex = ref(0);

const setTabIndex = (data: number) => {
  tabIndex.value = data;
};

const updateRide = (data: RideObject) => {
  rideObject.value = data;
};

const lastStep = computed(() => (rideObject.value?.step || 1) - 1);

const loading = ref(true);

const rideObject = ref<RideObject>();

const userLocale = computed(() => store.state.auth.profile?.locale);

const backToList = async () => {
  await router.push({
    name: "rides",
  });
};

const fetchRide = async (id: string) => {
  try {
    loading.value = true;
    const { data } = await getRide(id, {
      include,
    });

    rideObject.value = data;
  } catch (e: any) {
    snackbar(e.message);
    await backToList();
  } finally {
    loading.value = false;
  }
};

watch(
  () => rideObject.value?.step,
  (value, oldValue) => {
    if (oldValue) {
      snackbar(t("snackbar.step-loaded"), SnackbarType.success);
    }
    setTabIndex((value || 1) - 1);
  },
  { immediate: true },
);

watch(
  userLocale,
  () => {
    fetchRide(props.id);
  },
  { immediate: true },
);
</script>
