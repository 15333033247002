<template>
  <div class="d-inline-flex align-center">
    <v-img :src="require('@/assets/logo.webp')" width="96" />
    <div class="d-flex flex-column ml-4">
      <span class="text-body-1 font-weight-black text-black">
        Brennpunkt Logistik GmbH
      </span>
      <span class="text-body-3 font-weight-bold text-primary">
        permanent konsequent effizient
      </span>
    </div>
  </div>
</template>
<style scoped>
.text-body-3 {
  font-size: 0.5rem;
}
</style>
