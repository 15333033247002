<template>
  <v-sheet width="1010" class="mx-auto py-12 px-10 rounded-xl bpl-border-gray">
    <div class="mb-10 w-100 d-flex justify-space-between align-center">
      <div class="d-inline-flex align-center">
        <v-img :src="require('@/assets/logo.webp')" width="75" />
        <span class="text-h4 font-weight-light text-primary ml-4">
          {{ $t("registration") }}
        </span>
      </div>
      <div>
        <slot name="action"></slot>
      </div>
    </div>
    <slot />
  </v-sheet>
</template>

<script setup lang="ts"></script>

<style scoped></style>
