<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="auto"
      class="bpl-dialog"
      min-width="660"
      @keydown.esc="cancel()"
    >
      <slot :resolve="resolve" :cancel="cancel" :autofocus="autofocus"></slot>
    </v-dialog>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, nextTick, VNodeRef } from "vue";

import { broadcast, subscribe } from "@/utils/events";

type NonZero<T extends number> = T extends 0 ? never : T;

const props = defineProps<{
  id: symbol;
}>();

const dialog = ref(false);

const emitResolve = (value: number) => {
  broadcast("ResolveAsyncModal", { value, id: props.id });
  dialog.value = false;
};

const cancel = () => {
  emitResolve(0);
};

const resolve = <T extends number>(value: NonZero<T>) => {
  emitResolve(value);
};

const autofocus: VNodeRef = (el) => {
  if (el) {
    nextTick(() => {
      if (el instanceof HTMLElement) {
        el.focus();
      } else {
        (el as any).$el.focus();
      }
    });
  }
};

watch(dialog, (value) => {
  if (!value) cancel();
});

onMounted(() => {
  subscribe("SetAsyncModal", ({ id, value }) => {
    if (id === props.id) {
      dialog.value = value;
    }
  });
});
</script>

<style scoped></style>
