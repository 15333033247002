4
<template>
  <div ref="block" @keydown.enter="enterSave">
    <div class="d-flex justify-space-between align-center">
      <slot name="title" />
      <div>
        <div class="d-flex align-center">
          <slot name="header-content" />
          <template v-if="!props.hideActions">
            <slot v-if="!isEdited" name="edit-btn" :edit="clickEdit" />
            <template v-else>
              <v-btn
                variant="text"
                color="primary"
                :disabled="props.loading"
                @click="clickCancel"
              >
                {{ $t("button.cancel") }}
              </v-btn>
              <v-btn
                color="primary"
                class="ml-2"
                :loading="props.loading"
                :disabled="props.disabled"
                :type="props.submit ? 'submit' : 'button'"
                @click="clickSave"
              >
                {{ $t("button.save") }}
              </v-btn>
            </template>
          </template>
          <slot name="actions" />
        </div>
      </div>
    </div>
    <slot
      name="default"
      :readonly="!isEdited || props.loading"
      :edited="isEdited"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { VBtn } from "vuetify/components";

import { getAllInputs } from "@/utils/validHelper";

const block = ref();

const enterSave = (e: KeyboardEvent) => {
  if (!props.submit) e.preventDefault();
  clickSave();
};

type BlockWrapValue = string | number | boolean;

const props = defineProps<{
  modelValue: BlockWrapValue[] | boolean; //for multiply blocks with one form and individual block
  name?: BlockWrapValue;
  loading?: boolean;
  disabled?: boolean;
  submit?: boolean;
  hideActions?: boolean;
}>();

const isEdited = computed({
  get() {
    return typeof props.modelValue === "boolean"
      ? props.modelValue
      : props.modelValue.includes(props.name as BlockWrapValue);
  },
  set(value: boolean): void {
    emit(
      "update:modelValue",
      typeof props.modelValue === "boolean"
        ? value
        : value
        ? [...props.modelValue, props.name as BlockWrapValue]
        : props.modelValue.filter((name) => name !== props.name),
    );
  },
});

const clickEdit = () => {
  isEdited.value = true;
};

const clickCancel = () => {
  emit("cancel", props.name);
  isEdited.value = false;
};

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "save", data: { name: any; inputs: (string | number)[] }): void;
  // eslint-disable-next-line no-unused-vars
  (e: "cancel", data: any): void;
  // eslint-disable-next-line no-unused-vars
  (e: "update:modelValue", data: BlockWrapValue[] | boolean): void;
}>();

const clickSave = () => {
  const inputs = getAllInputs(block.value);
  emit("save", { name: props.name, inputs });
};
</script>

<style scoped></style>
