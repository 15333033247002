<template>
  <v-row no-gutters>
    <v-col cols="12" class="pa-10">
      <v-sheet class="pb-12 pt-11 px-10 rounded-xl bpl-border-gray">
        <v-form ref="form" v-model="valid" @submit.prevent="">
          <editable-block v-model="edited" submit>
            <template #title>
              <p class="text-h4 text-primary font-weight-light py-1">
                {{ $t("postal-code") }}
              </p>
            </template>
            <template #header-content>
              <div class="mr-2">
                <export-loader
                  v-if="isExported || exportLoading"
                  :tooltip="$t('loader.download-prepared')"
                />
              </div>
            </template>
            <template #edit-btn>
              <v-btn
                color="primary"
                :loading="uploadLoading"
                @click="csvInput && csvInput.click()"
                >{{ $t("button.upload-csv") }}</v-btn
              >
              <input
                :key="uploadLoading.toString()"
                ref="csvInput"
                type="file"
                accept="text/csv"
                class="d-none"
                name="csv"
                @input="csvInputHandler"
              />
            </template>
            <template #actions>
              <v-menu>
                <template #activator="{ props: menuProps }">
                  <v-btn
                    class="ml-2"
                    icon="mdi-dots-vertical"
                    variant="plain"
                    elevation="0"
                    v-bind="menuProps"
                  ></v-btn>
                </template>
                <v-list min-width="230">
                  <v-list-item @click="exportCsv()">
                    <v-list-item-title>
                      {{ $t("button.csv-export") }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template #default>
              <div class="mt-9 bpl-border-gray rounded-lg">
                <v-row no-gutters class="px-4 py-3">
                  <v-col cols="3">
                    <v-autocomplete
                      v-model="countries"
                      :items="store.getters['app/countries']"
                      item-title="title"
                      item-value="id"
                      clearable
                      multiple
                      variant="outlined"
                      hide-details
                      class="bpl-no-selection"
                    >
                      <template #prepend-inner>
                        <p class="text-no-wrap">
                          {{ $t("country") }}
                          {{ countries.length ? `(${countries.length})` : "" }}
                        </p></template
                      >
                      <template #item="{ props, item }">
                        <v-list-item v-bind="props">
                          <template #prepend>
                            <v-checkbox-btn
                              v-model="countries"
                              :value="item.value"
                              color="primary"
                              @click.stop
                            >
                            </v-checkbox-btn>
                          </template>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="4" class="d-flex mx-auto">
                    <v-text-field
                      v-model="postcodeSearch"
                      :label="$t('search')"
                      variant="outlined"
                      append-inner-icon="mdi-magnify"
                      hide-details
                      clearable
                    />
                  </v-col>
                  <v-col cols="3"></v-col>
                </v-row>
                <v-data-table-server
                  :headers="headers"
                  :items="prepItems"
                  class="bpl-custom-table-footer bpl-table-height-1096"
                  :items-per-page="pagData.per_page"
                  :page="pagData.current_page"
                  :items-length="pagData.total"
                  :items-per-page-options="[]"
                  :loading="loading"
                  @update:options="
                    fetchCountryPoints(
                      $event.page,
                      finalSearchString,
                      $event.sortBy[0],
                    )
                  "
                ></v-data-table-server>
              </div>
            </template>
          </editable-block>
        </v-form>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useStore } from "@/store";
import { useI18n } from "vue-i18n";

import { getCountryPoints, setCountryPoints } from "@/api/settings";

import { useValidHelper } from "@/utils/validHelper";
import { useWatchDebounceHelper } from "@/utils/watchDebounceHelper";
import { snackbar } from "@/utils/snackbar";
import { initialPagData, prepSearchString, useFormat } from "@/utils/other";

import { VForm } from "vuetify/components";
import { CountryPointObject } from "@/types/settings";
import { ExportNotificationType, SortData } from "@/types/other";

import EditableBlock from "@/components/EditableBlock.vue";
import ExportLoader from "@/components/ExportLoader.vue";

const { t } = useI18n();
const store = useStore();
const toFormat = useFormat();

const valid = ref(false);
const form = ref<VForm>();
const exportLoading = ref(false);

useValidHelper(form);

const countries = ref<string[]>([]);

const postcodeSearch = ref("");

const finalSearchString = computed(() =>
  prepSearchString(
    {
      title: "country.code",
      value: countries.value
        .map((id) => store.getters["app/getCountryById"](id)?.code)
        .filter((code) => code),
    },
    { title: "postcode", value: postcodeSearch.value },
  ),
);

useWatchDebounceHelper<string>(finalSearchString, 400, (value) => {
  fetchCountryPoints(1, value, sortData.value);
});

const edited = ref(false);

const csvInput = ref<HTMLInputElement>();

const pagData = ref(initialPagData);
const sortData = ref<SortData>();

const headers = computed(() => [
  {
    title: t("country-code"),
    key: "countryCode",
    sortable: false,
  },
  {
    title: t("plz"),
    key: "postcode",
    sortable: true,
  },
  {
    title: t("base-value-output-country"),
    key: "output",
    sortable: true,
  },
  {
    title: t("basic-value-country-entry"),
    key: "input",
    sortable: true,
  },
  {
    title: t("basic-value-ferry"),
    key: "ferry",
    sortable: true,
  },
]);

const countryPoints = ref<CountryPointObject[]>([]);
const loading = ref(false);
const uploadLoading = ref(false);

const prepItems = computed(() =>
  countryPoints.value.map(
    ({ id, ferry, output, input, postcode, country }) => ({
      id,
      countryCode: country.data.code.toUpperCase(),
      postcode: `${postcode}**`,
      output: toFormat(output),
      input: toFormat(input),
      ferry: toFormat(ferry),
    }),
  ),
);

const fetchCountryPoints = async (
  page: number,
  search: string,
  sort?: SortData,
) => {
  if (!loading.value) {
    try {
      loading.value = true;
      const {
        data,
        meta: { pagination },
      } = await getCountryPoints({
        page,
        search,
        searchJoin: "and",
        ...(sort ? { orderBy: sort.key, sortedBy: sort.order } : {}),
      });
      countryPoints.value = data;
      pagData.value = pagination;
    } catch (e: any) {
      snackbar(e.message);
    } finally {
      loading.value = false;
      sortData.value = sort;
    }
  }
};

const csvInputHandler = async (e: any) => {
  const file: File = e.target.files[0];
  if (file) {
    try {
      uploadLoading.value = true;
      await setCountryPoints(file);
      await fetchCountryPoints(1, finalSearchString.value, sortData.value);
    } catch (e: any) {
      snackbar(e.message);
    } finally {
      uploadLoading.value = false;
    }
  }
};

const exportCsv = async () => {
  try {
    exportLoading.value = true;
    await store.dispatch("app/exportFiles", {
      type: ExportNotificationType.countryPointsCsvExport,
      ids: [],
    });
  } catch (e: any) {
    snackbar(e.message);
  } finally {
    exportLoading.value = false;
  }
};

const isExported = computed(() =>
  store.state.app.exportsQueue.some(
    (e) => e.type === ExportNotificationType.countryPointsCsvExport,
  ),
);
</script>

<style>
.bpl-no-selection .v-autocomplete__selection,
.bpl-no-selection input {
  display: none;
}
</style>
