<template>
  <v-row no-gutters>
    <v-col cols="12" class="pa-10">
      <v-sheet class="pb-12 pt-11 px-10 rounded-xl bpl-border-gray">
        <div class="d-flex justify-space-between align-center">
          <div class="d-flex align-center">
            <v-btn
              icon="mdi-keyboard-backspace"
              variant="plain"
              :to="{ name: !props.isTransporter ? 'clients' : 'transporters' }"
              class="mr-4"
            />
            <template v-if="company">
              <p class="text-h4 text-primary font-weight-light py-1">
                {{ company.number }}
              </p>
              <status-chip
                v-if="!props.isTransporter"
                :status="{
                  name: company.status,
                  context: StatusContext.client,
                }"
                class="ml-4"
              />
              <status-chip
                v-else
                :status="{
                  name: company.status,
                  context: StatusContext.transporter,
                }"
                class="ml-4"
              />
            </template>
          </div>
          <div v-if="company" class="d-flex align-center">
            <export-loader
              v-if="isExported || exportLoading"
              :tooltip="$t('loader.download-prepared')"
            />
            <v-btn
              v-for="action in statusActions.actions.filter((d) => !d.hidden)"
              :key="action.status"
              :color="action.color"
              :variant="action.variant as any"
              class="ml-3"
              :loading="action.status === loadingStatus"
              @click="changeStatusWrapper(action)"
            >
              {{ action.title }}
            </v-btn>
            <v-menu>
              <template #activator="{ props: menuProps }">
                <v-btn
                  class="ml-2"
                  icon="mdi-dots-vertical"
                  variant="plain"
                  elevation="0"
                  v-bind="menuProps"
                ></v-btn>
              </template>
              <v-list min-width="230">
                <v-list-item @click="exportCsv()">
                  <v-list-item-title>
                    {{ $t("button.csv-export") }}
                  </v-list-item-title>
                  <template #append>
                    <v-icon
                      icon="mdi-file-table"
                      color="grey-darken-2"
                    ></v-icon>
                  </template>
                </v-list-item>
                <v-list-item
                  :disabled="removeLoading"
                  @click.stop="deleteCompany()"
                >
                  <v-list-item-title>
                    {{ $t("button.delete") }}
                  </v-list-item-title>
                  <template #append>
                    <v-icon icon="mdi-delete" color="grey-darken-2"></v-icon>
                  </template>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <v-alert
          v-if="currentAlert"
          :key="alert"
          :text="$t(currentAlert.label)"
          :color="currentAlert.color"
          :icon="currentAlert.icon"
          variant="tonal"
          class="mt-10"
          closable
          @close="alert = undefined"
        />
        <template v-if="company && store.getters['app/countries'].length">
          <v-form
            ref="compForm"
            v-model="compValid"
            @submit.prevent="saveChanges(CompanyBlockType.company)"
          >
            <editable-block
              v-model="editableBlocks"
              :name="CompanyBlockType.company"
              class="pt-7"
              :loading="compLoading"
              submit
              @cancel="cancelChanges"
            >
              <template
                v-if="access.someRoles(['admin', 'sub-admin'])"
                #edit-btn="{ edit }"
              >
                <v-btn
                  variant="text"
                  elevation="0"
                  color="primary"
                  icon="mdi-file-edit-outline"
                  @click="edit"
                />
              </template>
              <template #title>
                <p class="text-h5 text-primary py-2">
                  {{ $t("company-data") }}
                </p>
              </template>
              <template #default="{ readonly }">
                <v-row
                  class="my-0 pt-6"
                  :class="{ 'pointer-events-none': readonly }"
                >
                  <v-col v-if="!props.isTransporter" cols="4" class="py-0">
                    <v-select
                      v-model="editCompanyData.type"
                      :rules="[rules.required]"
                      :label="$t('company-type')"
                      :items="
                        companyTypeKeys
                          .filter(({ type }) => type !== 'contractor')
                          .map(({ type, key }) => ({
                            value: type,
                            title: $t(key),
                          }))
                      "
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      class="pb-2"
                    ></v-select>
                  </v-col>
                  <v-col :cols="!props.isTransporter ? 4 : 6" class="py-0">
                    <v-text-field
                      v-model="editCompanyData.name"
                      :error-messages="companyErrors.name"
                      :rules="[rules.required]"
                      :label="$t('company-name')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      @update:model-value="companyErrors.name = []"
                    ></v-text-field>
                  </v-col>
                  <v-col :cols="!props.isTransporter ? 4 : 6" class="py-0">
                    <v-text-field
                      v-model="editCompanyData.addendum"
                      :rules="[rules.required]"
                      :label="$t('company-addendum')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="props.isTransporter" cols="6" class="py-0">
                    <fake-input
                      :value="company?.creditor_number || ''"
                      class="pb-2"
                      :label="$t('creditor-number')"
                    />
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <fake-input
                      :value="company?.debitor_number || ''"
                      class="pb-2"
                      :label="$t('debitor-number')"
                    />
                  </v-col>
                </v-row>
              </template>
            </editable-block>
            <v-divider
          /></v-form>
          <v-form
            v-if="editHeadquartersData.headquarters_address"
            ref="headForm"
            v-model="headValid"
            @submit.prevent="saveChanges(CompanyBlockType.headquarters)"
          >
            <editable-block
              v-model="editableBlocks"
              :name="CompanyBlockType.headquarters"
              class="mt-10"
              :loading="headLoading"
              submit
              @cancel="cancelChanges"
            >
              <template #edit-btn="{ edit }">
                <v-btn
                  variant="text"
                  elevation="0"
                  color="primary"
                  icon="mdi-file-edit-outline"
                  @click="edit"
                />
              </template>
              <template #title>
                <p class="text-h5 text-primary py-2">
                  {{ $t("headquarters") }}
                </p></template
              >
              <template #default="{ readonly }">
                <v-row
                  class="my-0 pt-6"
                  :class="{ 'pointer-events-none': readonly }"
                >
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      v-model="editHeadquartersData.headquarters_address.street"
                      :error-messages="
                        companyErrors['headquarters_address.street']
                      "
                      :rules="[rules.required]"
                      :label="$t('street')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      @update:model-value="
                        companyErrors['headquarters_address.street'] = []
                      "
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      v-model="editHeadquartersData.headquarters_address.zip"
                      :error-messages="
                        companyErrors['headquarters_address.zip']
                      "
                      :rules="[rules.required, rules.postCodeOnly]"
                      :label="$t('zip-code')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      @update:model-value="
                        companyErrors['headquarters_address.zip'] = []
                      "
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-autocomplete
                      v-model="
                        editHeadquartersData.headquarters_address.country_id
                      "
                      autocomplete="off"
                      :rules="[rules.required]"
                      :label="$t('country')"
                      :items="store.getters['app/countries']"
                      class="pb-2"
                      :variant="readonly ? 'filled' : 'outlined'"
                      :readonly="readonly"
                      item-title="title"
                      item-value="id"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      v-model="editHeadquartersData.headquarters_address.city"
                      :error-messages="
                        companyErrors['headquarters_address.city']
                      "
                      :rules="[rules.required]"
                      :label="$t('town')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      @update:model-value="
                        companyErrors['headquarters_address.city'] = []
                      "
                    ></v-text-field>
                  </v-col>
                </v-row>
              </template>
            </editable-block>
            <v-divider />
          </v-form>
          <v-form
            v-if="editContactData.contact"
            ref="contactForm"
            v-model="contactValid"
            @submit.prevent="saveChanges(CompanyBlockType.contact)"
          >
            <editable-block
              v-model="editableBlocks"
              :name="CompanyBlockType.contact"
              class="mt-10"
              :loading="contactLoading"
              submit
              @cancel="cancelChanges"
            >
              <template #edit-btn="{ edit }">
                <v-btn
                  variant="text"
                  elevation="0"
                  color="primary"
                  icon="mdi-file-edit-outline"
                  @click="edit"
                />
              </template>
              <template #title>
                <p class="text-h5 text-primary py-2">
                  {{ $t("central-contact-person") }}
                </p></template
              >
              <template #default="{ readonly }">
                <v-row
                  class="my-0 pt-6"
                  :class="{ 'pointer-events-none': readonly }"
                >
                  <v-col cols="6" class="py-0">
                    <v-select
                      v-model="editContactData.contact.salutation"
                      autocomplete="off"
                      :rules="[rules.required]"
                      :label="$t('salutation')"
                      :items="
                        salutationKeys.map(({ key, type }) => ({
                          value: type,
                          title: $t(key),
                        }))
                      "
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      class="pb-2"
                    ></v-select>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      v-model="editContactData.contact.lastname"
                      :error-messages="companyErrors['contact.lastname']"
                      :rules="[rules.required]"
                      :label="$t('last-name')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      @update:model-value="
                        companyErrors['contact.lastname'] = []
                      "
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      v-model="editContactData.contact.firstname"
                      :error-messages="companyErrors['contact.firstname']"
                      :rules="[rules.required]"
                      :label="$t('first-name')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      @update:model-value="
                        companyErrors['contact.firstname'] = []
                      "
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      v-model="editContactData.contact.phone"
                      :error-messages="companyErrors['contact.phone']"
                      :rules="[rules.required]"
                      :label="$t('phone')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      @update:model-value="companyErrors['contact.phone'] = []"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </template>
            </editable-block>
            <v-divider />
          </v-form>
          <v-form
            ref="taxForm"
            v-model="taxValid"
            @submit.prevent="saveChanges(CompanyBlockType.tax)"
          >
            <editable-block
              v-model="editableBlocks"
              :name="CompanyBlockType.tax"
              class="mt-10"
              :loading="taxLoading"
              submit
              @cancel="cancelChanges"
            >
              <template #edit-btn="{ edit }">
                <v-btn
                  variant="text"
                  elevation="0"
                  color="primary"
                  icon="mdi-file-edit-outline"
                  @click="edit"
                />
              </template>
              <template #title>
                <p class="text-h5 text-primary py-2">
                  {{ $t("tax-information") }}
                </p></template
              >
              <template #default="{ readonly }">
                <v-row
                  class="my-0 pt-6"
                  :class="{ 'pointer-events-none': readonly }"
                >
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      v-model="editTaxData.vat_id"
                      :error-messages="companyErrors.vat_id"
                      :rules="[rules.required]"
                      :label="$t('vat')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      @update:model-value="companyErrors.vat_id = []"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col v-if="!props.isTransporter" cols="6" class="py-0">
                    <v-select
                      v-model="editTaxData.payment_target"
                      autocomplete="off"
                      :rules="[rules.required]"
                      :label="$t('payment-target')"
                      :items="
                        paymentTargetKeys.map(({ type, key }) => ({
                          value: type,
                          title: $t(key),
                        }))
                      "
                      :hint="$t('text.payment-target-info')"
                      persistent-hint
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      class="pb-2"
                    ></v-select>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      v-model="editTaxData.tax_id"
                      :error-messages="companyErrors.tax_id"
                      :rules="[rules.required]"
                      :label="$t('tax')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      @update:model-value="companyErrors.tax_id = []"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </template>
            </editable-block>
            <v-divider />
          </v-form>
          <v-form
            v-if="props.isTransporter"
            ref="bankForm"
            v-model="bankValid"
            @submit.prevent="saveChanges(CompanyBlockType.bank)"
          >
            <editable-block
              v-model="editableBlocks"
              :name="CompanyBlockType.bank"
              class="mt-10"
              :loading="bankLoading"
              submit
              @cancel="cancelChanges"
            >
              <template #edit-btn="{ edit }">
                <v-btn
                  variant="text"
                  elevation="0"
                  color="primary"
                  icon="mdi-file-edit-outline"
                  @click="edit"
                />
              </template>
              <template #title>
                <p class="text-h5 text-primary py-2">
                  {{ $t("bank-details") }}
                </p></template
              >
              <template #default="{ readonly }">
                <v-row
                  class="my-0 pt-6"
                  :class="{ 'pointer-events-none': readonly }"
                >
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      v-model="editBankData.iban"
                      :error-messages="companyErrors.iban"
                      :rules="[rules.iban]"
                      :label="$t('iban')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      @update:model-value="companyErrors.iban = []"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      v-model="editBankData.bank_name"
                      :error-messages="companyErrors.bank_name"
                      :label="$t('bank-name')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      @update:model-value="companyErrors.bank_name = []"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      v-model="editBankData.swift"
                      :error-messages="companyErrors.swift"
                      :label="$t('swift')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      @update:model-value="companyErrors.swift = []"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      v-model="editBankData.bank_code"
                      :error-messages="companyErrors.bank_code"
                      :label="$t('bank-code')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      @update:model-value="companyErrors.bank_code = []"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </template>
            </editable-block>
            <v-divider />
          </v-form>
          <v-form
            v-if="editAddressData.billing_address"
            ref="addrForm"
            v-model="addrValid"
            @submit.prevent="saveChanges(CompanyBlockType.address)"
          >
            <editable-block
              v-model="editableBlocks"
              :name="CompanyBlockType.address"
              class="mt-10"
              :loading="addrLoading"
              submit
              @cancel="cancelChanges"
            >
              <template #edit-btn="{ edit }">
                <v-btn
                  variant="text"
                  elevation="0"
                  color="primary"
                  icon="mdi-file-edit-outline"
                  @click="edit"
                />
              </template>
              <template #title>
                <p class="text-h5 text-primary py-2">
                  {{ $t("different-billing-address") }}
                </p>
              </template>
              <template #default="{ readonly }">
                <v-row
                  class="my-0 pt-6"
                  :class="{ 'pointer-events-none': readonly }"
                >
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      v-model="editAddressData.billing_address.street"
                      :error-messages="companyErrors['billing_address.street']"
                      :rules="[rules.required]"
                      :label="$t('street')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      @update:model-value="
                        companyErrors['billing_address.street'] = []
                      "
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      v-model="editAddressData.billing_address.zip"
                      :error-messages="companyErrors['billing_address.zip']"
                      :rules="[rules.required, rules.postCodeOnly]"
                      :label="$t('zip-code')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      @update:model-value="
                        companyErrors['billing_address.zip'] = []
                      "
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-autocomplete
                      v-model="editAddressData.billing_address.country_id"
                      autocomplete="off"
                      :rules="[rules.required]"
                      :label="$t('country')"
                      :items="store.getters['app/countries']"
                      class="pb-2"
                      :variant="readonly ? 'filled' : 'outlined'"
                      :readonly="readonly"
                      item-title="title"
                      item-value="id"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      v-model="editAddressData.billing_address.city"
                      :error-messages="companyErrors['billing_address.city']"
                      :rules="[rules.required]"
                      :label="$t('town')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      @update:model-value="
                        companyErrors['billing_address.city'] = []
                      "
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </template>
            </editable-block>
            <v-divider />
          </v-form>
          <v-row class="my-0">
            <v-col cols="6" class="py-0">
              <v-form
                ref="passForm"
                v-model="passValid"
                @submit.prevent="saveChanges(CompanyBlockType.password)"
              >
                <editable-block
                  v-model="editableBlocks"
                  :name="CompanyBlockType.password"
                  class="mt-10"
                  :loading="passLoading"
                  submit
                  @cancel="cancelChanges"
                >
                  <template #edit-btn="{ edit }">
                    <v-btn
                      variant="text"
                      elevation="0"
                      color="primary"
                      icon="mdi-file-edit-outline"
                      @click="edit"
                    />
                  </template>
                  <template #title>
                    <p class="text-h5 text-primary py-2">
                      {{ $t("password") }}
                    </p>
                  </template>
                  <template #default="{ readonly, edited }">
                    <v-row
                      class="my-0 pt-6"
                      :class="{ 'pointer-events-none': readonly }"
                    >
                      <v-col v-if="!edited" cols="12" class="py-0">
                        <v-text-field
                          :rules="[rules.required]"
                          :label="$t('current-password')"
                          class="pb-2"
                          :readonly="readonly"
                          :variant="readonly ? 'filled' : 'outlined'"
                          type="password"
                          :model-value="'password'"
                        >
                        </v-text-field>
                      </v-col>
                      <template v-if="edited">
                        <v-col cols="12" class="py-0">
                          <v-text-field
                            v-model="editPassData.password"
                            :error-messages="profileErrors.password"
                            :rules="[
                              rules.required,
                              rules.numberHas,
                              rules.letterHas,
                              rules.symbolHas,
                              rules.letterLowercaseHas,
                              rules.letterUppercaseHas,
                              rules.lengthMin(8),
                            ]"
                            :label="$t('new-password')"
                            autocomplete="new-password"
                            class="pb-2"
                            :readonly="readonly"
                            :variant="readonly ? 'filled' : 'outlined'"
                            :append-inner-icon="
                              newPasswordShow ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            :type="newPasswordShow ? 'text' : 'password'"
                            @click:append-inner="
                              newPasswordShow = !newPasswordShow
                            "
                            @update:model-value="profileErrors.password = []"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" class="py-0">
                          <v-text-field
                            v-model="editPassData.password_confirmation"
                            :error-messages="
                              profileErrors.password_confirmation
                            "
                            :rules="[
                              rules.required,
                              rules.match(editPassData.password),
                            ]"
                            :label="$t('repeat-password')"
                            autocomplete="new-password"
                            class="pb-2"
                            :readonly="readonly"
                            :variant="readonly ? 'filled' : 'outlined'"
                            :append-inner-icon="
                              repeatPasswordShow ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            :type="repeatPasswordShow ? 'text' : 'password'"
                            @click:append-inner="
                              repeatPasswordShow = !repeatPasswordShow
                            "
                            @update:model-value="
                              profileErrors.password_confirmation = []
                            "
                          ></v-text-field>
                        </v-col>
                      </template>
                    </v-row>
                  </template>
                </editable-block>
              </v-form>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-form
                ref="emailForm"
                v-model="emailValid"
                @submit.prevent="saveChanges(CompanyBlockType.email)"
              >
                <editable-block
                  v-model="editableBlocks"
                  :name="CompanyBlockType.email"
                  class="mt-10"
                  :loading="emailLoading"
                  submit
                  @cancel="cancelChanges"
                >
                  <template #edit-btn="{ edit }">
                    <v-btn
                      variant="text"
                      elevation="0"
                      color="primary"
                      icon="mdi-file-edit-outline"
                      @click="edit"
                    />
                  </template>
                  <template #title>
                    <p class="text-h5 text-primary py-2">
                      {{ $t("email") }}
                    </p></template
                  >
                  <template #default="{ readonly, edited }">
                    <v-row
                      class="my-0 pt-6"
                      :class="{ 'pointer-events-none': readonly }"
                    >
                      <v-col v-if="!edited" cols="12" class="py-0">
                        <fake-input
                          :label="$t('current-email')"
                          :value="company?.contact?.data.email"
                          class="pb-2"
                        />
                      </v-col>
                      <template v-if="edited">
                        <v-col cols="12" class="py-0"
                          ><v-text-field
                            v-model="editEmailData.email"
                            :error-messages="profileErrors.email"
                            :rules="[rules.required]"
                            :label="$t('new-email')"
                            class="pb-2"
                            :readonly="readonly"
                            :variant="readonly ? 'filled' : 'outlined'"
                            @update:model-value="profileErrors.email = []"
                          ></v-text-field
                        ></v-col>
                        <v-col cols="12" class="py-0">
                          <v-text-field
                            v-model="repeatEmail"
                            :rules="[
                              rules.required,
                              rules.match(editEmailData.email),
                            ]"
                            :label="$t('repeat-email')"
                            class="pb-2"
                            :readonly="readonly"
                            :variant="readonly ? 'filled' : 'outlined'"
                          >
                          </v-text-field>
                        </v-col>
                      </template>
                    </v-row>
                  </template> </editable-block
              ></v-form>
            </v-col>
          </v-row>
          <template v-for="userRole in usersRoles" :key="userRole">
            <p class="text-h5 text-primary pt-11 pb-8">
              {{ $t(userRole.key) }}
            </p>
            <company-users-table
              :items="company?.users.data"
              :roles="[userRole.type]"
              :removed-id="removedUserId"
              @remove="deleteUser"
            />
          </template>
        </template>
        <app-loader v-else />
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { VForm } from "vuetify/components";
import EditableBlock from "@/components/EditableBlock.vue";
import { useRules } from "@/utils/rules";
import { computed, nextTick, ref, watch } from "vue";
import { snackbar } from "@/utils/snackbar";
import { useValidHelper } from "@/utils/validHelper";
import { useStore } from "@/store";
import {
  companyTypeKeys,
  getRolesDetailsByCompany,
  paymentTargetKeys,
  salutationKeys,
} from "@/utils/other";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import AppLoader from "@/components/AppLoader.vue";
import { ExportNotificationType } from "@/types/other";
import {
  changeCompanyStatus,
  getCompany,
  removeCompanies,
  updateCompany,
} from "@/api/companies";
import {
  CompanyData,
  EditCompanyObject,
  NewCompanyUserObject,
  CompanyType,
  CompanyBlockType,
} from "@/types/company";
import StatusChip from "@/components/StatusChip.vue";
import CompanyUsersTable from "@/components/CompaniesView/CompanyView/CompanyUsersTable.vue";
import { removeEmployee, updateUser } from "@/api/users";
import {
  getInitialEditCompanyErrorObject,
  getInitialNewUserErrorObject,
} from "@/utils/auth";
import FakeInput from "@/components/FakeInput.vue";
import { access } from "@/utils/access";

//import ExportLoader from "@/components/ExportLoader.vue";
import { useConfirm } from "@/utils/confirmHelper";
import ExportLoader from "@/components/ExportLoader.vue";
import { TransporterStatusType, StatusContext } from "@/types/document";

const { t } = useI18n();

const router = useRouter();

const props = defineProps<{
  id: string;
  isTransporter?: boolean;
}>();

const include = "headquarters_address,billing_address,contact,users";

const rules = useRules();
const store = useStore();

const deleteCompanyConfirm = useConfirm("dialog.delete-item");
const deleteUserConfirm = useConfirm("dialog.delete-item");

const blockUserConfirm = useConfirm("text.block-user", {
  text: "text.accept-block-user",
  yes: "button.block-now",
  no: "button.cancel",
});
const unblockUserConfirm = useConfirm("text.unblock-user", {
  text: "text.accept-unblock-user",
  yes: "button.unlock-now",
  no: "button.cancel",
});

const acceptUserConfirm = useConfirm("text.user-accept-title", {
  text: "text.user-accept",
  yes: "button.accept",
  no: "button.cancel",
});

enum ClientAlertType {
  accepted = "accepted",
  blocked = "blocked",
  rejected = "rejected",
  unblocked = "unblocked",
}

enum VariantType {
  plain = "plain",
  outlined = "outlined",
}

enum ColorType {
  error = "error",
  primary = "primary",
  warning = "warning",
  success = "success",
}

type StatusAction = {
  variant?: VariantType;
  color: ColorType;
  title: string;
  status: CompanyData["status"];
  hidden?: boolean;
  alert: ClientAlertType;
};

type ClientAlertData = {
  alert: ClientAlertType;
  label: string;
  color: StatusAction["color"];
  icon: string;
};

const removedUserId = ref<string | undefined>();

const alert = ref<ClientAlertType>();

const company = ref<CompanyData>();

const repeatEmail = ref("");

const newPasswordShow = ref(false);
const repeatPasswordShow = ref(false);

const companyErrors = ref(getInitialEditCompanyErrorObject());
const profileErrors = ref(getInitialNewUserErrorObject());

const editCompanyData = ref<EditCompanyObject>({}); //  type, name, addendum
const editHeadquartersData = ref<EditCompanyObject>({
  headquarters_address: {},
}); //headquarters_address {street, zip, country_id, city}
const editContactData = ref<EditCompanyObject>({ contact: {} }); // contact {salutation, lastname, firstname, phone}
const editTaxData = ref<EditCompanyObject>({}); // vat_id, payment_target, tax_id
const editBankData = ref<EditCompanyObject>({}); //  iban, bank_name, swift, bank_code
const editAddressData = ref<EditCompanyObject>({ billing_address: {} }); //  billing_address {street, zip, country_id, city}
const editPassData = ref<NewCompanyUserObject>({}); //password, password_confirmation
const editEmailData = ref<NewCompanyUserObject>({}); //email

const clientAlertsData = computed<ClientAlertData[]>(() => [
  {
    alert: ClientAlertType.accepted,
    label: t("alert.user-accepted"),
    color: ColorType.success,
    icon: "mdi-checkbox-marked-circle",
  },
  {
    alert: ClientAlertType.blocked,
    label: t("alert.user-blocked"),
    color: ColorType.warning,
    icon: "mdi-alert-outline",
  },
  {
    alert: ClientAlertType.rejected,
    label: t("alert.user-rejected"),
    color: ColorType.warning,
    icon: "mdi-alert-outline",
  },
  {
    alert: ClientAlertType.unblocked,
    label: t("alert.user-unblocked"),
    color: ColorType.primary,
    icon: "mdi-information-outline",
  },
]);

const currentAlert = computed(() =>
  clientAlertsData.value.find((d) => d.alert === alert.value),
);

type StatusActions = {
  status: CompanyData["status"];
  actions: StatusAction[];
};

const statusActionsData = computed<StatusActions[]>(() => [
  {
    status: TransporterStatusType.pending,
    actions: [
      {
        variant: VariantType.outlined,
        color: ColorType.error,
        title: t("button.company-reject"),
        status: TransporterStatusType.rejected,
        alert: ClientAlertType.rejected,
      },
      {
        color: ColorType.primary,
        title: t("button.company-accept"),
        status: TransporterStatusType.accepted,
        alert: ClientAlertType.accepted,
      },
    ],
  },
  {
    status: TransporterStatusType.accepted,
    actions: [
      {
        variant: VariantType.outlined,
        color: ColorType.warning,
        title: t("button.company-temporarily-block"),
        status: TransporterStatusType.temporarilyBlocked,
        hidden: !props.isTransporter,
        alert: ClientAlertType.blocked,
      },
      {
        variant: VariantType.outlined,
        color: ColorType.warning,
        title: t("button.company-block"),
        status: TransporterStatusType.blocked,
        alert: ClientAlertType.blocked,
      },
    ],
  },
  {
    status: TransporterStatusType.blocked,
    actions: [
      {
        color: ColorType.success,
        title: t("button.company-unlock"),
        status: TransporterStatusType.accepted,
        alert: ClientAlertType.unblocked,
      },
    ],
  },
  {
    status: TransporterStatusType.temporarilyBlocked,
    actions: [
      {
        variant: VariantType.outlined,
        color: ColorType.warning,
        title: t("button.company-block"),
        status: TransporterStatusType.blocked,
        alert: ClientAlertType.blocked,
      },
      {
        color: ColorType.success,
        title: t("button.company-unlock"),
        status: TransporterStatusType.accepted,
        alert: ClientAlertType.unblocked,
      },
    ],
  },
  {
    status: TransporterStatusType.rejected,
    actions: [
      {
        color: ColorType.primary,
        title: t("button.company-accept"),
        status: TransporterStatusType.accepted,
        alert: ClientAlertType.accepted,
      },
    ],
  },
]);

const statusActions = computed(
  () =>
    statusActionsData.value.find((d) => d.status === company.value?.status) || {
      actions: [],
    },
);

const usersRoles = computed(() =>
  company.value ? getRolesDetailsByCompany(company.value.type, true) : [],
);

const userLocale = computed(() => store.state.auth.profile?.locale);

const clearPass = () => {
  editPassData.value = {};
  newPasswordShow.value = false;
  repeatPasswordShow.value = false;
};
const clearEmail = () => {
  editEmailData.value = {};
  repeatEmail.value = "";
};

const backToList = async () => {
  await router.push({
    name: !props.isTransporter ? "clients" : "transporters",
  });
};

const fetchCompany = async (id: string) => {
  try {
    const { data } = await getCompany(id, { include });

    if ((data.type === CompanyType.contractor) !== props.isTransporter) {
      //TODO remember about that special redirect logic
      await router.push({
        name: props.isTransporter ? "client" : "transporter",
        params: { id },
      });
    }
    company.value = data;
  } catch (e: any) {
    snackbar(e.message);
    await backToList();
  }
};

const deleteCompany = async () => {
  if (await deleteCompanyConfirm()) {
    try {
      removeLoading.value = true;
      await removeCompanies([props.id]);
      await backToList();
    } catch (e: any) {
      snackbar(e.message);
    } finally {
      removeLoading.value = false;
    }
  }
};

const deleteUser = async (id: string) => {
  if (await deleteUserConfirm()) {
    try {
      removedUserId.value = id;
      await removeEmployee(props.id, id);
      if (company.value?.users)
        company.value.users = {
          data: company.value?.users.data.filter((u) => u.id !== id),
        };
    } catch (e: any) {
      snackbar(e.message);
    } finally {
      removedUserId.value = undefined;
    }
  }
};

watch(
  userLocale,
  () => {
    fetchCompany(props.id);
  },
  { immediate: true },
);

const syncEdits = (value: CompanyData) => {
  //company sync
  if (!editableBlocks.value.includes(CompanyBlockType.company)) {
    editCompanyData.value = {
      type: value.type,
      name: value.name,
      addendum: value.addendum,
      ...(value.type === CompanyType.contractor
        ? {
            bank_name: company.value?.bank_name || undefined,
            bank_code: company.value?.bank_code || undefined,
            iban: company.value?.iban || undefined,
            swift: company.value?.swift || undefined,
          }
        : { payment_target: company.value?.payment_target }),
    };

    companyErrors.value.name = [];
  }
  if (!editableBlocks.value.includes(CompanyBlockType.headquarters)) {
    editHeadquartersData.value = {
      type: company.value?.type,
      headquarters_address: {
        country_id: value.headquarters_address.data.country.data.id,
        city: value.headquarters_address.data.city,
        zip: value.headquarters_address.data.zip,
        street: value.headquarters_address.data.street,
      },
    };
    companyErrors.value["headquarters_address.street"] = [];
    companyErrors.value["headquarters_address.zip"] = [];
    companyErrors.value["headquarters_address.city"] = [];
  }
  if (!editableBlocks.value.includes(CompanyBlockType.contact)) {
    editContactData.value = {
      type: company.value?.type,
      contact: {
        salutation: value.contact?.data.salutation,
        firstname: value.contact?.data.firstname,
        lastname: value.contact?.data.lastname,
        phone: value.contact?.data.phone,
      },
    };

    companyErrors.value["contact.firstname"] = [];
    companyErrors.value["contact.lastname"] = [];
    companyErrors.value["contact.phone"] = [];
  }
  if (!editableBlocks.value.includes(CompanyBlockType.tax)) {
    editTaxData.value = {
      type: company.value?.type,
      vat_id: value.vat_id,
      tax_id: value.tax_id,
      payment_target:
        company.value?.type !== CompanyType.contractor
          ? value.payment_target
          : undefined,
    };

    companyErrors.value.vat_id = [];
    companyErrors.value.tax_id = [];
  }
  if (!editableBlocks.value.includes(CompanyBlockType.bank)) {
    editBankData.value = {
      type: company.value?.type,
      iban: value.iban || undefined,
      swift: value.swift || undefined,
      bank_name: value.bank_name || undefined,
      bank_code: value.bank_code || undefined,
    };
    companyErrors.value.iban = [];
    companyErrors.value.swift = [];
    companyErrors.value.bank_name = [];
    companyErrors.value.bank_code = [];
  }
  if (!editableBlocks.value.includes(CompanyBlockType.address)) {
    editAddressData.value = {
      type: company.value?.type,
      billing_address: value.billing_address?.data
        ? {
            country_id: value.billing_address.data.country.data.id,
            city: value.billing_address.data.city,
            zip: value.billing_address.data.zip,
            street: value.billing_address.data.street,
          }
        : {},
    };
    companyErrors.value["billing_address.street"] = [];
    companyErrors.value["billing_address.zip"] = [];
    companyErrors.value["billing_address.city"] = [];
  }
  //profile sync
  if (!editableBlocks.value.includes(CompanyBlockType.password)) {
    clearPass();
    profileErrors.value.password = [];
    profileErrors.value.password_confirmation = [];
  }
  if (!editableBlocks.value.includes(CompanyBlockType.email)) {
    clearEmail();
    profileErrors.value.email = [];
  }
};

watch(company, (value) => {
  if (value) syncEdits(value);
});

const editableBlocks = ref<CompanyBlockType[]>([]);

const loadingStatus = ref<CompanyData["status"]>();

const removeLoading = ref(false);
const exportLoading = ref(false);

//company
const compValid = ref(false);
const compForm = ref<VForm>();
const compLoading = ref(false);
useValidHelper(compForm);

//headquarters
const headValid = ref(false);
const headForm = ref<VForm>();
const headLoading = ref(false);
useValidHelper(headForm);

//contact
const contactValid = ref(false);
const contactForm = ref<VForm>();
const contactLoading = ref(false);
useValidHelper(contactForm);

//tax
const taxValid = ref(false);
const taxForm = ref<VForm>();
const taxLoading = ref(false);
useValidHelper(taxForm);

//bank
const bankValid = ref(false);
const bankForm = ref<VForm>();
const bankLoading = ref(false);
useValidHelper(bankForm);

//address
const addrValid = ref(false);
const addrForm = ref<VForm>();
const addrLoading = ref(false);
useValidHelper(addrForm);

//password
const passValid = ref(false);
const passForm = ref<VForm>();
const passLoading = ref(false);
useValidHelper(passForm);

//email
const emailValid = ref(false);
const emailForm = ref<VForm>();
const emailLoading = ref(false);
useValidHelper(emailForm);

const closeBlock = (name: CompanyBlockType) => {
  editableBlocks.value = editableBlocks.value.filter(
    (blockName) => blockName !== name,
  );
};

const saveChanges = async (name: CompanyBlockType) => {
  let response: { data: CompanyData } | undefined;
  let companyError: any = {};
  let profileError: any = {};

  //company edits
  switch (name) {
    case CompanyBlockType.company:
      await compForm.value?.validate();
      if (compValid.value) {
        try {
          compLoading.value = true;
          response = await updateCompany(props.id, editCompanyData.value, {
            include,
          });
          closeBlock(CompanyBlockType.company);
        } catch (e: any) {
          companyError = e;
        } finally {
          compLoading.value = false;
        }
      }
      break;
    case CompanyBlockType.headquarters:
      await headForm.value?.validate();
      if (headValid.value) {
        try {
          headLoading.value = true;
          response = await updateCompany(props.id, editHeadquartersData.value, {
            include,
          });
          closeBlock(CompanyBlockType.headquarters);
        } catch (e: any) {
          companyError = e;
        } finally {
          headLoading.value = false;
        }
      }
      break;
    case CompanyBlockType.contact:
      await contactForm.value?.validate();
      if (contactValid.value) {
        try {
          contactLoading.value = true;
          response = await updateCompany(props.id, editContactData.value, {
            include,
          });
          closeBlock(CompanyBlockType.contact);
        } catch (e: any) {
          companyError = e;
        } finally {
          contactLoading.value = false;
        }
      }
      break;
    case CompanyBlockType.tax:
      await taxForm.value?.validate();
      if (taxValid.value) {
        try {
          taxLoading.value = true;
          response = await updateCompany(props.id, editTaxData.value, {
            include,
          });
          closeBlock(CompanyBlockType.tax);
        } catch (e: any) {
          companyError = e;
        } finally {
          taxLoading.value = false;
        }
      }
      break;
    case CompanyBlockType.bank:
      await bankForm.value?.validate();
      if (bankValid.value) {
        try {
          bankLoading.value = true;
          response = await updateCompany(props.id, editBankData.value, {
            include,
          });
          closeBlock(CompanyBlockType.bank);
        } catch (e: any) {
          companyError = e;
        } finally {
          bankLoading.value = false;
        }
      }
      break;
    case CompanyBlockType.address:
      await addrForm.value?.validate();
      if (addrValid.value) {
        try {
          addrLoading.value = true;
          response = await updateCompany(props.id, editAddressData.value, {
            include,
          });
          closeBlock(CompanyBlockType.address);
        } catch (e: any) {
          companyError = e;
        } finally {
          addrLoading.value = false;
        }
      }
      break;
  }
  if (response?.data) company.value = response.data;
  else if (companyError?.errors) {
    companyErrors.value = {
      ...getInitialEditCompanyErrorObject(),
      ...companyError.errors,
    };
  }

  //profile edits
  if (company.value?.contact?.data) {
    switch (name) {
      case CompanyBlockType.password:
        await passForm.value?.validate();
        if (passValid.value) {
          try {
            passLoading.value = true;
            await updateUser(company.value.contact.data.id, editPassData.value);
            closeBlock(CompanyBlockType.password);
            clearPass();
          } catch (e: any) {
            profileError = e;
          } finally {
            passLoading.value = false;
          }
        }
        break;
      case CompanyBlockType.email:
        await emailForm.value?.validate();
        if (emailValid.value) {
          try {
            emailLoading.value = true;
            const { data } = await updateUser(
              company.value.contact.data.id,
              editEmailData.value,
            );
            company.value.contact.data = data;
            closeBlock(CompanyBlockType.email);
            clearEmail();
          } catch (e: any) {
            profileError = e;
          } finally {
            emailLoading.value = false;
          }
        }
        break;
      default:
    }
    if (profileError?.errors) {
      profileErrors.value = {
        ...getInitialNewUserErrorObject(),
        ...profileError.errors,
      };
    }
  }
};
const cancelChanges = () => {
  nextTick(() => {
    if (company.value) syncEdits(company.value as CompanyData);
  });
};

const changeStatus = async (action: StatusAction) => {
  try {
    loadingStatus.value = action.status;
    const { data } = await changeCompanyStatus(props.id, action.status, {
      include,
    });
    company.value = data;
    alert.value = action.alert;
  } catch (e: any) {
    snackbar(e.message);
  } finally {
    loadingStatus.value = undefined;
  }
};

const changeStatusWrapper = async (action: StatusAction) => {
  switch (action.alert) {
    case "blocked":
      if (await blockUserConfirm()) {
        await changeStatus(action);
      }
      break;
    case "unblocked":
      if (await unblockUserConfirm()) {
        await changeStatus(action);
      }
      break;
    case "accepted":
      if (await acceptUserConfirm()) {
        await changeStatus(action);
      }
      break;
    default:
      await changeStatus(action);
  }
};

const exportCsv = async () => {
  try {
    exportLoading.value = true;
    const type: ExportNotificationType = props.isTransporter
      ? ExportNotificationType.transportersCsvExport
      : ExportNotificationType.clientsCsvExport;
    await store.dispatch("app/exportFiles", { type, ids: [props.id] });
  } catch (e: any) {
    snackbar(e.message);
  } finally {
    exportLoading.value = false;
  }
};

const isExported = computed(() =>
  store.state.app.exportsQueue
    .map((e) => e.ids)
    .flat()
    .includes(props.id),
);
</script>

<style scoped></style>
