<template>
  <button
    class="registration-role-btn px-10 py-12 rounded-xl text-center w-100 h-100"
  >
    <span class="text-body-2">{{ $t("text.iam") }}</span>
    <span class="text-h4 text-primary mt-1 mb-4">
      {{ props.role }}
    </span>
    <span class="text-body-2">{{ props.description }}</span>
  </button>
</template>

<script setup lang="ts">
const props = defineProps<{
  role: string;
  description: string;
}>();
</script>

<style scoped>
.registration-role-btn {
  display: grid;
  border: 2px solid #eeeeee !important;
  transition: border 0.2s !important;
  cursor: pointer;
  user-select: none;
  outline: none;
}

.registration-role-btn:hover,
.registration-role-btn:focus {
  border: 2px solid #1982d4 !important;
}
</style>
