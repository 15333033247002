<template>
  <document-view :id="props.id" is-offer />
</template>

<script setup lang="ts">
import DocumentView from "@/components/DocumentsView/DocumentView.vue";

const props = defineProps<{
  id: string;
}>();
</script>

<style scoped></style>
