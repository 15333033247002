<template>
  <v-autocomplete
    ref="asyncSelector"
    v-model:search="searchString"
    v-model="companyId"
    :items="prepCompaniesList"
    item-value="id"
    item-title="name"
    :loading="loading"
    :variant="props.readonly ? 'filled' : 'outlined'"
    :hide-no-data="loading || wait"
    :custom-filter="customFilter"
    prepend-inner-icon="mdi-magnify"
    :rules="props.rules || []"
    :label="props.title || ''"
    :disabled="!!props.modelValue && !companyObject"
    :readonly="props.readonly"
    @mousedown="updateListBox"
    @focus="focusHandler"
  >
    <template v-if="props.clearable" #clear>
      <v-icon
        icon="mdi-close-circle"
        @click="companyId = undefined"
      ></v-icon> </template
  ></v-autocomplete>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

import { getCompanies, getCompany } from "@/api/companies";

import { initialPagData, prepSearchString } from "@/utils/other";
import { snackbar } from "@/utils/snackbar";
import { useWatchDebounceHelper } from "@/utils/watchDebounceHelper";

import { CompanyData, CompanyQueryType } from "@/types/company";

import { VAutocomplete } from "vuetify/components";

const props = defineProps<{
  modelValue?: string | null;
  rules?: any[];
  title?: string;
  type: CompanyQueryType;
  clearable?: boolean;
  noSelection?: boolean;
  readonly?: boolean;
}>();

const { t } = useI18n();

const include = "headquarters_address,users,billing_address,contact";

const focusHandler = () => {
  nextTick(() => {
    searchString.value = "";
  });
};

//@ts-ignore
const companyId = computed({
  get() {
    const noSelection = props.noSelection ? "no-selection" : undefined;
    return companyObject.value?.id || noSelection;
  },
  set(value?: string | null) {
    // if value === null is returned with vuetify component
    if (value !== null) {
      emit("update:modelValue", value === "no-selection" ? null : value);
      asyncSelector.value?.blur();
      focusHandler();
    }
  },
});
const customFilter = (name: string, typed: string) => {
  if (name === companyObject.value?.name) return true;
  const index = name.toLowerCase().indexOf(typed.toLowerCase());
  return index !== 1 && index;
};

const emit = defineEmits<{
  (e: "update:modelValue", data?: string | null): void;
  (e: "changed", data?: CompanyData): void;
}>();

const loading = ref(true);

const companyObject = ref<CompanyData>();
const companiesList = ref<CompanyData[]>([]);

const searchString = ref("");

const pagData = ref(initialPagData);

const listBox = ref<HTMLDivElement>();

const asyncSelector = ref<VAutocomplete>();
const checkScroll = () => {
  if (listBox.value) {
    const box = listBox.value as HTMLDivElement;
    const scrollDiff = box.scrollHeight - box.clientHeight - box.scrollTop;
    const needFetch = scrollDiff < 340;
    if (
      needFetch &&
      pagData.value.current_page < pagData.value.total_pages &&
      !loading.value &&
      !wait.value
    ) {
      fetchCompanies(pagData.value.current_page + 1, finalSearchString.value);
    }
  }
};

const syncCompany = async (id?: string) => {
  if (id) {
    const newCompanyObject = companiesList.value.find((c) => c.id === id);
    if (newCompanyObject) companyObject.value = newCompanyObject;
    else {
      try {
        const { data } = await getCompany(id, {
          include,
        });
        companyObject.value = data;
      } catch (e: any) {
        snackbar(e.message);
      }
    }
  } else companyObject.value = undefined;
};

const updateListBox = () => {
  setTimeout(() => {
    const list = document.querySelector<HTMLDivElement>(
      '.v-overlay [role="listbox"]',
    );

    if (list) {
      listBox.value = list;
      checkScroll();
      list.addEventListener("scroll", checkScroll);
    } else {
      listBox.value = undefined;
    }
  }, 0);
};

watch(
  () => asyncSelector.value?.focused,
  (value) => {
    if (value) {
      updateListBox();
    }
  },
);

watch(companyObject, (value) => {
  emit("changed", value);
});

watch(
  () => props.modelValue,
  (value) => {
    syncCompany(value || undefined);
  },
  { immediate: true },
);

const prepCompaniesList = computed(() => {
  const noSelection = props.noSelection
    ? [{ id: "no-selection", name: t("no-selection") }]
    : [];

  if (companyObject.value)
    return [
      ...noSelection,
      companyObject.value,
      ...companiesList.value.filter(({ id }) => id !== companyObject.value?.id),
    ];
  else return [...noSelection, ...companiesList.value];
});

const finalSearchString = computed(() =>
  prepSearchString({
    title: "name",
    value: searchString.value,
  }),
);

const fetchCompanies = async (page: number, search: string) => {
  try {
    loading.value = true;
    const {
      data,
      meta: { pagination },
    } = await getCompanies({
      page,
      searchJoin: "and",
      search,
      type: props.type,
      include,
      filter:
        "id;addendum;number;type;name;code;city;firstname;lastname;phone;status;pagination;zip;street;email;salutation;role",
    });
    if (data) {
      companiesList.value =
        page === 1 ? data : [...companiesList.value, ...data];
      pagData.value = pagination;
    }
  } catch (e: any) {
    snackbar(e.message);
  } finally {
    loading.value = false;
  }
  await nextTick(() => {
    updateListBox();
  });
};

const wait = useWatchDebounceHelper<string>(finalSearchString, 400, (value) => {
  //@ts-ignore
  listBox.value?.scrollTo(0, 0);
  fetchCompanies(1, value);
});

onMounted(() => {
  fetchCompanies(1, finalSearchString.value);
});
</script>
