<template>
  <v-app>
    <v-main class="fill-height bg-background-primary">
      <router-view />
      <glob-snackbar />
      <confirm-modal />
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
import { computed, onMounted, watch } from "vue";

import { useStore } from "@/store";
import { useI18n } from "vue-i18n";

import { snackbar } from "@/utils/snackbar";
import { syncLocalVersion } from "@/utils/localStorage";
import { access } from "@/utils/access";
import { getNotificationName } from "@/utils/other";

import GlobSnackbar from "@/components/GlobSnackbar.vue";
import {
  ExportQueue,
  NotificationType,
  NotificationObject,
  ExportNotificationType,
  ExportNotificationErrorType,
} from "@/types/other";
import ConfirmModal from "@/components/ConfirmModal.vue";
import { exportFileByUrl } from "@/utils/exportFiles";

const store = useStore();

const { t, locale } = useI18n();

const isAuth = computed(() => store.getters["auth/isAuth"]);
const profile = computed(() => store.state.auth.profile);

const syncLocales = async () => {
  try {
    await store.dispatch("app/syncLocs");
  } catch (e: any) {
    snackbar(e.message);
  }
};
const syncCountries = async () => {
  try {
    await store.dispatch("app/syncCountries");
  } catch (e: any) {
    snackbar(e.message);
  }
};

const fetchAuthData = async (fetchProfile = false, fetchSettings = false) => {
  try {
    await Promise.all([
      fetchProfile && store.dispatch("auth/fetchProfile"),
      fetchSettings &&
        access.can("list-vehicles") &&
        store.dispatch("app/fetchVehicles"),
      access.can("list-pallets") && store.dispatch("app/fetchPallets"),
      access.can("list-market-factor") &&
        fetchSettings &&
        store.dispatch("app/fetchMarketFactors"),
      fetchSettings &&
        access.can("list-system-settings") &&
        store.dispatch("app/fetchSettings"),
      access.can("list-special-features") &&
        store.dispatch("app/fetchFeatures"),
    ]);
    await store.dispatch("app/fetchNotifications");
  } catch (e: any) {
    snackbar(e.message);
  }
};

const clearAfterLogout = () => {
  store.commit("app/setPallets", []);
  store.commit("app/setVehicles", []);
  store.commit("app/setSettings", null);
  store.commit("app/setMarketFactors", null);
};

const syncLocale = async (newLocale: string, oldLocale?: string) => {
  if (profile.value && newLocale !== profile.value.locale) {
    try {
      await store.dispatch("auth/updateProfile", {
        id: profile.value.id,
        newUserData: {
          locale: newLocale,
        },
      });
      await fetchAuthData();
    } catch (e: any) {
      snackbar(e.message);
    }
  }
  if (newLocale !== oldLocale) {
    await syncCountries();
  }
};

watch(
  isAuth,
  async (value, oldValue) => {
    if (value) {
      await fetchAuthData(oldValue === undefined, true);
      if (profile.value && locale.value !== profile.value.locale) {
        locale.value = profile.value.locale;
      }
    } else clearAfterLogout();
  },
  { immediate: true },
);

watch(
  locale,
  (newLocale, oldLocale) => {
    syncLocale(newLocale, oldLocale);
  },
  { immediate: true },
);

const exportTypes: NotificationType[] = Object.values(ExportNotificationType);
const exportErrorTypes: NotificationType[] = Object.values(
  ExportNotificationErrorType,
);

watch(
  () =>
    ({
      notifications: store.state.app.notifications,
      queue: store.state.app.exportsQueue,
    }) as { notifications: NotificationObject[]; queue: ExportQueue[] },
  (value) => {
    value.queue.forEach((exportData) => {
      const notification = value.notifications.find(
        (data) => data.data.uuid === exportData.uuid,
      );
      if (notification) {
        switch (true) {
          case exportTypes.includes(notification.data.event): {
            exportFileByUrl(notification.data.url, () => {
              store.commit("app/removeExportFromQueue", notification.data.uuid);
            });
            break;
          }
          case exportErrorTypes.includes(notification.data.event): {
            store.commit("app/removeExportFromQueue", notification.data.uuid);
            snackbar(t(getNotificationName(notification.data.event)));
            break;
          }
        }
      }
    });
  },
);

onMounted(() => {
  syncLocales();
  syncLocalVersion();
});
</script>

<style>
.pointer-events-none {
  pointer-events: none;
}

.bpl-border-gray {
  border: 1px solid #eeeeee !important;
}

.bpl-underlined-field .v-label {
  text-decoration: underline dotted;
  text-decoration-color: inherit;
  text-underline-offset: 0.4rem;
}

.bpl-underlined-text {
  text-decoration: underline dotted;
  text-decoration-color: inherit;
  text-underline-offset: 0.4rem;
}

.bpl-hyphen-col {
  position: relative;
}
.bpl-hyphen-col:before {
  content: "";
  position: absolute;
  left: -4px;
  top: 27px;
  height: 1.5px;
  width: 8px;
  background: black;
}

.bpl-action-wrap {
  padding-left: 84px;
}

.bpl-dialog .v-card {
  box-shadow: none !important;
}

.v-input .v-icon {
  opacity: 0.6 !important;
}

.v-input .v-icon.mdi-information {
  opacity: 1 !important;
}

.v-checkbox-btn .v-label,
.v-radio .v-label {
  opacity: 1;
  overflow: visible;
}

.v-checkbox-btn .v-icon,
.v-radio .v-icon {
  opacity: 1 !important;
}

.bpl-panels .v-expansion-panel::after {
  content: "";
  display: none;
}

.bpl-panels .v-expansion-panel-title__icon > .v-icon {
  color: #1982d4;
  width: 48px;
}

.bpl-panels .v-expansion-panel-title__overlay {
  display: none;
}

.bpl-panels .v-expansion-panel-title {
  padding: 0 !important;
  margin: 0;
  min-height: auto !important;
  height: auto;
  border-radius: 0;
}

.bpl-panels .v-expansion-panel-text__wrapper {
  padding: 0 !important;
}

.bpl-custom-table-footer .v-data-table-footer__items-per-page {
  display: none;
}
/* Disabled autofill indication (webkit+1password) for all autofilled fields  */
input[data-com-onepassword-filled],
select[data-com-onepassword-filled],
textarea[data-com-onepassword-filled],
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active {
  -webkit-background-clip: text;
}

.bpl-table-height-316 .v-table__wrapper {
  min-height: 316px;
}

.bpl-table-height-1096 .v-table__wrapper {
  min-height: 1096px;
}
</style>
