<template>
  <div class="d-flex flex-column small-checkboxes-list">
    <small-checkbox
      v-if="props.title"
      v-model="globCheckbox.checked"
      :disabled="props.disabled"
      :label="props.title"
      :tooltip="props.tooltip"
      :minus="globCheckbox.interim"
    />
    <div class="d-flex flex-column" :class="{ 'pl-4': props.title }">
      <small-checkbox
        v-for="(item, i) in prepItems"
        :key="i"
        v-model="checkedList"
        :label="item.title"
        :name="item.value"
        :disabled="props.disabled"
        :tooltip="item.tooltip"
      ></small-checkbox>
      <show-more-btn
        v-if="
          props.items.length > maxItems + (props.title ? 0 : 1) && !showMore
        "
        :disabled="disabled"
        @click="clickShowMore"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";
import ShowMoreBtn from "@/components/CheckboxesGroup/ShowMoreBtn.vue";
import SmallCheckbox from "@/components/CheckboxesGroup/SmallCheckbox.vue";
import { useCheckboxes } from "@/utils/checkboxHelper";
import { CheckboxData, CheckboxGroupValue } from "@/types/other";

const props = defineProps<{
  modelValue?: CheckboxGroupValue[];
  items: CheckboxData[];
  title?: string;
  tooltip?: string;
  disabled?: boolean;
}>();

const defChecked = computed(() =>
  (props.items || []).map(({ value }) => value)
);

const { globCheckbox, checkedList } = useCheckboxes(defChecked);

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "update:modelValue", data: CheckboxGroupValue[]): void;
}>();

const prepItems = computed(() => [
  ...props.items.slice(
    0,
    showMore.value ? props.items.length : maxItems.value + (props.title ? 0 : 1)
  ),
]);

watch(checkedList, (value) => {
  emit("update:modelValue", value);
});

watch(
  () => props.modelValue,
  (value) => {
    try {
      if (JSON.stringify(value) !== JSON.stringify(checkedList.value)) {
        checkedList.value = [...(value || [])];
      }
    } catch (e: any) {
      console.error(e.message);
    }
  },
  { immediate: true }
);

const clickShowMore = () => {
  showMore.value = true;
};

const showMore = ref(false);

const maxItems = computed(() => (props.title ? 4 : props.items.length));
</script>

<style scoped>
.small-checkboxes-list {
  width: max-content;
}
</style>
