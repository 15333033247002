<template>
  <div
    class="bpl-route-station align-center px-3 py-2"
    :class="{ 'bg-grey-lighten-5': !(props.index % 2) }"
  >
    <v-btn
      icon
      elevation="0"
      variant="plain"
      :class="{
        'text-grey-lighten-2': props.default || props.readonly,
        'text-grey-darken-2': !(props.default || props.readonly),
        draggable: !(props.default || props.readonly),
      }"
      :disabled="props.default || props.readonly"
    >
      <span class="text-h6 text-grey-darken-2">{{ props.index + 1 }}</span>
      <v-icon icon="mdi-drag-vertical"></v-icon>
    </v-btn>
    <v-text-field
      v-if="props.default"
      :model-value="currStationType"
      class="pt-6 padding-bottom-2 pointer-events-none"
      :variant="props.readonly ? 'filled' : 'outlined'"
      readonly
    ></v-text-field>
    <v-select
      v-else
      v-model="stationData.type"
      autocomplete="off"
      :label="$t('intermediate-station')"
      :items="pointTypeItems"
      class="pt-6 padding-bottom-2"
      :class="{ 'pointer-events-none': props.default || props.readonly }"
      :variant="props.default || props.readonly ? 'filled' : 'outlined'"
      :readonly="props.default || props.readonly"
      :rules="[rules.required]"
      :error-messages="typeErrorMessages || []"
    ></v-select>
    <v-autocomplete
      :key="String(props.vignette)"
      v-model="stationData.country_id"
      autocomplete="off"
      :rules="[rules.required, rules.vignette(props.vignette)]"
      :label="$t('country')"
      :items="store.getters['app/countries']"
      class="pt-6 padding-bottom-2"
      :class="{ 'pointer-events-none': props.readonly }"
      :variant="props.readonly ? 'filled' : 'outlined'"
      :readonly="props.readonly"
      item-title="title"
      item-value="id"
    ></v-autocomplete>
    <v-text-field
      v-model="stationData.zip"
      :error-messages="errors"
      autocomplete="off"
      :label="$t('zip-code')"
      class="pt-6 padding-bottom-2"
      :class="{ 'pointer-events-none': props.readonly }"
      :variant="props.readonly ? 'filled' : 'outlined'"
      :readonly="props.readonly"
      :rules="[rules.required, rules.postCodeOnly]"
    ></v-text-field>
    <div class="w-discharged">
      <v-tooltip
        v-if="isLoadingStation || isUploadingStation"
        :text="$t('tooltip-message.be-discharged')"
        location="bottom"
        offset="-32px"
      >
        <template #activator="{ props: prop }">
          <div v-bind="prop">
            <v-checkbox
              v-model="stationData.discharged"
              :label="
                isLoadingStation
                  ? $t('loading-by-driver')
                  : $t('unloading-by-driver')
              "
              :disabled="props.readonly"
              color="primary"
              class="pt-6 padding-bottom-2 bpl-underlined-field"
            >
            </v-checkbox>
          </div>
        </template>
      </v-tooltip>
    </div>
    <v-btn
      :icon="props.default || props.readonly ? 'mdi-delete-off' : 'mdi-delete'"
      variant="plain"
      :class="{
        'text-grey-lighten-2': props.default || props.readonly,
      }"
      :disabled="props.default || props.readonly"
      elevation="0"
      @click="emit('remove')"
    ></v-btn>
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";

import { useRules } from "@/utils/rules";
import { pointKeys } from "@/utils/other";

import { PointType } from "@/types/document";
import { StationObject } from "@/types/calculator";

const store = useStore();

const rules = useRules();

const { t } = useI18n();

const props = defineProps<{
  index: number;
  modelValue: StationObject;
  vignette: boolean;
  default?: boolean;
  readonly?: boolean;
  zipErrorMessages?: { [K: string]: string[] };
  typeErrorMessages?: string[];
}>();

const stationData = computed({
  get() {
    return props.modelValue;
  },
  set(value: StationObject) {
    emit("update:modelValue", value);
  },
});

const pointTypeItems = computed(() =>
  pointKeys.map(({ type, key }) => ({ title: t(key), value: type })),
);

const isLoadingStation = computed(
  () =>
    !!stationData.value.type &&
    stationData.value.type === PointType.loadingPoint,
);

const isUploadingStation = computed(
  () =>
    !!stationData.value.type &&
    stationData.value.type === PointType.unloadingPoint,
);

const currStationType = computed(
  () =>
    pointTypeItems.value.find(({ value }) => stationData.value.type === value)
      ?.title,
);

const errors = computed(() =>
  props.zipErrorMessages ? props.zipErrorMessages[String(props.index)] : [],
);

watch(
  [isLoadingStation, isUploadingStation],
  ([newIsLoadingStation, newIsUploadingStation]) => {
    if (!(newIsLoadingStation || newIsUploadingStation)) {
      stationData.value.discharged = false;
    }
  },
);

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "update:modelValue", data: StationObject): void;
  // eslint-disable-next-line no-unused-vars
  (e: "remove"): void;
}>();
</script>

<style scoped>
.bpl-route-station {
  display: grid;
  grid-template-columns: 48px 1fr 1fr 180px auto 48px;
  gap: 24px;
}

.padding-bottom-2 {
  padding-bottom: 2px;
}

.w-discharged {
  width: 180px;
}
</style>
