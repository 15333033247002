<template>
  <v-tooltip :text="props.tooltip" location="bottom">
    <template #activator="{ props: tooltipProps }">
      <v-progress-circular
        v-bind="tooltipProps"
        color="primary"
        indeterminate
        size="16"
        width="2"
        class="mx-2"
      >
      </v-progress-circular>
    </template>
  </v-tooltip>
</template>

<script setup lang="ts">
const props = defineProps<{
  tooltip: string;
}>();
</script>

<style scoped>
.app-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
