import { useI18n } from "vue-i18n";
import { store } from "@/store";
import moment from "moment";

import { backFormats } from "@/utils/locFormat";

export const chCode = "CH";

export const useRules = () => {
  const { t } = useI18n();

  const empty = (value: string) =>
    value === "" || value === null || value === undefined;

  const emailReg = /.+@.+\..+/;
  const numberOnlyReg = /^[0-9]+$/;
  const numberWithMinusOnlyReg = /^[-]?[0-9]+$/;
  const postCodeOnlyReg = /^[a-zA-Z0-9\s-]+$/;
  const floatNumberReg = /^[0-9]+([,/.][0-9]+)?$/;
  const floatNumberWithMinusReg = /^[-]?[0-9]+([,/.][0-9]+)?$/;
  const numberHasReg = /[0-9]+/;
  const letterHasReg = /[a-zA-Z]+/;
  const letterLowercaseHasReg = /[a-z]+/;
  const letterUppercaseHasReg = /[A-Z]+/;
  const symbolHasReg = /\W+/;
  const phoneReg =
    /^[+]?[(]?[0-9]{1,3}[)]?[-\s.]?[(]?[0-9]{2,3}[)]?([-\s.]?[0-9]){4,10}$/;
  const ibanReg = /^[A-Z]{2}\d{13,20}$/;
  const pageNumberReg = /^[a-zA-Z0-9-]+$/;

  return {
    required: (value: any) =>
      value === 0 || !!value || t("error-message.required"),
    numberOnly: (value: any) =>
      empty(value) || numberOnlyReg.test(value) || t("error-message.number"),
    numberWithMinusOnly: (value: any) =>
      empty(value) ||
      numberWithMinusOnlyReg.test(value) ||
      t("error-message.number"),
    postCodeOnly: (value: any) =>
      empty(value) ||
      postCodeOnlyReg.test(value) ||
      t("error-message.number-and-letter"),
    floatNumberOnly: (value: any) =>
      empty(value) ||
      floatNumberReg.test(value) ||
      t("error-message.float-number"),
    floatNumberWithMinusOnly: (value: any) =>
      empty(value) ||
      floatNumberWithMinusReg.test(value) ||
      t("error-message.float-number"),
    numberHas: (value: any) =>
      empty(value) || numberHasReg.test(value) || t("error-message.number-has"),
    letterHas: (value: any) =>
      empty(value) || letterHasReg.test(value) || t("error-message.letter-has"),
    letterLowercaseHas: (value: any) =>
      empty(value) ||
      letterLowercaseHasReg.test(value) ||
      t("error-message.letter-lowercase-has"),
    letterUppercaseHas: (value: any) =>
      empty(value) ||
      letterUppercaseHasReg.test(value) ||
      t("error-message.letter-uppercase-has"),
    symbolHas: (value: any) =>
      empty(value) || symbolHasReg.test(value) || t("error-message.symbol-has"),
    lengthMin: (min: number) => (value: any) =>
      empty(value) ||
      value?.length >= min ||
      t("error-message.min-length", { number: min }),
    lengthMax: (max: number) => (value: any) =>
      empty(value) ||
      value?.length <= max ||
      t("error-message.max-length", { number: max }),
    valueMax: (max: number, post: string) => (value: any) =>
      empty(value) ||
      Number(value) <= max ||
      t("alert.value-exceed", { value: `${max}${post}` }),
    match: (match?: string) => (value: any) =>
      value === match || t("error-message.match"),
    email: (value: any) =>
      empty(value) || emailReg.test(value) || t("error-message.email"),
    phone: (value: any) =>
      empty(value) || phoneReg.test(value) || t("error-message.phone"),
    iban: (value: any) =>
      empty(value) || ibanReg.test(value) || t("error-message.iban"),
    pageNumber: (value: any) =>
      empty(value) || pageNumberReg.test(value) || t("ride.page-number-rule"),
    range: (min: number, max: number) => (value: any) =>
      empty(value) ||
      (min <= value && value <= max) ||
      t("error-message.number-range", { min, max }),
    vignette: (vignette: boolean) => (value: any) => {
      return (
        !(
          vignette &&
          store.getters["app/getCountryById"](value)?.code.toUpperCase() ===
            chCode
        ) || t("error-message.vignette")
      );
    },
    mustAfter:
      (
        date: string,
        valueFormat: string = backFormats.yMmDdHhMmSs,
        dateFormat: string = backFormats.yMmDdHhMmSs,
      ) =>
      (value: string | number) => {
        const valueMoment = moment(value, valueFormat);
        const dateMoment = moment(date, dateFormat);
        return (
          moment(valueMoment).isSameOrAfter(dateMoment) ||
          `${t("error-message.must-after")} "${dateMoment.format(valueFormat)}"`
        );
      },
    mustBefore:
      (
        date: string,
        valueFormat: string = backFormats.yMmDdHhMmSs,
        dateFormat: string = backFormats.yMmDdHhMmSs,
      ) =>
      (value: string | number) => {
        const valueMoment = moment(value, valueFormat);
        const dateMoment = moment(date, dateFormat);
        return (
          moment(valueMoment).isSameOrBefore(dateMoment) ||
          `${t("error-message.must-before")} "${dateMoment.format(
            valueFormat,
          )}"`
        );
      },
  };
};
