<template>
  <v-form ref="form" v-model="valid" @submit.prevent="callSave()">
    <editable-block
      v-model="edited"
      class="mt-8"
      :loading="loadingUser"
      submit
      @cancel="callClear"
    >
      <template #title>
        <p class="text-h5 text-primary py-2">{{ title }}</p></template
      >
      <template #edit-btn>
        <v-btn color="primary" @click="edited = true">
          {{ $t("button.add") }}
        </v-btn>
      </template>
      <template #default="{ readonly }">
        <div v-if="edited" class="bg-grey-lighten-5 px-6 pt-8 rounded-lg mt-8">
          <v-row class="my-0">
            <v-col v-if="editedId && props.companyRoles" cols="12" class="py-0">
              <v-select
                v-model="newUserObject.role"
                autocomplete="off"
                :label="$t('role-text')"
                :items="
                  props.companyRoles.map(({ key, type }) => ({
                    value: type,
                    title: $t(key),
                  }))
                "
                class="pb-2"
                :readonly="readonly"
                :variant="readonly ? 'filled' : 'outlined'"
                :bg-color="!readonly ? ('white' as any) : undefined"
              >
              </v-select>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="newUserObject.firstname"
                :error-messages="errors.firstname"
                :rules="[rules.required]"
                :label="$t('first-name')"
                class="pb-2"
                :readonly="readonly"
                :variant="readonly ? 'filled' : 'outlined'"
                :bg-color="!readonly ? ('white' as any) : undefined"
                @update:model-value="errors.firstname = []"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="newUserObject.lastname"
                :error-messages="errors.lastname"
                :rules="[rules.required]"
                :label="$t('last-name')"
                class="pb-2"
                :readonly="readonly"
                :variant="readonly ? 'filled' : 'outlined'"
                :bg-color="!readonly ? ('white' as any) : undefined"
                @update:model-value="errors.lastname = []"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="my-0">
            <v-col
              :cols="!(isManager || isAccounting || descriptionNeed) ? 6 : ''"
              class="py-0"
            >
              <v-select
                v-model="newUserObject.salutation"
                :error-messages="errors.salutation"
                autocomplete="off"
                :rules="[rules.required]"
                :label="$t('salutation')"
                :items="
                  salutationKeys.map(({ key, type }) => ({
                    value: type,
                    title: $t(key),
                  }))
                "
                class="pb-2"
                :readonly="readonly"
                :variant="readonly ? 'filled' : 'outlined'"
                :bg-color="!readonly ? ('white' as any) : undefined"
                @update:model-value="errors.salutation = []"
              ></v-select>
            </v-col>
            <v-col v-if="isManager" class="py-0">
              <v-text-field
                v-model="newUserObject.designation"
                :error-messages="errors.designation"
                :label="$t('designation')"
                autocomplete="off"
                class="pb-2"
                :readonly="readonly"
                :variant="readonly ? 'filled' : 'outlined'"
                :bg-color="!readonly ? ('white' as any) : undefined"
                @update:model-value="errors.designation = []"
              >
              </v-text-field>
            </v-col>
            <v-col v-if="isAccounting" class="py-0">
              <v-select
                v-model="newUserObject.department"
                :error-messages="errors.department"
                autocomplete="off"
                :rules="[rules.required]"
                :label="$t('department')"
                :items="departmentItems"
                class="pb-2"
                :readonly="readonly"
                :variant="readonly ? 'filled' : 'outlined'"
                :bg-color="!readonly ? ('white' as any) : undefined"
                @update:model-value="errors.department = []"
              ></v-select>
            </v-col>
            <v-col v-if="descriptionNeed" class="py-0">
              <v-text-field
                v-model="newUserObject.department_miscellaneous"
                :error-messages="errors.department_miscellaneous"
                :rules="[rules.required]"
                :label="$t('description')"
                autocomplete="off"
                class="pb-2"
                :readonly="readonly"
                :variant="readonly ? 'filled' : 'outlined'"
                :bg-color="!readonly ? ('white' as any) : undefined"
                @update:model-value="errors.department_miscellaneous = []"
              ></v-text-field>
            </v-col>
            <v-col v-if="isTransporter" class="py-0">
              <language-selector
                v-model="newUserObject.languages"
                :readonly="readonly"
                :bg-color="!readonly ? ('white' as any) : undefined"
                :error-messages="errors.languages"
                :rules="[rules.required]"
                class="pb-2"
                @update:model-value="errors.languages = []"
              />
            </v-col>
          </v-row>
          <v-row class="my-0">
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="newUserObject.email"
                :error-messages="errors.email"
                :rules="[rules.required, rules.email]"
                :label="$t('email')"
                autocomplete="off"
                class="pb-2"
                :readonly="readonly"
                :variant="readonly ? 'filled' : 'outlined'"
                :bg-color="!readonly ? ('white' as any) : undefined"
                @update:model-value="errors.email = []"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="newUserObject.phone"
                :error-messages="errors.phone"
                :rules="[rules.required, rules.phone]"
                autocomplete="off"
                :label="$t('phone')"
                class="pb-2"
                :readonly="readonly"
                :variant="readonly ? 'filled' : 'outlined'"
                :bg-color="!readonly ? ('white' as any) : undefined"
                @update:model-value="errors.phone = []"
              >
              </v-text-field>
            </v-col>
            <v-col
              v-if="isAccounting || editedId"
              cols="12"
              class="d-flex justify-space-between py-0"
            >
              <v-checkbox
                v-if="isAccounting"
                v-model="newUserObject.voucher_recipient"
                :label="$t('receipt-recipient')"
                color="primary"
                class="pb-2"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-if="editedId"
                v-model="editPassword"
                :label="$t('change-password')"
                color="primary"
                class="pb-2"
              ></v-checkbox>
            </v-col>
            <template v-if="!editedId || editPassword">
              <v-col cols="6" class="py-0">
                <v-text-field
                  v-model="newUserObject.password"
                  :error-messages="errors.password"
                  :rules="[
                    rules.required,
                    rules.numberHas,
                    rules.letterHas,
                    rules.symbolHas,
                    rules.letterLowercaseHas,
                    rules.letterUppercaseHas,
                    rules.lengthMin(8),
                  ]"
                  :label="$t('password')"
                  autocomplete="new-password"
                  class="pb-2"
                  :readonly="readonly"
                  :variant="readonly ? 'filled' : 'outlined'"
                  :bg-color="!readonly ? ('white' as any) : undefined"
                  :append-inner-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="passwordShow ? 'text' : 'password'"
                  counter
                  @click:append-inner="passwordShow = !passwordShow"
                  @update:model-value="errors.password = []"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  v-model="newUserObject.password_confirmation"
                  :error-messages="errors.password_confirmation"
                  :rules="[rules.required, rules.match(newUserObject.password)]"
                  :label="$t('repeat-password')"
                  autocomplete="new-password"
                  class="pb-2"
                  :readonly="readonly"
                  :variant="readonly ? 'filled' : 'outlined'"
                  :bg-color="!readonly ? ('white' as any) : undefined"
                  :append-inner-icon="
                    passwordConfirmationShow ? 'mdi-eye' : 'mdi-eye-off'
                  "
                  :type="passwordConfirmationShow ? 'text' : 'password'"
                  @click:append-inner="
                    passwordConfirmationShow = !passwordConfirmationShow
                  "
                  @update:model-value="errors.password_confirmation = []"
                >
                </v-text-field>
              </v-col>
            </template>
          </v-row>
        </div>
        <company-users-table
          v-if="users.length || props.loading"
          :items="users"
          :roles="[role]"
          class="mt-8 employees-table"
          :removed-id="removedUserId"
          :loading="props.loading"
          edited
          @remove="deleteUser"
          @edit="editedId = $event"
        />
        <div
          v-else-if="!edited"
          class="d-flex align-center justify-center bpl-border-gray rounded-lg employees-plus-wrap mt-8"
        >
          <v-btn
            variant="text"
            icon
            width="150"
            height="150"
            @click="edited = true"
          >
            <v-icon icon="mdi-plus" color="grey-lighten-3" size="120"></v-icon>
          </v-btn>
        </div>
      </template> </editable-block
  ></v-form>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";

import { createUser, removeUser, updateUser } from "@/api/users";

import { useConfirm } from "@/utils/confirmHelper";
import { useRules } from "@/utils/rules";
import { useValidHelper } from "@/utils/validHelper";
import { snackbar } from "@/utils/snackbar";
import {
  getInitialNewCompanyUserErrorObject,
  getInitialNewCompanyUserObject,
  getDepartmentKey,
} from "@/utils/auth";
import { getUsersByRoles, KeyByType, salutationKeys } from "@/utils/other";

import { VForm } from "vuetify/components";
import { RoleType } from "@/types/roles";
import { NewCompanyUserObject } from "@/types/company";
import { ProfileData, DepartmentType } from "@/types/auth";

import CompanyUsersTable from "@/components/CompaniesView/CompanyView/CompanyUsersTable.vue";
import EditableBlock from "@/components/EditableBlock.vue";
import LanguageSelector from "@/components/LanguageSelector.vue";

const store = useStore();
const rules = useRules();
const { t } = useI18n();

const props = defineProps<{
  role: RoleType;
  title?: string;
  admins?: ProfileData[];
  loading?: boolean;
  companyRoles?: KeyByType<RoleType>[];
}>();

const deleteConfirm = useConfirm("dialog.delete-item");

const newUserObject = ref<NewCompanyUserObject>({
  ...getInitialNewCompanyUserObject(),
  role: props.role,
});

const isBplAdmin = computed(() => !!props.admins);

const errors = ref(getInitialNewCompanyUserErrorObject());

const users = computed(() =>
  getUsersByRoles(
    !isBplAdmin.value
      ? store.state.auth.profile?.company?.data?.users.data
      : props.admins,
    [props.role],
  ),
);

const valid = ref(false);
const form = ref<VForm>();

useValidHelper(form);

const passwordShow = ref(false);
const passwordConfirmationShow = ref(false);
const loadingUser = ref(false);
const removedUserId = ref<string | undefined>();
const edited = ref(false);
const editedId = ref("");
const editPassword = ref(false);

const editedUserData = computed(
  () => users.value.find((user) => user.id === editedId.value) || null,
);

const isManager = computed(() => {
  const managerRoles: RoleType[] = [
    "manager",
    "planner",
    "sub-logistic",
    "sub-industry",
    "sub-forwarder",
  ];
  return managerRoles.includes(newUserObject.value.role || props.role);
});

const isAccounting = computed(() => {
  const accountingRoles: RoleType[] = [
    "accounting-contractor",
    "accounting-client",
    "sub-logistic",
    "sub-industry",
    "sub-forwarder",
  ];
  return accountingRoles.includes(newUserObject.value.role || props.role);
});

const isTransporter = computed(() => {
  const accountingRoles: RoleType[] = [
    "forwarder",
    "sub-forwarder",
    "planner",
    "accounting-contractor",
    "driver",
    "pallet-department-contractor",
  ];
  return accountingRoles.includes(newUserObject.value.role || props.role);
});

const departmentItems = computed(() =>
  Object.values(DepartmentType).map((key: DepartmentType) => ({
    value: key,
    title: t(getDepartmentKey(key)),
  })),
);

const departmentMiscellaneous = computed(() => {
  if (newUserObject.value.department === DepartmentType.miscellaneous) {
    return newUserObject.value.department_miscellaneous;
  } else {
    return undefined;
  }
});

const descriptionNeed = computed(
  () =>
    isAccounting.value &&
    newUserObject.value.department === DepartmentType.miscellaneous,
);

const callClear = () => {
  newUserObject.value = {
    ...getInitialNewCompanyUserObject(),
    role: props.role,
  };
  editedId.value = "";
  editPassword.value = false;
};
const callSave = () => {
  if (!editedId.value) addUser();
  else saveEditedUser();
};

const saveEditedUser = async () => {
  await form.value?.validate();
  if (valid.value) {
    try {
      loadingUser.value = true;
      const updatedUser: NewCompanyUserObject = {
        firstname: newUserObject.value.firstname,
        lastname: newUserObject.value.lastname,
        salutation: newUserObject.value.salutation,
        phone: newUserObject.value.phone,
        role: newUserObject.value.role,
        ...(isManager.value || isAccounting.value
          ? {
              designation: newUserObject.value.designation,
            }
          : {}),
        ...(isAccounting.value
          ? {
              department: newUserObject.value.department,
              department_miscellaneous: departmentMiscellaneous.value,
              voucher_recipient: newUserObject.value.voucher_recipient,
            }
          : {}),
        ...(isTransporter.value
          ? {
              languages: newUserObject.value.languages,
            }
          : {}),
      };

      if (editedUserData.value?.email !== newUserObject.value.email)
        updatedUser.email = newUserObject.value.email;

      if (editPassword.value) {
        updatedUser.password = newUserObject.value.password;
        updatedUser.password_confirmation =
          newUserObject.value.password_confirmation;
      }

      const { data } = await updateUser(editedId.value, updatedUser);

      if (!isBplAdmin.value) {
        await store.dispatch("auth/updateEmployee", data);
      } else {
        emit("update", data);
      }
      edited.value = false;
      callClear();
    } catch (e: any) {
      snackbar(e.message);
      errors.value = {
        ...getInitialNewCompanyUserErrorObject(),
        ...e.errors,
      };
    } finally {
      loadingUser.value = false;
    }
  }
};

const addUser = async () => {
  await form.value?.validate();
  if (valid.value) {
    try {
      loadingUser.value = true;
      if (!isBplAdmin.value) {
        await store.dispatch("auth/addEmployee", {
          companyId: store.state.auth.profile?.company?.data.id,
          userData: newUserObject.value,
        });
      } else {
        const { data } = await createUser(newUserObject.value);
        emit("add", data);
      }
      edited.value = false;
      callClear();
    } catch (e: any) {
      snackbar(e.message);
      errors.value = {
        ...getInitialNewCompanyUserErrorObject(),
        ...e.errors,
      };
    } finally {
      loadingUser.value = false;
    }
  }
};
const deleteUser = async (id: string) => {
  if (await deleteConfirm()) {
    try {
      removedUserId.value = id;
      if (!isBplAdmin.value) {
        await store.dispatch("auth/removeEmployee", {
          userId: id,
          companyId: store.state.auth.profile?.company?.data.id,
        });
      } else {
        await removeUser(id);
        emit("remove", id);
        if (editedId.value === id) editedId.value = "";
      }
    } catch (e: any) {
      snackbar(e.message);
    } finally {
      removedUserId.value = undefined;
    }
  }
};

watch(editedUserData, (value) => {
  if (value) {
    newUserObject.value = {
      firstname: value.firstname,
      lastname: value.lastname,
      salutation: value.salutation,
      designation: value.designation,
      department: value.department,
      department_miscellaneous: value.department_miscellaneous,
      languages: value.languages || undefined,
      email: value.email,
      phone: value.phone,
      role: value.role,
      voucher_recipient: value.voucher_recipient,
    };
    edited.value = true;
  } else {
    edited.value = false;
    callClear();
  }
});

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "add", data: ProfileData): void;
  (e: "update", data: ProfileData): void;
  (e: "remove", data: string): void;
}>();
</script>

<style>
.employees-plus-wrap {
  height: 376px;
}

.employees-table .v-table__wrapper {
  height: 326px;
}
</style>
