<template>
  <v-row no-gutters>
    <v-col cols="12" class="pa-10">
      <v-sheet class="pb-12 pt-11 px-10 rounded-xl bpl-border-gray">
        <p class="text-h4 text-primary font-weight-light py-1">
          {{ $t("market-factor") }}
        </p>
        <v-form
          ref="form"
          v-model="valid"
          class="mt-9"
          @submit.prevent="saveChanges"
        >
          <editable-block
            v-model="edited"
            :loading="editLoading"
            :disabled="!isDataReady"
            submit
          >
            <template #title>
              <p class="text-h5 text-primary py-2">
                {{ $t("factor") }}
              </p>
            </template>
            <template #header-content>
              <div class="mr-2">
                <export-loader
                  v-if="isExported || exportLoading"
                  :tooltip="$t('loader.download-prepared')"
                />
              </div>
            </template>
            <template #edit-btn="{ edit }">
              <v-btn color="primary" :disabled="!isDataReady" @click="edit">
                {{ $t("button.edit") }}
              </v-btn>
            </template>
            <template #actions>
              <v-menu :key="isDataReady.toString()">
                <template #activator="{ props: menuProps }">
                  <v-btn
                    class="ml-2"
                    icon="mdi-dots-vertical"
                    variant="plain"
                    elevation="0"
                    v-bind="menuProps"
                  ></v-btn>
                </template>
                <v-list min-width="230">
                  <v-list-item
                    :disabled="uploadLoading"
                    @click.stop="csvInput && csvInput.click()"
                  >
                    <v-list-item-title>
                      {{ $t("button.upload-csv") }}
                    </v-list-item-title>
                    <template #append>
                      <v-icon icon="mdi-upload" color="grey-darken-2"></v-icon>
                    </template>
                    <input
                      :key="isDataReady.toString()"
                      ref="csvInput"
                      type="file"
                      accept="text/csv"
                      class="d-none"
                      name="csv"
                      @input="csvInputHandler"
                    />
                  </v-list-item>

                  <v-list-item @click="exportCsv()">
                    <v-list-item-title>
                      {{ $t("button.csv-export") }}
                    </v-list-item-title>
                    <template #append>
                      <v-icon
                        icon="mdi-file-table"
                        color="grey-darken-2"
                      ></v-icon>
                    </template>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template #default="{ readonly }">
              <div class="mt-6">
                <app-loader v-if="!isDataReady" height="86px" />
                <div
                  v-else
                  class="bpl-market-factor-wrap"
                  :class="{ 'pointer-events-none': readonly }"
                >
                  <v-text-field
                    ref="percentInput"
                    v-model="percent"
                    :label="$t('percentage-value')"
                    append-inner-icon="mdi-percent"
                    class="pb-2"
                    :readonly="readonly"
                    :variant="readonly ? 'filled' : 'outlined'"
                    :rules="[rules.required]"
                  />
                  <span class="text-h6 px-6 pt-3">=</span>
                  <fake-input
                    :label="$t('market-factor')"
                    append-inner-icon="mdi-numeric"
                    :value="marketFactorString"
                  />
                </div>
              </div>
            </template>
          </editable-block>
        </v-form>
        <p class="text-h5 text-primary py-2">
          {{ $t("replacement-factor") }}
        </p>
        <factor-calendar is-market class="mt-6" /> </v-sheet></v-col
  ></v-row>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useStore } from "@/store";

import { setMarketFactors } from "@/api/settings";

import { useNumberHelper } from "@/utils/numberHelper";
import { snackbar } from "@/utils/snackbar";
import { useRules } from "@/utils/rules";
import { useValidHelper } from "@/utils/validHelper";
import { useFormat } from "@/utils/other";

import { VForm, VTextField } from "vuetify/components";
import { ExportNotificationType } from "@/types/other";

import AppLoader from "@/components/AppLoader.vue";
import FakeInput from "@/components/FakeInput.vue";
import ExportLoader from "@/components/ExportLoader.vue";
import EditableBlock from "@/components/EditableBlock.vue";
import FactorCalendar from "@/components/FactorCalendar.vue";

const store = useStore();
const rules = useRules();
const toFormat = useFormat();

const valid = ref(false);
const form = ref<VForm>();

useValidHelper(form);

const edited = ref(false);

const editLoading = ref(false);

const uploadLoading = ref(false);

const csvInput = ref<HTMLInputElement>();

const percentInput = ref<VTextField>();

const percent = useNumberHelper(0, 1, 101);

const exportLoading = ref(false);

const csvInputHandler = async (e: any) => {
  const file: File = e.target.files[0];
  if (file) {
    try {
      uploadLoading.value = true;
      await setMarketFactors(file);
      store.commit("app/setMarketFactors", null);
      await store.dispatch("app/fetchMarketFactors");
    } catch (e: any) {
      snackbar(e.message);
    } finally {
      uploadLoading.value = false;
    }
  }
};

const exportCsv = async () => {
  try {
    exportLoading.value = true;
    await store.dispatch("app/exportFiles", {
      type: ExportNotificationType.marketFactorsCsvExport,
      ids: [],
    });
  } catch (e: any) {
    snackbar(e.message);
  } finally {
    exportLoading.value = false;
  }
};

const isExported = computed(() =>
  store.state.app.exportsQueue.some(
    (e) => e.type === ExportNotificationType.marketFactorsCsvExport,
  ),
);

watch(edited, (value) => {
  if (value) percentInput.value?.focus();
  else {
    const oldFactor = store.state.app.settings?.market_factor;
    if (oldFactor || oldFactor === 0) percent.value = String(oldFactor);
  }
});

const saveChanges = async () => {
  await form.value?.validate();
  if (valid.value) {
    try {
      editLoading.value = true;
      await store.dispatch("app/updateSettings", {
        market_factor: Number(percent.value),
      });
      edited.value = false;
    } catch (e: any) {
      snackbar(e.message);
    } finally {
      editLoading.value = false;
    }
  }
};

const marketFactorString = computed(() => {
  return toFormat(
    store.state.app.market_factors?.find(
      (m) => String(m.percent) === percent.value,
    )?.value,
  );
});

const isDataReady = computed(
  () => !!store.state.app.settings && !!store.state.app.market_factors,
);

watch(
  () => store.state.app.settings?.market_factor,
  (value) => {
    if ((value || value === 0) && !percent.value) percent.value = String(value);
  },
  { immediate: true },
);
</script>

<style scoped>
.bpl-market-factor-wrap {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}
</style>
