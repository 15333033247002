<template>
  <auth-wrapper>
    <role-form v-if="!newUserRole" @select="selectRole" />
    <registration-wrap v-else>
      <template #action>
        <div v-if="tabIndex === 2">
          <div v-if="edited">
            <v-btn variant="text" color="primary" @click="callCancel">
              {{ $t("button.cancel") }}
            </v-btn>
            <v-btn color="primary" class="ml-2" @click="callSave">
              {{ $t("button.save") }}
            </v-btn>
          </div>
          <v-btn
            v-else
            color="primary"
            prepend-icon="mdi-file-edit-outline"
            @click="callEdit"
            >{{ $t("button.edit") }}</v-btn
          >
        </div>
      </template>
      <progress-tabs
        :tabs="tabs"
        :tab-index="tabIndex"
        disabled
        @select="tabIndex = $event"
      />
      <reg-first
        v-if="tabIndex === 0"
        :new-user-object="newUserObject"
        :role="newUserRole"
        @submit="callSubmit"
        @back="callClear"
      />
      <reg-second
        v-if="tabIndex === 1"
        :new-user-object="newUserObject"
        :role="newUserRole"
        @submit="callSubmit"
        @back="tabIndex = 0"
      />
      <reg-summary
        v-if="tabIndex === 2"
        :new-user-object="newUserObject"
        :role="newUserRole"
        @back="tabIndex = 1"
        @submit="callSubmit"
      />
    </registration-wrap>
  </auth-wrapper>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";

import RoleForm from "@/components/Auth/Registration/RoleForm.vue";
import RegistrationWrap from "@/components/Auth/Registration/RegistrationWrap.vue";
import ProgressTabs from "@/components/ProgressTabs.vue";
import AuthWrapper from "@/components/Auth/AuthWrapper.vue";

import { getInitialNewUserObject } from "@/utils/auth";
import { subscribe, broadcast } from "@/utils/events";

import { NewUserRole, NewUserObject } from "@/types/auth";
import RegFirst from "@/components/Auth/Registration/RegFirst.vue";
import RegSecond from "@/components/Auth/Registration/RegSecond.vue";
import RegSummary from "@/components/Auth/Registration/RegSummary.vue";

const edited = ref(false);

const newUserRole = ref<NewUserRole | null>(null);

const tabs = ["company-data", "tax-information", "summary"];

const tabIndex = ref(0);

const newUserObject = ref(getInitialNewUserObject());

const callSubmit = (data: { data: NewUserObject; tabIndex?: number }) => {
  newUserObject.value = data.data;
  if (data?.tabIndex !== undefined) {
    tabIndex.value = data.tabIndex;
  }
};

const callClear = () => {
  newUserObject.value = getInitialNewUserObject();
  newUserRole.value = null;
};

const selectRole = (role: NewUserRole) => {
  newUserRole.value = role;
};

const callEdit = () => {
  broadcast("EditFormStart", {});
  edited.value = true;
};

const callSave = () => {
  broadcast("SaveFormStart", {});
};

const callCancel = () => {
  broadcast("EditFormCancel", {});
  edited.value = false;
};

const onSave = () => {
  edited.value = false;
};

onMounted(() => {
  subscribe("EditFormEnd", onSave);
});
</script>
