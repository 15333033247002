<template>
  <div
    class="d-flex align-start pl-3"
    :class="{ 'bg-grey-lighten-5': index % 2 !== 0 }"
  >
    <div class="text-h6 pt-12">{{ index + 1 }}</div>
    <v-row class="my-0 px-6 pt-8">
      <!-- all pallets -->
      <v-col cols="12" class="py-0">
        <fake-input
          :label="pointData.label"
          class="pb-2"
          :value="pointData.name"
        />
      </v-col>
      <!-- customs station -->
      <template v-if="props.point.type === PointType.customsStation">
        <v-col cols="6" class="py-0">
          <fake-input
            :value="props.point.agent_lastname"
            :label="$t('customs-agent.last-name')"
            class="pb-2"
          />
        </v-col>
        <v-col cols="6" class="py-0">
          <fake-input
            :value="props.point.agent_firstname"
            :label="$t('customs-agent.first-name')"
            class="pb-2"
          />
        </v-col>
      </template>
      <!-- loading or unloading point  -->
      <template
        v-if="
          props.point.type === PointType.loadingPoint ||
          props.point.type === PointType.unloadingPoint
        "
      >
        <!-- not last unloading point -->
        <template v-if="!isLast">
          <v-col cols="3" class="py-0">
            <fake-input
              :value="palletTypesList"
              :label="$t('pallet-type')"
              class="pb-2"
            />
          </v-col>
          <v-col cols="3" class="py-0">
            <fake-input
              :value="props.point.meta.number_of_pallets"
              :label="$t('number-of-pallets')"
              class="pb-2"
            />
          </v-col>
          <v-col cols="3" class="py-0">
            <fake-input
              :value="formatWeightGoods"
              :label="$t('weight-goods')"
              append-inner-icon="mdi-weight-kilogram"
              class="pb-2"
            />
          </v-col>
          <v-col cols="3" class="py-0">
            <fake-input
              :value="palletExchangeList"
              :label="$t('pallet-exchange')"
              class="pb-2"
            />
          </v-col>
          <template
            v-if="props.point.meta.pallet_type === PalletCustomTypes.other"
          >
            <v-col cols="12" class="py-0">
              <fake-input
                :value="props.point.meta.pallet_type_other"
                :label="$t('pallet-type-other')"
                class="pb-2"
              />
            </v-col>
          </template>
        </template>
        <!-- last unloading point -->
        <template v-else>
          <v-col cols="4" class="py-0">
            <fake-input
              :value="palletTypesList"
              :label="$t('pallet-type')"
              class="pb-2"
            />
          </v-col>
          <v-col cols="4" class="py-0">
            <fake-input
              :value="props.point.meta.number_of_pallets"
              :label="$t('number-of-pallets')"
              class="pb-2"
            />
          </v-col>
          <v-col cols="4" class="py-0">
            <fake-input
              :value="formatWeightGoods"
              :label="$t('weight-goods')"
              append-inner-icon="mdi-weight-kilogram"
              class="pb-2"
            />
          </v-col>
          <template
            v-if="props.point.meta.pallet_type === PalletCustomTypes.other"
          >
            <v-col cols="12" class="py-0">
              <fake-input
                :value="props.point.meta.pallet_type_other"
                :label="$t('pallet-type-other')"
                class="pb-2"
              />
            </v-col>
          </template>
          <v-col cols="12" class="py-0">
            <fake-input
              :value="palletExchangeList"
              :label="$t('pallet-exchange')"
              class="pb-2"
            />
          </v-col>
          <!-- return to another branch is selected -->
          <template
            v-if="
              props.point.meta.pallet_exchange ===
              PalletExchange.returnToAnotherBranch
            "
          >
            <v-col cols="4" class="py-0">
              <fake-input
                :label="$t('country')"
                :value="anotherBranchCountry"
                class="pb-2"
              ></fake-input>
            </v-col>
            <v-col cols="4" class="py-0">
              <fake-input
                :value="props.point.meta.another_branch.zip"
                :label="$t('zip-code')"
                class="pb-2"
              />
            </v-col>
            <v-col cols="4" class="py-0">
              <fake-input
                :value="props.point.meta.another_branch.city"
                :label="$t('location')"
                class="pb-2"
              />
            </v-col>
          </template>
          <!-- return to loading point is selected -->
          <template
            v-if="
              props.point.meta.pallet_exchange ===
              PalletExchange.returnToLoadingPoint
            "
          >
            <v-col cols="12" class="py-0">
              <fake-input
                :value="pointsList"
                :label="$t('destination-return')"
                class="pb-2"
              />
            </v-col>
          </template>
        </template>
        <v-col cols="12" class="py-0">
          <v-textarea
            :model-value="props.point.meta.other_comments"
            :label="$t('other-comments')"
            readonly
            variant="filled"
            no-resize
            auto-grow
            class="pb-2"
          />
        </v-col>
        <v-col cols="12" class="pt-0 d-flex justify-space-between align-center">
          <div v-if="props.point.discharged">
            <v-icon icon="mdi-information" color="primary" />
            <span class="text-primary ml-2">
              {{ $t("loading-unloading-driver") }}
            </span>
          </div>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "@/store";
import { useI18n } from "vue-i18n";

import { getPointName, joinWithDots, useFormat } from "@/utils/other";

import { PalletExchange, EditPointObject, PointType } from "@/types/document";
import { MarketplaceData } from "@/types/marketplace";

import FakeInput from "@/components/FakeInput.vue";

enum PalletCustomTypes {
  no = "no",
  other = "other",
}

const props = defineProps<{
  point: EditPointObject;
  document: MarketplaceData;
  index: number;
  isLast: boolean;
}>();

const store = useStore();
const { t } = useI18n();
const toFormat = useFormat();

const palletExchangeList = computed(() => {
  const code = Object.values(PalletExchange).find(
    (code) => props.point.meta.pallet_exchange === code,
  );
  return code && t(code);
});

const palletTypesList = computed(
  () =>
    [
      ...store.state.app.pallets,
      { code: PalletCustomTypes.other, name: t(PalletCustomTypes.other) },
    ].find(({ code }) => props.point.meta.pallet_type === code)?.name,
);

const pointData = computed(() => {
  return {
    name: joinWithDots(
      props.point.country.data.name,
      props.point.postcode,
      props.point.city,
    ),
    label: props.point.type ? t(getPointName(props.point.type)) : "",
  };
});

const pointsList = computed(() => {
  const selectedPoint = props.document.points.data.find(
    ({ id }) => props.point.meta.return_to_loading_point === id,
  );
  if (selectedPoint) {
    return `${selectedPoint.type ? t(getPointName(selectedPoint.type)) : ""}
      ${joinWithDots(
        selectedPoint.country.data.code.toUpperCase(),
        selectedPoint.postcode,
        selectedPoint.city,
      )}`;
  } else {
    return "";
  }
});

const anotherBranchCountry = computed(
  () =>
    store.getters["app/countries"].find(
      ({ code }: any) => props.point.meta.another_branch.country_code === code,
    ).title,
);

const formatWeightGoods = computed(() =>
  toFormat(props.point.meta.weight_goods),
);
</script>

<style scoped></style>
