import moment from "moment";

import {
  Vehicle,
  FactorDay,
  FeatureCalcType,
  FeatureObject,
  FeatureCostObject,
  VehicleCalcType,
} from "@/types/settings";
import { DocumentCalcObject } from "@/types/calculator";

enum FaVariants {
  oneDriver = 1,
  twoDrivers,
}

type Commissions = {
  commission_normal: number;
  commission_express_1: number;
  commission_express_2: number;
  commission_express_3: number;
  commission_express_4: number;
};

type ExpressFactorItem = {
  max: number;
  value: number;
  commissionKey: keyof Commissions;
};

type Time = string | undefined;

type StartTimesFinally = {
  start: string;
  fa: FaVariants;
  deliveryDuration: number;
  downDuration: number;
};

type CalendarFactorArgs = {
  start?: string;
  end?: string;
  s: FactorDay[];
};

type DowntimeArgs = {
  loadingDate: string;
  startDuration: number;
  endDuration: number;
};

type XDriverDurationArgs = {
  x: FaVariants;
  loadingDate: string;
  fz: number;
  points: number;
  revert?: boolean;
};

type PArgs = {
  duration: number;
  commissions: Commissions;
};

type DowntimeWrapArgs = {
  startDuration: number;
  endDuration: number;
};

type DateTimeArgs = {
  date: string;
  time: string;
};

type IsBetweenTimeArgs = {
  date: string;
  startTime: string;
  endTime: string;
};

type DocumentDateArgs = {
  dist: number;
  PLZ: number;
  S: FactorDay[];
  commissions: Commissions;
  LZFK: number;
  T: number;
  TE: number;
  BPM: number;
  points: number;
  fDPoints: number;
  loadingDateStart?: string;
  loadingDateEnd?: string;
  unloadingDateStart?: string;
  unloadingDateEnd?: string;
  currentDate: string;
  dateFormat: string;
  rawFT: Vehicle;
  rawBE: FeatureObject[];
  PT: number;
  boatCost: number;
  railCost: number;
};

type VariantWrapperArgs = {
  start?: string;
  end?: string;
  FA: FaVariants;
  deliveryDuration: number;
  downDuration: number;
  isMock?: boolean;
};

type VariantArgs = {
  KM: number;
  PLZ: number;
  S: number;
  commissions: Commissions;
  LZFK: number;
  T: number;
  TE: number;
  BPM: number;
  SB: number;
  SZ: number;
  loadingDate?: string;
  unloadingDate?: string;
  currentDate: string;
  rawFT: Vehicle;
  rawBE: FeatureObject[];
  PT: number;
  FA: FaVariants;
  deliveryDuration: number;
  downDuration: number;
  isMock?: boolean;
  boatCost: number;
  railCost: number;
};

// Business logic constants
const MIN_CALC_DISTANCE_OF_KM = 400;
const AVERAGE_SPEED_EUROPE = 70;
const FORWARDER_LOADING_PRICE = 50;
const STOPOVER_PRICE = 50;
const LOADING_UNLOADING_TIME = 2; // LZ
const ADDITIONAL_WORKING_TIME_FOR_LOADING_UNLOADING =
  LOADING_UNLOADING_TIME * 2; // ZA
const LOADING_UNLOADING_TIME_FOR_STOPOVER = 1.5; // ZwLZ
const FIRST_DRIVER_SHIFT = 4.5; // FS1
const SECOND_DRIVER_SHIFT = 5.5; // FS2
const BREAK_AFTER_FIRST_SHIFT = 1.0; // FP1
const SECOND_AFTER_FIRST_SHIFT = 9.0; // FP2
const SUPPLEMENT_FOR_ADDITIONAL_DRIVER = 500; // DC
const SUPPLEMENT_FOR_NIGHT_ON_THE_WAY = 100; // DN
const DOWNTIME_HOUR_COST = 60; // STH
const SUNDAY_LOADING_UNLOADING_COST = 250;
const SATURDAY_LOADING_UNLOADING_COST = 150;
const MIN_START_DELAY_TO_USE_MARKET_FACTOR_OF_HOURS = 72;

const EXPRESS_FACTOR_COST_TABLE: ExpressFactorItem[] = [
  { max: 8, value: 0.65, commissionKey: "commission_express_4" },
  { max: 12, value: 0.5, commissionKey: "commission_express_3" },
  { max: 17, value: 0.25, commissionKey: "commission_express_2" },
  { max: 24, value: 0.12, commissionKey: "commission_express_1" },
  { max: Infinity, value: 0, commissionKey: "commission_normal" },
];

const revSort = (a: ExpressFactorItem, b: ExpressFactorItem) => a.max - b.max;

const SORT_EXPRESS_FACTOR_COST_TABLE = EXPRESS_FACTOR_COST_TABLE.sort(revSort);

// Moment js format constants
const FULL_DATE_FORMAT = "Y-MM-DD HH:mm:ss";
const WITHOUT_TIME_DATE_FORMAT = "Y-MM-DD";
const TIME_FORMAT = "HH:mm:ss";

// Time constants
const NIGHT_SHIFT_START = "22:00:00";
const NIGHT_SHIFT_END = "06:00:00";
const HOLIDAY_START = "07-01 00:00:00";
const HOLIDAY_END = "08-31 23:59:59";
const HOLYDAY_SATURDAY_START = "07:00:00";
const HOLYDAY_SATURDAY_END = "23:59:59";
const SUNDAY_START = "00:00:00";
const SUNDAY_END = "22:00:00";

const SUNDAY_NUMBER_MOMENT_JS = 0;
const SATURDAY_NUMBER_MOMENT_JS = 6;

const roundTo10 = (n: number) => {
  const div = Math.abs(n) > 10 ? 10 : 1;
  return Math.round(n / div) * div;
};

const isSat = (date: string) =>
  moment(date, FULL_DATE_FORMAT).day() === SATURDAY_NUMBER_MOMENT_JS;

const isSun = (date: string) =>
  moment(date, FULL_DATE_FORMAT).day() === SUNDAY_NUMBER_MOMENT_JS;

const getFullDateFromTime = ({ date, time }: DateTimeArgs) => {
  const mDate = moment(date, FULL_DATE_FORMAT);
  if (!mDate.isValid()) {
    throw new Error(
      `date: "${date}" is not valid for format: "${WITHOUT_TIME_DATE_FORMAT}"`,
    );
  }

  if (!moment(time, TIME_FORMAT).isValid()) {
    throw new Error(
      `time: "${time}" is not valid for format: "${TIME_FORMAT}"`,
    );
  }

  return moment(
    `${mDate.format(WITHOUT_TIME_DATE_FORMAT)} ${time}`,
    FULL_DATE_FORMAT,
  );
};

const getSundayFromWeekendDateAndTime = ({ date, time }: DateTimeArgs) => {
  if (isSun(date)) {
    return getFullDateFromTime({ date, time });
  }

  const nextDayDate = moment(date, FULL_DATE_FORMAT)
    .add(1, "days")
    .format(FULL_DATE_FORMAT);

  if (isSun(nextDayDate)) {
    return getFullDateFromTime({
      date: nextDayDate,
      time,
    });
  }

  throw new Error(`date: "${date}" is not weekend`);
};

const getSaturdayFromWeekendDateAndTime = ({ date, time }: DateTimeArgs) => {
  if (isSat(date)) {
    return getFullDateFromTime({ date, time });
  }

  const prevDayDate = moment(date, FULL_DATE_FORMAT)
    .subtract(1, "days")
    .format(FULL_DATE_FORMAT);

  if (isSat(prevDayDate)) {
    return getFullDateFromTime({
      date: prevDayDate,
      time,
    });
  }

  throw new Error(`date: "${date}" is not weekend`);
};

const isBetweenTime = ({ date, startTime, endTime }: IsBetweenTimeArgs) => {
  const mDate = moment(date, FULL_DATE_FORMAT);

  if (!mDate.isValid()) {
    throw new Error(
      `date: "${date}" is not valid for format: "${WITHOUT_TIME_DATE_FORMAT}"`,
    );
  }

  return mDate.isBetween(
    getFullDateFromTime({
      date,
      time: startTime,
    }),
    getFullDateFromTime({
      date,
      time: endTime,
    }),
    undefined,
    "[]",
  );
};

const isSundayStopTime = (data: string) => {
  return (
    isSun(data) &&
    isBetweenTime({
      date: data,
      startTime: SUNDAY_START,
      endTime: SUNDAY_END,
    })
  );
};

const calcCalendarFactors = ({ start, end, s }: CalendarFactorArgs) => {
  const getValue = (date?: string) => {
    const defFactor = { value: 1, mf_value: 1 };
    if (date) {
      const { value, mf_value } =
        s.find((item) => date.includes(item.date)) || defFactor;
      return {
        value: value || 1,
        mf_value: mf_value || 1,
      };
    }
    return defFactor;
  };

  const startFactor = getValue(start);
  const endFactor = getValue(end);

  return {
    season: startFactor.value * endFactor.value,
    market: startFactor.mf_value * endFactor.mf_value,
  };
};

const calcVehicleTypePrice = (
  { calculation_type, value }: Vehicle,
  kps: number,
) => {
  if (value) {
    switch (calculation_type) {
      case VehicleCalcType.fixedPrice:
        return value;
      case VehicleCalcType.factor:
        return roundTo10(value * kps);
      default:
        return 0;
    }
  }
  return 0;
};

const calcSpecialFeaturesCost = (
  rawBE: FeatureObject[],
  kps: number,
): { arrBE: FeatureCostObject[]; BE: number } => {
  // Calculates BE factor cost
  const specialFeaturesFactorCalc = ({
    calculation_type,
    value,
  }: FeatureObject) => {
    if (value) {
      switch (calculation_type) {
        case FeatureCalcType.fixedPrice:
          return value;
        case FeatureCalcType.factor:
          return roundTo10(value * kps);
        default:
          return 0;
      }
    }
    return 0;
  };
  // Make BE factors array
  const arrBE = rawBE.map((value) => ({
    ...value,
    cost: specialFeaturesFactorCalc(value),
  }));
  // Calculates summary BE factors cost
  const BE = arrBE.reduce((acc, { cost }) => {
    return acc + cost;
  }, 0);

  return { arrBE, BE };
};

const isHolydayStopTime = (date: string) => {
  const mDate = moment(date, FULL_DATE_FORMAT);

  if (!mDate.isValid()) {
    return false;
  }

  const startHD = moment(`${mDate.year()}-${HOLIDAY_START}`, FULL_DATE_FORMAT);
  const endHD = moment(`${mDate.year()}-${HOLIDAY_END}`, FULL_DATE_FORMAT);

  const isHolydayDate = mDate.isBetween(startHD, endHD, undefined, "[]");

  if (isHolydayDate) {
    if (isSat(date)) {
      return isBetweenTime({
        date,
        startTime: HOLYDAY_SATURDAY_START,
        endTime: HOLYDAY_SATURDAY_END,
      });
    }

    if (isSun(date)) {
      return isBetweenTime({
        date,
        startTime: SUNDAY_START,
        endTime: SUNDAY_END,
      });
    }
  }

  return false;
};

// Calculates the holidays and weekends duration
const calcDowntime = ({
  loadingDate,
  startDuration,
  endDuration,
}: DowntimeArgs) => {
  let downtime = 0;
  let preDrive = 0;

  const startDate = moment(loadingDate, FULL_DATE_FORMAT)
    .add(startDuration + LOADING_UNLOADING_TIME, "hours")
    .format(FULL_DATE_FORMAT);
  const endDate = moment(loadingDate, FULL_DATE_FORMAT)
    .add(endDuration + LOADING_UNLOADING_TIME, "hours")
    .format(FULL_DATE_FORMAT);

  // Holidays
  // Start date is holydays stop time
  if (isHolydayStopTime(startDate)) {
    const currHolydayStopDateEnd = getSundayFromWeekendDateAndTime({
      date: startDate,
      time: SUNDAY_END,
    });
    downtime = moment(currHolydayStopDateEnd, FULL_DATE_FORMAT).diff(
      startDate,
      "hours",
      true,
    ); // Remainder off holiday stop
    return { downtime, preDrive };
  }

  // End date is holidays stop time
  if (isHolydayStopTime(endDate)) {
    const currHolydayStopDateStart = getSaturdayFromWeekendDateAndTime({
      date: endDate,
      time: HOLYDAY_SATURDAY_START,
    });
    const currHolydayStopDateEnd = getSundayFromWeekendDateAndTime({
      date: endDate,
      time: SUNDAY_END,
    });

    downtime = currHolydayStopDateEnd.diff(
      currHolydayStopDateStart,
      "hours",
      true,
    ); // Remainder off holiday stop
    preDrive = currHolydayStopDateStart.diff(startDate, "hours", true); // Remainder off driving
    return { downtime, preDrive };
  }

  // No holidays sunday
  // Start date is sunday stop time
  if (isSundayStopTime(startDate)) {
    const currSundayStopDateEnd = getSundayFromWeekendDateAndTime({
      date: startDate,
      time: SUNDAY_END,
    });
    downtime = currSundayStopDateEnd.diff(startDate, "hours", true); // Remainder off sunday stop
    return { downtime, preDrive };
  }

  // End date is sunday stop time
  if (isSundayStopTime(endDate)) {
    const currSundayStopDateStart = getSundayFromWeekendDateAndTime({
      date: endDate,
      time: SUNDAY_START,
    });
    const currSundayStopDateEnd = getSundayFromWeekendDateAndTime({
      date: endDate,
      time: SUNDAY_END,
    });

    downtime = currSundayStopDateEnd.diff(
      currSundayStopDateStart,
      "hours",
      true,
    ); // Remainder off sunday stop
    preDrive = currSundayStopDateStart.diff(startDate, "hours", true); // Remainder off driving
    return { downtime, preDrive };
  }

  return { downtime, preDrive };
};

const calcDowntimeRevert = ({
  loadingDate,
  startDuration,
  endDuration,
}: DowntimeArgs) => {
  let downtime = 0;
  let preDrive = 0;

  const startDate = moment(loadingDate, FULL_DATE_FORMAT)
    .subtract(startDuration, "hours")
    .format(FULL_DATE_FORMAT);
  const endDate = moment(loadingDate, FULL_DATE_FORMAT)
    .subtract(endDuration, "hours")
    .format(FULL_DATE_FORMAT);

  // Holidays
  // Start date is holydays stop time
  if (isHolydayStopTime(startDate)) {
    const currHolydayStopDateEnd = getSaturdayFromWeekendDateAndTime({
      date: startDate,
      time: HOLYDAY_SATURDAY_START,
    });
    downtime = moment(startDate, FULL_DATE_FORMAT).diff(
      currHolydayStopDateEnd,
      "hours",
      true,
    ); // Remainder off holiday stop
    return { downtime, preDrive };
  }

  // End date is holidays stop time
  if (isHolydayStopTime(endDate)) {
    const currHolydayStopDateStart = getSundayFromWeekendDateAndTime({
      date: endDate,
      time: SUNDAY_END,
    });
    const currHolydayStopDateEnd = getSaturdayFromWeekendDateAndTime({
      date: endDate,
      time: HOLYDAY_SATURDAY_START,
    });

    downtime = currHolydayStopDateStart.diff(
      currHolydayStopDateEnd,
      "hours",
      true,
    ); // Remainder off holiday stop
    preDrive = moment(startDate, FULL_DATE_FORMAT).diff(
      currHolydayStopDateEnd,
      "hours",
      true,
    ); // Remainder off driving
    return { downtime, preDrive };
  }

  // No holidays sunday
  // Start date is sunday stop time
  if (isSundayStopTime(startDate)) {
    const currSundayStopDateEnd = getSundayFromWeekendDateAndTime({
      date: startDate,
      time: SUNDAY_START,
    });
    downtime = moment(startDate, FULL_DATE_FORMAT).diff(
      currSundayStopDateEnd,
      "hours",
      true,
    ); // Remainder off sunday stop
    return { downtime, preDrive };
  }

  // End date is sunday stop time
  if (isSundayStopTime(endDate)) {
    const currSundayStopDateStart = getSundayFromWeekendDateAndTime({
      date: endDate,
      time: SUNDAY_END,
    });
    const currSundayStopDateEnd = getSundayFromWeekendDateAndTime({
      date: endDate,
      time: SUNDAY_START,
    });

    downtime = currSundayStopDateStart.diff(
      currSundayStopDateEnd,
      "hours",
      true,
    ); // Remainder off sunday stop
    preDrive = moment(startDate, FULL_DATE_FORMAT).diff(
      currSundayStopDateStart,
      "hours",
      true,
    ); // Remainder off driving
    return { downtime, preDrive };
  }

  return { downtime, preDrive };
};

// Calculates the time it will take x drivers to cover the distance fz
const calcXDriverDurations = ({
  x,
  loadingDate,
  fz,
  points,
  revert,
}: XDriverDurationArgs) => {
  let remTime = fz; // Remainder required driving time
  let duration = 0;
  let downDuration = 0;

  enum DrivTypes {
    driving,
    waiting,
  }

  // Driving steps array
  const drivingSteps = [
    {
      type: DrivTypes.driving,
      time: FIRST_DRIVER_SHIFT * x,
    },
    {
      type: DrivTypes.waiting,
      time: x > 1 ? 0 : BREAK_AFTER_FIRST_SHIFT,
    },
    {
      type: DrivTypes.driving,
      time: SECOND_DRIVER_SHIFT * x,
    },
    {
      type: DrivTypes.waiting,
      time: SECOND_AFTER_FIRST_SHIFT,
    },
  ];

  const calcDowntimeWrap = ({
    startDuration,
    endDuration,
  }: DowntimeWrapArgs) => {
    if (revert) {
      return calcDowntimeRevert({
        loadingDate,
        startDuration,
        endDuration,
      });
    } else {
      return calcDowntime({
        loadingDate,
        startDuration,
        endDuration,
      });
    }
  };

  let step = 0;
  while (remTime > 0) {
    // If driving step isn't last
    if (step < drivingSteps.length) {
      // Get current step constants
      const { type, time } = drivingSteps[step];
      // If this is drive step
      if (type === DrivTypes.driving) {
        // If the arrival does not happen at the current step
        if (remTime - time >= 0) {
          const { downtime, preDrive } = calcDowntimeWrap({
            startDuration: duration,
            endDuration: duration + time,
          });
          // If there were forced stops
          if (downtime > 0) {
            remTime -= preDrive;
            duration += preDrive + downtime;
            downDuration += downtime;
            // Go to first step
            step = 0;
            continue;
          }
          // If there were no forced stops
          remTime -= time;
          duration += time;
        }
        // If the arrival happen at the current step
        else {
          const { downtime, preDrive } = calcDowntimeWrap({
            startDuration: duration,
            endDuration: duration + remTime,
          });
          // If there were forced stops
          if (downtime > 0) {
            remTime -= preDrive;
            duration += preDrive + downtime;
            downDuration += downtime;
            // Go to first step
            step = 0;
            continue;
          }
          // If there were no forced stops
          duration += remTime;
          break;
        }
      }
      // If this is rest step
      else if (type === DrivTypes.waiting) {
        duration += time;
      }
      // Go to next step
      step++;
    } else {
      // Go to first step
      step = 0;
    }
  }

  return {
    deliveryDuration: Math.round(
      duration +
        LOADING_UNLOADING_TIME +
        points * LOADING_UNLOADING_TIME_FOR_STOPOVER,
    ),
    downDuration,
  };
};

const calcNumberOfNightShifts = (date: string): number => {
  const mData = moment(date, FULL_DATE_FORMAT);
  const startNZ = moment(
    `${mData.format(WITHOUT_TIME_DATE_FORMAT)} ${NIGHT_SHIFT_START}`,
    FULL_DATE_FORMAT,
  );
  const endNZ = moment(
    `${mData.format(WITHOUT_TIME_DATE_FORMAT)} ${NIGHT_SHIFT_END}`,
    FULL_DATE_FORMAT,
  );

  const startH = moment(date, FULL_DATE_FORMAT);
  const endH = moment(date, FULL_DATE_FORMAT).add(
    LOADING_UNLOADING_TIME,
    "hours",
  );
  const isNight =
    !moment(startH).isBetween(endNZ, startNZ, undefined, "[]") ||
    !moment(endH).isBetween(endNZ, startNZ, undefined, "[]");

  return isNight ? 1 : 0;
};

const calcWeekendLoadingUnloadingCost = (date: string) => {
  const fromDay = moment(date, FULL_DATE_FORMAT);
  const toDay = moment(date, FULL_DATE_FORMAT).add(2, "hours");
  let cost = 0;
  if (
    isSun(fromDay.format(FULL_DATE_FORMAT)) ||
    isSun(toDay.format(FULL_DATE_FORMAT))
  ) {
    cost = SUNDAY_LOADING_UNLOADING_COST;
  } else if (
    isSat(fromDay.format(FULL_DATE_FORMAT)) ||
    isSat(toDay.format(FULL_DATE_FORMAT))
  ) {
    cost = SATURDAY_LOADING_UNLOADING_COST;
  }
  return cost;
};

const calcExpressFactorCost = (duration: number): number =>
  SORT_EXPRESS_FACTOR_COST_TABLE.find(({ max }) => duration <= max)?.value || 0;

const calcP = ({ duration, commissions }: PArgs) => {
  const commissionKey =
    SORT_EXPRESS_FACTOR_COST_TABLE.find(({ max }) => duration <= max)
      ?.commissionKey || "commission_normal";
  return 1 - commissions[commissionKey] / 100;
};

const calcDownTimeCost = (duration: number) => {
  const hoursPerDay = 24;
  const workingHoursPerDay = 10;

  // Calculate remaining hours
  const calcRemainHours = (fullRemain: number) => {
    if (fullRemain > 0) {
      if (workingHoursPerDay < fullRemain) {
        return workingHoursPerDay;
      } else {
        return fullRemain;
      }
    } else {
      return 0;
    }
  };

  if (duration > 0) {
    /** Complete division */
    const fullRemain = duration % hoursPerDay;
    const fullDaysNumber = (duration - (duration % hoursPerDay)) / hoursPerDay;
    /** End */
    const remainHours = calcRemainHours(fullRemain);
    const workHoursHours = Math.ceil(
      fullDaysNumber * workingHoursPerDay + remainHours,
    );

    return workHoursHours * DOWNTIME_HOUR_COST;
  } else {
    return 0;
  }
};

const calcVariant = ({
  KM,
  PLZ,
  S,
  commissions,
  LZFK,
  T,
  TE,
  BPM,
  SB,
  SZ,
  loadingDate,
  unloadingDate,
  currentDate,
  rawFT,
  rawBE,
  PT,
  FA,
  deliveryDuration,
  downDuration,
  isMock,
  boatCost,
  railCost,
}: VariantArgs): DocumentCalcObject => {
  const loadingDateMoment = moment(loadingDate, FULL_DATE_FORMAT);
  const unloadingDateMoment = moment(unloadingDate, FULL_DATE_FORMAT);

  const loadingDuration =
    loadingDateMoment.diff(moment(currentDate, FULL_DATE_FORMAT), "minutes") /
    60;
  const duration = unloadingDateMoment.diff(loadingDateMoment, "minutes") / 60;

  const KP = KM * PLZ * LZFK; // Base price width zip code factor only
  const KPS = S ? roundTo10(KP * S) : 0; // Base price with season and zip code factors
  const KPSTAkt = KPS * T;
  const KPSTZuk = KPS * TE;
  const KPST = loadingDuration > 24 ? KPSTZuk : KPSTAkt; // Base price with market and season and zip code factors
  const FT = calcVehicleTypePrice(rawFT, KPS); // Transport type surcharge
  const STC = calcDownTimeCost(duration - deliveryDuration + downDuration); // Downtime surcharge

  const BPMf = 1 + BPM / 100; // Base price multiplier factor
  const prepBP = roundTo10(KPST) + SB; // Base price without transport type surcharge and downtime surcharge
  const BP = S ? roundTo10(prepBP * BPMf) + FT + STC : 0; // Base price with transport type surcharge and downtime surcharge

  const { arrBE, BE } = calcSpecialFeaturesCost(rawBE, KPS); // BE summary price

  const NZ =
    calcNumberOfNightShifts(loadingDateMoment.format(FULL_DATE_FORMAT)) +
    calcNumberOfNightShifts(unloadingDateMoment.format(FULL_DATE_FORMAT)); // Number of night loading/uploading
  const BLT = calcWeekendLoadingUnloadingCost(
    loadingDateMoment.format(FULL_DATE_FORMAT),
  ); // Loading for day off surcharge
  const ELT = calcWeekendLoadingUnloadingCost(
    unloadingDateMoment.format(FULL_DATE_FORMAT),
  ); // Uploading for day off surcharge
  const VZ = Math.round(calcExpressFactorCost(loadingDuration) * KPS); // Surcharge for urgency

  const withoutFerryGP = roundTo10(
    BP +
      BE +
      PT +
      (FA - 1) * SUPPLEMENT_FOR_ADDITIONAL_DRIVER +
      NZ * SUPPLEMENT_FOR_NIGHT_ON_THE_WAY +
      BLT +
      ELT +
      VZ,
  );
  const GP = roundTo10(withoutFerryGP + boatCost + railCost);

  const P = calcP({ duration: loadingDuration, commissions });
  const PFS = roundTo10(withoutFerryGP * P + boatCost + railCost); // Price for the carrier

  // Function intended for calculations with incomplete data
  const mockZero = (value: number) => (isMock ? 0 : value);
  return {
    distance: KM, // Distance from web app
    duration: SZ - LOADING_UNLOADING_TIME, // Route duration
    drivers: mockZero(FA), // Number of drivers
    expCost: mockZero(VZ), // Express factor surcharge
    arrBE, // BE surcharges
    bltCost: mockZero(BLT), // Loading for day off surcharge
    eltCost: mockZero(ELT), // Uploading for day off surcharge
    ptCost: PT, // Pallet type surcharge
    faCost: mockZero((FA - 1) * SUPPLEMENT_FOR_ADDITIONAL_DRIVER), // Second driver surcharge
    nzLoadCost: mockZero(
      calcNumberOfNightShifts(loadingDateMoment.format(FULL_DATE_FORMAT)) *
        SUPPLEMENT_FOR_NIGHT_ON_THE_WAY,
    ), // Night loading surcharge
    nzUpCost: mockZero(
      calcNumberOfNightShifts(unloadingDateMoment.format(FULL_DATE_FORMAT)) *
        SUPPLEMENT_FOR_NIGHT_ON_THE_WAY,
    ), // Night uploading surcharge
    boatCost,
    railCost,
    basePrice: BP,
    totalPrice: mockZero(GP),
    transporterPrice: mockZero(PFS),
    optimalLoadingTime: loadingDateMoment.format(FULL_DATE_FORMAT),
  };
};

// calculates the most profitable route possible within the proposed time intervals
export const calcDocumentDate = ({
  dist,
  PLZ,
  S,
  commissions,
  LZFK,
  T,
  TE,
  BPM,
  points,
  fDPoints,
  loadingDateStart,
  loadingDateEnd,
  unloadingDateStart,
  unloadingDateEnd,
  currentDate,
  dateFormat,
  rawFT,
  rawBE,
  PT,
  boatCost,
  railCost,
}: DocumentDateArgs): DocumentCalcObject => {
  // Calc variables to all routes
  const KM = roundTo10(
    dist > MIN_CALC_DISTANCE_OF_KM ? dist : MIN_CALC_DISTANCE_OF_KM,
  ); // Dist
  const FZ = KM / AVERAGE_SPEED_EUROPE; // Base driving time
  const SB = fDPoints * FORWARDER_LOADING_PRICE + (points + 2) * STOPOVER_PRICE; // Forwarder loading point and stopover price
  const SZ =
    FZ +
    ADDITIONAL_WORKING_TIME_FOR_LOADING_UNLOADING +
    LOADING_UNLOADING_TIME_FOR_STOPOVER * points; // Time required for the route

  // Document calc function wrapper
  const variantWrapper = ({
    start,
    end,
    FA,
    deliveryDuration,
    downDuration,
    isMock,
  }: VariantWrapperArgs) => {
    const startMoment = moment(start, FULL_DATE_FORMAT);
    const startDur =
      startMoment.diff(moment(currentDate, FULL_DATE_FORMAT), "minutes") / 60;
    const { market, season } = calcCalendarFactors({ start, end, s: S });
    const msFactor =
      startDur > MIN_START_DELAY_TO_USE_MARKET_FACTOR_OF_HOURS
        ? market
        : season;
    return calcVariant({
      KM,
      PLZ,
      S: msFactor,
      commissions,
      LZFK,
      T,
      TE,
      BPM,
      SB,
      SZ,
      loadingDate: start,
      unloadingDate: end,
      currentDate,
      rawFT,
      rawBE,
      PT,
      FA,
      deliveryDuration,
      downDuration,
      isMock,
      boatCost,
      railCost,
    });
  };

  // Initial start/end interval arrays
  const startTimes: Time[] = [loadingDateStart, loadingDateEnd];
  const endTimes: Time[] = [unloadingDateStart, unloadingDateEnd];

  let minDuration = 0;

  const startTimesFinally: StartTimesFinally[] = [];

  const faArray: FaVariants[] = [FaVariants.oneDriver, FaVariants.twoDrivers];

  // Enumeration of end dates
  endTimes.forEach((end?: string) => {
    if (end && loadingDateStart && loadingDateEnd) {
      const endMoment = moment(end, dateFormat);
      const mLoadingDateStart = moment(loadingDateStart, dateFormat);
      const mLoadingDateEnd = moment(loadingDateEnd, dateFormat);
      const firstMoment = moment(loadingDateStart, dateFormat);
      const duration = endMoment.diff(firstMoment, "minutes") / 60;
      // Enumeration drivers count
      faArray.forEach((fa: FaVariants) => {
        // revert deliveryDuration and downDuration for current endMoment find
        const { deliveryDuration, downDuration } = calcXDriverDurations({
          x: fa,
          loadingDate: endMoment.format(FULL_DATE_FORMAT),
          fz: FZ,
          points,
          revert: true,
        });

        if (deliveryDuration <= duration) {
          // Find new start time
          const startMoment = moment(end, dateFormat).subtract(
            deliveryDuration,
            "hours",
          );
          // Pushed new start time if it is valid
          if (startMoment.isBetween(mLoadingDateStart, mLoadingDateEnd)) {
            startTimesFinally.push({
              start: startMoment.format(dateFormat),
              fa,
              deliveryDuration,
              downDuration,
            });
          }
        }
      });
    }
  });

  // Enumeration of start dates
  startTimes.forEach((start: string | undefined) => {
    if (start && (unloadingDateStart || unloadingDateEnd)) {
      const startMoment = moment(start, dateFormat);
      const lastMoment = moment(
        unloadingDateEnd || unloadingDateStart,
        dateFormat,
      );
      const duration = lastMoment.diff(startMoment, "minutes") / 60;
      // Enumeration drivers count
      faArray.forEach((fa: FaVariants) => {
        // deliveryDuration and downDuration for current startMoment find
        const { deliveryDuration, downDuration } = calcXDriverDurations({
          x: fa,
          loadingDate: startMoment.format(FULL_DATE_FORMAT),
          fz: FZ,
          points,
        });

        // find min duration
        if (minDuration === 0 || deliveryDuration < minDuration) {
          minDuration = deliveryDuration;
        }

        // if route is valid
        if (deliveryDuration <= duration) {
          // Pushed current startFinally object
          startTimesFinally.push({
            start,
            fa,
            deliveryDuration,
            downDuration,
          });
          // if discharge date is flexible
          if (unloadingDateStart && unloadingDateEnd) {
            const uploadTimeStartMoment = moment(
              unloadingDateStart,
              dateFormat,
            );
            const uploadTimeEndMoment = moment(unloadingDateEnd, dateFormat);
            // Find new end time
            const endMoment = moment(start, dateFormat).add(
              deliveryDuration,
              "hours",
            );
            // Pushed new end time if it is valid
            if (
              endMoment.isBetween(uploadTimeStartMoment, uploadTimeEndMoment)
            ) {
              endTimes.push(endMoment.format(dateFormat));
            }
          }
        }
      });
    }
  });

  // Fake value for preliminary calculations
  let minVariant = variantWrapper({
    FA: FaVariants.oneDriver,
    deliveryDuration: 0,
    downDuration: 0,
    isMock: true,
  });

  // Enumeration of all possible dates
  startTimesFinally.forEach(
    ({ start, fa, deliveryDuration, downDuration }, index) => {
      if (index === 0 || deliveryDuration < minDuration) {
        minDuration = deliveryDuration;
      }
      endTimes.forEach((end?: string) => {
        if (start && end) {
          const currVariant = variantWrapper({
            start: moment(start, dateFormat).format(FULL_DATE_FORMAT),
            end: moment(end, dateFormat).format(FULL_DATE_FORMAT),
            FA: fa,
            deliveryDuration,
            downDuration,
          });

          // Choosing the option with the lowest non-zero cost
          if (minVariant.totalPrice) {
            if (
              currVariant.totalPrice &&
              currVariant.totalPrice < minVariant.totalPrice
            ) {
              minVariant = currVariant;
            }
          } else {
            minVariant = currVariant;
          }
        }
      });
    },
  );

  return { ...minVariant, minDuration };
};

export const expFunc = {
  roundTo10,
  isSat,
  isSun,
  getFullDateFromTime,
  getSundayFromWeekendDateAndTime,
  getSaturdayFromWeekendDateAndTime,
  isBetweenTime,
  isSundayStopTime,
  calcCalendarFactors,
  calcVehicleTypePrice,
  calcSpecialFeaturesCost,
  isHolydayStopTime,
  calcDowntime,
  calcDowntimeRevert,
  calcXDriverDurations,
  calcNumberOfNightShifts,
  calcWeekendLoadingUnloadingCost,
  calcExpressFactorCost,
  calcP,
  calcDownTimeCost,
  calcVariant,
};

export const expConst = {
  // Business logic constants
  MIN_CALC_DISTANCE_OF_KM,
  AVERAGE_SPEED_EUROPE,
  FORWARDER_LOADING_PRICE,
  STOPOVER_PRICE,
  LOADING_UNLOADING_TIME,
  ADDITIONAL_WORKING_TIME_FOR_LOADING_UNLOADING,
  LOADING_UNLOADING_TIME_FOR_STOPOVER,
  FIRST_DRIVER_SHIFT,
  SECOND_DRIVER_SHIFT,
  BREAK_AFTER_FIRST_SHIFT,
  SECOND_AFTER_FIRST_SHIFT,
  SUPPLEMENT_FOR_ADDITIONAL_DRIVER,
  SUPPLEMENT_FOR_NIGHT_ON_THE_WAY,
  DOWNTIME_HOUR_COST,
  SUNDAY_LOADING_UNLOADING_COST,
  SATURDAY_LOADING_UNLOADING_COST,
  MIN_START_DELAY_TO_USE_MARKET_FACTOR_OF_HOURS,
  SORT_EXPRESS_FACTOR_COST_TABLE,
  // Moment js format constants
  FULL_DATE_FORMAT,
  WITHOUT_TIME_DATE_FORMAT,
  TIME_FORMAT,
  // Time constants
  NIGHT_SHIFT_START,
  NIGHT_SHIFT_END,
  HOLIDAY_START,
  HOLIDAY_END,
  HOLYDAY_SATURDAY_START,
  HOLYDAY_SATURDAY_END,
  SUNDAY_START,
  SUNDAY_END,
  SUNDAY_NUMBER_MOMENT_JS,
  SATURDAY_NUMBER_MOMENT_JS,
};
