<template>
  <div class="mt-1">
    <div class="d-flex justify-space-between">
      <div class="d-inline-flex">
        <v-tooltip
          v-if="isBplAdmin"
          text="Lorem Ipsum"
          location="bottom"
          offset="-32px"
        >
          <template #activator="{ props: tooltipProps }">
            <div v-bind="tooltipProps" class="mr-6">
              <v-checkbox
                :model-value="documentObject.manual"
                :label="$t('manual-offer-creation')"
                color="primary"
                class="bpl-underlined-field"
                :disabled="props.readonly"
                @update:model-value="vModalUpdate('manual', $event)"
              ></v-checkbox>
            </div>
          </template>
        </v-tooltip>
        <v-tooltip
          :text="$t('tooltip-message.vignette')"
          location="bottom"
          offset="-32px"
        >
          <template #activator="{ props: tooltipProps }">
            <div v-bind="tooltipProps" class="mr-6">
              <v-checkbox
                :model-value="documentObject.vignette"
                :label="$t('vignette-avoidance')"
                color="primary"
                class="bpl-underlined-field"
                :disabled="isVignetteDisabled || props.readonly"
                @update:model-value="vModalUpdate('vignette', $event)"
              ></v-checkbox>
            </div>
          </template>
        </v-tooltip>
        <v-tooltip
          :text="$t('tooltip-message.optimale')"
          location="bottom"
          offset="-32px"
        >
          <template #activator="{ props: tooltipProps }">
            <div v-bind="tooltipProps">
              <v-checkbox
                :model-value="documentObject.optimal_route"
                :label="$t('optimal-route')"
                color="primary"
                class="bpl-underlined-field"
                :disabled="props.readonly"
                @update:model-value="vModalUpdate('optimal_route', $event)"
              ></v-checkbox></div></template
        ></v-tooltip>
      </div>
      <div class="w-25">
        <v-text-field
          :model-value="documentObject.document_count"
          :error-messages="errors.document_count"
          :rules="[rules.required, rules.numberOnly, rules.range(1, 15)]"
          :label="$t('number-trucks')"
          append-inner-icon="mdi-truck"
          :readonly="props.readonly"
          :variant="props.readonly ? 'filled' : 'outlined'"
          class="pb-2"
          @update:model-value="vModalUpdate('document_count', $event)"
        ></v-text-field>
      </div>
    </div>
    <route-station
      :model-value="documentObject.firstStationData"
      :index="0"
      :vignette="documentObject.vignette"
      :zip-error-messages="zipErrorsObject"
      default
      :readonly="props.readonly"
      @update:model-value="vModalUpdate('firstStationData', $event)"
    />
    <draggable-list
      :model-value="documentObject.stationsList"
      :index="1"
      :vignette-avoidance="documentObject.vignette"
      :zip-error-messages="zipErrorsObject"
      :readonly="props.readonly"
      @update:model-value="vModalUpdate('stationsList', $event)"
    />
    <route-station
      :model-value="documentObject.lastStationData"
      :index="documentObject.stationsList.length + 1"
      :vignette="documentObject.vignette"
      :zip-error-messages="zipErrorsObject"
      default
      :readonly="props.readonly"
      @update:model-value="vModalUpdate('lastStationData', $event)"
    />
    <div v-if="!props.readonly" class="pt-2 pb-8 bpl-action-wrap">
      <v-btn
        type="button"
        size="large"
        color="primary"
        variant="outlined"
        prepend-icon="mdi-plus"
        :disabled="documentObject.stationsList.length + 2 >= maxStations"
        @click="addStation"
        >{{ $t("button.add-charge-point") }} ({{
          documentObject.stationsList.length + 2
        }}/{{ maxStations }})</v-btn
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted } from "vue";
import { useStore } from "@/store";

import { chCode, useRules } from "@/utils/rules";
import {
  getLocalDocumentObject,
  getInitialDocumentErrorObject,
} from "@/utils/calculator";
import { access } from "@/utils/access";

import { StationObject } from "@/types/calculator";
import { DocumentErrorObject, DocumentObject } from "@/types/document";

import DraggableList from "@/components/CalculatorView/CalcFirst/DraggableList.vue";
import RouteStation from "@/components/CalculatorView/CalcFirst/RouteStation.vue";

const rules = useRules();
const store = useStore();

const props = defineProps<{
  modelValue?: DocumentObject;
  readonly?: boolean;
  errors?: DocumentErrorObject;
  errorsRaw: { [K: string]: string[] };
}>();

const emit = defineEmits<{
  (e: "update:modelValue", data?: DocumentObject): void;
  (e: "update:errors", data: DocumentErrorObject): void;
}>();

const documentObject = computed(
  () => props.modelValue || getLocalDocumentObject(),
);

const errors = computed<DocumentErrorObject>({
  get() {
    return props.errors || getInitialDocumentErrorObject();
  },
  set(value?: DocumentErrorObject): void {
    emit("update:errors", value || getInitialDocumentErrorObject());
  },
});

const maxStations = 8;

const fullStationsList = computed<StationObject[]>(() => [
  documentObject.value.firstStationData,
  ...documentObject.value.stationsList,
  documentObject.value.lastStationData,
]);

const isVignetteDisabled = computed(() => {
  return (
    !documentObject.value.vignette &&
    fullStationsList.value.some(
      ({ country_id }) =>
        store.getters["app/getCountryById"](country_id)?.code.toUpperCase() ===
        chCode,
    )
  );
});

const zipErrorsObject = computed(() => {
  const errorsEntriesArray: [string, string[]][] = [];

  Object.entries(props.errorsRaw).forEach(([key, value]) => {
    fullStationsList.value.forEach(({ zip }, index) => {
      if (zip === key) {
        errorsEntriesArray.push([String(index), value]);
      }
    });
  });

  return Object.fromEntries(errorsEntriesArray);
});

const isBplAdmin = computed(() =>
  access.someRoles([
    "admin",
    "sub-admin",
    "bpl-manager",
    "bpl-calculation-manager",
  ]),
);

const addStation = () => {
  vModalUpdate("stationsList", [
    ...documentObject.value.stationsList,
    {
      id: Symbol(),
      type: null,
      country_id: null,
      zip: null,
      discharged: false,
    },
  ]);
  nextTick(() => {
    window.scroll({ top: window.scrollY + 120, behavior: "smooth" });
  });
};

const vModalUpdate = (field: keyof DocumentObject, value: any) => {
  errors.value[field as keyof DocumentErrorObject] = [];
  emit("update:modelValue", {
    ...documentObject.value,
    [field]: value,
  });
};

onMounted(() => {
  if (isBplAdmin.value && documentObject.value.manual === undefined) {
    vModalUpdate("manual", true);
  }
});
</script>

<style scoped></style>
