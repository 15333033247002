<template>
  <div
    class="position-relative bpl-calendar-wrap"
    :class="{ 'bpl-calendar-readonly': props.readonly }"
  >
    <v-combobox
      ref="field"
      :model-value="title"
      :error-messages="props.errorMessages || []"
      :label="props.title || ''"
      variant="outlined"
      :prepend-inner-icon="!range ? ('mdi-calendar' as any) : undefined"
      :append-inner-icon="range ? ('mdi-calendar' as any) : undefined"
      readonly
      multiple
      :rules="props.rules || []"
      :loading="props.loading"
      :bg-color="props.bgColor"
    >
      <template #chip="data">
        <v-chip size="default">
          {{ data.item.raw }}
        </v-chip>
      </template>
      <template v-if="props.clearable" #clear>
        <v-icon
          icon="mdi-close-circle"
          @click="currentData = undefined"
        ></v-icon>
      </template>
    </v-combobox>
    <template v-if="showCalendar">
      <date-picker
        v-if="!props.range"
        ref="calendar"
        v-model.number="currentData"
        class="bpl-calendar position-absolute elevation-5"
        :class="{
          'bpl-calendar-hidden-time': hiddenTime,
          'bpl-calendar-top': isTop,
        }"
        mode="dateTime"
        :locale="locale"
        is24hr
        :min-date="min"
        :max-date="max"
        :disabled-dates="props.loading ? [{}] : undefined"
        :timezone="timezone"
        color="primary"
      >
        <template #header-prev-button>
          <div>
            <v-icon icon="mdi-chevron-left" />
          </div>
        </template>
        <template #header-next-button>
          <div>
            <v-icon icon="mdi-chevron-right" />
          </div>
        </template>
      </date-picker>
      <date-picker
        v-else
        ref="calendar"
        v-model.range.number="currentData"
        class="bpl-calendar position-absolute elevation-5"
        :class="{ 'bpl-calendar-hidden-time': hiddenTime }"
        mode="dateTime"
        :locale="locale"
        is24hr
        :min-date="min"
        :max-date="max"
        :disabled-dates="props.loading ? [{}] : undefined"
        :timezone="timezone"
        color="primary"
      >
        <template #header-prev-button>
          <div>
            <v-icon icon="mdi-chevron-left" />
          </div>
        </template>
        <template #header-next-button>
          <div>
            <v-icon icon="mdi-chevron-right" />
          </div>
        </template>
      </date-picker>
    </template>
    <v-icon
      v-if="props.info"
      icon="mdi-information"
      class="bpl-calendar-info position-absolute"
      color="primary"
    ></v-icon>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, onBeforeUnmount, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import moment from "moment";
import { DatePicker } from "v-calendar";
import { backFormats, useLocFormat } from "@/utils/locFormat";

import { DateValue, RangeValue } from "@/types/other";

const { locale } = useI18n();

const locFormat = useLocFormat();

const timezone = "CET";

const props = defineProps<{
  modelValue?: DateValue | RangeValue;
  title?: string;
  rules?: any[];
  info?: boolean;
  min?: string;
  max?: string;
  readonly?: boolean;
  loading?: boolean;
  errorMessages?: string[];
  range?: boolean;
  clearable?: boolean;
  bgColor?: string;
  hiddenTime?: boolean;
  chipFormat?: string;
}>();

const maxCalendarHeight = 340;

const showCalendar = ref(false);

const isTop = ref(false);

const calendar = ref<any | null>(null);
const field = ref<any | null>(null);

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "update:modelValue", data?: DateValue | RangeValue): void;
}>();

const convertToUnix = (date?: DateValue) => moment(date).unix() * 1000;

const makeFormat = (v?: DateValue) =>
  moment.unix(Number(v) / 1000).format(backFormats.yMmDdHhMmSs);

const currentData = computed({
  get() {
    let unixDate: DateValue | RangeValue | undefined;
    if (props.modelValue) {
      if (!props.range) unixDate = convertToUnix(props.modelValue as DateValue);
      else {
        const { start, end } = props.modelValue as RangeValue;
        unixDate = { start: convertToUnix(start), end: convertToUnix(end) };
      }
    }
    return unixDate;
  },
  set(value?: DateValue | RangeValue): void {
    let formatDate: DateValue | RangeValue | undefined;
    if (value) {
      if (!props.range) formatDate = makeFormat(value as DateValue);
      else {
        const { start, end } = value as RangeValue;
        formatDate = {
          start: makeFormat(start),
          end: makeFormat(end),
        };
      }
    }
    emit("update:modelValue", formatDate);
  },
});

const min = computed(() => (props?.min ? convertToUnix(props.min) : null));
const max = computed(() => (props?.max ? convertToUnix(props.max) : null));

const mouseDownHandler = (e: any) => {
  const inputEl =
    field.value?.$el?.getElementsByClassName("v-input__control")[0];
  const calendarEl = calendar.value?.$el?.nextSibling;

  const clickOutside = !(
    calendarEl === e.target ||
    calendarEl?.contains(e.target) ||
    inputEl === e.target ||
    inputEl?.contains(e.target)
  );
  if (clickOutside || !props.readonly) showCalendar.value = !clickOutside;
};

watch(
  () => props.readonly,
  (value) => {
    if (!value) showCalendar.value = false;
  },
);

watch(showCalendar, (value) => {
  if (value && field.value) {
    const { top } = field.value.getBoundingClientRect();
    //move to top is calendar is too low
    isTop.value =
      top + window.scrollY + 68 + maxCalendarHeight >
      document.body.offsetHeight;
  }
});

const chipFormat = computed(
  () => props.chipFormat || locFormat.value.dDMmYHhMmUhr,
);
const formatTitle = (t?: DateValue) =>
  moment.unix(Number(t) / 1000).format(chipFormat.value);

const title = computed(() => {
  if (currentData.value === undefined) return undefined;
  else if (!props.range) return [formatTitle(currentData.value as DateValue)];
  else {
    const { start, end } = currentData.value as RangeValue;
    return [`${formatTitle(start)} – ${formatTitle(end)}`];
  }
});

onMounted(() => {
  document.addEventListener("mousedown", mouseDownHandler);
});

onBeforeUnmount(() => {
  document.removeEventListener("mousedown", mouseDownHandler);
});
</script>

<style>
.bpl-calendar {
  top: 68px;
  left: 0;
  z-index: 10;
  font-family: Roboto, sans-serif !important;
}

.bpl-calendar-top {
  top: auto;
  bottom: calc(100% + 12px);
}

.bpl-calendar-hidden-time .vc-time-picker {
  display: none;
}

.bpl-calendar-wrap input,
.bpl-calendar-wrap .v-combobox__selection {
  height: 32px;
}
.bpl-calendar-wrap .v-field-label {
  height: 32px;
  top: 12px;
}
.bpl-calendar-wrap .v-field__input {
  padding: 12px 6px;
  flex-wrap: nowrap;
}

.bpl-calendar-readonly {
  pointer-events: none;
}

.bpl-calendar-info {
  left: calc(100% + 16px);
  top: 16px;
}

.bpl-calendar .vc-day-content.vc-primary:active,
.bpl-calendar button.vc-arrow.vc-primary:active,
.bpl-calendar select.vc-primary:active,
.bpl-calendar .vc-day-content.vc-primary:focus {
  box-shadow: 0 0 0 2px rgba(25, 130, 212, 0.4) !important;
}
.bpl-calendar .vc-day-content.vc-highlight-content-solid.vc-primary {
  background-color: #1982d4 !important;
}
.bpl-calendar .vc-primary .vc-highlight-bg-light {
  background-color: rgba(25, 130, 212, 0.4) !important;
}
.bpl-calendar .vc-primary .vc-highlight.vc-highlight-bg-outline {
  border-color: #1982d4 !important;
}
.bpl-calendar .vc-time-month,
.bpl-calendar .vc-time-day {
  color: #1982d4 !important;
}
.bpl-calendar .vc-base-icon.vc-primary {
  stroke: #1982d4;
}
.bpl-calendar .vc-title {
  pointer-events: none !important;
}
</style>
