import { CountryObjectRaw } from "@/types/other";
import { PaymentTargetType, SalutationType } from "@/types/calculator";
import { ClientStatusType, TransporterStatusType } from "@/types/document";
import { DepartmentType, ProfileData, Languages } from "@/types/auth";
import { RoleType } from "@/types/roles";

export enum CompanyType {
  logistic = "logistic",
  industry = "industry",
  contractor = "contractor",
}

export enum CompanyQueryType {
  customers = "customers",
  carrier = "carrier",
}

export type CompanyAddressData = {
  city: string;
  id: string;
  street: string;
  zip: string;
  country: { data: CountryObjectRaw };
};

export type CompanyData = {
  id: string;
  name: string;
  addendum: string;
  debitor_number: string | null;
  creditor_number: string | null;
  bank_code: string | null;
  bank_name: string | null;
  iban: string | null;
  payment_target: PaymentTargetType;
  swift: string | null;
  tax_id: string;
  type: CompanyType;
  users: { data: ProfileData[] };
  billing_address: { data: CompanyAddressData } | null;
  headquarters_address: { data: CompanyAddressData };
  contact: { data: ProfileData } | null;
  vat_id: string;
  status: ClientStatusType | TransporterStatusType;
  number: number;
};

export type NewCompanyUserObject = {
  firstname?: string;
  lastname?: string;
  salutation?: SalutationType;
  designation?: string;
  department?: DepartmentType;
  department_miscellaneous?: string;
  languages?: Languages[];
  email?: string;
  password?: string;
  password_confirmation?: string;
  phone?: string;
  role?: RoleType;
  locale?: string;
  voucher_recipient?: boolean;
};

export type NewCompanyUserErrorObject = {
  firstname: string[];
  lastname: string[];
  salutation: string[];
  designation: string[];
  department: string[];
  department_miscellaneous: string[];
  languages: string[];
  email: string[];
  password: string[];
  password_confirmation: string[];
  phone: string[];
  role: string[];
};

export type EditCompanyObject = {
  type?: CompanyType;
  name?: string;
  addendum?: string;
  headquarters_address?: {
    country_id?: string;
    city?: string;
    zip?: string;
    street?: string;
  };
  billing_address?: {
    country_id?: string;
    city?: string;
    zip?: string;
    street?: string;
  };

  contact?: {
    salutation?: SalutationType;
    firstname?: string;
    lastname?: string;
    phone?: string;
    email?: string;
    password?: string;
  };

  vat_id?: string;
  tax_id?: string;
  payment_target?: PaymentTargetType;
  iban?: string;
  swift?: string;
  bank_name?: string;
  bank_code?: string;
};

export type EditCompanyErrorObject = {
  name: string[];
  "headquarters_address.city": string[];
  "headquarters_address.street": string[];
  "headquarters_address.zip": string[];

  "contact.firstname": string[];
  "contact.lastname": string[];
  "contact.phone": string[];

  tax_id: string[];
  payment_target: string[];
  vat_id: string[];

  bank_code: string[];
  bank_name: string[];
  iban: string[];
  swift: string[];

  "billing_address.city": string[];
  "billing_address.street": string[];
  "billing_address.zip": string[];

  password: string[];
  email: string[];
};

export enum CompanyBlockType {
  company,
  headquarters,
  contact,
  tax,
  bank,
  address,
  password,
  email,
}
