import { nextTick, ref, watch } from "vue";

export const useNumberHelper = (
  maxDecimals = 3,
  min?: number,
  max?: number,
) => {
  const amount = ref("");
  const oldAmount = ref(amount.value);

  watch(amount, (value) => {
    const v = value?.replaceAll(",", ".");
    const after = v?.split(".")[1];
    if (
      value?.length &&
      !(
        !isNaN(Number(v /*v[0] !== "-" ? v : v.slice(1)*/)) &&
        (maxDecimals > 0 || !v.includes(".")) &&
        !v.includes("e") &&
        !v.includes(" ") &&
        (!after || after.length <= maxDecimals) &&
        (!min || Number(v) >= min) &&
        (!max || Number(v) < max)
      )
    )
      nextTick(() => {
        amount.value = oldAmount.value;
      });
    else {
      amount.value = v;
      oldAmount.value = v;
    }
  });
  return amount;
};
