import { computed } from "vue";
import { useI18n } from "vue-i18n";

type LocFormat = {
  yMmDd: string;
  yMmDdHhMmSs: string;
  yMmDdHhMmWithDots: string;
  dDMmYHhMmUhr: string;
  dDMmYHhMm: string;
  dDMmY: string;
  yMm: string;
  dDMmYWithDots: string;
  yMmmm: string;
};

export const backFormats = {
  yMmDd: "Y-MM-DD",
  yMmDdHhMmSs: "Y-MM-DD HH:mm:ss",
  yMmDdStart: "Y-MM-DD-00-00",
  yMmDdEnd: "Y-MM-DD-23-59",
  yMm: "Y-MM",
};

const formatsEn: LocFormat = {
  yMmDd: "MM-DD-Y",
  yMmDdHhMmSs: "MM-DD-Y HH:mm:ss",
  yMmDdHhMmWithDots: "MM.DD.Y HH:mm",
  dDMmYHhMmUhr: "MM DD Y · HH:mm",
  dDMmYHhMm: "MM DD Y · HH:mm",
  dDMmY: "MM DD Y",
  yMm: "MM-Y",
  dDMmYWithDots: "MM.DD.Y",
  yMmmm: "MMMM Y",
};

const formatsDe: LocFormat = {
  yMmDd: "DD-MM-Y",
  yMmDdHhMmSs: "DD-MM-Y HH:mm:ss",
  yMmDdHhMmWithDots: "DD.MM.Y HH:mm",
  dDMmYHhMmUhr: "DD MM Y · HH:mm [Uhr]",
  dDMmYHhMm: "DD MM Y · HH:mm",
  dDMmY: "DD MM Y",
  yMm: "MM-Y",
  dDMmYWithDots: "DD.MM.Y",
  yMmmm: "MMMM Y",
};

export const useLocFormat = () => {
  const { locale } = useI18n();

  return computed(() => {
    const isDe = locale.value === "de";
    return !isDe ? formatsEn : formatsDe;
  });
};
