<template>
  <v-row no-gutters>
    <v-col cols="12" class="pa-10">
      <v-sheet class="py-12 px-10 rounded-xl bpl-border-gray">
        <div>
          <v-btn @click="testAsyncModal()">Test Base Modal</v-btn>
        </div>
        <async-modal :id="asyncData.id" v-slot="{ resolve, cancel, autofocus }">
          <v-card class="px-10 py-12" max-width="660">
            <p class="text-h4 font-weight-light text-primary text-center">
              {{ $t("text.yes") }}
            </p>
            <v-btn
              :ref="autofocus"
              variant="outlined"
              color="primary"
              size="large"
              class="mt-10"
              @click="resolve(TestResolveType.confirm)"
            >
              {{ $t("text.yes") }}
            </v-btn>
            <v-btn
              variant="outlined"
              color="primary"
              size="large"
              class="mt-4 bg-light-blue-lighten-5"
              @click="resolve(TestResolveType.test)"
            >
              test
            </v-btn>
            <v-btn
              variant="outlined"
              color="primary"
              size="large"
              class="mt-4 bg-light-blue-lighten-5"
              @click="cancel()"
            >
              {{ $t("text.no") }}
            </v-btn>
          </v-card>
        </async-modal>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { useAsyncModal } from "@/utils/asyncModalHelper";

import AsyncModal from "@/components/AsyncModal.vue";

enum TestResolveType {
  cancel,
  confirm,
  test,
}

const asyncData = useAsyncModal<TestResolveType>();

const testAsyncModal = async () => {
  const res = await asyncData.callback();
  switch (res) {
    case TestResolveType.confirm:
      console.log("confirm");
      break;
    case TestResolveType.test:
      console.log("test");
      break;
    case TestResolveType.cancel:
      console.log("cancel");
      break;
    default:
      console.log("default");
  }
};
</script>
