<template>
  <p
    class="text-subtitle-1 px-2 pt-2 pb-1"
    :class="{
      'cursor-pointer': !props.disabled,
      'text-primary': !props.disabled,
      'bpl-show-more-disabled': props.disabled,
    }"
    @click="emit('click')"
  >
    {{ $t("show-more") }}
  </p>
</template>

<script setup lang="ts">
const props = defineProps<{
  disabled?: boolean;
}>();

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "click"): void;
}>();
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.bpl-show-more-disabled {
  opacity: 0.38;
  cursor: default;
  pointer-events: none;
}
</style>
