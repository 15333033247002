import { FeatureCostObject } from "@/types/settings";
import { PointObject } from "@/types/document";

export enum PaymentTargetType {
  days7 = 1,
  days8to14,
  days15to21,
}

export enum SalutationType {
  mr = 1,
  mrs,
  divers,
}

export type StationObject = PointObject & {
  id: symbol;
};

export type PrepareResponseObject = {
  distance: number;
  ferries: {
    boat: number;
    rail: number;
    price_on_request: boolean;
  };
  lzf: {
    client_factor: number;
  };
  market_factor: number;
  plz: number;
  points_ids: string[];
};

export type DocumentCalcObject = {
  distance: number;
  duration: number;
  drivers: number;
  expCost: number;
  arrBE: FeatureCostObject[];
  bltCost: number;
  eltCost: number;
  ptCost: number;
  faCost: number;
  nzLoadCost: number;
  nzUpCost: number;
  boatCost: number;
  railCost: number;
  basePrice: number;
  totalPrice: number;
  transporterPrice: number;
  minDuration?: number;
  optimalLoadingTime: string;
  priceOnRequestOverpriced?: boolean;
  priceOnRequestOverweight?: boolean;
  priceOnRequestOversize?: boolean;
  priceOnRequestFerry?: boolean;
  priceOnRequestTime?: boolean;
};

export enum CalcBlockType {
  arrivalTime,
  routeData,
  transport,
  goods,
  features,
  other,
  company,
}

export type EditCarrierObject = {
  carrier_id?: string;
  carrier_contact_id?: string;
};

export type EditAssignmentObject = {
  reference_number?: string;
};

export type EditDatesObject = {
  loading_time_start?: string;
  loading_time_end?: string;
  unloading_time_start?: string;
  unloading_time_end?: string;
};

export type EditTotalPriceObject = {
  total_price?: number;
};

export type EditTransporterPriceObject = {
  transporter_price?: number;
};

export type PriceOnRequest = {
  overpriced: boolean;
  overweight: boolean;
  oversize: boolean;
  features: boolean;
};
