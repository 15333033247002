<template>
  <div class="d-flex justify-center align-center my-12">
    <router-link
      to="/imprint"
      target="_blank"
      class="text-body-2 text-medium-emphasis text-decoration-none"
    >
      {{ $t("imprint") }}
    </router-link>
    <router-link
      to="/privacy-policy"
      target="_blank"
      class="ml-6 text-body-2 text-medium-emphasis text-decoration-none"
    >
      {{ $t("privacy-policy") }}
    </router-link>
    <router-link
      to="/terms"
      target="_blank"
      class="ml-6 text-body-2 text-medium-emphasis text-decoration-none"
    >
      {{ $t("terms-conditions") }}
    </router-link>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
