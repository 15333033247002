<template>
  <v-form ref="form" v-model="valid" @submit.prevent="callSubmit()">
    <v-alert
      v-if="registerError"
      :title="$t('notification-request-failed')"
      :text="registerError"
      color="error"
      variant="tonal"
    ></v-alert>
    <div ref="editableBlock" :class="{ 'pointer-events-none': readonly }">
      <v-row class="my-0">
        <v-col class="py-0">
          <div class="d-flex align-center pt-10 pb-8">
            <v-avatar color="primary">
              <v-icon icon="mdi-domain"></v-icon>
            </v-avatar>
            <div class="text-h5 text-primary ml-4">
              {{ $t("company-data") }}
            </div>
          </div>
          <v-select
            v-if="props.role === 'client'"
            v-model="data.company_type"
            autocomplete="off"
            :error-messages="errors.company_type"
            :rules="[rules.required]"
            :label="$t('company-type')"
            :items="
              companyTypeKeys
                .filter(({ type }) => type !== 'contractor')
                .map(({ type, key }) => ({ value: type, title: $t(key) }))
            "
            :readonly="readonly"
            :variant="readonly ? 'filled' : 'outlined'"
            class="pb-2"
            @update:model-value="errors.company_type = []"
          ></v-select>
          <v-text-field
            v-model="data.company_name"
            :error-messages="errors.company_name"
            autocomplete="organization"
            :rules="[rules.required]"
            :label="$t('company-name')"
            :readonly="readonly"
            :variant="readonly ? 'filled' : 'outlined'"
            class="pb-2"
            @update:model-value="errors.company_name = []"
          ></v-text-field>
          <v-text-field
            v-model="data.company_addendum"
            :error-messages="errors.company_addendum"
            autocomplete="off"
            :label="$t('company-addendum')"
            :readonly="readonly"
            :variant="readonly ? 'filled' : 'outlined'"
            class="pb-2"
            @update:model-value="errors.company_addendum = []"
          ></v-text-field>
        </v-col>
        <v-col class="py-0">
          <div class="d-flex align-center pt-10 pb-8">
            <v-avatar color="primary">
              <v-icon icon="mdi-domain"></v-icon>
            </v-avatar>
            <div class="text-h5 text-primary ml-4">
              {{ $t("headquarters") }}
            </div>
          </div>
          <v-text-field
            v-model="data.headquarters_street"
            autocomplete="street-address"
            :error-messages="errors.headquarters_street"
            :label="$t('street')"
            :readonly="readonly"
            :rules="[rules.required]"
            :variant="readonly ? 'filled' : 'outlined'"
            class="pb-2"
            @update:model-value="errors.headquarters_street = []"
          ></v-text-field>
          <v-autocomplete
            v-model="data.headquarters_country_id"
            :error-messages="errors.headquarters_country_id"
            autocomplete="country"
            :readonly="readonly"
            :rules="[rules.required]"
            :label="$t('country')"
            :items="store.getters['app/countries']"
            item-title="title"
            item-value="id"
            :variant="readonly ? 'filled' : 'outlined'"
            class="pb-2"
            @update:model-value="errors.headquarters_country_id = []"
          ></v-autocomplete>
          <v-text-field
            v-model="data.headquarters_zip"
            autocomplete="postal-code"
            :error-messages="errors.headquarters_zip"
            :label="$t('zip-code')"
            :readonly="readonly"
            :rules="[rules.required, rules.postCodeOnly]"
            :variant="readonly ? 'filled' : 'outlined'"
            class="pb-2"
            @update:model-value="errors.headquarters_zip = []"
          ></v-text-field>
          <v-text-field
            v-model="data.headquarters_city"
            :error-messages="errors.headquarters_city"
            autocomplete="address-level2"
            :label="$t('town')"
            :readonly="readonly"
            :rules="[rules.required]"
            :variant="readonly ? 'filled' : 'outlined'"
            class="pb-2"
            @update:model-value="errors.headquarters_city = []"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="my-0">
        <v-col class="py-0">
          <div class="d-flex align-center pt-10 pb-8">
            <v-avatar color="primary">
              <v-icon icon="mdi-account-group-outline"></v-icon>
            </v-avatar>
            <div class="text-h5 text-primary ml-4">
              {{ $t("central-contact-person") }}
            </div>
          </div>
          <v-select
            v-model="data.salutation"
            :error-messages="errors.salutation"
            autocomplete="honorific-prefix"
            :rules="[rules.required]"
            :label="$t('salutation')"
            :items="
              salutationKeys.map(({ key, type }) => ({
                value: type,
                title: $t(key),
              }))
            "
            :readonly="readonly"
            :variant="readonly ? 'filled' : 'outlined'"
            class="pb-2"
            @update:model-value="errors.salutation = []"
          ></v-select>
          <v-text-field
            v-model="data.firstname"
            :error-messages="errors.firstname"
            autocomplete="given-name"
            :label="$t('name')"
            :readonly="readonly"
            :rules="[rules.required]"
            :variant="readonly ? 'filled' : 'outlined'"
            class="pb-2"
            @update:model-value="errors.firstname = []"
          ></v-text-field>
          <v-text-field
            v-model="data.lastname"
            :error-messages="errors.lastname"
            autocomplete="family-name"
            :rules="[rules.required]"
            :label="$t('last-name')"
            :readonly="readonly"
            :variant="readonly ? 'filled' : 'outlined'"
            class="pb-2"
            @update:model-value="errors.lastname = []"
          ></v-text-field>
          <v-text-field
            v-model="data.phone"
            :error-messages="errors.phone"
            autocomplete="tel"
            :label="$t('phone')"
            :readonly="readonly"
            :rules="[rules.required, rules.phone]"
            :variant="readonly ? 'filled' : 'outlined'"
            class="pb-2"
            @update:model-value="errors.phone = []"
          ></v-text-field>
          <v-text-field
            v-model="data.email"
            :error-messages="errors.email"
            autocomplete="email"
            :label="$t('email')"
            :readonly="readonly"
            :rules="[rules.required, rules.email]"
            :variant="readonly ? 'filled' : 'outlined'"
            class="pb-2"
            @update:model-value="errors.email = []"
          ></v-text-field>
          <v-text-field
            v-model="data.password"
            :error-messages="errors.password"
            autocomplete="new-password"
            :label="$t('password')"
            :append-inner-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[
              rules.required,
              rules.numberHas,
              rules.letterHas,
              rules.symbolHas,
              rules.letterLowercaseHas,
              rules.letterUppercaseHas,
              rules.lengthMin(8),
            ]"
            :type="passwordShow ? 'text' : 'password'"
            counter
            :readonly="readonly"
            :variant="readonly ? 'filled' : 'outlined'"
            class="pb-2"
            @update:model-value="errors.password = []"
            @click:append-inner="passwordShow = !passwordShow"
          ></v-text-field>
          <v-text-field
            v-model="data.password_confirmation"
            :error-messages="errors.password_confirmation"
            autocomplete="new-password"
            :label="$t('repeat-password')"
            :append-inner-icon="
              passwordConfirmationShow ? 'mdi-eye' : 'mdi-eye-off'
            "
            :rules="[rules.required, rules.match(data.password)]"
            :type="passwordConfirmationShow ? 'text' : 'password'"
            :readonly="readonly"
            :variant="readonly ? 'filled' : 'outlined'"
            class="pb-2"
            @update:model-value="errors.password_confirmation = []"
            @click:append-inner="
              passwordConfirmationShow = !passwordConfirmationShow
            "
          ></v-text-field>
        </v-col>
        <v-col class="py-0">
          <div class="d-flex align-center pt-10 pb-8">
            <v-avatar color="primary">
              <v-icon icon="mdi-chart-areaspline"></v-icon>
            </v-avatar>
            <div class="text-h5 text-primary ml-4">
              {{ $t("tax-information") }}
            </div>
          </div>
          <v-text-field
            v-model="data.company_vat_id"
            :error-messages="errors.company_vat_id"
            autocomplete="off"
            :label="$t('vat')"
            :readonly="readonly"
            :rules="[rules.required]"
            :variant="readonly ? 'filled' : 'outlined'"
            class="pb-2"
            @update:model-value="errors.company_vat_id = []"
          ></v-text-field>
          <v-text-field
            v-model="data.company_tax_id"
            :error-messages="errors.company_tax_id"
            autocomplete="off"
            :label="$t('tax')"
            :readonly="readonly"
            :rules="[rules.required]"
            :variant="readonly ? 'filled' : 'outlined'"
            class="pb-2"
            @update:model-value="errors.company_tax_id = []"
          ></v-text-field>
          <v-select
            v-if="props.role === 'client'"
            v-model="data.company_payment_target"
            :error-messages="errors.company_payment_target"
            autocomplete="off"
            :readonly="readonly"
            :rules="[rules.required]"
            :label="$t('payment-target')"
            :items="
              paymentTargetKeys.map(({ type, key }) => ({
                value: type,
                title: $t(key),
              }))
            "
            :variant="readonly ? 'filled' : 'outlined'"
            class="pb-2"
            @update:model-value="errors.company_payment_target = []"
          ></v-select>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="my-0">
        <v-col
          class="py-0"
          :class="{ 'opacity-60': differentBillingAddressDisabled }"
        >
          <div class="d-flex align-center pt-10 pb-8">
            <v-avatar
              :color="
                differentBillingAddressDisabled ? 'grey-darken-1' : 'primary'
              "
            >
              <v-icon color="white" icon="mdi-map-marker-outline"></v-icon>
            </v-avatar>
            <div
              class="text-h5 ml-4"
              :class="
                differentBillingAddressDisabled
                  ? 'text-grey-darken-1'
                  : 'text-primary'
              "
            >
              {{ $t("different-billing-address") }}
            </div>
          </div>
          <v-text-field
            v-model="data.billing_street"
            :error-messages="errors.billing_street"
            autocomplete="street-address"
            :label="$t('street')"
            :readonly="readonly"
            :rules="data.different_billing_address ? [rules.required] : []"
            :variant="readonly ? 'filled' : 'outlined'"
            class="pb-2"
            @update:model-value="errors.billing_street = []"
          ></v-text-field>
          <v-autocomplete
            v-model="data.billing_country_id"
            :error-messages="errors.billing_country_id"
            color="primary"
            autocomplete="country"
            :rules="data.different_billing_address ? [rules.required] : []"
            :readonly="readonly"
            :label="$t('country')"
            :items="store.getters['app/countries']"
            item-title="title"
            item-value="id"
            :variant="readonly ? 'filled' : 'outlined'"
            class="pb-2"
            @update:model-value="errors.billing_country_id = []"
          ></v-autocomplete>
          <v-text-field
            v-model="data.billing_zip"
            :error-messages="errors.billing_zip"
            color="primary"
            autocomplete="postal-code"
            :rules="
              data.different_billing_address
                ? [rules.required, rules.postCodeOnly]
                : []
            "
            :label="$t('zip-code')"
            :readonly="readonly"
            :variant="readonly ? 'filled' : 'outlined'"
            class="pb-2"
            @update:model-value="errors.billing_zip = []"
          ></v-text-field>
          <v-text-field
            v-model="data.billing_city"
            :error-messages="errors.billing_city"
            color="primary"
            autocomplete="address-level2"
            :rules="data.different_billing_address ? [rules.required] : []"
            :label="$t('town')"
            :readonly="readonly"
            :variant="readonly ? 'filled' : 'outlined'"
            class="pb-2"
            @update:model-value="errors.billing_city = []"
          ></v-text-field>
        </v-col>
        <v-col class="py-0">
          <div v-if="props.role === 'contractor'">
            <div class="d-flex align-center pt-10 pb-8">
              <v-avatar color="primary">
                <v-icon icon="mdi-bank"></v-icon>
              </v-avatar>
              <div class="text-h5 text-primary ml-4">
                {{ $t("bank-details") }}
              </div>
            </div>
            <v-text-field
              v-model="data.company_iban"
              :error-messages="errors.company_iban"
              color="primary"
              autocomplete="off"
              :rules="[rules.iban]"
              :label="$t('iban')"
              :variant="readonly ? 'filled' : 'outlined'"
              class="pb-2"
              @update:model-value="errors.company_iban = []"
            ></v-text-field>
            <v-text-field
              v-model="data.company_swift"
              :error-messages="errors.company_swift"
              color="primary"
              autocomplete="off"
              :label="$t('swift')"
              :variant="readonly ? 'filled' : 'outlined'"
              class="pb-2"
              @update:model-value="errors.company_swift = []"
            ></v-text-field>
            <v-text-field
              v-model="data.company_bank_name"
              :error-messages="errors.company_bank_name"
              color="primary"
              autocomplete="off"
              :label="$t('bank-name')"
              :variant="readonly ? 'filled' : 'outlined'"
              class="pb-2"
              @update:model-value="errors.company_bank_name = []"
            ></v-text-field>
            <v-text-field
              v-model="data.company_bank_code"
              :error-messages="errors.company_bank_code"
              color="primary"
              autocomplete="off"
              :label="$t('bank-code')"
              :variant="readonly ? 'filled' : 'outlined'"
              class="pb-2"
              @update:model-value="errors.company_bank_code = []"
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </div>
    <v-row class="mt-10">
      <v-col class="py-0 d-flex">
        <v-checkbox
          v-model="acceptTermsAndConditions"
          :rules="[rules.required]"
          color="primary"
          class="mr-10"
        >
          <template #label>
            <span
              >{{ $t("text.check-terms-conditions") }}
              <router-link
                :to="{ name: 'terms' }"
                target="_blank"
                class="text-primary"
                >{{ $t("button.privacy-agb") }}</router-link
              >
              *</span
            >
          </template>
        </v-checkbox>
        <v-checkbox
          v-model="acceptPrivacyPolicy"
          :rules="[rules.required]"
          color="primary"
        >
          <template #label>
            <span
              >{{ $t("text.check-privacy-policy") }}
              <router-link
                :to="{ name: 'privacy-policy' }"
                target="_blank"
                class="text-primary"
                >{{ $t("button.privacy-policy") }}</router-link
              >
              *</span
            >
          </template>
        </v-checkbox>
      </v-col>
    </v-row>
    <div class="d-flex justify-space-between mt-10">
      <v-btn
        :disabled="!readonly"
        :color="!readonly ? '' : 'primary'"
        variant="text"
        size="large"
        @click="emit('back')"
      >
        {{ $t("button.back") }}</v-btn
      >
      <v-btn :loading="loading" color="primary" type="submit" size="large">
        {{ $t("button.submit") }}
      </v-btn>
    </div>
  </v-form>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "@/store";
import { useI18n } from "vue-i18n";

import { register } from "@/api/auth";

import { useRules } from "@/utils/rules";
import {
  getInitialNewUserObject,
  getInitialNewUserErrorObject,
} from "@/utils/auth";
import { subscribe, broadcast } from "@/utils/events";
import { snackbar } from "@/utils/snackbar";

import {
  useValidHelper,
  getAllInputs,
  isValidCheck,
} from "@/utils/validHelper";
import { useMountScroller } from "@/utils/mountScroller";
import {
  companyTypeKeys,
  paymentTargetKeys,
  salutationKeys,
} from "@/utils/other";
import { NewUserRole, NewUserObject } from "@/types/auth";
import { VForm } from "vuetify/components";

const store = useStore();

const router = useRouter();
const rules = useRules();

const { t, locale } = useI18n();

const props = defineProps<{
  role: NewUserRole;
  newUserObject: NewUserObject;
}>();

const valid = ref(false);
const readonly = ref(true);

const acceptTermsAndConditions = ref(false);
const acceptPrivacyPolicy = ref(false);

const disabled = computed(() => {
  return (
    !acceptTermsAndConditions.value ||
    !acceptPrivacyPolicy.value ||
    !readonly.value
  );
});

const differentBillingAddressDisabled = computed(() => {
  return !data.value.different_billing_address && readonly.value;
});

const updateDifferentBillingAddress = () => {
  const enable = !!(
    data.value.billing_street ||
    data.value.billing_country_id ||
    data.value.billing_zip ||
    data.value.billing_city
  );

  data.value.different_billing_address = enable;

  if (!enable) {
    data.value.billing_street = undefined;
    data.value.billing_country_id = undefined;
    data.value.billing_zip = undefined;
    data.value.billing_city = undefined;

    errors.value.billing_street = [];
    errors.value.billing_country_id = [];
    errors.value.billing_zip = [];
    errors.value.billing_city = [];
  }
};

const data = ref<NewUserObject>({ ...props.newUserObject });

const form = ref<VForm>();
const editableBlock = ref();

useValidHelper(form);
useMountScroller();

const passwordShow = ref(false);
const passwordConfirmationShow = ref(false);

const registerError = ref("");
const errors = ref(getInitialNewUserErrorObject());
const loading = ref(false);

const callSubmit = async () => {
  if (!loading.value) {
    updateDifferentBillingAddress();
    await form.value?.validate();
    if (valid.value) {
      if (disabled.value) {
        snackbar(t("error-message.editable-must-be-saved"));
      } else {
        try {
          loading.value = true;
          data.value.verification_url = `${window.location.origin}/email/verify`;
          await register({
            ...data.value,
            company_iban: data.value.company_iban || undefined,
            company_swift: data.value.company_swift || undefined,
            company_bank_name: data.value.company_bank_name || undefined,
            company_bank_code: data.value.company_bank_code || undefined,
            locale: locale.value,
          });
          emit("submit", { data: getInitialNewUserObject(), tabIndex: 2 });
          await router.push({ name: "login" });
        } catch (e: any) {
          registerError.value = e.message;
          errors.value = { ...getInitialNewUserErrorObject(), ...e.errors };
        } finally {
          loading.value = false;
        }
      }
    }
  }
};
const callEdit = () => {
  readonly.value = false;
};

const callCancel = () => {
  data.value = { ...props.newUserObject };
  readonly.value = true;
};

const callSave = async () => {
  updateDifferentBillingAddress();
  const inputs = getAllInputs(editableBlock.value);
  const isValid = await isValidCheck(inputs, form.value);
  if (isValid) {
    emit("submit", { data: data.value });
    broadcast("EditFormEnd", data.value);
    readonly.value = true;
  }
};

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "submit", data: { data: NewUserObject; tabIndex?: number }): void;
  // eslint-disable-next-line no-unused-vars
  (e: "back"): void;
}>();

onMounted(() => {
  subscribe("EditFormStart", callEdit);
  subscribe("EditFormCancel", callCancel);
  subscribe("SaveFormStart", callSave);
});
</script>

<style scoped>
.opacity-60 {
  opacity: 0.6;
}
</style>
