<template>
  <v-row no-gutters>
    <v-col cols="12" class="pa-10">
      <v-sheet class="pb-12 pt-11 px-10 rounded-xl bpl-border-gray">
        <v-form ref="form" v-model="valid" @submit.prevent="saveChanges">
          <editable-block
            v-model="edited"
            :loading="editLoading"
            :disabled="!isDataReady"
            submit
            @cancel="syncData(store.state.app.settings)"
          >
            <template #title>
              <p class="text-h4 text-primary font-weight-light py-1">
                {{ $t("general") }}
              </p>
            </template>
            <template #edit-btn="{ edit }">
              <v-btn color="primary" :disabled="!isDataReady" @click="edit">
                {{ $t("button.edit") }}
              </v-btn>
            </template>
            <template #default="{ readonly }">
              <div class="mt-9">
                <app-loader v-if="!isDataReady" height="450px" />
                <v-row
                  v-else
                  :class="{ 'pointer-events-none': readonly }"
                  class="my-0"
                >
                  <v-col cols="12" class="py-0">
                    <p class="text-h5 text-primary mb-8">
                      {{ $t("commission") }}
                    </p>
                    <div class="d-flex gen-settings-commission-list">
                      <v-text-field
                        v-model="editSettings.commission_normal"
                        :label="$t('regular-price')"
                        append-inner-icon="mdi-percent"
                        class="pb-2"
                        :readonly="readonly"
                        :variant="readonly ? 'filled' : 'outlined'"
                        :rules="[
                          rules.required,
                          rules.numberWithMinusOnly,
                          rules.range(-100, 100),
                        ]"
                      />
                      <v-text-field
                        v-model="editSettings.commission_express_1"
                        :label="$t('express') + ' 1'"
                        append-inner-icon="mdi-percent"
                        class="pb-2"
                        :readonly="readonly"
                        :variant="readonly ? 'filled' : 'outlined'"
                        :rules="[
                          rules.required,
                          rules.numberWithMinusOnly,
                          rules.range(-100, 100),
                        ]"
                      />
                      <v-text-field
                        v-model="editSettings.commission_express_2"
                        :label="$t('express') + ' 2'"
                        append-inner-icon="mdi-percent"
                        class="pb-2"
                        :readonly="readonly"
                        :variant="readonly ? 'filled' : 'outlined'"
                        :rules="[
                          rules.required,
                          rules.numberWithMinusOnly,
                          rules.range(-100, 100),
                        ]"
                      />
                      <v-text-field
                        v-model="editSettings.commission_express_3"
                        :label="$t('express') + ' 3'"
                        append-inner-icon="mdi-percent"
                        class="pb-2"
                        :readonly="readonly"
                        :variant="readonly ? 'filled' : 'outlined'"
                        :rules="[
                          rules.required,
                          rules.numberWithMinusOnly,
                          rules.range(-100, 100),
                        ]"
                      />
                      <v-text-field
                        v-model="editSettings.commission_express_4"
                        :label="$t('express') + ' 4'"
                        append-inner-icon="mdi-percent"
                        class="pb-2"
                        :readonly="readonly"
                        :variant="readonly ? 'filled' : 'outlined'"
                        :rules="[
                          rules.required,
                          rules.numberWithMinusOnly,
                          rules.range(-100, 100),
                        ]"
                      />
                    </div>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <p class="text-h5 text-primary mb-8">
                      {{ $t("base-price-multiplier") }}
                    </p>
                    <v-text-field
                      v-model="editSettings.base_price_multiplier"
                      :label="$t('base-price-multiplier')"
                      type="number"
                      hide-spin-buttons
                      append-inner-icon="mdi-percent"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      :rules="[rules.required, rules.floatNumberOnly]"
                    />
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <p class="text-h5 text-primary mb-8">
                      {{ $t("credit-limit") }}
                    </p>
                    <v-text-field
                      v-model="editSettings.credit_limit"
                      :label="$t('credit-limit')"
                      append-inner-icon="mdi-currency-eur"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      :rules="[rules.required, rules.numberOnly]"
                    />
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <p class="text-h5 text-primary mb-8">
                      {{ $t("office-hours-date") }}
                    </p>
                  </v-col>
                  <v-col cols="3" class="py-0">
                    <v-select
                      v-model="editSettings.working_time_start"
                      :error-messages="errors.working_time_start"
                      autocomplete="off"
                      :rules="[rules.required]"
                      :label="$t('start')"
                      :items="times"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      :bg-color="!readonly ? ('white' as any) : undefined"
                      append-inner-icon="mdi-clock-time-eight"
                      @update:model-value="errors.working_time_start = []"
                    ></v-select
                  ></v-col>
                  <v-col cols="3" class="py-0 bpl-hyphen-col">
                    <v-select
                      v-model="editSettings.working_time_end"
                      :error-messages="errors.working_time_end"
                      autocomplete="off"
                      :rules="[rules.required]"
                      :label="$t('end')"
                      :items="times"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      :bg-color="!readonly ? ('white' as any) : undefined"
                      append-inner-icon="mdi-clock-time-eight"
                      @update:model-value="errors.working_time_end = []"
                    ></v-select
                  ></v-col>
                </v-row>
              </div>
            </template>
          </editable-block>
        </v-form>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useStore } from "@/store";

import { snackbar } from "@/utils/snackbar";
import { useRules } from "@/utils/rules";
import { useValidHelper } from "@/utils/validHelper";
import { getInitialEditSettingsErrorObject } from "@/utils/settings";

import { VForm, VTextField } from "vuetify/components";
import { EditSettingsObject, SettingsObject } from "@/types/settings";

import AppLoader from "@/components/AppLoader.vue";
import EditableBlock from "@/components/EditableBlock.vue";

const times = Array.from({ length: 24 })
  .map((_, i) => {
    const hour = String(i).padStart(2, "0");
    return [`${hour}:00`, `${hour}:30`];
  })
  .flat();

const store = useStore();

const rules = useRules();

const valid = ref(false);
const form = ref<VForm>();

const errors = ref(getInitialEditSettingsErrorObject());

useValidHelper(form);

const edited = ref(false);

const editLoading = ref(false);

const editSettings = ref<EditSettingsObject>({});

const saveChanges = async () => {
  await form.value?.validate();
  if (valid.value) {
    try {
      editLoading.value = true;

      await store.dispatch("app/updateSettings", editSettings.value);

      edited.value = false;
    } catch (e: any) {
      snackbar(e.message);
      errors.value = {
        ...getInitialEditSettingsErrorObject(),
        ...e.errors,
      };
    } finally {
      editLoading.value = false;
    }
  }
};

const isDataReady = computed(() => !!store.state.app.settings);

const syncData = (value: SettingsObject | null) => {
  if (value) {
    editSettings.value = { ...value };
    errors.value = getInitialEditSettingsErrorObject();
  }
};

watch(() => store.state.app.settings, syncData, { immediate: true });
</script>

<style scoped>
.gen-settings-commission-list {
  gap: 2rem;
}
</style>
