<template>
  <v-row no-gutters>
    <v-col cols="12" class="pa-10">
      <v-sheet class="py-12 px-10 rounded-xl bpl-border-gray">
        <div class="d-flex justify-space-between align-center">
          <p class="text-h4 text-primary font-weight-light">
            {{ $t("profile-settings") }}
          </p>
        </div>
        <template v-if="profile && store.getters['app/countries'].length">
          <div
            v-if="
              access.someRoles([
                'logistic',
                'sub-logistic',
                'industry',
                'sub-industry',
                'forwarder',
                'sub-forwarder',
              ])
            "
            class="pt-8 mb-2"
          >
            <p class="text-h5 text-primary py-2">
              {{ $t("company-data") }}
            </p>
            <v-row class="my-0 pt-6">
              <v-col cols="4" class="py-0">
                <fake-input
                  :label="$t('company-type')"
                  :value="companyTypeTitle"
                  class="pb-2"
                />
              </v-col>
              <v-col cols="4" class="py-0">
                <fake-input
                  :label="$t('company-name')"
                  :value="profile.company?.data.name"
                  class="pb-2"
                />
              </v-col>
              <v-col cols="4" class="py-0">
                <fake-input
                  :label="$t('company-addendum')"
                  :value="profile.company?.data.addendum"
                  class="pb-2"
                />
              </v-col>
            </v-row>
            <v-divider />
          </div>
          <v-form
            v-if="
              access.someRoles([
                'logistic',
                'sub-logistic',
                'industry',
                'sub-industry',
                'forwarder',
                'sub-forwarder',
              ]) && editHeadquartersData.headquarters_address
            "
            ref="headForm"
            v-model="headValid"
            class="pt-8 mb-2"
            @submit.prevent="saveChanges(ProfileBlockType.headquarters)"
          >
            <editable-block
              v-model="editableBlocks"
              :name="ProfileBlockType.headquarters"
              :loading="headLoading"
              submit
              @cancel="cancelChanges"
            >
              <template #edit-btn="{ edit }">
                <v-btn
                  variant="text"
                  elevation="0"
                  color="primary"
                  icon="mdi-file-edit-outline"
                  @click="edit"
                />
              </template>
              <template #title>
                <p class="text-h5 text-primary py-2">
                  {{ $t("headquarters") }}
                </p>
              </template>
              <template #default="{ readonly }">
                <v-row
                  class="my-0 pt-6"
                  :class="{ 'pointer-events-none': readonly }"
                >
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      v-model="editHeadquartersData.headquarters_address.street"
                      :error-messages="
                        companyErrors['headquarters_address.street']
                      "
                      :rules="[rules.required]"
                      :label="$t('street')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      @update:model-value="
                        companyErrors['headquarters_address.street'] = []
                      "
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      v-model="editHeadquartersData.headquarters_address.zip"
                      :error-messages="
                        companyErrors['headquarters_address.zip']
                      "
                      :rules="[rules.required, rules.postCodeOnly]"
                      :label="$t('zip-code')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      @update:model-value="
                        companyErrors['headquarters_address.zip'] = []
                      "
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-autocomplete
                      v-model="
                        editHeadquartersData.headquarters_address.country_id
                      "
                      autocomplete="off"
                      :rules="[rules.required]"
                      :label="$t('country')"
                      :items="store.getters['app/countries']"
                      class="pb-2"
                      :variant="readonly ? 'filled' : 'outlined'"
                      :readonly="readonly"
                      item-title="title"
                      item-value="id"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      v-model="editHeadquartersData.headquarters_address.city"
                      :error-messages="
                        companyErrors['headquarters_address.city']
                      "
                      :rules="[rules.required]"
                      :label="$t('town')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      @update:model-value="
                        companyErrors['headquarters_address.city'] = []
                      "
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </template>
            </editable-block>
            <v-divider />
          </v-form>
          <v-form
            v-if="
              access.someRoles([
                'logistic',
                'sub-logistic',
                'industry',
                'sub-industry',
                'forwarder',
                'sub-forwarder',
              ]) && editContactData.contact
            "
            ref="contactForm"
            v-model="contactValid"
            class="pt-8 mb-2"
            @submit.prevent="saveChanges(ProfileBlockType.contact)"
          >
            <editable-block
              v-model="editableBlocks"
              :name="ProfileBlockType.contact"
              :loading="contactLoading"
              submit
              @cancel="cancelChanges"
            >
              <template #edit-btn="{ edit }">
                <v-btn
                  variant="text"
                  elevation="0"
                  color="primary"
                  icon="mdi-file-edit-outline"
                  @click="edit"
                />
              </template>
              <template #title>
                <p class="text-h5 text-primary py-2">
                  {{ $t("central-contact-person") }}
                </p>
              </template>
              <template #default="{ readonly }">
                <v-row
                  class="my-0 pt-6"
                  :class="{ 'pointer-events-none': readonly }"
                >
                  <v-col cols="6" class="py-0">
                    <v-select
                      v-model="editContactData.contact.salutation"
                      autocomplete="off"
                      :rules="[rules.required]"
                      :label="$t('salutation')"
                      :items="
                        salutationKeys.map(({ key, type }) => ({
                          value: type,
                          title: $t(key),
                        }))
                      "
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      class="pb-2"
                    ></v-select>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      v-model="editContactData.contact.lastname"
                      :error-messages="companyErrors['contact.lastname']"
                      :rules="[rules.required]"
                      :label="$t('last-name')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      @update:model-value="
                        companyErrors['contact.lastname'] = []
                      "
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      v-model="editContactData.contact.firstname"
                      :error-messages="companyErrors['contact.firstname']"
                      :rules="[rules.required]"
                      :label="$t('first-name')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      @update:model-value="
                        companyErrors['contact.firstname'] = []
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      v-model="editContactData.contact.phone"
                      :error-messages="companyErrors['contact.phone']"
                      :rules="[rules.required]"
                      :label="$t('phone')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      @update:model-value="companyErrors['contact.phone'] = []"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </template>
            </editable-block>
            <v-divider />
          </v-form>
          <v-form
            v-if="
              access.someRoles([
                'logistic',
                'sub-logistic',
                'industry',
                'sub-industry',
                'forwarder',
                'sub-forwarder',
              ])
            "
            ref="taxForm"
            v-model="taxValid"
            class="pt-8 mb-2"
            @submit.prevent="saveChanges(ProfileBlockType.tax)"
          >
            <editable-block
              v-model="editableBlocks"
              :name="ProfileBlockType.tax"
              :loading="taxLoading"
              submit
              @cancel="cancelChanges"
            >
              <template #edit-btn="{ edit }">
                <v-btn
                  variant="text"
                  elevation="0"
                  color="primary"
                  icon="mdi-file-edit-outline"
                  @click="edit"
                />
              </template>
              <template #title>
                <p class="text-h5 text-primary py-2">
                  {{ $t("tax-information") }}
                </p>
              </template>
              <template #default="{ readonly }">
                <v-row
                  class="my-0 pt-6"
                  :class="{ 'pointer-events-none': readonly }"
                >
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      v-model="editTaxData.vat_id"
                      :error-messages="companyErrors.vat_id"
                      :rules="[rules.required]"
                      :label="$t('vat')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      @update:model-value="companyErrors.vat_id = []"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-select
                      v-model="editTaxData.payment_target"
                      :error-messages="companyErrors.payment_target"
                      autocomplete="off"
                      :rules="[rules.required]"
                      :label="$t('payment-target')"
                      :items="
                        paymentTargetKeys.map(({ type, key }) => ({
                          value: type,
                          title: $t(key),
                        }))
                      "
                      :hint="$t('text.payment-target-info')"
                      persistent-hint
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      class="pb-2"
                      @update:model-value="companyErrors.payment_target = []"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      v-model="editTaxData.tax_id"
                      :error-messages="companyErrors.tax_id"
                      :rules="[rules.required]"
                      :label="$t('tax')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      @update:model-value="companyErrors.tax_id = []"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </template>
            </editable-block>
            <v-divider />
          </v-form>
          <v-form
            v-if="
              access.someRoles([
                'logistic',
                'sub-logistic',
                'industry',
                'sub-industry',
                'forwarder',
                'sub-forwarder',
              ]) && editAddressData.billing_address
            "
            ref="addrForm"
            v-model="addrValid"
            class="pt-8 mb-2"
            @submit.prevent="saveChanges(ProfileBlockType.address)"
          >
            <editable-block
              v-model="editableBlocks"
              :name="ProfileBlockType.address"
              :loading="addrLoading"
              submit
              @cancel="cancelChanges"
            >
              <template #edit-btn="{ edit }">
                <v-btn
                  variant="text"
                  elevation="0"
                  color="primary"
                  icon="mdi-file-edit-outline"
                  @click="edit"
                />
              </template>
              <template #title>
                <p class="text-h5 text-primary py-2">
                  {{ $t("different-billing-address") }}
                </p>
              </template>
              <template #default="{ readonly }">
                <v-row
                  class="my-0 pt-6"
                  :class="{ 'pointer-events-none': readonly }"
                >
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      v-model="editAddressData.billing_address.street"
                      :error-messages="companyErrors['billing_address.street']"
                      :rules="[rules.required]"
                      :label="$t('street')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      @update:model-value="
                        companyErrors['billing_address.street'] = []
                      "
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      v-model="editAddressData.billing_address.zip"
                      :error-messages="companyErrors['billing_address.zip']"
                      :rules="[rules.required, rules.postCodeOnly]"
                      :label="$t('zip-code')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      @update:model-value="
                        companyErrors['billing_address.zip'] = []
                      "
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-autocomplete
                      v-model="editAddressData.billing_address.country_id"
                      autocomplete="off"
                      :rules="[rules.required]"
                      :label="$t('country')"
                      :items="store.getters['app/countries']"
                      class="pb-2"
                      :variant="readonly ? 'filled' : 'outlined'"
                      :readonly="readonly"
                      item-title="title"
                      item-value="id"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      v-model="editAddressData.billing_address.city"
                      :error-messages="companyErrors['billing_address.city']"
                      :rules="[rules.required]"
                      :label="$t('town')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      @update:model-value="
                        companyErrors['billing_address.city'] = []
                      "
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </template>
            </editable-block>
            <v-divider />
          </v-form>
          <v-form
            v-if="access.someRoles(['forwarder', 'sub-forwarder'])"
            ref="bankForm"
            v-model="bankValid"
            class="pt-8 mb-2"
            @submit.prevent="saveChanges(ProfileBlockType.bank)"
          >
            <editable-block
              v-model="editableBlocks"
              :name="ProfileBlockType.bank"
              :loading="bankLoading"
              submit
              @cancel="cancelChanges"
            >
              <template #edit-btn="{ edit }">
                <v-btn
                  variant="text"
                  elevation="0"
                  color="primary"
                  icon="mdi-file-edit-outline"
                  @click="edit"
                />
              </template>
              <template #title>
                <p class="text-h5 text-primary py-2">
                  {{ $t("bank-details") }}
                </p>
              </template>
              <template #default="{ readonly }">
                <v-row
                  class="my-0 pt-6"
                  :class="{ 'pointer-events-none': readonly }"
                >
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      v-model="editBankData.iban"
                      :error-messages="companyErrors.iban"
                      :rules="[rules.iban]"
                      :label="$t('iban')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      @update:model-value="companyErrors.iban = []"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      v-model="editBankData.bank_name"
                      :error-messages="companyErrors.bank_name"
                      :label="$t('bank-name')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      @update:model-value="companyErrors.bank_name = []"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      v-model="editBankData.swift"
                      :error-messages="companyErrors.swift"
                      :label="$t('swift')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      @update:model-value="companyErrors.swift = []"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      v-model="editBankData.bank_code"
                      :error-messages="companyErrors.bank_code"
                      :label="$t('bank-code')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      @update:model-value="companyErrors.bank_code = []"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </template>
            </editable-block>
            <v-divider />
          </v-form>
          <v-form
            v-if="access.role('admin') || isSubRole"
            ref="accForm"
            v-model="accValid"
            class="pt-8 mb-2"
            @submit.prevent="saveChanges(ProfileBlockType.account)"
          >
            <editable-block
              v-model="editableBlocks"
              :name="ProfileBlockType.account"
              :loading="accLoading"
              submit
              @cancel="cancelChanges"
            >
              <template #edit-btn="{ edit }">
                <v-btn
                  variant="text"
                  elevation="0"
                  color="primary"
                  icon="mdi-file-edit-outline"
                  @click="edit"
                />
              </template>
              <template #title>
                <div class="d-flex align-center">
                  <p class="text-h5 text-primary py-2">
                    {{ $t("my-account") }}
                  </p>
                  <base-chip
                    v-if="profile.voucher_recipient"
                    class="ml-3 text-capitalize py-1 px-3"
                  >
                    {{ $t("receipt-recipient") }}
                  </base-chip>
                </div>
              </template>
              <template #default="{ readonly }">
                <v-row
                  class="my-0 pt-6"
                  :class="{ 'pointer-events-none': readonly }"
                >
                  <v-col cols="4" class="py-0">
                    <v-text-field
                      v-model="editAccData.firstname"
                      :error-messages="profileErrors.firstname"
                      :rules="[rules.required]"
                      :label="$t('first-name')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      @update:model-value="profileErrors.firstname = []"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4" class="py-0">
                    <v-text-field
                      v-model="editAccData.lastname"
                      :error-messages="profileErrors.lastname"
                      :rules="[rules.required]"
                      :label="$t('last-name')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      @update:model-value="profileErrors.lastname = []"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4" class="py-0">
                    <v-text-field
                      v-model="editAccData.phone"
                      :error-messages="profileErrors.phone"
                      :rules="[rules.required]"
                      :label="$t('phone')"
                      class="pb-2"
                      :readonly="readonly"
                      :variant="readonly ? 'filled' : 'outlined'"
                      @update:model-value="profileErrors.phone = []"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </template>
            </editable-block>
            <v-divider />
          </v-form>
          <v-row
            v-if="
              access.someRoles([
                'logistic',
                'sub-logistic',
                'industry',
                'sub-industry',
                'admin',
                'forwarder',
                'sub-forwarder',
              ]) || isSubRole
            "
            class="my-0"
          >
            <v-col cols="6" class="py-0">
              <v-form
                ref="passForm"
                v-model="passValid"
                class="pt-8 mb-2"
                @submit.prevent="saveChanges(ProfileBlockType.password)"
              >
                <editable-block
                  v-model="editableBlocks"
                  :name="ProfileBlockType.password"
                  :loading="passLoading"
                  submit
                  @cancel="cancelChanges"
                >
                  <template #edit-btn="{ edit }">
                    <v-btn
                      variant="text"
                      elevation="0"
                      color="primary"
                      icon="mdi-file-edit-outline"
                      @click="edit"
                    />
                  </template>
                  <template #title>
                    <p class="text-h5 text-primary py-2">
                      {{ $t("password") }}
                    </p>
                  </template>
                  <template #default="{ readonly, edited }">
                    <v-row
                      class="my-0 pt-6"
                      :class="{ 'pointer-events-none': readonly }"
                    >
                      <v-col v-if="!edited" cols="12" class="py-0">
                        <v-text-field
                          :rules="[rules.required]"
                          :label="$t('current-password')"
                          class="pb-2"
                          :readonly="readonly"
                          :variant="readonly ? 'filled' : 'outlined'"
                          type="password"
                          :model-value="'password'"
                        >
                        </v-text-field>
                      </v-col>
                      <template v-if="edited">
                        <v-col cols="12" class="py-0">
                          <v-text-field
                            v-model="editPassData.password"
                            :error-messages="profileErrors.password"
                            :rules="[
                              rules.required,
                              rules.numberHas,
                              rules.letterHas,
                              rules.symbolHas,
                              rules.letterLowercaseHas,
                              rules.letterUppercaseHas,
                              rules.lengthMin(8),
                            ]"
                            :label="$t('new-password')"
                            autocomplete="new-password"
                            class="pb-2"
                            :readonly="readonly"
                            :variant="readonly ? 'filled' : 'outlined'"
                            :append-inner-icon="
                              newPasswordShow ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            :type="newPasswordShow ? 'text' : 'password'"
                            @click:append-inner="
                              newPasswordShow = !newPasswordShow
                            "
                            @update:model-value="profileErrors.password = []"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" class="py-0">
                          <v-text-field
                            v-model="editPassData.password_confirmation"
                            :error-messages="
                              profileErrors.password_confirmation
                            "
                            :rules="[
                              rules.required,
                              rules.match(editPassData.password),
                            ]"
                            :label="$t('repeat-password')"
                            autocomplete="new-password"
                            class="pb-2"
                            :readonly="readonly"
                            :variant="readonly ? 'filled' : 'outlined'"
                            :append-inner-icon="
                              repeatPasswordShow ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            :type="repeatPasswordShow ? 'text' : 'password'"
                            @click:append-inner="
                              repeatPasswordShow = !repeatPasswordShow
                            "
                            @update:model-value="
                              profileErrors.password_confirmation = []
                            "
                          >
                          </v-text-field>
                        </v-col>
                      </template>
                    </v-row>
                  </template>
                </editable-block>
              </v-form>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-form
                ref="emailForm"
                v-model="emailValid"
                class="pt-8 mb-2"
                @submit.prevent="saveChanges(ProfileBlockType.email)"
              >
                <editable-block
                  v-model="editableBlocks"
                  :name="ProfileBlockType.email"
                  :loading="emailLoading"
                  submit
                  @cancel="cancelChanges"
                >
                  <template #edit-btn="{ edit }">
                    <v-btn
                      variant="text"
                      elevation="0"
                      color="primary"
                      icon="mdi-file-edit-outline"
                      @click="edit"
                    />
                  </template>
                  <template #title>
                    <p class="text-h5 text-primary py-2">
                      {{ $t("email") }}
                    </p>
                  </template>
                  <template #default="{ readonly, edited }">
                    <v-row
                      class="my-0 pt-6"
                      :class="{ 'pointer-events-none': readonly }"
                    >
                      <v-col v-if="!edited" cols="12" class="py-0">
                        <fake-input
                          :label="$t('current-email')"
                          :value="profile.email"
                          class="pb-2"
                        />
                      </v-col>
                      <template v-if="edited">
                        <v-col cols="12" class="py-0">
                          <v-text-field
                            v-model="editEmailData.email"
                            :error-messages="profileErrors.email"
                            :rules="[rules.required]"
                            :label="$t('new-email')"
                            class="pb-2"
                            :readonly="readonly"
                            :variant="readonly ? 'filled' : 'outlined'"
                            @update:model-value="profileErrors.email = []"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" class="py-0">
                          <v-text-field
                            v-model="repeatEmail"
                            :rules="[
                              rules.required,
                              rules.match(editEmailData.email),
                            ]"
                            :label="$t('repeat-email')"
                            class="pb-2"
                            :readonly="readonly"
                            :variant="readonly ? 'filled' : 'outlined'"
                          >
                          </v-text-field>
                        </v-col>
                      </template>
                    </v-row>
                  </template>
                </editable-block>
              </v-form>
            </v-col>
          </v-row>
        </template>
        <app-loader v-else />
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { computed, nextTick, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";

import { updateCompany } from "@/api/companies";

import { useRules } from "@/utils/rules";
import { useValidHelper } from "@/utils/validHelper";
import {
  getCompanyTypeName,
  paymentTargetKeys,
  salutationKeys,
} from "@/utils/other";
import { access } from "@/utils/access";
import {
  getInitialEditCompanyErrorObject,
  getInitialNewUserErrorObject,
} from "@/utils/auth";

import {
  CompanyData,
  EditCompanyObject,
  NewCompanyUserObject,
} from "@/types/company";
import { ProfileBlockType, ProfileData } from "@/types/auth";
import { VForm } from "vuetify/components";

import EditableBlock from "@/components/EditableBlock.vue";
import AppLoader from "@/components/AppLoader.vue";
import FakeInput from "@/components/FakeInput.vue";
import BaseChip from "@/components/BaseChip.vue";

const rules = useRules();
const store = useStore();

const { t } = useI18n();

const include = "headquarters_address,billing_address,contact,users";

const repeatEmail = ref("");

const newPasswordShow = ref(false);
const repeatPasswordShow = ref(false);

const companyErrors = ref(getInitialEditCompanyErrorObject());
const profileErrors = ref(getInitialNewUserErrorObject());

const editHeadquartersData = ref<EditCompanyObject>({
  headquarters_address: {},
}); //headquarters_address {street, zip, country_id, city}
const editContactData = ref<EditCompanyObject>({ contact: {} }); // contact {salutation, lastname, firstname, phone}
const editTaxData = ref<EditCompanyObject>({}); // vat_id, payment_target, tax_id
const editAddressData = ref<EditCompanyObject>({ billing_address: {} }); //  billing_address {street, zip, country_id, city}
const editBankData = ref<EditCompanyObject>({}); //  iban, bank_name, swift, bank_code
const editAccData = ref<NewCompanyUserObject>({}); //firstname, lastname, phone
const editPassData = ref<NewCompanyUserObject>({}); //password, password_confirmation
const editEmailData = ref<NewCompanyUserObject>({}); //email

const clearPass = () => {
  editPassData.value = {};
  newPasswordShow.value = false;
  repeatPasswordShow.value = false;
};
const clearEmail = () => {
  editEmailData.value = {};
  repeatEmail.value = "";
};

const profile = computed(() => store.state.auth.profile as ProfileData);

const isSubRole = computed(() =>
  access.someRoles([
    "sub-admin",
    "bpl-manager",
    "bpl-calculation-manager",
    "planner",
    "accounting-contractor",
    "driver",
    "pallet-department-contractor",
    "accounting-client",
    "manager",
    "pallet-department-client",
  ]),
);

const editableBlocks = ref<ProfileBlockType[]>([]);

const syncEdits = (value: ProfileData) => {
  const profileCompany = value.company?.data;
  //company sync
  if (!editableBlocks.value.includes(ProfileBlockType.headquarters)) {
    editHeadquartersData.value = {
      headquarters_address: {
        country_id: profileCompany?.headquarters_address.data.country.data.id,
        city: profileCompany?.headquarters_address.data.city,
        zip: profileCompany?.headquarters_address.data.zip,
        street: profileCompany?.headquarters_address.data.street,
      },
    };
    companyErrors.value["headquarters_address.street"] = [];
    companyErrors.value["headquarters_address.zip"] = [];
    companyErrors.value["headquarters_address.city"] = [];
  }
  if (!editableBlocks.value.includes(ProfileBlockType.contact)) {
    editContactData.value = {
      contact: {
        salutation: profileCompany?.contact?.data.salutation,
        firstname: profileCompany?.contact?.data.firstname,
        lastname: profileCompany?.contact?.data.lastname,
        phone: profileCompany?.contact?.data.phone,
      },
    };
    companyErrors.value["contact.firstname"] = [];
    companyErrors.value["contact.lastname"] = [];
    companyErrors.value["contact.phone"] = [];
  }
  if (!editableBlocks.value.includes(ProfileBlockType.tax)) {
    editTaxData.value = {
      vat_id: profileCompany?.vat_id,
      tax_id: profileCompany?.tax_id,
      payment_target: profileCompany?.payment_target,
    };
    companyErrors.value.vat_id = [];
    companyErrors.value.tax_id = [];
  }
  if (!editableBlocks.value.includes(ProfileBlockType.bank)) {
    editBankData.value = {
      iban: profileCompany?.iban || undefined,
      swift: profileCompany?.swift || undefined,
      bank_name: profileCompany?.bank_name || undefined,
      bank_code: profileCompany?.bank_code || undefined,
    };

    companyErrors.value.iban = [];
    companyErrors.value.swift = [];
    companyErrors.value.bank_name = [];
    companyErrors.value.bank_code = [];
  }

  if (!editableBlocks.value.includes(ProfileBlockType.address)) {
    editAddressData.value = {
      billing_address: profileCompany?.billing_address?.data
        ? {
            country_id: profileCompany?.billing_address.data.country.data.id,
            city: profileCompany?.billing_address.data.city,
            zip: profileCompany?.billing_address.data.zip,
            street: profileCompany?.billing_address.data.street,
          }
        : {},
    };

    companyErrors.value["billing_address.street"] = [];
    companyErrors.value["billing_address.zip"] = [];
    companyErrors.value["billing_address.city"] = [];
  }
  //profile sync
  if (!editableBlocks.value.includes(ProfileBlockType.account)) {
    editAccData.value = {
      firstname: value.firstname,
      lastname: value.lastname,
      phone: value.phone,
    };
    profileErrors.value.firstname = [];
    profileErrors.value.lastname = [];
    profileErrors.value.phone = [];
  }
  if (!editableBlocks.value.includes(ProfileBlockType.password)) {
    clearPass();
    profileErrors.value.password = [];
    profileErrors.value.password_confirmation = [];
  }
  if (!editableBlocks.value.includes(ProfileBlockType.email)) {
    clearEmail();
    profileErrors.value.email = [];
  }
};

watch(
  profile,
  (value) => {
    if (value) syncEdits(value);
  },
  { immediate: true },
);

//company
const companyTypeTitle = computed(() => {
  const type = profile.value.company?.data.type;
  return type ? t(getCompanyTypeName(type)) : "";
});

//headquarters
const headValid = ref(false);
const headForm = ref<VForm>();
const headLoading = ref(false);
useValidHelper(headForm);

//contact
const contactValid = ref(false);
const contactForm = ref<VForm>();
const contactLoading = ref(false);
useValidHelper(contactForm);

//tax
const taxValid = ref(false);
const taxForm = ref<VForm>();
const taxLoading = ref(false);
useValidHelper(taxForm);

//address
const addrValid = ref(false);
const addrForm = ref<VForm>();
const addrLoading = ref(false);
useValidHelper(addrForm);

//bank
const bankValid = ref(false);
const bankForm = ref<VForm>();
const bankLoading = ref(false);
useValidHelper(bankForm);

//acc
const accValid = ref(false);
const accForm = ref<VForm>();
const accLoading = ref(false);
useValidHelper(accForm);

//password
const passValid = ref(false);
const passForm = ref<VForm>();
const passLoading = ref(false);
useValidHelper(passForm);

//email
const emailValid = ref(false);
const emailForm = ref<VForm>();
const emailLoading = ref(false);
useValidHelper(emailForm);

const closeBlock = (name: ProfileBlockType) => {
  editableBlocks.value = editableBlocks.value.filter(
    (blockName) => blockName !== name,
  );
};

const saveChanges = async (name: ProfileBlockType) => {
  let companyResponse: { data: CompanyData } | undefined;
  let companyError: any = {};
  let profileError: any = {};

  if (profile.value.company) {
    //company edits
    switch (name) {
      case ProfileBlockType.headquarters:
        await headForm.value?.validate();
        if (headValid.value) {
          try {
            headLoading.value = true;
            companyResponse = await updateCompany(
              profile.value.company.data.id,
              editHeadquartersData.value,
              { include },
            );
            closeBlock(ProfileBlockType.headquarters);
          } catch (e: any) {
            companyError = e;
          } finally {
            headLoading.value = false;
          }
        }
        break;
      case ProfileBlockType.contact:
        await contactForm.value?.validate();
        if (contactValid.value) {
          try {
            contactLoading.value = true;
            companyResponse = await updateCompany(
              profile.value.company.data.id,
              editContactData.value,
              { include },
            );
            closeBlock(ProfileBlockType.contact);
          } catch (e: any) {
            companyError = e;
          } finally {
            contactLoading.value = false;
          }
        }
        break;
      case ProfileBlockType.tax:
        await taxForm.value?.validate();
        if (taxValid.value) {
          try {
            taxLoading.value = true;
            companyResponse = await updateCompany(
              profile.value.company.data.id,
              editTaxData.value,
              { include },
            );
            closeBlock(ProfileBlockType.tax);
          } catch (e: any) {
            companyError = e;
          } finally {
            taxLoading.value = false;
          }
        }
        break;
      case ProfileBlockType.bank:
        await bankForm.value?.validate();
        if (bankValid.value) {
          try {
            bankLoading.value = true;
            companyResponse = await updateCompany(
              profile.value.company.data.id,
              editBankData.value,
              { include },
            );
            closeBlock(ProfileBlockType.bank);
          } catch (e: any) {
            companyError = e;
          } finally {
            bankLoading.value = false;
          }
        }
        break;
      case ProfileBlockType.address:
        await addrForm.value?.validate();
        if (addrValid.value) {
          try {
            addrLoading.value = true;
            companyResponse = await updateCompany(
              profile.value.company.data.id,
              editAddressData.value,
              { include },
            );
            closeBlock(ProfileBlockType.address);
          } catch (e: any) {
            companyError = e;
          } finally {
            addrLoading.value = false;
          }
        }
        break;
      default:
    }
    if (companyResponse?.data) {
      if (companyResponse.data.contact?.data.id === profile.value.id) {
        store.commit("auth/setProfile", {
          ...profile.value,
          company: companyResponse,
          firstname: companyResponse.data.contact?.data.firstname,
          lastname: companyResponse.data.contact?.data.lastname,
          phone: companyResponse.data.contact?.data.phone,
          salutation: companyResponse.data.contact?.data.salutation,
        } as ProfileData);
      }
    } else if (companyError?.errors) {
      companyErrors.value = {
        ...getInitialEditCompanyErrorObject(),
        ...companyError.errors,
      };
    }
  }

  //profile edits
  switch (name) {
    case ProfileBlockType.account:
      await accForm.value?.validate();
      if (accValid.value) {
        try {
          accLoading.value = true;
          await store.dispatch("auth/updateProfile", {
            id: profile.value.id,
            newUserData: editAccData.value,
          });
          closeBlock(ProfileBlockType.account);
        } catch (e: any) {
          profileError = e;
        } finally {
          accLoading.value = false;
        }
      }
      break;
    case ProfileBlockType.password:
      await passForm.value?.validate();
      if (passValid.value) {
        try {
          passLoading.value = true;
          await store.dispatch("auth/updateProfile", {
            id: profile.value.id,
            newUserData: editPassData.value,
          });
          closeBlock(ProfileBlockType.password);
          clearPass();
        } catch (e: any) {
          profileError = e;
        } finally {
          passLoading.value = false;
        }
      }
      break;
    case ProfileBlockType.email:
      await emailForm.value?.validate();
      if (emailValid.value) {
        try {
          emailLoading.value = true;
          await store.dispatch("auth/updateProfile", {
            id: profile.value.id,
            newUserData: editEmailData.value,
          });
          closeBlock(ProfileBlockType.email);
          clearEmail();
        } catch (e: any) {
          profileError = e;
        } finally {
          emailLoading.value = false;
        }
      }
      break;
    default:
  }
  if (profileError?.errors) {
    profileErrors.value = {
      ...getInitialNewUserErrorObject(),
      ...profileError.errors,
    };
  }
};
const cancelChanges = () => {
  nextTick(() => {
    if (profile.value) syncEdits(profile.value);
  });
};
</script>

<style scoped></style>
