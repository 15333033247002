<template>
  <v-sheet
    width="666"
    class="mx-auto pa-10 d-flex flex-column align-center text-center rounded-xl bpl-border-gray"
  >
    <div class="d-flex align-center justify-center">
      <v-img :src="require('@/assets/logo.webp')" width="82" height="82" />
      <h4 class="ml-3 text-h4 text-primary font-weight-light flex-shrink-0">
        {{ $t("password-requested") }}
      </h4>
    </div>
    <v-img
      class="mt-6"
      :src="require('@/assets/mail.svg')"
      width="125"
      height="121"
    />
    <p class="mt-12 text-body-1 text-grey-darken-2">
      {{ $t("notification-password-request") }}
    </p>
  </v-sheet>
</template>
