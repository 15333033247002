<template>
  <auth-wrapper>
    <v-sheet width="487" class="mx-auto py-12 px-10 rounded-xl bpl-border-gray">
      <v-alert
        v-if="resetError"
        :title="$t('notification-request-failed')"
        :text="resetError"
        color="error"
        variant="tonal"
        class="mb-6"
      ></v-alert>
      <div class="d-flex align-center justify-center">
        <v-img :src="require('@/assets/logo.webp')" width="82" height="82" />
        <h4 class="ml-3 text-h4 text-primary font-weight-light flex-shrink-0">
          {{ $t("change-password") }}
        </h4>
      </div>
      <v-form
        ref="form"
        v-model="valid"
        class="mt-6"
        @submit.prevent="callResetPassword()"
      >
        <v-text-field
          v-model="password"
          :error-messages="errors.password"
          name="new-password"
          autocomplete="new-password"
          :label="$t('new-password')"
          :append-inner-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[
            rules.required,
            rules.numberHas,
            rules.letterHas,
            rules.symbolHas,
            rules.letterLowercaseHas,
            rules.letterUppercaseHas,
            rules.lengthMin(8),
          ]"
          :type="passwordShow ? 'text' : 'password'"
          counter
          variant="outlined"
          class="pb-2"
          @click:append-inner="passwordShow = !passwordShow"
          @update:model-value="errors.password = []"
        ></v-text-field>
        <v-text-field
          v-model="passwordConfirmation"
          :error-messages="errors.password_confirmation"
          name="confirm-password"
          autocomplete="confirm-password"
          :label="$t('repeat-password')"
          :append-inner-icon="
            passwordConfirmationShow ? 'mdi-eye' : 'mdi-eye-off'
          "
          :rules="[rules.required, rules.match(password)]"
          :type="passwordConfirmationShow ? 'text' : 'password'"
          variant="outlined"
          class="pb-2"
          @click:append-inner="
            passwordConfirmationShow = !passwordConfirmationShow
          "
          @update:model-value="errors.password_confirmation = []"
        ></v-text-field>
        <v-btn
          :loading="loading"
          :disabled="!isValidLink"
          type="submit"
          :color="isValidLink ? 'primary' : ''"
          size="large"
          block
          class="mt-6"
        >
          {{ $t("button.change-password") }}
        </v-btn>
      </v-form>
    </v-sheet>
  </auth-wrapper>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { VForm } from "vuetify/components";

import AuthWrapper from "@/components/Auth/AuthWrapper.vue";

import { useRules } from "@/utils/rules";
import { useValidHelper } from "@/utils/validHelper";

import { reset } from "@/api/auth";
import { ResetObject } from "@/types/auth";

const route = useRoute();
const router = useRouter();
const { t } = useI18n();
const rules = useRules();

const valid = ref(false);
const form = ref<VForm>();

useValidHelper(form);

const email = ref("");
const token = ref("");
const password = ref("");
const passwordShow = ref(false);

const passwordConfirmation = ref("");
const passwordConfirmationShow = ref(false);

const resetError = ref("");
const errors = ref({
  email: [],
  token: [],
  password: [],
  password_confirmation: [],
});
const loading = ref(false);

const isValidLink = computed(() => !!email.value && !!token.value);

const callResetPassword = async () => {
  if (!loading.value) {
    await form.value?.validate();
    if (valid.value && isValidLink.value) {
      try {
        loading.value = true;
        const data: ResetObject = {
          email: email.value,
          token: token.value,
          password: password.value,
          password_confirmation: passwordConfirmation.value,
        };
        await reset(data);
        await router.push({
          name: "login",
          query: { success_message: t("notification-password-reset") },
        });
      } catch (e: any) {
        resetError.value = e.message;
        errors.value = {
          email: [],
          token: [],
          password: [],
          password_confirmation: [],
          ...e.errors,
        };
      } finally {
        loading.value = false;
      }
    }
  }
};

onMounted(() => {
  email.value = String(route.query.email);
  token.value = String(route.query.token);
});
</script>
