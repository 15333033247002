import { del, get, put } from "@/api/index";
import { MetaPagination } from "@/types/other";
import {
  CompanyData,
  CompanyQueryType,
  EditCompanyObject,
} from "@/types/company";

export const getCompanies = (params: {
  include: string;
  filter: string;
  page: number;
  search: string;
  type: CompanyQueryType;
  searchJoin?: string;
  orderBy?: string;
  sortedBy?: string;
}) => get<{ data: CompanyData[]; meta: MetaPagination }>("companies", params);

export const getCompany = (id: string, params?: { include: string }) =>
  get<{ data: CompanyData }>(`companies/${id}`, params);

export const removeCompanies = (ids: string[]) => del(`companies`, { ids });

export const changeCompanyStatus = (
  id: string,
  status: CompanyData["status"],
  params?: { include: string },
) => put<{ data: CompanyData }>(`companies/${id}`, { status }, params);

export const updateCompany = (
  id: string,
  companyData: EditCompanyObject,
  params?: { include: string },
) => put<{ data: CompanyData }>(`companies/${id}`, companyData, params);
