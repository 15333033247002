import { InjectionKey } from "vue";
import { createStore, useStore as baseUseStore, Store } from "vuex";

import { app, AppState } from "@/store/app";
import { auth, AuthState } from "@/store/auth";
import { calculator, CalculatorState } from "@/store/calculator";

export interface State {
  app: AppState;
  auth: AuthState;
  calculator: CalculatorState;
}

export const storeKey: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
  modules: {
    app,
    auth,
    calculator,
  },
});

export const useStore = () => {
  return baseUseStore(storeKey);
};
