<template>
  <v-menu activator="parent" location="start" :close-on-content-click="false">
    <v-list rounded="lg" elevation="5">
      <v-list-item
        v-for="(loc, j) in store.state.app.locsList"
        :key="j"
        class="pl-1"
      >
        <template #default>
          <v-radio
            v-model="locale"
            :value="loc.language.code"
            color="primary"
            @click="locale = loc.language.code"
          >
            <template #label>
              <div class="d-flex w-100 justify-space-between">
                <span class="pr-8">{{ loc.language.locale_name }}</span>
                <v-img
                  :src="
                    require(`../assets/icons/localization/${loc.language.code}.svg`)
                  "
                  width="24"
                  class="ml-4 flex-shrink-0 flex-grow-0"
                />
              </div>
            </template>
          </v-radio>
        </template>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";

const store = useStore();
const { locale } = useI18n();
</script>

<style scoped></style>
