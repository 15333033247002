import { LanguageType, CountryObjectRaw } from "@/types/other";
import { CompanyData } from "@/types/company";
import { Pallet, Vehicle } from "@/types/settings";
import { ProfileData } from "@/types/auth";
import { StationObject } from "@/types/calculator";
import { RideObject } from "@/types/rides";
import { PaymentTargetType } from "@/types/calculator";

export enum DocumentType {
  order = "order",
  offer = "offer",
}

export enum PointType {
  loadingPoint = "loading-point",
  unloadingPoint = "unloading-point",
  palletExchange = "pallet-exchange",
  customsStation = "customs-station",
}

export enum PalletExchange {
  noExchange = "no-exchange",
  exchange = "exchange",
  palletSlipDpl = "pallet-slip-dpl",
  palletNoteUnloadingPoint = "pallet-note-unloading-point",
  returnToLoadingPoint = "return-to-loading-point",
  returnToAnotherBranch = "return-to-another-branch",
}

export enum OfferStatusType {
  open = "open",
  expired = "expired",
  onRequest = "on-request",
  priceOnRequest = "price-on-request",
}

export enum OrderStatusType {
  upcoming = "upcoming",
  billing = "billing",
  ongoing = "ongoing",
  completed = "completed",
  outageFreight = "outage-freight",
  fautfracht = "fautfracht",
  tripCompleted = "trip-completed",
  canceled = "canceled",
}

export enum ClientStatusType {
  accepted = "accepted",
  blocked = "blocked",
  pending = "pending",
  rejected = "rejected",
}

export enum TransporterStatusType {
  accepted = "accepted",
  blocked = "blocked",
  pending = "pending",
  rejected = "rejected",
  temporarilyBlocked = "temporarily_blocked",
}

export enum StatusContext {
  offer = "offer",
  order = "order",
  client = "client",
  transporter = "transporter",
}

export enum GoodsProductGroup {
  productGroupItem1 = "0",
  productGroupItem2 = "1",
  productGroupItem3 = "2",
  productGroupItem4 = "3",
  productGroupItem5 = "4",
}

export type PointObject = {
  type: PointType | null;
  country_id: string | null;
  zip: string | null;
  discharged: boolean;
};

export type Address = {
  active: boolean;
  company_name?: string;
  street?: string;
  house?: string;
  country_code?: string;
  zip?: string;
  city?: string;
};

export type PointMeta = {
  reference?: string;
  pallet_type?: string;
  number_of_pallets?: string;
  pallet_exchange?: PalletExchange;
  return_to_loading_point?: string;
  weight_goods?: string;
  pallet_type_other?: string;
  other_comments?: string;
  another_branch: Address;
  neutral_shipping: Address;
};

export type EditPointObject = {
  id: string;
  agent_firstname: string;
  agent_lastname: string;
  city: string;
  company_name: string;
  country: { data: CountryObjectRaw };
  house: string;
  postcode: string;
  street: string;
  discharged: boolean;
  meta: PointMeta;
  type: PointType;
};

export type SavedPointObject = {
  id: string;
  agent_firstname: string;
  agent_lastname: string;
  city: string;
  company_name?: string;
  country: { data: CountryObjectRaw };
  house: string;
  postcode: string;
  street: string;
  discharged: boolean;
  meta?: string;
  type: PointType;
};

export type StatusType =
  | OfferStatusType
  | OrderStatusType
  | ClientStatusType
  | TransporterStatusType;

export type PrepareObject = {
  vignette: boolean;
  optimal_route: boolean;
  points: PointObject[];
};

export type DocumentObject = {
  type: DocumentType;
  special_department?: boolean;
  manual?: boolean;
  loading_time_start?: string;
  loading_time_end?: string;
  unloading_time_start?: string;
  unloading_time_end?: string;
  vignette: boolean;
  optimal_route: boolean;
  points_ids: string[];
  transport_type_id?: string;
  goods_type?: string;
  goods_weight?: string;
  goods_value?: string;
  goods_length?: string;
  goods_width?: string;
  goods_height?: string;
  goods_insurance: boolean;
  goods_product_group?: GoodsProductGroup;
  pallet_exchange_id?: string;
  company_id?: string;
  user_id?: string;
  // no localStore fields
  distance?: number;
  total_price?: number;
  transporter_price?: number;
  calc?: string;
  optimal_loading_time?: string;
  price_on_request?: boolean;
  // row fields
  adr: string | null;
  languageSkills: string | null;
  other: string[];
  firstStationData: StationObject;
  stationsList: StationObject[];
  lastStationData: StationObject;
  document_count: string;
  marketplace?: boolean;
};

export type DocumentSavedData = {
  id: string;
  number: string;
  type: DocumentType;
  special_department: boolean;
  manual: boolean;
  payment_target: PaymentTargetType;
  company: { data: CompanyData };
  contact: { data: ProfileData };
  routes: string;
  created_at: string;
  transporter_assigned_at: string;
  points: { data: SavedPointObject[] };
  loadingPoint?: { data: SavedPointObject };
  unloadingPoint?: { data: SavedPointObject };
  filled: boolean;
  pallet: { data: Pallet };
  vehicle: { data: Vehicle };
  user: { data: ProfileData };
  status: OfferStatusType | OrderStatusType;
  loading_time_start: string;
  loading_time_end: string;
  unloading_time_start: string;
  unloading_time_end: string;
  reference_number: string;
  goods_type: string;
  goods_value: string;
  goods_weight: string;
  goods_length: string;
  goods_width: string;
  goods_height: string;
  goods_insurance: boolean;
  goods_product_group: GoodsProductGroup | null;
  optimal_route: boolean;
  vignette: boolean;
  adr: number;
  code_xl: boolean;
  drinks_zf: boolean;
  a_shield: boolean;
  customs_clearance: boolean;
  insurance: boolean;
  forklift_license: boolean;
  anti_slip_mats: boolean;
  language_skills: LanguageType | null;
  calc: string;
  price_on_request?: boolean;
  distance: number;
  carrier: { data: CompanyData } | null;
  carrierContact: { data: ProfileData } | null;
  ride: { data: RideObject } | null;
  total_price: number;
  total_price_initial: number;
  transporter_price: number;
  transporter_price_initial: number;
  marketplace?: boolean;
};

export type DocumentUpdateObject = {
  type?: DocumentType;
  status?: OfferStatusType | OrderStatusType;
  points?: SavedPointObject[];
  filled?: boolean;
  carrier_id?: string;
  carrier_contact_id?: string;
  loading_time_start?: string;
  loading_time_end?: string;
  unloading_time_start?: string;
  unloading_time_end?: string;
  total_price?: number;
  transporter_price?: number;
  marketplace?: boolean;
  reference_number?: string;
};

export type DocumentErrorObject = {
  loading_time_start: string[];
  loading_time_end: string[];
  unloading_time_start: string[];
  unloading_time_end: string[];
  vignette: string[];
  optimal_route: string[];
  points_ids: string[];
  transport_type_id: string[];
  reference_number: string[];
  goods_type: string[];
  goods_weight: string[];
  goods_value: string[];
  goods_length: string[];
  goods_width: string[];
  goods_height: string[];
  goods_insurance: string[];
  goods_product_group: string[];
  pallet_exchange_id: string[];
  company_id: string[];
  user_id: string[];
  total_price: string[];
  transporter_price: string[];
  document_count: string[];
};
