<template>
  <v-form ref="form" v-model="valid" @submit.prevent="callSubmit()">
    <template
      v-if="store.state.app.vehicles.length && store.state.app.pallets.length"
    >
      <div class="pt-10 pb-6">
        <p class="text-h5 text-primary mb-8">
          {{ $t("arrival-time") }}
        </p>
        <arrival-time-block
          v-model="documentObject"
          :form="form"
          @loading="updateLoading"
        />
      </div>
      <v-divider />
      <div class="pt-10 pb-6">
        <p class="text-h5 text-primary mb-8">
          {{ $t("transportation") }}
        </p>
        <transport-block v-model="documentObject" />
      </div>
      <v-divider />
      <div class="pt-10">
        <p class="text-h5 text-primary mb-8">
          {{ $t("goods") }}
        </p>
        <goods-block v-model="documentObject" />
      </div>
      <v-divider />
      <div class="pt-10 pb-6">
        <p class="text-h5 text-primary mb-8">
          {{ $t("special-features") }}
        </p>
        <features-block v-model="documentObject" />
      </div>
      <v-divider />
      <div class="pt-10">
        <p class="text-h5 text-primary mb-8">
          {{ $t("other") }}
        </p>
        <other-block v-model="documentObject" />
      </div>
    </template>
    <app-loader v-else />
    <div class="d-flex justify-space-between mt-10">
      <v-btn
        variant="text"
        color="primary"
        size="large"
        @click="emit('step', 0)"
      >
        {{ $t("button.back") }}
      </v-btn>
      <v-btn type="submit" size="large" color="primary">
        {{ $t("button.next") }}
      </v-btn>
    </div>
  </v-form>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "@/store";

import { useValidHelper } from "@/utils/validHelper";
import { useMountScroller } from "@/utils/mountScroller";
import { getLocalDocumentObject } from "@/utils/calculator";

import { DocumentType, DocumentObject } from "@/types/document";
import { VForm } from "vuetify/components";

import AppLoader from "@/components/AppLoader.vue";
import ArrivalTimeBlock from "@/components/CalculatorView/Blocks/ArrivalTimeBlock.vue";
import TransportBlock from "@/components/CalculatorView/Blocks/TransportBlock.vue";
import GoodsBlock from "@/components/CalculatorView/Blocks/GoodsBlock.vue";
import FeaturesBlock from "@/components/CalculatorView/Blocks/FeaturesBlock.vue";
import OtherBlock from "@/components/CalculatorView/Blocks/OtherBlock.vue";

const store = useStore();
const form = ref<VForm>();

useValidHelper(form);
useMountScroller();

const documentObject = ref<DocumentObject>(getLocalDocumentObject());

const valid = ref(false);
const loading = ref(false);

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "step", data: number): void;
}>();

const callSubmit = async () => {
  await form.value?.validate();
  if (valid.value && !loading.value) {
    store.commit("calculator/setDocumentLocal", documentObject.value);
    store.commit("calculator/setSeasonFactorLocal");
    store.commit("calculator/addStep", 2);
    emit("step", 2);
  }
};

const updateLoading = (value: boolean) => {
  loading.value = value;
};

const isPriceOnRequest = computed(
  () =>
    !!store.state.calculator.document.manual ||
    !!store.state.calculator.priceOnRequest.features ||
    !!store.state.calculator.prepare.ferries.price_on_request ||
    !!store.state.calculator.calc.priceOnRequestOverpriced ||
    !!store.state.calculator.calc.priceOnRequestOverweight ||
    !!store.state.calculator.calc.priceOnRequestOversize ||
    !!store.state.calculator.calc.priceOnRequestTime,
);

watch(
  isPriceOnRequest,
  (value) => {
    documentObject.value.type = value ? DocumentType.offer : DocumentType.order;
    documentObject.value.price_on_request = value;
  },
  { immediate: true },
);

watch(
  documentObject,
  (value) => {
    store.commit("calculator/setDocument", value);
  },
  { deep: true },
);

onMounted(() => {
  store.commit("calculator/refreshDocument");
});
</script>

<style scoped></style>
