<template>
  <div>
    <div class="bpl-fake-input position-relative">
      <span
        class="bpl-fake-input-text"
        :class="{ 'with-icon': props.appendInnerIcon }"
        >{{ props.value }}</span
      >
      <span
        class="bpl-fake-input-label position-absolute text-caption text-grey-darken-1"
        >{{ props.label }}</span
      >
      <v-icon
        v-if="props.appendInnerIcon"
        class="bpl-fake-input-icon position-absolute"
        :icon="props.appendInnerIcon"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  label: string;
  value?: string | number;
  appendInnerIcon?: string;
}>();
</script>

<style scoped>
.bpl-fake-input {
  border-bottom: 1px rgba(0, 0, 0, 0.42) solid;
  height: 56px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin-bottom: 22px;
  padding: 26px 0 6px 16px;
  background-color: rgba(0, 0, 0, 0.0348);
}
.bpl-fake-input .bpl-fake-input-text {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  letter-spacing: 0.009375em;
  width: calc(100% - 18px);
}
.bpl-fake-input .bpl-fake-input-text.with-icon {
  width: calc(100% - 42px);
}
.bpl-fake-input .bpl-fake-input-label {
  top: 7px;
  left: 16px;
}
.bpl-fake-input .bpl-fake-input-icon {
  top: 16px;
  right: 12px;
  opacity: 0.6;
}
</style>
