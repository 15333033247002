import { store } from "@/store";
import { PermissionType, RoleType } from "@/types/roles";

const can = (name: PermissionType) =>
  store.getters["auth/permissions"].includes(name);
const allCan = (names: PermissionType[]) => names.every((n) => can(n));

const someCan = (names: PermissionType[]) => names.some((n) => can(n));

const role = (name: RoleType) => store.getters["auth/roles"].includes(name);
const allRoles = (names: RoleType[]) => names.every((n) => role(n));

const someRoles = (names: RoleType[]) => names.some((n) => role(n));

const routesByRoles: { role: RoleType; route: string }[] = [
  { role: "admin", route: "calculator" },
  { role: "sub-admin", route: "calculator" },
  { role: "bpl-manager", route: "calculator" },
  { role: "bpl-calculation-manager", route: "calculator" },
  { role: "forwarder", route: "marketplace" },
  { role: "sub-forwarder", route: "marketplace" },
  { role: "planner", route: "marketplace" },
  // { role: "accounting-contractor", route: "marketplace" },
  { role: "accounting-contractor", route: "orders" },
  { role: "driver", route: "rides" },
  { role: "pallet-department-contractor", route: "marketplace" },
  { role: "industry", route: "calculator" },
  { role: "sub-industry", route: "calculator" },
  { role: "accounting-client", route: "offers" },
  { role: "manager", route: "calculator" },
  { role: "logistic", route: "calculator" },
  { role: "pallet-department-client", route: "calculator" },
];

const getRole = () => store.getters["auth/roles"][0];
const routeByRole = (role: RoleType) =>
  routesByRoles.find((d) => d.role === role)?.route || "profile-settings";

export const access = {
  can,
  allCan,
  someCan,
  role,
  allRoles,
  someRoles,
  routeByRole,
  getRole,
};
