<template>
  <div>
    <p class="text-h5 text-primary pt-2 pb-6">
      {{ $t("driver") }}
    </p>
    <v-combobox
      variant="filled"
      :label="$t('ride.find-driver')"
      :model-value="
        props.ride.step_1_drivers?.map((user) =>
          joinWithDots(`${user.firstname} ${user.lastname}`, user.phone),
        )
      "
      multiple
      chips
      readonly
      class="pointer-events-none pb-2"
    />
    <p class="text-h5 text-primary pt-2 pb-6">
      {{ $t("ride.tracking") }}
    </p>
    <v-row class="my-0">
      <v-col cols="6" class="py-0">
        <fake-input
          :label="$t('ride.license-plate')"
          class="pb-2"
          :value="props.ride.step_1_license_plate"
        />
      </v-col>
      <v-col cols="6" class="py-0">
        <v-text-field
          :label="$t('ride.gps')"
          class="pb-2"
          readonly
          :model-value="props.ride.step_1_gps_link"
          :append-inner-icon="
            props.ride.step_1_gps_link ? 'mdi-content-copy' : (undefined as any)
          "
          @click:append-inner="copyToClipboard(props.ride.step_1_gps_link)"
        />
      </v-col>
    </v-row>
    <p class="text-h5 text-primary pt-2 pb-6">
      {{ $t("ride.estimated-arrival") }}
    </p>
    <fake-input
      :label="$t('ride.scheduled-arrival-loading-point')"
      class="pb-2"
      :value="formatDates.step_1_arrival_time"
      append-inner-icon="mdi-calendar"
    />
    <p class="text-h5 text-primary pt-2 pb-6">
      {{ $t("ride.estimated-time") }}
    </p>
    <fake-input
      :label="$t('ride.scheduled-arrival-loading-point')"
      class="pb-2"
      :value="formatDates.step_2_arrival_time"
      append-inner-icon="mdi-calendar"
    />
    <v-row class="my-0">
      <v-col cols="6" class="py-0"
        ><p class="text-h5 text-primary pt-2 pb-6">
          {{ $t("actual-arrival") }}
        </p></v-col
      >
      <v-col cols="6" class="py-0"
        ><p class="text-h5 text-primary pt-2 pb-6">
          {{ $t("charging-status") }}
        </p></v-col
      >
    </v-row>
    <v-row class="my-0">
      <v-col cols="6" class="py-0">
        <fake-input
          :label="$t('arrived-loading-point')"
          class="pb-2"
          :value="formatDates.step_3_arrival_time"
          append-inner-icon="mdi-calendar"
      /></v-col>
      <v-col :cols="props.ride.step_3_page ? 3 : 6" class="py-0">
        <fake-input
          :label="$t('status')"
          class="pb-2"
          :value="$t(loadingStatus)"
        />
      </v-col>
      <v-col v-if="props.ride.step_3_page" cols="3" class="py-0">
        <fake-input
          :label="$t('pager-number')"
          class="pb-2"
          :value="props.ride.step_3_page"
        /> </v-col
    ></v-row>
    <p class="text-h5 text-primary pt-2 pb-6">
      {{ $t("actual-departure") }}
    </p>
    <fake-input
      :label="$t('leave-loading-point')"
      class="pb-2"
      :value="formatDates.step_4_leave_point_time"
      append-inner-icon="mdi-calendar"
    />
    <p class="text-h5 text-primary pt-2 pb-6">
      {{ $t("estimated-arrival") }}
    </p>
    <fake-input
      :label="$t('scheduled-unloading-point')"
      class="pb-2"
      :value="formatDates.step_4_scheduled_unloading_time"
      append-inner-icon="mdi-calendar"
    />
    <p class="text-h5 text-primary pt-2 pb-6">
      {{ $t("estimated-arrival") }}
    </p>
    <fake-input
      :label="$t('scheduled-unloading-point')"
      class="pb-2"
      :value="formatDates.step_5_scheduled_unloading_time"
      append-inner-icon="mdi-calendar"
    />
    <v-row class="my-0">
      <v-col cols="6" class="py-0"
        ><p class="text-h5 text-primary pt-2 pb-6">
          {{ $t("actual-arrival") }}
        </p></v-col
      >
      <v-col cols="6" class="py-0"
        ><p class="text-h5 text-primary pt-2 pb-6">
          {{ $t("charging-status") }}
        </p></v-col
      >
    </v-row>
    <v-row class="my-0">
      <v-col cols="6" class="py-0">
        <fake-input
          :label="$t('arrived-loading-point')"
          class="pb-2"
          :value="formatDates.step_6_arrived_unloading_time"
          append-inner-icon="mdi-calendar"
      /></v-col>
      <v-col :cols="props.ride.step_6_page ? 3 : 6" class="py-0">
        <fake-input
          :label="$t('status')"
          class="pb-2"
          :value="$t(unloadingStatus)"
        />
      </v-col>
      <v-col v-if="props.ride.step_6_page" cols="3" class="py-0">
        <fake-input
          :label="$t('pager-number')"
          class="pb-2"
          :value="props.ride.step_6_page"
        /> </v-col
    ></v-row>
    <p class="text-h5 text-primary pt-2 pb-6">
      {{ $t("transport-completed") }}
    </p>
    <fake-input
      :label="$t('transport-completed')"
      class="pb-2"
      :value="formatDates.step_7_leave_unloading_time"
      append-inner-icon="mdi-calendar"
    />
    <v-alert
      v-if="fileError"
      :text="$t('alert.document-upload-refused')"
      color="error"
      variant="tonal"
      class="my-8"
      icon="mdi-alert-octagon-outline"
      closable
    ></v-alert>
    <v-alert
      v-if="fileSuccess"
      :text="$t('alert.document-upload-completed')"
      color="success"
      variant="tonal"
      class="mb-6"
      icon="mdi-check-circle"
      closable
    ></v-alert>
    <ride-files-view
      v-if="isBplAdmin || isTransporter"
      :ride="props.ride"
      readonly
      @upload-error="fileError = $event"
      @upload-success="fileSuccess = $event"
      @update-ride="emit('updateRide', $event)"
    />
    <div v-if="isBplAdmin" class="d-flex justify-end mt-12">
      <v-btn
        v-if="!isTransportCompleted"
        :loading="completeLoading"
        type="button"
        color="primary"
        size="large"
        :disabled="isTransportCompleted"
        @click="completeTransport"
      >
        {{ $t("button.complete-transport") }} </v-btn
      ><v-btn
        v-else
        size="large"
        class="bg-grey-lighten-1 pointer-events-none"
        elevation="0"
        ><span class="text-white">
          {{ $t("button.transport-completed") }} – {{ updatedTime }}
        </span></v-btn
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import moment from "moment/moment";

import {
  copyToClipboard,
  getRideLoadingStatusName,
  getRideUnloadingStatusName,
  joinWithDots,
} from "@/utils/other";
import { useLocFormat } from "@/utils/locFormat";
import { access } from "@/utils/access";

import { RideObject, RideStatus } from "@/types/rides";

import FakeInput from "@/components/FakeInput.vue";
import RideFilesView from "@/components/RideView/RideSeventh/RideFilesView.vue";
import { updateRide } from "@/api/rides";
import { snackbar } from "@/utils/snackbar";

const locFormat = useLocFormat();

const fileError = ref(false);
const fileSuccess = ref(false);

const completeLoading = ref(false);

const include = "document.carrier.users";

const formatDates = computed(() => {
  const formatTime = (time: string) => {
    return moment(time).format(locFormat.value.dDMmYHhMmUhr);
  };
  return {
    step_1_arrival_time: formatTime(props.ride.step_1_arrival_time),
    step_2_arrival_time: formatTime(props.ride.step_2_arrival_time),
    step_3_arrival_time: formatTime(props.ride.step_3_arrival_time),
    step_4_leave_point_time: formatTime(props.ride.step_4_leave_point_time),
    step_4_scheduled_unloading_time: formatTime(
      props.ride.step_4_scheduled_unloading_time,
    ),
    step_5_scheduled_unloading_time: formatTime(
      props.ride.step_5_scheduled_unloading_time,
    ),
    step_6_arrived_unloading_time: formatTime(
      props.ride.step_6_arrived_unloading_time,
    ),
    step_7_leave_unloading_time: formatTime(
      props.ride.step_7_leave_unloading_time,
    ),
  };
});

const loadingStatus = computed(() =>
  getRideLoadingStatusName(props.ride.step_3_status),
);

const unloadingStatus = computed(() =>
  getRideUnloadingStatusName(props.ride.step_6_status),
);

const isBplAdmin = computed(() =>
  access.someRoles(["admin", "sub-admin", "bpl-manager"]),
);

const isTransporter = computed(() =>
  access.someRoles([
    "forwarder",
    "sub-forwarder",
    "planner",
    "accounting-contractor",
    "driver",
    "pallet-department-contractor",
  ]),
);

const props = defineProps<{
  ride: RideObject;
  editable?: boolean;
}>();

const completeTransport = async () => {
  try {
    completeLoading.value = true;
    const response = await updateRide(
      props.ride.id,
      {
        status: RideStatus.transportCompleted,
      },
      {
        include,
      },
    );

    emit("updateRide", response.data);
  } catch (e: any) {
    snackbar(e.message);
  } finally {
    completeLoading.value = false;
  }
};

const isTransportCompleted = computed(
  () => props.ride.status === RideStatus.transportCompleted,
);

const updatedTime = computed(() =>
  moment(props.ride.updated_at).format(locFormat.value.dDMmYWithDots),
);

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "updateRide", data: RideObject): void;
}>();
</script>

<style scoped></style>
