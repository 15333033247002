<template>
  <v-row no-gutters>
    <v-col cols="12" class="pa-10">
      <v-sheet class="py-12 px-10 rounded-xl bpl-border-gray">
        <v-form ref="form" v-model="valid" @submit.prevent="saveChanges()">
          <editable-block v-model="edited" :loading="editLoading" submit>
            <template #title>
              <p class="text-h4 text-primary font-weight-light">
                {{ $t("pallets") }}
              </p>
            </template>
            <template #edit-btn="{ edit }">
              <v-btn color="primary" @click="edit">
                {{ $t("button.edit-pallet") }}
              </v-btn>
            </template>
            <template #default="{ readonly }">
              <div class="mt-10">
                <div
                  v-if="edited"
                  class="px-4 pt-8 pb-4 bg-grey-lighten-5 mb-10"
                >
                  <p class="text-h5 text-primary pb-8">
                    {{ $t("edit-pallet-type") }}
                  </p>
                  <v-row class="my-0">
                    <v-col cols="4" class="py-0">
                      <v-autocomplete
                        v-model="palletId"
                        autocomplete="off"
                        :rules="[rules.required]"
                        :label="$t('pallet-type')"
                        :items="store.state.app.pallets"
                        item-title="name"
                        item-value="id"
                        class="pb-2"
                        :readonly="readonly"
                        :variant="readonly ? 'filled' : 'outlined'"
                        :bg-color="!readonly ? ('white' as any) : undefined"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="4" class="py-0">
                      <v-text-field
                        v-model="editPallet.price"
                        :error-messages="errors.price"
                        :label="$t('fixed-price')"
                        append-inner-icon="mdi-currency-eur"
                        class="pb-2"
                        :readonly="readonly"
                        :variant="readonly ? 'filled' : 'outlined'"
                        :bg-color="!readonly ? ('white' as any) : undefined"
                        :rules="[rules.required, rules.numberOnly]"
                        @update:model-value="errors.price = []"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4" class="py-0">
                      <v-text-field
                        v-model="editPallet.price_accounting"
                        :error-messages="errors.price_accounting"
                        :label="$t('fixed-price-pallet-account')"
                        append-inner-icon="mdi-currency-eur"
                        class="pb-2"
                        :readonly="readonly"
                        :variant="readonly ? 'filled' : 'outlined'"
                        :bg-color="!readonly ? ('white' as any) : undefined"
                        :rules="[rules.required, rules.numberOnly]"
                        @update:model-value="errors.price_accounting = []"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2" class="py-0">
                      <v-checkbox
                        v-model="editPallet.active"
                        :label="$t('active')"
                        color="primary"
                        :disabled="readonly"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </div>
                <v-data-table
                  :headers="headers"
                  :items="tableItems"
                  class="no-table-footer elevation-0 bpl-border-gray rounded-lg bpl-table-height-316"
                  :items-per-page="tableItems.length || 5"
                  :items-per-page-options="[]"
                  :loading="
                    !tableItems.length && store.state.app.palletsLoading
                  "
                >
                  <template #[`item.active`]="{ item }">
                    <span v-if="item.active">{{ $t("text.yes") }}</span>
                    <span v-else class="text-red-lighten-1">
                      {{ $t("text.no") }}
                    </span>
                  </template>
                  <template #[`item.actions`]="{ item }">
                    <v-menu>
                      <template #activator="{ props: menuProps }">
                        <v-btn
                          icon="mdi-dots-vertical"
                          variant="plain"
                          elevation="0"
                          v-bind="menuProps"
                        ></v-btn>
                      </template>
                      <v-list min-width="230">
                        <v-list-item @click="clickEdit(item.id)">
                          <v-list-item-title>
                            {{ $t("button.edit") }}
                          </v-list-item-title>
                          <template #append>
                            <v-icon
                              icon="mdi-file-edit"
                              color="grey-darken-2"
                            ></v-icon>
                          </template>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-data-table>
              </div>
            </template>
          </editable-block>
        </v-form>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useStore } from "@/store";
import { useI18n } from "vue-i18n";

import { snackbar } from "@/utils/snackbar";
import { useRules } from "@/utils/rules";
import { useValidHelper } from "@/utils/validHelper";
import { getInitialPalletErrorObject } from "@/utils/settings";
import { useFormat } from "@/utils/other";

import { VForm, VTextField } from "vuetify/components";
import { EditPallet, PalletErrorObject } from "@/types/settings";

import EditableBlock from "@/components/EditableBlock.vue";

const { t } = useI18n();
const toFormat = useFormat();

const palletId = ref("");

const initialEditPallet: EditPallet = {
  price: undefined,
  price_accounting: undefined,
  active: false,
};

const errors = ref<PalletErrorObject>(getInitialPalletErrorObject());

const editPallet = ref<EditPallet>({ ...initialEditPallet });

const rules = useRules();

const valid = ref(false);
const form = ref<VForm>();

useValidHelper(form);

const edited = ref(false);

const editLoading = ref(false);

const store = useStore();

const palletById = computed(
  () => store.state.app.pallets.find((p) => p.id === palletId.value) || null,
);

const clickEdit = (id: string) => {
  palletId.value = id;
  edited.value = true;
};

const saveChanges = async () => {
  await form.value?.validate();
  if (valid.value) {
    try {
      editLoading.value = true;

      const id = store.state.app.pallets.find(({ id }) => id === palletId.value)
        ?.id;

      await store.dispatch("app/updatePallet", {
        id,
        pallet: editPallet.value,
      });

      edited.value = false;
    } catch (e: any) {
      snackbar(e.message);
      errors.value = { ...getInitialPalletErrorObject(), ...e.errors };
    } finally {
      editLoading.value = false;
    }
  }
};

const tableItems = computed(() =>
  store.state.app.pallets.map((p, i) => ({
    tableNumber: i + 1,
    pallet: p.name,
    price: toFormat(p.price),
    price_accounting: toFormat(p.price_accounting),
    active: p.active,
    id: p.id,
  })),
);

const headers = computed(
  () =>
    [
      {
        title: t("table-number"),
        key: "tableNumber",
        width: "50px",
        sortable: false,
      },
      {
        title: t("pallets"),
        key: "pallet",
        sortable: false,
      },
      {
        title: `${t("fixed-price")} €`,
        key: "price",
        sortable: false,
      },
      {
        title: `${t("fixed-price-pallet-account")} €`,
        key: "price_accounting",
        sortable: false,
      },
      {
        title: t("active"),
        key: "active",
        sortable: false,
      },
      {
        title: "",
        key: "actions",
        width: "48px",
        align: "end",
        sortable: false,
      },
    ] as any[],
);

watch(edited, (value) => {
  if (!value) {
    palletId.value = "";
    editPallet.value = { ...initialEditPallet };
  }
});

watch(palletById, (value, oldValue) => {
  if (value?.id !== oldValue?.id) {
    editPallet.value = value
      ? {
          price: value.price?.toString(),
          price_accounting: value.price_accounting?.toString(),
          active: value.active,
        }
      : { ...initialEditPallet };
  }
});
</script>

<style>
.no-table-footer .v-data-table-footer {
  display: none;
}
</style>
