<template>
  <v-form ref="form" v-model="valid" @submit.prevent="callSubmit()">
    <div class="pt-10 pb-4">
      <p class="text-h5 text-primary">
        {{ $t("text.calculator-route-data-description") }}
      </p>
      <p class="text-subtitle-2 text-primary">
        {{ $t("text.calculator-route-data-title") }}
      </p>
    </div>
    <route-data-block v-model="documentObject" :errors-raw="errorsRaw" />
    <div class="d-flex justify-end mt-10">
      <v-btn type="submit" size="large" color="primary" :loading="loading">
        {{ $t("button.calculate-ride") }}
      </v-btn>
    </div>
  </v-form>
</template>

<script setup lang="ts">
import { ref, watch, computed, onMounted } from "vue";
import { VForm } from "vuetify/components";
import { useStore } from "@/store";

import { useValidHelper } from "@/utils/validHelper";
import { useMountScroller } from "@/utils/mountScroller";
import { getLocalDocumentObject } from "@/utils/calculator";
import { snackbar } from "@/utils/snackbar";

import { StationObject } from "@/types/calculator";
import { DocumentObject } from "@/types/document";

import RouteDataBlock from "@/components/CalculatorView/Blocks/RouteDataBlock.vue";

const store = useStore();
const form = ref<VForm>();

useValidHelper(form);
useMountScroller();

const documentObject = ref<DocumentObject>(getLocalDocumentObject());

const valid = ref(false);
const loading = ref(false);

const errorsRaw = ref<{ [K: string]: string[] }>({});

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "step", data: number): void;
}>();

const fullStationsList = computed<StationObject[]>(() => [
  documentObject.value.firstStationData,
  ...documentObject.value.stationsList,
  documentObject.value.lastStationData,
]);

const callSubmit = async () => {
  if (!loading.value) {
    await form.value?.validate();
    if (valid.value) {
      try {
        loading.value = true;

        await store.dispatch("calculator/fetchPrepare", {
          vignette: documentObject.value.vignette,
          optimal_route: documentObject.value.optimal_route,
          points: fullStationsList.value,
        });

        store.commit("calculator/setDocumentLocal", documentObject.value);
        store.commit("calculator/addStep", 1);
        emit("step", 1);
      } catch (e: any) {
        snackbar(e.message);
        errorsRaw.value = e.errors;
      } finally {
        loading.value = false;
      }
    }
  }
};

watch(
  fullStationsList,
  () => {
    errorsRaw.value = {};
  },
  { deep: true },
);

watch(
  documentObject,
  (value) => {
    store.commit("calculator/setDocument", value);
  },
  { deep: true },
);

onMounted(() => {
  store.commit("calculator/refreshDocument");
});
</script>

<style scoped></style>
