<template>
  <div class="d-flex justify-space-between pa-6 pb-8">
    <template v-for="(tab, i) in prepTabs" :key="i">
      <div v-if="!!i" class="d-flex align-center w-100 px-2">
        <v-divider />
      </div>
      <div
        class="d-flex flex-column align-center"
        :class="{
          'bpl-progress-tab-active': i <= props.tabIndex && !props.disabled,
        }"
        @click="i <= props.tabIndex && !props.disabled && emit('select', i)"
      >
        <v-chip
          class="text-caption d-flex justify-center text-white progress-tab-chip"
          :class="{
            'bg-primary': i <= props.tabIndex,
            'bg-grey-lighten-1': i > props.tabIndex,
            'bpl-progress-tab-outlined': i === props.currentTabIndex,
          }"
          :link="i <= props.tabIndex && !props.disabled"
          >{{ i + 1 }}
        </v-chip>
        <span
          class="mt-2 text-center progress-tab-chip-title"
          :class="{ 'text-grey-lighten-1': i > props.tabIndex }"
        >
          {{ $t(tab.title) }}
        </span>
        <span
          v-if="tab.subtitle"
          class="text-center progress-tab-chip-title text-caption text-grey-darken-1"
        >
          {{ $t(tab.subtitle) }}
        </span>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { ProgressTab } from "@/types/other";

const props = defineProps<{
  tabIndex: number;
  currentTabIndex?: number;
  tabs: ProgressTab[];
  disabled?: boolean;
}>();

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "select", data: number): void;
}>();

const prepTabs = computed(() =>
  props.tabs.map((tab) => {
    const title = typeof tab === "string" ? tab : tab.title;
    const subtitle = typeof tab === "string" ? "" : tab.subtitle;
    return { title, subtitle };
  }),
);
</script>

<style scoped>
.progress-tab-chip {
  width: 24px;
  height: 24px;
}

.progress-tab-chip-title {
  white-space: nowrap;
}

.bpl-progress-tab-active {
  cursor: pointer;
}

.bpl-progress-tab-outlined {
  outline: 2px #3190da solid;
  outline-offset: 2px;
}
</style>
