<template>
  <div
    class="bpl-ride-status-point"
    :style="{
      backgroundColor: color,
    }"
  >
    <v-icon
      v-if="props.status === RideStatus.transportCompleted"
      icon="mdi-check"
      color="white"
      size="16"
    ></v-icon>
    <span v-else>&nbsp;</span>
  </div>
</template>

<script setup lang="ts">
import { RideStatus } from "@/types/rides";
import { computed } from "vue";

const props = defineProps<{
  status?: RideStatus;
}>();

const color = computed(() => {
  switch (props.status) {
    case RideStatus.success:
      return "#388E3C";
    case RideStatus.warning:
      return "#F57C00";
    case RideStatus.transportCompleted:
      return "#9E9E9E";
    default:
      return "#D32F2F";
  }
});
</script>

<style scoped>
.bpl-ride-status-point {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 20px;
}
</style>
