import { post, get } from "@/api/index";

import { MetaPagination } from "@/types/other";
import { MarketplaceData } from "@/types/marketplace";

export const confirmMarketplace = (id: string) =>
  post<{ data: MarketplaceData }>(`marketplace/${id}/confirm`);

export const getMarketplaces = (params?: {
  include: string;
  filter: string;
  page: number;
  search?: string;
  orderBy?: string;
  sortedBy?: string;
}) =>
  get<{ data: MarketplaceData[]; meta: MetaPagination }>("marketplace", params);

export const getMarketplace = (id: string, params?: { include: string }) =>
  get<{ data: MarketplaceData }>(`marketplace/${id}`, params);
