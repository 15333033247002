import { ref, Ref, watch } from "vue";

export const useWatchDebounceHelper = <T>(
  value: Ref<T>,
  delay: number,
  callback: (v: T) => void
) => {
  let searchTimeout: ReturnType<typeof setTimeout>
  const wait = ref(false);
  const debValue = ref(value.value) as Ref<T>;

  watch(value, (v) => {
    clearTimeout(searchTimeout);
    wait.value = true;
    searchTimeout = setTimeout(() => {
      debValue.value = v;
      wait.value = false;
    }, delay);
  });

  watch(debValue, (v) => {
    callback(v);
  });

  return wait;
};
