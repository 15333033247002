<template>
  <v-row no-gutters>
    <v-col cols="12" class="pa-10">
      <v-sheet class="py-12 px-10 rounded-xl bpl-border-gray">
        <manual-register
          :is-transporter="props.isTransporter"
          :auto-open="autoAddOpen"
          @submit="
            fetchCompanies(
              initialPagData.current_page,
              finalSearchString,
              sortData,
            )
          "
        />
        <div class="mt-10 bpl-border-gray rounded-lg">
          <v-row no-gutters class="align-center px-4 py-3">
            <v-col cols="4">
              <v-btn
                :disabled="!checkedList.length"
                color="primary"
                @click="exportCsv(checkedList)"
              >
                {{ $t("button.csv-export") }}
                {{ checkedList.length ? ` (${checkedList.length})` : "" }}
              </v-btn>
              <v-menu :close-on-content-click="false">
                <template #activator="{ props: menuProps }">
                  <v-btn
                    class="mx-2"
                    icon="mdi-dots-vertical"
                    variant="plain"
                    elevation="0"
                    v-bind="menuProps"
                    :disabled="!checkedList.length"
                  ></v-btn>
                </template>
                <v-list min-width="230">
                  <v-list-item
                    :disabled="!!removedIds.length || !checkedList.length"
                    @click="deleteCompanies(checkedList)"
                  >
                    <v-list-item-title>
                      {{ $t("button.delete") }}
                    </v-list-item-title>
                    <template #append>
                      <v-icon icon="mdi-delete" color="grey-darken-2"></v-icon>
                    </template>
                  </v-list-item>
                </v-list>
              </v-menu>
              <export-loader
                v-if="isExported || exportLoading"
                :tooltip="$t('loader.download-prepared')"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="globSearch"
                :label="$t('search')"
                variant="outlined"
                append-inner-icon="mdi-magnify"
                hide-details
                clearable
            /></v-col>
            <v-col cols="4" class="text-right">
              <v-btn
                elevation="0"
                variant="text"
                class="text-capitalize text-grey-darken-2"
                :class="{ 'text-primary': isAdvancedSearch }"
                :append-icon="!isFormOpened ? 'mdi-menu-down' : 'mdi-menu-up'"
                @click="isFormOpened = !isFormOpened"
                >{{ $t("advanced-search-settings") }}</v-btn
              >
            </v-col>
          </v-row>
          <div v-if="isFormOpened" class="bg-grey-lighten-5 px-4 pt-8">
            <v-row class="my-0">
              <v-col v-if="!props.isTransporter" cols="4" class="py-0">
                <v-select
                  v-model="searchObject.type"
                  :label="$t('customer-type')"
                  :items="typeItems"
                  class="pb-2"
                  variant="outlined"
                  clearable
                  bg-color="white"
                ></v-select>
              </v-col>
              <v-col :cols="!props.isTransporter ? 4 : 6" class="py-0">
                <v-text-field
                  v-model="searchObject.number"
                  :label="$t('customer-number')"
                  class="pb-2"
                  variant="outlined"
                  append-inner-icon="mdi-magnify"
                  bg-color="white"
                  clearable
                >
                </v-text-field>
              </v-col>
              <v-col :cols="!props.isTransporter ? 4 : 6" class="py-0">
                <v-text-field
                  v-model="searchObject.name"
                  :label="$t('company-name')"
                  class="pb-2"
                  variant="outlined"
                  append-inner-icon="mdi-magnify"
                  bg-color="white"
                  clearable
                >
                </v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-text-field
                  v-model="searchObject.city"
                  :label="$t('town')"
                  class="pb-2"
                  variant="outlined"
                  append-inner-icon="mdi-magnify"
                  bg-color="white"
                  clearable
                >
                </v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-text-field
                  v-model="searchObject.plz"
                  :label="$t('plz')"
                  class="pb-2"
                  variant="outlined"
                  append-inner-icon="mdi-magnify"
                  bg-color="white"
                  clearable
                >
                </v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-select
                  v-model="searchObject.status"
                  :label="$t('status')"
                  :items="statuses"
                  class="pb-2"
                  variant="outlined"
                  bg-color="white"
                  clearable
                ></v-select>
              </v-col>
            </v-row>
          </div>
          <v-data-table-server
            :headers="headers.filter((h) => !h.hidden)"
            :items="prepCompanies"
            class="bpl-custom-table-footer elevation-0 border-0 align-self-start bpl-table-height-1096"
            :items-per-page="pagData.per_page"
            :page="pagData.current_page"
            :items-length="pagData.total"
            :items-per-page-options="[]"
            :loading="loading"
            @click:row="clickRow"
            @update:options="
              fetchCompanies($event.page, finalSearchString, $event.sortBy[0])
            "
          >
            <template #[`header.checkbox`]>
              <v-checkbox-btn
                v-model="globCheckbox.checked"
                :true-icon="
                  globCheckbox.interim ? 'mdi-minus-box' : 'mdi-checkbox-marked'
                "
                color="primary"
                :disabled="!prepCompanies.length"
              ></v-checkbox-btn>
            </template>
            <template #[`item.checkbox`]="{ item }">
              <v-checkbox-btn
                v-model="checkedList"
                :value="item.id"
                color="primary"
                @click.stop
              ></v-checkbox-btn>
            </template>
            <template
              v-for="n in [
                'number',
                'type',
                'companyName',
                'companyAddress',
                'contact',
                'phone',
              ]"
              :key="n"
              #[`item.${n}`]="{ item }"
            >
              <span
                :style="
                  item.color && {
                    color: item.color,
                  }
                "
                >{{ item[n] }}
              </span>
            </template>
            <template #[`item.status`]="{ item }">
              <status-chip
                v-if="!props.isTransporter"
                :status="{
                  name: item.status,
                  context: StatusContext.client,
                }"
              />
              <status-chip
                v-else
                :status="{
                  name: item.status,
                  context: StatusContext.transporter,
                }"
              />
            </template>
            <template #[`item.actions`]="{ item }">
              <v-menu :key="loading.toString()">
                <template #activator="{ props: menuProps }">
                  <v-btn
                    class="ml-3"
                    icon="mdi-dots-vertical"
                    variant="plain"
                    elevation="0"
                    v-bind="menuProps"
                  ></v-btn>
                </template>
                <v-list min-width="230">
                  <v-list-item @click="exportCsv([item.id])">
                    <v-list-item-title>
                      {{ $t("button.csv-export") }}
                    </v-list-item-title>
                    <template #append>
                      <v-icon
                        icon="mdi-file-table"
                        color="grey-darken-2"
                      ></v-icon>
                    </template>
                  </v-list-item>
                  <v-list-item
                    :disabled="removedIds.includes(item.id)"
                    @click.stop="deleteCompanies([item.id])"
                  >
                    <v-list-item-title>
                      {{ $t("button.delete") }}
                    </v-list-item-title>
                    <template #append>
                      <v-icon icon="mdi-delete" color="grey-darken-2"></v-icon>
                    </template>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table-server>
          <v-footer
            v-if="globCheckedList.length > checkedList.length"
            app
            color="grey-darken-2 py-4 px-10"
            class="position-fixed"
          >
            <v-row no-gutters>
              <v-col cols="4" class="d-flex align-center">
                <span>
                  {{ globCheckedList.length }}
                  {{ $t("text.client-select-all") }}</span
                >
              </v-col>
              <v-col cols="4" class="text-center">
                <v-btn
                  variant="text"
                  color="primary"
                  class="bg-white ml-2"
                  @click="exportCsv(globCheckedList)"
                >
                  {{ $t("button.csv-export") }}
                </v-btn>
                <v-btn
                  variant="text"
                  color="red-darken-3"
                  class="bg-red-lighten-4 ml-2"
                  :disabled="globCheckedList.length === removedIds.length"
                  @click="deleteCompanies(globCheckedList)"
                >
                  {{ $t("button.delete-all") }}
                </v-btn>
              </v-col>
              <v-col cols="4" class="text-right">
                <v-btn variant="text" @click="deselectAll">
                  {{ $t("button.deselect-all") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-footer>
        </div>
      </v-sheet>
    </v-col></v-row
  >
</template>

<script setup lang="ts">
import { computed, ref, watch, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";

import { getCompanies, removeCompanies } from "@/api/companies";

import { useCheckboxes } from "@/utils/checkboxHelper";
import { snackbar } from "@/utils/snackbar";
import {
  calcFetchPage,
  calcGlobChecked,
  companyTypeKeys,
  getCompanyTypeName,
  getMatches,
  initialPagData,
  joinWithDots,
  prepSearchString,
} from "@/utils/other";
import { getStatus, statusesData } from "@/utils/statuses";
import { useWatchDebounceHelper } from "@/utils/watchDebounceHelper";
import { useConfirm } from "@/utils/confirmHelper";

import { ExportNotificationType, SortData } from "@/types/other";
import { CompanyData, CompanyType, CompanyQueryType } from "@/types/company";
import {
  ClientStatusType,
  TransporterStatusType,
  StatusContext,
} from "@/types/document";

import StatusChip from "@/components/StatusChip.vue";
import ExportLoader from "@/components/ExportLoader.vue";
import ManualRegister from "@/components/CompaniesView/ManualRegister.vue";

const props = defineProps<{
  isTransporter?: boolean;
}>();

const router = useRouter();
const route = useRoute();
const store = useStore();
const { t } = useI18n();

const include = "contact,headquarters_address";
const filter =
  "id;addendum;number;type;name;code;city;firstname;lastname;phone;status;pagination;zip;street;data";

const companies = ref<CompanyData[]>([]);
const pagData = ref(initialPagData);
const sortData = ref<SortData>();

const isFormOpened = ref(false);

const loading = ref(false);
const exportLoading = ref(false);
const removedIds = ref<string[]>([]);

const autoAddOpen = ref(false);

const deleteConfirm = useConfirm("dialog.delete-item");

const typeItems = computed(() =>
  companyTypeKeys
    .filter(
      ({ type }) => !props.isTransporter && type !== CompanyType.contractor,
    )
    .map(({ key, type }) => ({
      title: t(key),
      value: type,
    })),
);

const statuses = computed(() =>
  statusesData
    .filter(({ context }) => context === StatusContext.client)
    .map(({ key, name }) => ({ title: t(key), value: name })),
);

const headers = computed(
  () =>
    [
      {
        title: "",
        key: "checkbox",
        width: "40px",
        sortable: false,
      },
      { title: t("client-number"), key: "number", sortable: true },
      {
        title: t("client-type"),
        key: "type",
        sortable: true,
        hidden: props.isTransporter,
      },
      {
        title: joinWithDots(t("company-name"), t("addition")),
        key: "companyName",
        sortable: true,
      },
      { title: t("company-address"), key: "companyAddress", sortable: true },
      { title: t("contact"), key: "contact", sortable: true },
      { title: t("phone"), key: "phone", sortable: true },
      { title: t("status"), key: "status", sortable: true },
      {
        title: "",
        key: "actions",
        width: "48px",
        align: "end",
        sortable: false,
      },
    ] as any[],
);

const fetchCompanies = async (
  page: number,
  search: string,
  sort?: SortData,
) => {
  if (!loading.value) {
    try {
      loading.value = true;
      const {
        data,
        meta: { pagination },
      } = await getCompanies({
        include,
        filter,
        page,
        search,
        ...(!isGlobSearch.value ? { searchJoin: "and" } : {}),
        type: !props.isTransporter
          ? CompanyQueryType.customers
          : CompanyQueryType.carrier,
        ...(sort ? { orderBy: sort.key, sortedBy: sort.order } : {}),
      });
      companies.value = data;
      pagData.value = pagination;
    } catch (e: any) {
      snackbar(e.message);
    } finally {
      loading.value = false;
      sortData.value = sort;
    }
  }
};

const globSearch = ref("");

const isGlobSearch = computed(() => !!globSearch.value);

const isAdvancedSearch = computed(
  () => !isGlobSearch.value && !!finalSearchString.value,
);

type CompaniesSearchObject = {
  type?: CompanyType;
  number?: string;
  name?: string;
  city?: string;
  plz?: string;
  status?: CompanyData["status"];
};

const searchObject = ref<CompaniesSearchObject>({});

watch(
  searchObject,
  (value) => {
    if (Object.keys(value).length) globSearch.value = "";
  },
  { deep: true },
);

watch(globSearch, (value) => {
  if (value) {
    searchObject.value = {};
  }
});

const finalSearchString = computed(() =>
  isGlobSearch.value
    ? globSearch.value
    : prepSearchString(
        {
          title: "type",
          value: searchObject.value?.type || "",
        },
        {
          title: "number",
          value: searchObject.value?.number || "",
        },
        {
          title: "name",
          value: searchObject.value?.name || "",
        },
        {
          title: "headquartersAddress.city",
          value: searchObject.value?.city || "",
        },
        {
          title: "headquartersAddress.zip",
          value: searchObject.value?.plz || "",
        },
        {
          title: "status",
          value: searchObject.value?.status || "",
        },
      ),
);

useWatchDebounceHelper<string>(finalSearchString, 400, (value) => {
  if (globSearch.value) isFormOpened.value = false;
  fetchCompanies(1, value, sortData.value);
});
const deleteCompanies = async (ids: string[]) => {
  if (await deleteConfirm()) {
    try {
      removedIds.value = ids;
      await removeCompanies(ids);
      const fetchPage = calcFetchPage(ids.length, pagData.value);

      await fetchCompanies(fetchPage, finalSearchString.value, sortData.value);
      globCheckedList.value = globCheckedList.value.filter(
        (id) => !removedIds.value.includes(id),
      );
    } catch (e: any) {
      snackbar(e.message);
    } finally {
      removedIds.value = [];
    }
  }
};

const clickRow = async (e: any, data: { item: any }) => {
  await router.push({
    name: !props.isTransporter ? "client" : "transporter",
    params: { id: data.item.id },
  });
};

const prepCompanies = computed(() =>
  companies.value.map(
    ({
      id,
      status,
      type,
      name,
      addendum,
      headquarters_address,
      contact,
      number,
    }) => {
      return {
        number,
        type: type ? t(getCompanyTypeName(type)) : "",
        companyName: joinWithDots(name, addendum),
        companyAddress: joinWithDots(
          headquarters_address.data.street,
          headquarters_address.data.country.data.code.toUpperCase(),
          `${headquarters_address.data.zip} ${headquarters_address.data.city}`,
        ),
        contact: contact
          ? `${contact.data.firstname} ${contact.data.lastname}`
          : "",
        phone: contact?.data.phone || "",
        status,
        id,
        color: highlightedStatuses.includes(status)
          ? getStatus(
              status,
              !props.isTransporter
                ? StatusContext.client
                : StatusContext.transporter,
            )?.color
          : undefined,
      };
    },
  ),
);

const highlightedStatuses = [
  ClientStatusType.blocked,
  ClientStatusType.rejected,
  TransporterStatusType.blocked,
  TransporterStatusType.rejected,
  TransporterStatusType.temporarilyBlocked,
];

const defChecked = computed(() => prepCompanies.value.map(({ id }) => id));

const { globCheckbox, checkedList } = useCheckboxes(defChecked);

const globCheckedList = ref(<string[]>[]);

const deselectAll = () => {
  globCheckedList.value = [];
  checkedList.value = [];
};

const exportCsv = async (ids: string[]) => {
  try {
    exportLoading.value = true;
    const type: ExportNotificationType = props.isTransporter
      ? ExportNotificationType.transportersCsvExport
      : ExportNotificationType.clientsCsvExport;
    await store.dispatch("app/exportFiles", { type, ids });
  } catch (e: any) {
    snackbar(e.message);
  } finally {
    exportLoading.value = false;
  }
};

const isExported = computed(() =>
  store.state.app.exportsQueue.some(
    (e) =>
      e.type ===
      (props.isTransporter
        ? ExportNotificationType.transportersCsvExport
        : ExportNotificationType.clientsCsvExport),
  ),
);

watch(defChecked, (value) => {
  checkedList.value = getMatches(value, globCheckedList.value);
});

watch(checkedList, (value) => {
  globCheckedList.value = calcGlobChecked(
    value,
    defChecked.value,
    globCheckedList.value,
  );
});

onMounted(() => {
  if (route.query) {
    if (route.query.add) {
      autoAddOpen.value = true;
    }
    router.replace({ query: undefined });
  }
});
</script>

<style></style>
