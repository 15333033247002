<template>
  <div class="app-loader" :style="{ minHeight: `${props.height || '455px'}` }">
    <v-progress-circular indeterminate color="primary" />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  height?: string;
}>();
</script>

<style scoped>
.app-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
