import { Module } from "vuex";

import { State } from "@/store";

import moment from "moment";

import {
  getLocs,
  getCountries,
  getNotifications,
  markAsReadNotifications,
  exportSome,
} from "@/api/other";

import {
  createVehicle,
  getMarketFactors,
  getPallets,
  getSettings,
  getSpecialFeatures,
  getVehicles,
  removeVehicle,
  updatePallet,
  updateSettings,
  updateSpecialFeature,
  updateVehicle,
} from "@/api/settings";

import { getLocValue, setLocValue } from "@/utils/localStorage";

import {
  CountryObject,
  CountryObjectRaw,
  LocObjectWrap,
  SnackbarData,
  NotificationObject,
  DataBaseNotification,
  ExportNotificationType,
  ExportQueue,
} from "@/types/other";

import {
  EditFeature,
  EditPallet,
  EditSettingsObject,
  EditVehicle,
  MarketFactor,
  Pallet,
  SettingsObject,
  Vehicle,
  FeatureObject,
} from "@/types/settings";
import { addItem, removeItem, updateItem } from "@/utils/listHelper";

export interface AppState {
  locsList: LocObjectWrap[] | null;
  countriesRaw: CountryObjectRaw[];
  snackbar: SnackbarData | null;
  drawerOpened: boolean;
  pallets: Pallet[];
  palletsLoading: boolean;
  vehicles: Vehicle[];
  vehiclesLoading: boolean;
  features: FeatureObject[];
  featuresLoading: boolean;
  market_factors: MarketFactor[] | null;
  settings: SettingsObject | null;
  notifications: DataBaseNotification[];
  exportsQueue: ExportQueue[];
}

export type AppModule = Module<AppState, State>;
export const app: AppModule = {
  namespaced: true,
  state: () => ({
    locsList: [],
    countriesRaw: [],
    snackbar: null,
    drawerOpened: getLocValue("DRAWER_OPENED") !== false,
    pallets: [],
    palletsLoading: true,
    vehicles: [],
    vehiclesLoading: true,
    features: [],
    featuresLoading: true,
    market_factors: null,
    settings: null,
    notifications: [],
    exportsQueue: [],
  }),
  mutations: {
    setLocsList(state, value: LocObjectWrap[]) {
      state.locsList = value;
    },
    setCountries(state, value: CountryObjectRaw[]) {
      state.countriesRaw = value;
    },
    setSnackbar(state, value: SnackbarData | null) {
      state.snackbar = value;
    },
    setDrawerOpened(state, value: boolean) {
      state.drawerOpened = value;
      setLocValue("DRAWER_OPENED", value);
    },
    setPallets(state, value: Pallet[]) {
      state.pallets = value;
    },
    setPalletsLoading(state, value: boolean) {
      state.palletsLoading = value;
    },
    setVehicles(state, value: Vehicle[]) {
      state.vehicles = value;
    },
    setVehiclesLoading(state, value: boolean) {
      state.vehiclesLoading = value;
    },
    setFeatures(state, value: FeatureObject[]) {
      state.features = value;
    },
    setFeaturesLoading(state, value: boolean) {
      state.featuresLoading = value;
    },
    setSettings(state, value: SettingsObject | null) {
      state.settings = value;
    },
    setMarketFactors(state, value: MarketFactor[] | null) {
      state.market_factors = value;
    },
    setNotifications(state, value: DataBaseNotification[]) {
      state.notifications = [...value];
    },
    addNotification(state, value: NotificationObject) {
      const created_at = moment().format();
      state.notifications = [
        { ...value, read_at: null, created_at },
        ...state.notifications,
      ];
    },
    addExportToQueue(state, value: ExportQueue) {
      state.exportsQueue = [...state.exportsQueue, value];
    },
    removeExportFromQueue(state, uuid: string) {
      state.exportsQueue = state.exportsQueue.filter((e) => e.uuid !== uuid);
    },
  },
  actions: {
    async syncLocs(context) {
      const { data } = await getLocs();
      context.commit("setLocsList", data || []);
    },
    async syncCountries(context) {
      const { data } = await getCountries();
      context.commit("setCountries", data || []);
    },
    async fetchPallets(context) {
      context.commit("setPalletsLoading", true);
      const { data } = await getPallets();
      context.commit("setPallets", data);
      context.commit("setPalletsLoading", false);
    },
    async updatePallet(
      context,
      { id, pallet }: { id: string; pallet: EditPallet },
    ) {
      const { data } = await updatePallet(id, pallet);
      context.commit(
        "setPallets",
        updateItem<Pallet>(context.state.pallets, data),
      );
    },
    async fetchVehicles(context) {
      context.commit("setVehiclesLoading", true);
      const { data } = await getVehicles();
      context.commit("setVehicles", data);
      context.commit("setVehiclesLoading", false);
    },
    async fetchFeatures(context) {
      context.commit("setFeaturesLoading", true);
      const { data } = await getSpecialFeatures();
      context.commit("setFeatures", data);
      context.commit("setFeaturesLoading", false);
    },
    async updateVehicle(
      context,
      { id, vehicle }: { id: string; vehicle: EditVehicle },
    ) {
      const { data } = await updateVehicle(id, vehicle);
      context.commit(
        "setVehicles",
        updateItem<Vehicle>(context.state.vehicles, data),
      );
    },
    async addVehicle(context, value: EditVehicle) {
      const { data } = await createVehicle(value);
      context.commit(
        "setVehicles",
        addItem<Vehicle>(context.state.vehicles, data),
      );
    },
    async deleteVehicle(context, value: string) {
      await removeVehicle(value);
      context.commit(
        "setVehicles",
        removeItem<Vehicle>(context.state.vehicles, value),
      );
    },
    async updateFeature(
      context,
      { id, feature }: { id: string; feature: EditFeature },
    ) {
      const { data } = await updateSpecialFeature(id, feature);
      context.commit(
        "setFeatures",
        updateItem<FeatureObject>(context.state.features, data),
      );
    },
    async fetchSettings(context) {
      const { data } = await getSettings();
      context.commit("setSettings", data);
    },
    async updateSettings(context, settings: EditSettingsObject) {
      const { data } = await updateSettings(settings);
      context.commit("setSettings", data);
    },
    async fetchMarketFactors(context) {
      const { data } = await getMarketFactors();
      context.commit("setMarketFactors", data);
    },
    async fetchNotifications(context) {
      const { data } = await getNotifications();
      context.commit("setNotifications", data);
    },
    async makeMarkAsReadNotifications(context) {
      await markAsReadNotifications();
      const read_at = moment().format();
      context.commit(
        "setNotifications",
        context.state.notifications.map((item) => ({ ...item, read_at })),
      );
    },
    async exportFiles(
      context,
      { type, ids }: { type: ExportNotificationType; ids: string[] },
    ) {
      const { uuid } = await exportSome(ids, type);
      context.commit("addExportToQueue", { type, uuid, ids });
    },
  },
  getters: {
    countries: (state: AppState): CountryObject[] =>
      state.countriesRaw.map((item) => ({
        ...item,
        title: `${item.code.toUpperCase()} - ${item.name}`,
      })),
    getCountryById:
      (state, getters: any) =>
      (id: string): CountryObject | null => {
        return (
          (getters.countries as CountryObject[]).find(
            (country) => country.id === id,
          ) || null
        );
      },
  },
};
