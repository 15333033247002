<template>
  <v-row no-gutters>
    <v-col cols="12" class="pa-10">
      <v-sheet class="py-12 px-10 rounded-xl bpl-border-gray">
        <div class="d-flex justify-space-between align-center">
          <p class="text-h4 text-primary font-weight-light">
            {{ $t("marketplace") }}
          </p>
        </div>
        <div class="mt-10 bpl-border-gray rounded-lg">
          <v-row no-gutters class="align-center px-4 py-3">
            <v-col cols="4"></v-col>
            <v-col cols="4">
              <v-text-field
                v-model="globSearch"
                :label="$t('search')"
                variant="outlined"
                append-inner-icon="mdi-magnify"
                hide-details
                clearable
            /></v-col>
            <v-col cols="4" class="text-right">
              <v-btn
                elevation="0"
                variant="text"
                class="text-capitalize text-grey-darken-2"
                :class="{ 'text-primary': isAdvancedSearch }"
                :append-icon="!isFormOpened ? 'mdi-menu-down' : 'mdi-menu-up'"
                @click="isFormOpened = !isFormOpened"
                >{{ $t("advanced-search-settings") }}</v-btn
              >
            </v-col>
          </v-row>
          <div v-if="isFormOpened" class="bg-grey-lighten-5 px-4 pt-8">
            <v-row class="my-0">
              <v-col cols="6" class="py-0">
                <v-text-field
                  v-model="searchObject.loadingPostcode"
                  :label="$t('text.offer-search.loading')"
                  class="pb-2"
                  variant="outlined"
                  append-inner-icon="mdi-magnify"
                  bg-color="white"
                  clearable
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  v-model="searchObject.unloadingPostcode"
                  :label="$t('text.offer-search.unloading')"
                  class="pb-2"
                  variant="outlined"
                  append-inner-icon="mdi-magnify"
                  bg-color="white"
                  clearable
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <calendar-field
                  v-model="searchObject.loadingDate"
                  :title="$t('loading-date-from-to')"
                  class="pb-2"
                  range
                  clearable
                  bg-color="white"
                  hidden-time
                  :chip-format="locFormat.dDMmY"
                />
              </v-col>
              <v-col cols="6" class="py-0">
                <calendar-field
                  v-model="searchObject.unloadingDate"
                  :title="$t('unloading-date-from-to')"
                  class="pb-2"
                  range
                  clearable
                  bg-color="white"
                  hidden-time
                  :chip-format="locFormat.dDMmY"
                />
              </v-col>
              <v-col cols="3" class="py-0">
                <v-text-field
                  v-model="distanceStart"
                  :label="$t('distance-from')"
                  class="pb-2"
                  variant="outlined"
                  append-inner-icon="mdi-numeric"
                  bg-color="white"
                  clearable
                >
                </v-text-field>
              </v-col>
              <v-col cols="3" class="py-0 bpl-hyphen-col">
                <v-text-field
                  v-model="distanceEnd"
                  :label="$t('distance-to')"
                  class="pb-2"
                  variant="outlined"
                  append-inner-icon="mdi-numeric"
                  bg-color="white"
                  clearable
                >
                </v-text-field>
              </v-col>
              <v-col cols="3" class="py-0">
                <v-text-field
                  v-model="transporterPriceStart"
                  :label="`${$t('price')} min`"
                  class="pb-2"
                  variant="outlined"
                  append-inner-icon="mdi-currency-eur"
                  bg-color="white"
                  clearable
                >
                </v-text-field>
              </v-col>
              <v-col cols="3" class="py-0 bpl-hyphen-col">
                <v-text-field
                  v-model="transporterPriceEnd"
                  :label="`${$t('price')} max`"
                  class="pb-2"
                  variant="outlined"
                  append-inner-icon="mdi-currency-eur"
                  bg-color="white"
                  clearable
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-select
                  v-model="searchObject.vehicleName"
                  :label="$t('vehicle-type')"
                  :items="transportTypes"
                  item-value="name"
                  item-title="name"
                  class="pb-2"
                  variant="outlined"
                  bg-color="white"
                  multiple
                  chips
                  clearable
                ></v-select>
              </v-col>
            </v-row>
          </div>
          <v-data-table-server
            :headers="headers.filter((h) => !h.hidden)"
            :items="prepDocuments"
            class="bpl-custom-table-footer elevation-0 border-0 align-self-start bpl-table-height-1096"
            :items-per-page="pagData.per_page"
            :page="pagData.current_page"
            :items-length="pagData.total"
            :items-per-page-options="[]"
            :loading="loading"
            @update:options="
              fetchDocuments($event.page, finalSearchString, $event.sortBy[0])
            "
            @click:row="clickRow"
          >
            <template #[`item.express`]="{ item }">
              <base-chip
                v-if="item.express"
                color="#4CAF50"
                class="text-capitalize py-1 px-3"
              >
                {{ $t("express") }}
              </base-chip>
            </template>
          </v-data-table-server>
        </div>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import moment from "moment";
import { useRouter } from "vue-router";
import { useStore } from "@/store";
import { useI18n } from "vue-i18n";

import { getMarketplaces } from "@/api/marketplace";

import { snackbar } from "@/utils/snackbar";
import {
  initialPagData,
  joinWithDots,
  prepSearchString,
  useFormat,
} from "@/utils/other";
import { useWatchDebounceHelper } from "@/utils/watchDebounceHelper";
import { backFormats, useLocFormat } from "@/utils/locFormat";
import { useNumberHelper } from "@/utils/numberHelper";

import { MarketplaceData } from "@/types/marketplace";
import { RangeValue, SortData } from "@/types/other";

import CalendarField from "@/components/CalendarField.vue";
import BaseChip from "@/components/BaseChip.vue";

const store = useStore();
const router = useRouter();
const { t } = useI18n();
const toFormat = useFormat();

const include = "loadingPoint.country,unloadingPoint.country,vehicle";
const filter =
  "id;name;number;created_at;firstname;lastname;postcode;city;status;pagination;data";

const documents = ref<MarketplaceData[]>([]);
const sortData = ref<SortData>();
const pagData = ref(initialPagData);

const isFormOpened = ref(false);

const loading = ref(false);

const distanceStart = useNumberHelper(0);
const distanceEnd = useNumberHelper(0);
const transporterPriceStart = useNumberHelper(0);
const transporterPriceEnd = useNumberHelper(0);

const headers = computed(
  () =>
    [
      { title: t("loading-date"), key: "loadingDate", sortable: false },
      {
        title: t("loading-point"),
        key: "loadingPoint",
        sortable: false,
      },
      {
        title: t("unloading-date"),
        key: "unloadingDate",
        sortable: false,
      },
      { title: t("unloading-point"), key: "unloadingPoint", sortable: false },
      { title: `${t("distance")} km`, key: "distance", sortable: true },
      { title: t("price"), key: "transporter_price", sortable: true },
      { title: t("vehicle-type"), key: "vehicle", sortable: true },
      { title: t("status"), key: "express", sortable: true },
    ] as any[],
);

const fetchDocuments = async (
  page: number,
  search: string,
  sort?: SortData,
) => {
  if (!loading.value) {
    try {
      loading.value = true;
      const {
        data,
        meta: { pagination },
      } = await getMarketplaces({
        include,
        filter,
        page,
        search,
        ...(!isGlobSearch.value ? { searchJoin: "and" } : {}),
        ...(sort ? { orderBy: sort.key, sortedBy: sort.order } : {}),
      });
      documents.value = data;
      pagData.value = pagination;
    } catch (e: any) {
      snackbar(e.message);
    } finally {
      loading.value = false;
      sortData.value = sort;
    }
  }
};

const globSearch = ref("");

const isGlobSearch = computed(() => !!globSearch.value);

const isAdvancedSearch = computed(
  () => !isGlobSearch.value && !!finalSearchString.value,
);

type SearchObject = {
  number?: string;
  postcode?: string;
  loadingPostcode?: string;
  unloadingPostcode?: string;
  status?: MarketplaceData["express"];
  firstname?: string;
  lastname?: string;
  loadingDate?: RangeValue;
  unloadingDate?: RangeValue;
  vehicleName?: string[];
};

const defSearchObject: SearchObject = {};

const searchObject = ref<SearchObject>({ ...defSearchObject });

watch(
  searchObject,
  (value) => {
    if (Object.keys(value).length) globSearch.value = "";
  },
  { deep: true },
);

watch(globSearch, (value) => {
  if (value) {
    searchObject.value = { ...defSearchObject };
    distanceStart.value = "";
    distanceEnd.value = "";
    transporterPriceStart.value = "";
    transporterPriceEnd.value = "";
  }
});

const finalSearchString = computed(() => {
  const PHP_MAX_INTEGER = 2147483647;
  return isGlobSearch.value
    ? globSearch.value
    : prepSearchString(
        {
          title: "loadingPoint.postcode",
          value: searchObject.value?.loadingPostcode || "",
        },
        {
          title: "unloadingPoint.postcode",
          value: searchObject.value?.unloadingPostcode || "",
        },
        {
          title: "loading_time",
          value: searchObject.value.loadingDate?.end
            ? [
                moment(searchObject.value.loadingDate.start).format(
                  backFormats.yMmDdStart,
                ),
                moment(searchObject.value.loadingDate.end).format(
                  backFormats.yMmDdEnd,
                ),
              ].join(",")
            : "",
        },
        {
          title: "unloading_time",
          value: searchObject.value.unloadingDate?.end
            ? [
                moment(searchObject.value.unloadingDate.start).format(
                  backFormats.yMmDdStart,
                ),
                moment(searchObject.value.unloadingDate.end).format(
                  backFormats.yMmDdEnd,
                ),
              ].join(",")
            : "",
        },
        {
          title: "distance",
          value:
            distanceStart.value || distanceEnd.value
              ? [
                  distanceStart.value || 0,
                  distanceEnd.value || PHP_MAX_INTEGER,
                ].join(",")
              : "",
        },
        {
          title: "transporter_price",
          value:
            transporterPriceStart.value || transporterPriceEnd.value
              ? [
                  transporterPriceStart.value || 0,
                  transporterPriceEnd.value || PHP_MAX_INTEGER,
                ].join(",")
              : "",
        },
        {
          title: "vehicle.name",
          value: searchObject.value?.vehicleName?.join(",") || "",
        },
      );
});

const transportTypes = computed(() => {
  return store.state.app.vehicles.filter(({ active }) => active);
});

useWatchDebounceHelper<string>(finalSearchString, 400, (value) => {
  if (globSearch.value) isFormOpened.value = false;
  fetchDocuments(1, value, sortData.value);
});

const clickRow = (e: any, data: { item: any }) => {
  router.push({
    name: "marketplace-item",
    params: { id: data.item.id },
  });
};

const prepDocuments = computed(() =>
  documents.value.map(
    ({
      id,
      loading_time_start,
      loading_time_end,
      unloading_time_start,
      unloading_time_end,
      loadingPoint,
      unloadingPoint,
      distance,
      transporter_price,
      vehicle,
      express,
    }) => {
      const mLoadingStart = moment(loading_time_start);
      const loadingStart = mLoadingStart.isValid()
        ? mLoadingStart.format(locFormat.value.dDMmYWithDots)
        : "";

      const mLoadingEnd = moment(loading_time_end);
      const loadingEnd = mLoadingEnd.isValid()
        ? mLoadingEnd.format(locFormat.value.dDMmYWithDots)
        : "";

      const mUnloadingStart = moment(unloading_time_start);
      const unloadingStart = mUnloadingStart.isValid()
        ? mUnloadingStart.format(locFormat.value.dDMmYWithDots)
        : "";

      const mUnloadingEnd = moment(unloading_time_end);
      const unloadingEnd = mUnloadingEnd.isValid()
        ? mUnloadingEnd.format(locFormat.value.dDMmYWithDots)
        : "";
      return {
        loadingDate: `${loadingStart} ${loadingEnd && "-"} ${loadingEnd}`,
        unloadingDate: `${unloadingStart} ${
          unloadingEnd && "-"
        } ${unloadingEnd}`,
        loadingPoint: joinWithDots(
          loadingPoint?.data.country.data.name,
          loadingPoint?.data.postcode,
          loadingPoint?.data.city,
        ),
        unloadingPoint: joinWithDots(
          unloadingPoint?.data.country.data.name,
          unloadingPoint?.data.postcode,
          unloadingPoint?.data.city,
        ),
        distance: toFormat(distance),
        transporter_price: toFormat(transporter_price),
        vehicle: vehicle.data.name,
        express,
        id,
      };
    },
  ),
);

const locFormat = useLocFormat();
</script>

<style scoped></style>
