import { patch, del, get, post } from "@/api/index";

import { MetaPagination } from "@/types/other";
import {
  DocumentType,
  DocumentUpdateObject,
  DocumentSavedData,
  DocumentObject,
} from "@/types/document";

export const createDocument = (data: DocumentObject) =>
  post<{ data: DocumentSavedData; meta: MetaPagination }>("documents", data);

export const updateDocument = (
  id: string,
  document: DocumentUpdateObject,
  params?: { include: string },
) => patch<{ data: DocumentSavedData }>(`documents/${id}`, document, params);

export const getDocuments = (params?: {
  type: DocumentType;
  include: string;
  filter: string;
  page: number;
  search?: string;
  orderBy?: string;
  sortedBy?: string;
}) =>
  get<{ data: DocumentSavedData[]; meta: MetaPagination }>("documents", params);

export const getDocument = (id: string, params?: { include: string }) =>
  get<{ data: DocumentSavedData }>(`documents/${id}`, params);

export const removeDocuments = (ids: string[]) => del(`documents`, { ids });
