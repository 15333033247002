<template>
  <v-row no-gutters>
    <v-col cols="12" class="pa-10">
      <v-sheet class="py-12 px-10 rounded-xl bpl-border-gray">
        <v-form ref="form" v-model="valid" @submit.prevent="saveChanges()">
          <editable-block v-model="edited" :loading="editLoading" submit>
            <template #title>
              <p class="text-h4 text-primary font-weight-light">
                {{ $t("special-features") }}
              </p>
            </template>
            <template #edit-btn="{ edit }">
              <v-btn
                :disabled="!store.state.app.features.length"
                color="primary"
                @click="edit"
                >{{ $t("button.edit-feature") }}</v-btn
              >
            </template>
            <template #default="{ readonly }">
              <div class="mt-10">
                <div
                  v-if="edited"
                  class="px-4 pt-8 pb-4 bg-grey-lighten-5 mb-10"
                >
                  <p class="text-h5 text-primary pb-8">
                    {{ $t("edit-special-feature") }}
                  </p>
                  <v-row class="my-0">
                    <v-col cols="12" class="py-0">
                      <v-autocomplete
                        v-model="featureId"
                        autocomplete="off"
                        :rules="[rules.required]"
                        :label="$t('feature')"
                        :items="store.state.app.features"
                        item-title="name"
                        item-value="id"
                        class="pb-2"
                        :readonly="readonly"
                        :variant="readonly ? 'filled' : 'outlined'"
                        :bg-color="!readonly ? ('white' as any) : undefined"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6" class="py-0">
                      <v-radio-group
                        v-model="editFeature.calculation_type"
                        inline
                        class="mt-2 pb-4"
                      >
                        <v-radio
                          v-for="(typeData, i) in calcTypes"
                          :key="i"
                          :label="typeData.label"
                          :value="typeData.type"
                          color="primary"
                          :class="{ 'ml-4': !!i }"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="py-0">
                      <v-text-field
                        v-if="currentCalcType && currentCalcType.type !== 3"
                        v-model="editFeature.value"
                        :label="currentCalcType.label"
                        type="number"
                        hide-spin-buttons
                        :append-inner-icon="currentCalcType.icon"
                        :readonly="readonly"
                        :variant="readonly ? 'filled' : 'outlined'"
                        :bg-color="!readonly ? ('white' as any) : undefined"
                        :rules="[rules.floatNumberOnly]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2" class="py-0">
                      <v-checkbox
                        v-model="editFeature.active"
                        :label="$t('active')"
                        color="primary"
                        :disabled="readonly"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </div>
                <v-data-table
                  :headers="headers"
                  :items="prepFeatures"
                  class="bpl-custom-table-footer elevation-0 bpl-border-gray rounded-lg bpl-table-height-1096"
                  :items-per-page="perPage"
                  :items-per-page-options="[]"
                  :page="page"
                  :loading="
                    !prepFeatures.length && store.state.app.featuresLoading
                  "
                >
                  <template #[`item.active`]="{ item }">
                    <span v-if="item.active">{{ $t("text.yes") }}</span>
                    <span v-else class="text-red-lighten-1">
                      {{ $t("text.no") }}
                    </span>
                  </template>
                  <template #[`item.actions`]="{ item }">
                    <v-menu>
                      <template #activator="{ props: menuProps }">
                        <v-btn
                          icon="mdi-dots-vertical"
                          variant="plain"
                          elevation="0"
                          v-bind="menuProps"
                        ></v-btn>
                      </template>
                      <v-list min-width="230">
                        <v-list-item @click="clickEdit(item.id)">
                          <v-list-item-title>
                            {{ $t("button.edit") }}
                          </v-list-item-title>
                          <template #append>
                            <v-icon
                              icon="mdi-file-edit"
                              color="grey-darken-2"
                            ></v-icon>
                          </template>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-data-table>
              </div>
            </template>
          </editable-block>
        </v-form>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useStore } from "@/store";
import { useI18n } from "vue-i18n";

import { snackbar } from "@/utils/snackbar";
import { useRules } from "@/utils/rules";
import { useValidHelper } from "@/utils/validHelper";
import { getInitialFeatureErrorObject } from "@/utils/settings";
import { getFeatureKey } from "@/utils/features";
import { useFormat } from "@/utils/other";

import {
  FeatureCalcType,
  EditFeature,
  FeatureErrorObject,
  FeatureObject,
} from "@/types/settings";
import { VForm, VTextField } from "vuetify/components";

import EditableBlock from "@/components/EditableBlock.vue";

const { t } = useI18n();
const toFormat = useFormat();

const featureId = ref("");

const initialEditFeature: EditFeature = {
  active: false,
  value: "0",
  calculation_type: FeatureCalcType.priceOnRequest,
};

const calcTypes = computed<
  { type: FeatureObject["calculation_type"]; label: string; icon?: string }[]
>(() => [
  {
    type: FeatureCalcType.fixedPrice,
    label: t("fixed-price"),
    icon: "mdi-currency-eur",
  },
  { type: FeatureCalcType.factor, label: t("factor"), icon: "mdi-percent" },
  { type: FeatureCalcType.priceOnRequest, label: t("price-on-request") },
]);

const currentCalcType = computed(() => {
  return calcTypes.value.find(
    ({ type }) => type === editFeature.value.calculation_type,
  );
});

const errors = ref<FeatureErrorObject>(getInitialFeatureErrorObject());

const editFeature = ref<EditFeature>({ ...initialEditFeature });

const rules = useRules();

const valid = ref(false);
const form = ref<VForm>();

const perPage = 20;

const page = ref(1);

useValidHelper(form);

const edited = ref(false);

const editLoading = ref(false);

const store = useStore();

const featureById = computed<FeatureObject | null>(
  () => store.state.app.features.find((p) => p.id === featureId.value) || null,
);

const clickEdit = (id: string) => {
  featureId.value = id;
  edited.value = true;

  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};
const saveChanges = async () => {
  await form.value?.validate();
  if (valid.value) {
    try {
      editLoading.value = true;

      const id = store.state.app.features.find(
        ({ id }) => id === featureId.value,
      )?.id;

      await store.dispatch("app/updateFeature", {
        id,
        feature: editFeature.value,
      });
      edited.value = false;
    } catch (e: any) {
      snackbar(e.message);
      errors.value = { ...getInitialFeatureErrorObject(), ...e.errors };
    } finally {
      editLoading.value = false;
    }
  }
};

const prepFeatures = computed(() =>
  store.state.app.features.map((v, i) => ({
    tableNumber: i + 1,
    type: t(getFeatureKey(v.type)),
    feature: v.name,
    calcType:
      v.calculation_type === FeatureCalcType.fixedPrice
        ? t("fixed-price")
        : v.calculation_type === FeatureCalcType.factor
        ? t("factor")
        : t("price-on-request"),
    value:
      v.calculation_type !== FeatureCalcType.priceOnRequest
        ? toFormat(v.value)
        : "",
    active: v.active,
    id: v.id,
  })),
);

const headers = computed(
  () =>
    [
      {
        title: t("table-number"),
        key: "tableNumber",
        width: "50px",
        sortable: false,
      },
      {
        title: t("type"),
        key: "type",
        sortable: false,
      },
      {
        title: t("feature"),
        key: "feature",
        sortable: false,
      },
      {
        title: t("calculation-type"),
        key: "calcType",
        sortable: false,
      },
      {
        title: t("value"),
        key: "value",
        sortable: false,
      },
      {
        title: t("active"),
        key: "active",
        sortable: false,
      },
      {
        title: "",
        key: "actions",
        width: "48px",
        align: "end",
        sortable: false,
      },
    ] as any[],
);

watch(edited, (value) => {
  if (!value) {
    featureId.value = "";
    editFeature.value = { ...initialEditFeature };
  }
});

watch(
  () => editFeature.value.calculation_type,
  (value) => {
    editFeature.value.value =
      featureById.value?.calculation_type === value
        ? String(featureById.value.value)
        : value !== 3
        ? ""
        : "0";
  },
);

watch(featureById, (value, oldValue) => {
  if (value?.id !== oldValue?.id) {
    editFeature.value = value
      ? {
          active: value.active,
          calculation_type: value.calculation_type,
          value: String(value.value),
        }
      : {
          ...initialEditFeature,
        };
  }
});
</script>

<style></style>
