<template>
  <draggable
    v-model="stationsList"
    class="dragArea list-group position-relative w-full"
    handle=".draggable"
  >
    <route-station
      v-for="(station, i) in stationsList"
      :key="station.id"
      v-model="stationsList[i]"
      :index="i + (props.index || 0)"
      :vignette="props.vignette"
      class="draggable-item position-relative"
      :readonly="props.readonly"
      :zip-error-messages="props.zipErrorMessages || {}"
      :type-error-messages="
        firstUnloadingIndex !== -1 &&
        firstUnloadingIndex < i &&
        station.type === PointType.loadingPoint
          ? [$t('error-message.stopovers-position')]
          : []
      "
      @remove="removeStation(station.id)"
    />
  </draggable>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { VueDraggableNext as Draggable } from "vue-draggable-next";

import { StationObject } from "@/types/calculator";

import RouteStation from "@/components/CalculatorView/CalcFirst/RouteStation.vue";
import { PointType } from "@/types/document";

const props = defineProps<{
  modelValue?: StationObject[];
  index?: number;
  vignette?: boolean;
  readonly?: boolean;
  zipErrorMessages?: { [K: string]: string[] };
}>();

const firstUnloadingIndex = computed(() =>
  stationsList.value.findIndex(
    (value) => value.type === PointType.unloadingPoint,
  ),
);

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "update:modelValue", data: StationObject[]): void;
}>();

const stationsList = computed<StationObject[]>({
  get() {
    return props.modelValue || [];
  },
  set(value?: StationObject[]): void {
    emit("update:modelValue", value || []);
  },
});

const removeStation = (id: symbol) => {
  stationsList.value = stationsList.value.filter(
    (station) => station.id !== id,
  );
};
</script>

<style scoped>
.list-group:after {
  content: "";
  background-color: transparent;
  transition: background-color 0.25s;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.list-group:has([draggable="true"]):after {
  background-color: rgba(0, 0, 0, 0.05);
}
.draggable-item[draggable="true"] {
  background-color: rgba(25, 130, 212, 0.2) !important;
}
</style>
