<template>
  <base-chip
    v-if="statusData"
    :color="statusData.color"
    :border-color="statusData.color"
    class="text-capitalize py-1 px-3"
  >
    {{ $t(statusData.key) }}
  </base-chip>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { getStatus } from "@/utils/statuses";

import { StatusContext, StatusType } from "@/types/document";

import BaseChip from "@/components/BaseChip.vue";
const props = defineProps<{
  status: { name: StatusType; context: StatusContext };
}>();

const statusData = computed(() =>
  getStatus(props.status.name, props.status.context)
);
</script>

<style scoped></style>
