<template>
  <v-form ref="form" v-model="valid" @submit.prevent="callSubmit()">
    <editable-block
      v-model="edited"
      :loading="loading"
      submit
      @cancel="callClear()"
    >
      <template #title>
        <p class="text-h4 text-primary font-weight-light">
          {{ props.isTransporter ? $t("transporters") : $t("clients") }}
        </p></template
      >
      <template
        v-if="
          access.someRoles([
            'admin',
            'sub-admin',
            'bpl-manager',
            'bpl-calculation-manager',
          ])
        "
        #edit-btn
      >
        <v-btn color="primary" @click="edited = true">
          {{
            isTransporter
              ? $t("button.register-transporter")
              : $t("button.register-company")
          }}
        </v-btn>
      </template>
      <template #default="{ readonly }">
        <div v-if="edited" class="bg-grey-lighten-5 px-6 pt-8 rounded-lg mt-8">
          <v-alert
            v-if="registerError"
            :title="$t('notification-request-failed')"
            :text="registerError"
            color="error"
            variant="tonal"
          ></v-alert>
          <p class="text-h5 text-primary">
            {{
              props.isTransporter
                ? $t("add-new-transporter")
                : $t("add-new-client")
            }}
          </p>
          <v-row class="my-0">
            <v-col class="py-0">
              <div class="d-flex align-center pt-10 pb-8">
                <v-avatar color="primary">
                  <v-icon icon="mdi-domain"></v-icon>
                </v-avatar>
                <div class="text-h5 text-primary ml-4">
                  {{ $t("company-data") }}
                </div>
              </div>
              <v-select
                v-if="!props.isTransporter"
                v-model="data.company_type"
                :error-messages="errors.company_type"
                autocomplete="off"
                :rules="[rules.required]"
                :label="$t('company-type')"
                :items="
                  companyTypeKeys
                    .filter(({ type }) => type !== 'contractor')
                    .map(({ type, key }) => ({
                      value: type,
                      title: $t(key),
                    }))
                "
                :readonly="readonly"
                :variant="readonly ? 'filled' : 'outlined'"
                :bg-color="!readonly ? ('white' as any) : undefined"
                class="pb-2"
                @update:model-value="errors.company_type = []"
              ></v-select>
              <v-text-field
                v-model="data.company_name"
                :error-messages="errors.company_name"
                autocomplete="organization"
                :rules="[rules.required]"
                :label="$t('company-name')"
                :readonly="readonly"
                :variant="readonly ? 'filled' : 'outlined'"
                :bg-color="!readonly ? ('white' as any) : undefined"
                class="pb-2"
                @update:model-value="errors.company_name = []"
              ></v-text-field>
              <v-text-field
                v-model="data.company_addendum"
                :error-messages="errors.company_addendum"
                autocomplete="off"
                :label="$t('company-addendum')"
                :readonly="readonly"
                :variant="readonly ? 'filled' : 'outlined'"
                :bg-color="!readonly ? ('white' as any) : undefined"
                class="pb-2"
                @update:model-value="errors.company_addendum = []"
              ></v-text-field>
            </v-col>
            <v-col class="py-0">
              <div class="d-flex align-center pt-10 pb-8">
                <v-avatar color="primary">
                  <v-icon icon="mdi-domain"></v-icon>
                </v-avatar>
                <div class="text-h5 text-primary ml-4">
                  {{ $t("headquarters") }}
                </div>
              </div>
              <v-text-field
                v-model="data.headquarters_street"
                :error-messages="errors.headquarters_street"
                autocomplete="street-address"
                :label="$t('street')"
                :readonly="readonly"
                :rules="[rules.required]"
                :variant="readonly ? 'filled' : 'outlined'"
                :bg-color="!readonly ? ('white' as any) : undefined"
                class="pb-2"
                @update:model-value="errors.headquarters_street = []"
              ></v-text-field>
              <v-autocomplete
                v-model="data.headquarters_country_id"
                :error-messages="errors.headquarters_country_id"
                autocomplete="country"
                :readonly="readonly"
                :rules="[rules.required]"
                :label="$t('country')"
                :items="store.getters['app/countries']"
                item-title="title"
                item-value="id"
                :variant="readonly ? 'filled' : 'outlined'"
                :bg-color="!readonly ? ('white' as any) : undefined"
                class="pb-2"
                @update:model-value="errors.headquarters_country_id = []"
              ></v-autocomplete>
              <v-text-field
                v-model="data.headquarters_zip"
                :error-messages="errors.headquarters_zip"
                autocomplete="postal-code"
                :label="$t('zip-code')"
                :readonly="readonly"
                :rules="[rules.required, rules.postCodeOnly]"
                :variant="readonly ? 'filled' : 'outlined'"
                :bg-color="!readonly ? ('white' as any) : undefined"
                class="pb-2"
                @update:model-value="errors.headquarters_zip = []"
              ></v-text-field>
              <v-text-field
                v-model="data.headquarters_city"
                :error-messages="errors.headquarters_city"
                autocomplete="address-level2"
                :label="$t('town')"
                :readonly="readonly"
                :rules="[rules.required]"
                :variant="readonly ? 'filled' : 'outlined'"
                :bg-color="!readonly ? ('white' as any) : undefined"
                class="pb-2"
                @update:model-value="errors.headquarters_city = []"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="my-0">
            <v-col class="py-0">
              <div class="d-flex align-center pt-10 pb-8">
                <v-avatar color="primary">
                  <v-icon icon="mdi-account-group-outline"></v-icon>
                </v-avatar>
                <div class="text-h5 text-primary ml-4">
                  {{ $t("central-contact-person") }}
                </div>
              </div>
              <v-select
                v-model="data.salutation"
                :error-messages="errors.salutation"
                autocomplete="honorific-prefix"
                :rules="[rules.required]"
                :label="$t('salutation')"
                :items="
                  salutationKeys.map(({ key, type }) => ({
                    value: type,
                    title: $t(key),
                  }))
                "
                :readonly="readonly"
                :variant="readonly ? 'filled' : 'outlined'"
                :bg-color="!readonly ? ('white' as any) : undefined"
                class="pb-2"
                @update:model-value="errors.salutation = []"
              ></v-select>
              <v-text-field
                v-model="data.firstname"
                :error-messages="errors.firstname"
                autocomplete="given-name"
                :label="$t('name')"
                :readonly="readonly"
                :rules="[rules.required]"
                :variant="readonly ? 'filled' : 'outlined'"
                :bg-color="!readonly ? ('white' as any) : undefined"
                class="pb-2"
                @update:model-value="errors.firstname = []"
              ></v-text-field>
              <v-text-field
                v-model="data.lastname"
                :error-messages="errors.lastname"
                autocomplete="family-name"
                :rules="[rules.required]"
                :label="$t('last-name')"
                :readonly="readonly"
                :variant="readonly ? 'filled' : 'outlined'"
                :bg-color="!readonly ? ('white' as any) : undefined"
                class="pb-2"
                @update:model-value="errors.lastname = []"
              ></v-text-field>
              <v-text-field
                v-model="data.phone"
                :error-messages="errors.phone"
                autocomplete="tel"
                :label="$t('phone')"
                :readonly="readonly"
                :rules="[rules.required, rules.phone]"
                :variant="readonly ? 'filled' : 'outlined'"
                :bg-color="!readonly ? ('white' as any) : undefined"
                class="pb-2"
                @update:model-value="errors.phone = []"
              ></v-text-field>
              <v-text-field
                v-model="data.email"
                :error-messages="errors.email"
                autocomplete="email"
                :label="$t('email')"
                :readonly="readonly"
                :rules="[rules.required, rules.email]"
                :variant="readonly ? 'filled' : 'outlined'"
                :bg-color="!readonly ? ('white' as any) : undefined"
                class="pb-2"
                @update:model-value="errors.email = []"
              ></v-text-field>
            </v-col>
            <v-col class="py-0">
              <div class="d-flex align-center pt-10 pb-8">
                <v-avatar color="primary">
                  <v-icon icon="mdi-chart-areaspline"></v-icon>
                </v-avatar>
                <div class="text-h5 text-primary ml-4">
                  {{ $t("tax-information") }}
                </div>
              </div>
              <v-text-field
                v-model="data.company_vat_id"
                :error-messages="errors.company_vat_id"
                autocomplete="off"
                :label="$t('vat')"
                :readonly="readonly"
                :rules="[rules.required]"
                :variant="readonly ? 'filled' : 'outlined'"
                :bg-color="!readonly ? ('white' as any) : undefined"
                class="pb-2"
                @update:model-value="errors.company_vat_id = []"
              ></v-text-field>
              <v-text-field
                v-model="data.company_tax_id"
                :error-messages="errors.company_tax_id"
                autocomplete="off"
                :label="$t('tax')"
                :readonly="readonly"
                :rules="[rules.required]"
                :variant="readonly ? 'filled' : 'outlined'"
                :bg-color="!readonly ? ('white' as any) : undefined"
                class="pb-2"
                @update:model-value="errors.company_tax_id = []"
              ></v-text-field>
              <v-select
                v-if="!props.isTransporter"
                v-model="data.company_payment_target"
                :error-messages="errors.company_payment_target"
                autocomplete="off"
                :readonly="readonly"
                :rules="[rules.required]"
                :label="$t('payment-target')"
                :items="
                  paymentTargetKeys.map(({ type, key }) => ({
                    value: type,
                    title: $t(key),
                  }))
                "
                :variant="readonly ? 'filled' : 'outlined'"
                :bg-color="!readonly ? ('white' as any) : undefined"
                class="pb-2"
                @update:model-value="errors.company_payment_target = []"
              ></v-select>
            </v-col>
          </v-row>
          <v-checkbox
            v-model="data.different_billing_address"
            :label="$t('different-billing-address')"
            color="primary"
            class="pb-2"
          ></v-checkbox>
          <v-row class="my-0">
            <v-col v-if="data.different_billing_address" cols="6" class="py-0">
              <div class="d-flex align-center pb-8">
                <v-avatar color="primary">
                  <v-icon color="white" icon="mdi-map-marker-outline"></v-icon>
                </v-avatar>
                <div class="text-h5 ml-4 text-primary">
                  {{ $t("different-billing-address") }}
                </div>
              </div>
              <v-text-field
                v-model="data.billing_street"
                :error-messages="errors.billing_street"
                autocomplete="street-address"
                :label="$t('street')"
                :readonly="readonly"
                :rules="data.different_billing_address ? [rules.required] : []"
                :variant="readonly ? 'filled' : 'outlined'"
                :bg-color="!readonly ? ('white' as any) : undefined"
                class="pb-2"
                @update:model-value="errors.billing_street = []"
              ></v-text-field>
              <v-autocomplete
                v-model="data.billing_country_id"
                :error-messages="errors.billing_country_id"
                color="primary"
                autocomplete="country"
                :rules="data.different_billing_address ? [rules.required] : []"
                :readonly="readonly"
                :label="$t('country')"
                :items="store.getters['app/countries']"
                item-title="title"
                item-value="id"
                :variant="readonly ? 'filled' : 'outlined'"
                :bg-color="!readonly ? ('white' as any) : undefined"
                class="pb-2"
                @update:model-value="errors.billing_country_id = []"
              ></v-autocomplete>
              <v-text-field
                v-model="data.billing_zip"
                :error-messages="errors.billing_zip"
                color="primary"
                autocomplete="postal-code"
                :rules="
                  data.different_billing_address
                    ? [rules.required, rules.postCodeOnly]
                    : []
                "
                :label="$t('zip-code')"
                :readonly="readonly"
                :variant="readonly ? 'filled' : 'outlined'"
                :bg-color="!readonly ? ('white' as any) : undefined"
                class="pb-2"
                @update:model-value="errors.billing_zip = []"
              ></v-text-field>
              <v-text-field
                v-model="data.billing_city"
                :error-messages="errors.billing_city"
                color="primary"
                autocomplete="address-level2"
                :rules="data.different_billing_address ? [rules.required] : []"
                :label="$t('town')"
                :readonly="readonly"
                :variant="readonly ? 'filled' : 'outlined'"
                :bg-color="!readonly ? ('white' as any) : undefined"
                class="pb-2"
                @update:model-value="errors.billing_city = []"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="py-0">
              <div v-if="props.isTransporter">
                <div class="d-flex align-center pb-8">
                  <v-avatar color="primary">
                    <v-icon icon="mdi-bank"></v-icon>
                  </v-avatar>
                  <div class="text-h5 text-primary ml-4">
                    {{ $t("bank-details") }}
                  </div>
                </div>
                <v-text-field
                  v-model="data.company_iban"
                  :error-messages="errors.company_iban"
                  color="primary"
                  autocomplete="off"
                  :rules="[rules.iban]"
                  :label="$t('iban')"
                  :variant="readonly ? 'filled' : 'outlined'"
                  :bg-color="!readonly ? ('white' as any) : undefined"
                  class="pb-2"
                  @update:model-value="errors.company_iban = []"
                ></v-text-field>
                <v-text-field
                  v-model="data.company_swift"
                  :error-messages="errors.company_swift"
                  color="primary"
                  autocomplete="off"
                  :label="$t('swift')"
                  :variant="readonly ? 'filled' : 'outlined'"
                  :bg-color="!readonly ? ('white' as any) : undefined"
                  class="pb-2"
                  @update:model-value="errors.company_swift = []"
                ></v-text-field>
                <v-text-field
                  v-model="data.company_bank_name"
                  :error-messages="errors.company_bank_name"
                  color="primary"
                  autocomplete="off"
                  :label="$t('bank-name')"
                  :variant="readonly ? 'filled' : 'outlined'"
                  :bg-color="!readonly ? ('white' as any) : undefined"
                  class="pb-2"
                  @update:model-value="errors.company_bank_name = []"
                ></v-text-field>
                <v-text-field
                  v-model="data.company_bank_code"
                  :error-messages="errors.company_bank_code"
                  color="primary"
                  autocomplete="off"
                  :label="$t('bank-code')"
                  :variant="readonly ? 'filled' : 'outlined'"
                  :bg-color="!readonly ? ('white' as any) : undefined"
                  class="pb-2"
                  @update:model-value="errors.company_bank_code = []"
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
        </div>
      </template>
    </editable-block>
  </v-form>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { useStore } from "@/store";
import { useI18n } from "vue-i18n";

import { manualRegister } from "@/api/auth";

import { useRules } from "@/utils/rules";
import {
  getInitialNewUserObject,
  getInitialNewUserErrorObject,
} from "@/utils/auth";
import { useValidHelper } from "@/utils/validHelper";
import {
  companyTypeKeys,
  paymentTargetKeys,
  salutationKeys,
} from "@/utils/other";
import { access } from "@/utils/access";

import { NewUserObject } from "@/types/auth";
import { CompanyType } from "@/types/company";
import { VForm } from "vuetify/components";

import EditableBlock from "@/components/EditableBlock.vue";

const store = useStore();

const rules = useRules();

const { locale } = useI18n();

const props = defineProps<{
  isTransporter?: boolean;
  autoOpen: boolean;
}>();

const valid = ref(false);
const form = ref<VForm>();

useValidHelper(form);

const edited = ref(false);

const data = ref<NewUserObject>(getInitialNewUserObject());

const registerError = ref("");
const errors = ref(getInitialNewUserErrorObject());
const loading = ref(false);

const callClear = () => {
  data.value = { ...getInitialNewUserObject() };
  registerError.value = "";
};

const callSubmit = async () => {
  if (!loading.value) {
    await form.value?.validate();
    if (valid.value) {
      try {
        const dAddress = data.value.different_billing_address;
        loading.value = true;
        const mockPassword = "Pa$$w0rd!";
        await manualRegister({
          ...data.value,
          company_type: !props.isTransporter
            ? data.value.company_type
            : CompanyType.contractor,
          company_iban: data.value.company_iban || undefined,
          company_swift: data.value.company_swift || undefined,
          company_bank_name: data.value.company_bank_name || undefined,
          company_bank_code: data.value.company_bank_code || undefined,
          billing_street: dAddress ? data.value.billing_street : undefined,
          billing_country_id: dAddress
            ? data.value.billing_country_id
            : undefined,
          billing_zip: dAddress ? data.value.billing_zip : undefined,
          billing_city: dAddress ? data.value.billing_city : undefined,
          password: mockPassword,
          password_confirmation: mockPassword,
          verification_url: `${window.location.origin}/email/verify`,
          locale: locale.value,
        });
        emit("submit");
        edited.value = false;
        callClear();
      } catch (e: any) {
        registerError.value = e.message;
        errors.value = { ...getInitialNewUserErrorObject(), ...e.errors };
      } finally {
        loading.value = false;
      }
    }
  }
};

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "submit"): void;
}>();

watch(
  () => props.autoOpen,
  (value) => {
    edited.value = value;
  },
);
</script>

<style scoped></style>
