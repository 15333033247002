import { NotificationObject, ConfirmData } from "@/types/other";

type CallbackData<T> = { id: Symbol; callback: (payload: T) => void };

type CallbackSet<T = any> = CallbackData<T>[];

type Subscriptions = {
  EditFormStart?: CallbackSet;
  SaveFormStart?: CallbackSet;
  EditFormCancel?: CallbackSet;
  EditFormEnd?: CallbackSet;
  Notification?: CallbackSet<NotificationObject>;
  SetAsyncModal?: CallbackSet<{ value: boolean; id: symbol }>;
  ResolveAsyncModal?: CallbackSet<{ value: any; id: symbol }>;
  SetConfirm?: CallbackSet<ConfirmData>;
};

const subscriptions: Subscriptions = {};

type GetCallback<E extends keyof Subscriptions> = Exclude<
  Subscriptions[E],
  undefined
>[number]["callback"];

export const subscribe = <E extends keyof Subscriptions>(
  eventName: E,
  callback: GetCallback<E>
) => {
  if (!subscriptions[eventName]) {
    subscriptions[eventName] = [];
  }

  let callbacks = subscriptions[eventName] as CallbackSet;
  const id = Symbol();
  callbacks.push({ callback, id });

  return () => {
    callbacks = callbacks.filter((c) => c.id !== id);

    if (callbacks.length === 0) {
      delete subscriptions[eventName];
    }
  };
};

export const broadcast = <E extends keyof Subscriptions>(
  eventName: E,
  payload: Parameters<GetCallback<E>>[0]
) => {
  if (!subscriptions[eventName]) {
    return;
  }

  const callbacks = subscriptions[eventName];

  callbacks?.forEach((callbackData) => callbackData.callback(payload as never));
};
