<template>
  <v-form
    v-if="isBplAdmin || isTransporter"
    ref="driversForm"
    v-model="driversValid"
    @submit.prevent="saveChanges(RideFirstBlockType.drivers)"
  >
    <v-alert
      v-if="props.editable"
      :text="$t('alert.ride-step1-required')"
      color="warning"
      variant="tonal"
      class="my-8"
      icon="mdi-alert-outline"
    />
    <v-alert
      v-if="isInvalidDate"
      :text="$t('alert.expected-arrival-differs')"
      color="error"
      variant="tonal"
      class="my-8"
      icon="mdi-alert-octagon-outline"
    />
    <editable-block
      v-model="editableBlocks"
      :hide-actions="!isEditableBlocks"
      :name="RideFirstBlockType.drivers"
      :loading="driversLoading"
      submit
      @cancel="cancelChanges"
    >
      <template #title>
        <p class="text-h5 text-primary py-2">
          {{ $t("driver") }}
        </p>
      </template>
      <template #edit-btn="{ edit }">
        <v-btn
          v-if="editable"
          variant="text"
          elevation="0"
          color="primary"
          icon="mdi-file-edit-outline"
          @click="edit"
        />
      </template>
      <template #default="{ readonly }">
        <v-row class="my-0 pt-4">
          <v-col
            cols="12"
            class="py-0"
            :class="{ 'pointer-events-none': readonly || isCustomDrivers }"
          >
            <v-combobox
              v-model="rideDriverIds"
              :label="$t('ride.find-driver')"
              :items="driversList"
              :return-object="false"
              item-value="id"
              item-title="title"
              multiple
              autocomplete="off"
              prepend-inner-icon="mdi-magnify"
              :readonly="readonly || isCustomDrivers"
              :variant="readonly || isCustomDrivers ? 'filled' : 'outlined'"
              :bg-color="
                !(readonly || isCustomDrivers) ? ('white' as any) : undefined
              "
              :error-messages="errors.step_1_drivers"
              @update:model-value="errors.step_1_drivers = []"
            >
              <template #chip="{ item }">
                <v-chip
                  :key="item.value"
                  :readonly="readonly || isCustomDrivers"
                  closable
                  @click:close="
                    rideDriverIds = rideDriverIds.filter(
                      (id) => id !== item.value,
                    )
                  "
                >
                  {{ item.title }}
                </v-chip>
              </template>
              <template #item="{ props: driverItemProps, item }">
                <v-list-item v-bind="driverItemProps">
                  <template #prepend>
                    <v-checkbox-btn
                      v-model="rideDriverIds"
                      :value="item.value"
                      color="primary"
                      @click.stop
                    >
                    </v-checkbox-btn>
                  </template>
                </v-list-item>
              </template>
            </v-combobox>
          </v-col>
          <v-col
            v-for="i in customDrivers.length"
            :key="i"
            cols="6"
            class="py-0"
            :class="{
              'pointer-events-none':
                !!editRideDrivers.step_1_drivers?.length || readonly,
            }"
          >
            <custom-ride-form
              v-model="customDrivers[i - 1]"
              :readonly="!!editRideDrivers.step_1_drivers?.length || readonly"
              :title="`${$t('driver')} ${i}`"
            />
          </v-col>
        </v-row>
      </template>
    </editable-block>
  </v-form>
  <v-form
    ref="trackingForm"
    v-model="trackingValid"
    @submit.prevent="saveChanges(RideFirstBlockType.tracking)"
  >
    <editable-block
      v-model="editableBlocks"
      :hide-actions="!isEditableBlocks"
      :name="RideFirstBlockType.tracking"
      :loading="trackingLoading"
      submit
      @cancel="cancelChanges"
    >
      <template #title>
        <p class="text-h5 text-primary py-2">
          {{ $t("ride.tracking") }}
        </p>
      </template>
      <template #edit-btn="{ edit }">
        <v-btn
          v-if="editable"
          variant="text"
          elevation="0"
          color="primary"
          icon="mdi-file-edit-outline"
          @click="edit"
        />
      </template>
      <template #default="{ readonly }">
        <v-row class="my-0 pt-4">
          <v-col
            cols="4"
            class="py-0"
            :class="{ 'pointer-events-none': readonly }"
          >
            <v-text-field
              v-model="editRideTracking.step_1_license_plate"
              :rules="[rules.required]"
              :label="$t('ride.license-plate')"
              :readonly="readonly"
              :variant="readonly ? 'filled' : 'outlined'"
              class="pb-2"
              :error-messages="errors.step_1_license_plate"
              @update:model-value="errors.step_1_license_plate = []"
            ></v-text-field>
          </v-col>
          <v-col
            cols="4"
            class="py-0"
            :class="{ 'pointer-events-none': readonly }"
          >
            <v-text-field
              v-model="editRideTracking.step_1_trailer_license_plate"
              :rules="[rules.required]"
              :label="$t('license-plate-tag')"
              :readonly="readonly"
              :variant="readonly ? 'filled' : 'outlined'"
              class="pb-2"
              :error-messages="errors.step_1_trailer_license_plate"
              @update:model-value="errors.step_1_trailer_license_plate = []"
            ></v-text-field>
          </v-col>
          <v-col cols="4" class="py-0">
            <v-text-field
              v-model="editRideTracking.step_1_gps_link"
              :rules="[]"
              :label="$t('ride.gps')"
              :readonly="readonly"
              :variant="readonly ? 'filled' : 'outlined'"
              class="pb-2"
              :append-inner-icon="
                readonly && editRideTracking.step_1_gps_link
                  ? 'mdi-content-copy'
                  : undefined
              "
              :error-messages="errors.step_1_gps_link"
              @click:append-inner="
                copyToClipboard(editRideTracking.step_1_gps_link)
              "
              @update:model-value="errors.step_1_gps_link = []"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
    </editable-block>
  </v-form>
  <v-form
    v-if="isBplAdmin || isTransporter"
    ref="arrivalForm"
    v-model="arrivalValid"
    @submit.prevent="saveChanges(RideFirstBlockType.arrival)"
  >
    <editable-block
      v-model="editableBlocks"
      :hide-actions="!isEditableBlocks"
      :name="RideFirstBlockType.arrival"
      :loading="arrivalLoading"
      submit
      @cancel="cancelChanges"
    >
      <template #title>
        <p class="text-h5 text-primary py-2">
          {{ $t("ride.estimated-arrival") }}
        </p>
      </template>
      <template #edit-btn="{ edit }">
        <v-btn
          v-if="editable"
          variant="text"
          elevation="0"
          color="primary"
          icon="mdi-file-edit-outline"
          @click="edit"
        />
      </template>
      <template #default="{ readonly }">
        <v-row class="my-0 pt-4" :class="{ 'pointer-events-none': readonly }">
          <v-col cols="12" class="py-0">
            <calendar-field
              v-model="editRideArrival.step_1_arrival_time"
              :title="$t('ride.scheduled-arrival-loading-point')"
              class="pb-2"
              :chip-format="locFormat.dDMmYHhMmUhr"
              :readonly="readonly"
              :error-messages="
                errors.step_1_arrival_time?.length
                  ? errors.step_1_arrival_time
                  : calendarErrors
              "
              :rules="[rules.required]"
              :min="props.ride.document.data.loading_time_start"
              @update:model-value="errors.step_1_arrival_time = []"
            />
          </v-col>
        </v-row>
      </template>
    </editable-block>
  </v-form>
  <div v-if="isBplAdmin || isTransporter" class="d-flex justify-end">
    <v-btn
      v-if="!isSaved"
      :disabled="!editRideArrival.step_1_arrival_time"
      :loading="loading"
      type="button"
      color="primary"
      size="large"
      @click="saveAllChanges"
    >
      {{ $t("button.save-and-send") }}
    </v-btn>
    <v-btn
      v-else-if="isBplAdmin && isInvalidDateSaved"
      type="button"
      color="primary"
      size="large"
      :disabled="!!editableBlocks.length"
      :loading="manuallyLoading"
      @click="switchManually"
    >
      {{ $t("button.switch-manually") }}
    </v-btn>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import moment from "moment";

import { updateRide } from "@/api/rides";

import { useMountScroller } from "@/utils/mountScroller";
import { snackbar } from "@/utils/snackbar";
import { useRules } from "@/utils/rules";
import { useLocFormat } from "@/utils/locFormat";
import { VForm } from "vuetify/components";
import { getInitialEditRideErrorObject } from "@/utils/rides";
import { useValidHelper } from "@/utils/validHelper";
import { access } from "@/utils/access";
import { copyToClipboard, joinWithDots } from "@/utils/other";

import { DocumentCalcObject } from "@/types/calculator";
import {
  EditRideObject,
  RideDriverObject,
  RideFirstBlockType,
  RideObject,
} from "@/types/rides";

import CustomRideForm from "@/components/RideView/RideFirst/CustomRideForm.vue";
import CalendarField from "@/components/CalendarField.vue";
import EditableBlock from "@/components/EditableBlock.vue";

const { t } = useI18n();
const locFormat = useLocFormat();

const include = "document.carrier.users";

const rules = useRules();

const editableBlocks = ref<RideFirstBlockType[]>([]);

const props = defineProps<{
  ride: RideObject;
  editable?: boolean;
}>();

const editRideDrivers = ref<EditRideObject>({}); //step_1_drivers
const editRideTracking = ref<EditRideObject>({}); //step_1_license_plate, step_1_trailer_license_plate, step_1_gps_link
const editRideArrival = ref<EditRideObject>({}); //step_1_arrival_time

const customDrivers = ref<RideDriverObject[]>([]);

const isCustomDrivers = computed(() => {
  return customDrivers.value
    .map((driver) => Object.values(driver))
    .flat()
    .some((data) => (Array.isArray(data) ? data.length : data));
});

const getDatesDiff = (time: string, locTime: string) => {
  const timeMoment = moment(time);
  const locTimeMoment = moment(locTime);
  return locTimeMoment.diff(timeMoment, "minutes");
};

const isInvalidDate = computed(() => {
  if (editRideArrival.value.step_1_arrival_time)
    return (
      getDatesDiff(
        props.ride.document.data.loading_time_start,
        editRideArrival.value.step_1_arrival_time,
      ) >= 1
    );
  return false;
});

const isInvalidDateSaved = computed(() => {
  return props.ride.step_1_invalid_date;
});

const calendarErrors = computed(() => {
  if (
    editRideArrival.value.step_1_arrival_time &&
    getDatesDiff(
      props.ride.document.data.loading_time_start,
      editRideArrival.value.step_1_arrival_time,
    ) < 0
  ) {
    return [t("ride.order-agreed-time")];
  }
  return [];
});

const loading = ref(false);

const manuallyLoading = ref(false);

useMountScroller();

//drivers
const driversValid = ref(false);
const driversForm = ref<VForm>();
const driversLoading = ref(false);

useValidHelper(driversForm);

//tracking
const trackingValid = ref(false);
const trackingForm = ref<VForm>();
const trackingLoading = ref(false);

useValidHelper(trackingForm);

//arrival
const arrivalValid = ref(false);
const arrivalForm = ref<VForm>();
const arrivalLoading = ref(false);

useValidHelper(arrivalForm);

const errors = ref(getInitialEditRideErrorObject());

const driversNumber = computed(() => {
  const calc = JSON.parse(props.ride.document.data.calc) as DocumentCalcObject;
  return calc.drivers;
});

const getInitialCustomDrives = () => {
  return Array.from({ length: driversNumber.value }).map(
    () => ({}),
  ) as RideDriverObject[];
};
const syncEdits = (value: RideObject) => {
  //drivers sync
  if (!editableBlocks.value.includes(RideFirstBlockType.drivers)) {
    const rideDriversIsCustom = !!value.step_1_drivers?.some(
      (driver) => !driver.id,
    );

    editRideDrivers.value.step_1_drivers = rideDriversIsCustom
      ? []
      : (value.step_1_drivers as RideDriverObject[]);

    customDrivers.value = rideDriversIsCustom
      ? getInitialCustomDrives().map((e, i) =>
          value.step_1_drivers?.[i] ? { ...value.step_1_drivers[i] } : e,
        )
      : getInitialCustomDrives();

    errors.value.step_1_drivers = [];
  }
  //tracking sync
  if (!editableBlocks.value.includes(RideFirstBlockType.tracking)) {
    editRideTracking.value.step_1_license_plate = value.step_1_license_plate;
    editRideTracking.value.step_1_trailer_license_plate =
      value.step_1_trailer_license_plate;

    editRideTracking.value.step_1_gps_link = value.step_1_gps_link;

    errors.value.step_1_license_plate = [];
    errors.value.step_1_trailer_license_plate = [];
    errors.value.step_1_gps_link = [];
  }
  //arrival sync
  if (!editableBlocks.value.includes(RideFirstBlockType.arrival)) {
    editRideArrival.value.step_1_arrival_time = value.step_1_arrival_time;

    errors.value.step_1_arrival_time = [];
  }
};

const cancelChanges = () => {
  nextTick(() => {
    syncEdits(props.ride);
  });
};

const rideDriverIds = computed<string[]>({
  get() {
    return (editRideDrivers.value.step_1_drivers
      ?.filter((item) => item?.id)
      ?.map(({ id }) => id) || []) as string[];
  },
  set(value: string[]) {
    editRideDrivers.value.step_1_drivers = value
      ?.filter((id) => driversList.value.some((item) => item?.id === id))
      ?.slice(0, driversNumber.value)
      .map(
        (id) => driversList.value.find((data) => data.id === id)?.driver,
      ) as RideDriverObject[];
  },
});

const driversList = computed<
  { title: string; id: string; driver: RideDriverObject }[]
>(() =>
  (props.ride.document.data.carrier?.data?.users.data || [])
    .filter((user) => user.role === "driver")
    .map((user) => ({
      title: joinWithDots(`${user.firstname} ${user.lastname}`, user.phone),
      id: user.id,
      driver: {
        firstname: user.firstname,
        lastname: user.lastname,
        phone: user.phone,
        languages: user.languages && Array.from(new Set(user.languages)),
        id: user.id,
      },
    })),
);

const closeBlock = (name: RideFirstBlockType) => {
  editableBlocks.value = editableBlocks.value.filter(
    (blockName) => blockName !== name,
  );
};

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "updateRide", data: RideObject): void;
}>();

const finDriversData = computed(() => {
  const drivers = isCustomDrivers.value
    ? customDrivers.value
    : editRideDrivers.value.step_1_drivers;

  return {
    ...editRideDrivers.value,
    step_1_drivers: drivers || [],
  } as EditRideObject;
});

const finTrackingData = computed(() => {
  return {
    ...editRideTracking.value,
    step_1_gps_link: editRideTracking.value.step_1_gps_link || undefined,
  } as EditRideObject;
});

const validAll = async () => {
  await Promise.all([
    driversForm.value?.validate(),
    trackingForm.value?.validate(),
    arrivalForm.value?.validate(),
  ]);

  return driversValid.value && trackingValid.value && arrivalValid.value;
};
const saveAllChanges = async () => {
  if (await validAll()) {
    try {
      loading.value = true;
      const response = await updateRide(
        props.ride.id,
        {
          ...finDriversData.value,
          ...finTrackingData.value,
          ...editRideArrival.value,
          step_1_invalid_date: isInvalidDate.value,
          ...(!isInvalidDate.value
            ? {
                step: 2,
                step_2_arrival_time: editRideArrival.value.step_1_arrival_time,
              }
            : {}),
        },
        {
          include,
        },
      );

      emit("updateRide", response.data);
    } catch (e: any) {
      errors.value = {
        ...getInitialEditRideErrorObject(),
        ...e.errors,
      };
    } finally {
      loading.value = false;
    }
  }
};
const switchManually = async () => {
  if (await validAll()) {
    try {
      manuallyLoading.value = true;
      const response = await updateRide(
        props.ride.id,
        {
          step: 2,
          step_2_arrival_time: editRideArrival.value.step_1_arrival_time,
          step_1_invalid_date: false,
        },
        {
          include,
        },
      );

      emit("updateRide", response.data);
    } catch (e: any) {
      snackbar(e.message);
    } finally {
      manuallyLoading.value = false;
    }
  }
};
const saveChanges = async (name: RideFirstBlockType) => {
  let response: { data: RideObject } | undefined;
  let error: any = {};

  switch (name) {
    case RideFirstBlockType.drivers:
      await driversForm.value?.validate();
      if (driversValid.value) {
        try {
          driversLoading.value = true;
          response = await updateRide(props.ride.id, finDriversData.value, {
            include,
          });
          closeBlock(RideFirstBlockType.drivers);
        } catch (e: any) {
          error = e;
        } finally {
          driversLoading.value = false;
        }
      }
      break;
    case RideFirstBlockType.tracking:
      await trackingForm.value?.validate();
      if (trackingValid.value) {
        try {
          trackingLoading.value = true;
          response = await updateRide(props.ride.id, finTrackingData.value, {
            include,
          });
          closeBlock(RideFirstBlockType.tracking);
        } catch (e: any) {
          error = e;
        } finally {
          trackingLoading.value = false;
        }
      }
      break;
    case RideFirstBlockType.arrival:
      await arrivalForm.value?.validate();
      if (arrivalValid.value) {
        try {
          arrivalLoading.value = true;
          response = await updateRide(props.ride.id, editRideArrival.value, {
            include,
          });
          closeBlock(RideFirstBlockType.arrival);
        } catch (e: any) {
          error = e;
        } finally {
          arrivalLoading.value = false;
        }
      }
      break;
  }
  if (error?.errors) {
    errors.value = {
      ...getInitialEditRideErrorObject(),
      ...error.errors,
    };
  }
  if (response?.data) emit("updateRide", response.data);
};

const isBplAdmin = computed(() =>
  access.someRoles(["admin", "sub-admin", "bpl-manager"]),
);

const isTransporter = computed(() =>
  access.someRoles([
    "forwarder",
    "sub-forwarder",
    "planner",
    "accounting-contractor",
    "driver",
    "pallet-department-contractor",
  ]),
);

const isSaved = computed(() => !!props.ride.step_1_arrival_time);

const isEditableBlocks = computed(
  () => isBplAdmin.value && isSaved.value && isInvalidDateSaved.value,
);

const isEditable = computed(() => {
  if (isBplAdmin.value) {
    return (!isSaved.value || isInvalidDateSaved.value) && props.editable;
  } else if (isTransporter.value) {
    return !isSaved.value && props.editable;
  }
  return false;
});

watch(
  () => props.ride,
  (value) => {
    syncEdits(value);
  },
  { immediate: true },
);

watch(isCustomDrivers, (value) => {
  if (value) {
    editRideDrivers.value.step_1_drivers = [];
  } else {
    customDrivers.value = getInitialCustomDrives();
  }
});

watch(
  () => !isEditableBlocks.value && isEditable.value,
  (value) => {
    if (value) {
      editableBlocks.value = Object.values(
        RideFirstBlockType,
      ) as RideFirstBlockType[];
    } else {
      editableBlocks.value = [];
    }
  },
  { immediate: true },
);
</script>

<style scoped></style>
