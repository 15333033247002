<template>
  <button
    class="d-flex align-center bpl-upload-file-btn w-100"
    :disabled="props.disabled"
    :class="{
      'bg-grey-lighten-5': !props.disabled,
      'bg-grey-lighten-4': props.disabled,
      'bpl-upload-file-btn-active': isDragActive,
    }"
    v-bind="getRootProps()"
    type="button"
  >
    <v-icon icon="mdi-plus-circle" color="primary"></v-icon>
    <span class="d-flex flex-column align-start ml-6">
      <span class="text-subtitle-1 font-weight-medium">
        {{ $t("add-ride-document") }}</span
      >
      <span class="text-body-2 text-grey-darken-2">
        {{ $t("document-file-type") }}</span
      >
    </span>
    <input v-bind="getInputProps()" />
  </button>
</template>

<script setup lang="ts">
import { useDropzone } from "vue3-dropzone";

const props = defineProps<{
  disabled?: boolean;
}>();

const { getRootProps, getInputProps, isDragActive } = useDropzone({
  onDrop: (acceptedFiles) => {
    if (acceptedFiles.length) emit("uploaded", acceptedFiles);
    else emit("error");
  },
  accept: ["application/pdf", "image/jpeg", "image/png"],
  disabled: props.disabled,
  multiple: true,
});

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "uploaded", data: File[]): void;
  (e: "error"): void;
}>();
</script>

<style scoped>
.bpl-upload-file-btn {
  padding: 18px;
  border-radius: 16px;
  cursor: pointer;
  border: #1982d4 1px dashed;
  outline: none;
  &:disabled {
    cursor: default;
  }
}

.bpl-upload-file-btn-active {
  border: #1982d4 1px solid;
}
</style>
