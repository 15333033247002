import {
  DeliveryFactorErrorObject,
  EditSettingsErrorObject,
  FeatureErrorObject,
  PalletErrorObject,
  VehicleErrorObject,
} from "@/types/settings";

export const getInitialPalletErrorObject = (): PalletErrorObject => ({
  price: [],
  price_accounting: [],
});

export const getInitialVehicleErrorObject = (): VehicleErrorObject => ({
  value: [],
  length: [],
  width: [],
  height: [],
  capacity: [],
  volume: [],
  equipment: [],
  name: [],
});
export const getInitialFeatureErrorObject = (): FeatureErrorObject => ({});

export const getInitialDeliveryFactorErrorObject =
  (): DeliveryFactorErrorObject => ({
    client_factor: [],
  });

export const makeFormData = (data = {}) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]: [string, any]) => {
    formData.append(key, value);
  });
  return formData;
};

export const getInitialEditSettingsErrorObject =
  (): EditSettingsErrorObject => ({
    working_time_start: [],
    working_time_end: [],
  });
