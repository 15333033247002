import {
  DepartmentType,
  Languages,
  NewUserErrorObject,
  NewUserObject,
  UserCredentialsObject,
} from "@/types/auth";
import {
  EditCompanyErrorObject,
  NewCompanyUserErrorObject,
  NewCompanyUserObject,
} from "@/types/company";

const email =
  process.env.NODE_ENV === "development"
    ? "admin@brennpunkt-logistik.test"
    : "";
const password = process.env.NODE_ENV === "development" ? "password" : "";

export const getInitialNewUserObject = (): NewUserObject => ({
  different_billing_address: false,
});

export const getInitialNewUserErrorObject = (): NewUserErrorObject => ({
  company_type: [],
  company_name: [],
  company_addendum: [],
  headquarters_street: [],
  headquarters_country_id: [],
  headquarters_city: [],
  headquarters_zip: [],
  different_billing_address: [],
  billing_street: [],
  billing_country_id: [],
  billing_city: [],
  billing_zip: [],
  email: [],
  password: [],
  password_confirmation: [],
  salutation: [],
  firstname: [],
  lastname: [],
  phone: [],
  company_vat_id: [],
  company_tax_id: [],
  company_payment_target: [],
  company_iban: [],
  company_swift: [],
  company_bank_name: [],
  company_bank_code: [],
});

export const getInitialUserCredentialsObject = (): UserCredentialsObject => ({
  email: email,
  password: password,
});

export const getInitialNewCompanyUserObject = (): NewCompanyUserObject => ({
  department: DepartmentType.no,
});

export const getInitialNewCompanyUserErrorObject =
  (): NewCompanyUserErrorObject => ({
    firstname: [],
    lastname: [],
    salutation: [],
    designation: [],
    department: [],
    department_miscellaneous: [],
    languages: [],
    email: [],
    password: [],
    password_confirmation: [],
    phone: [],
    role: [],
  });

export const getInitialEditCompanyErrorObject = (): EditCompanyErrorObject => ({
  name: [],
  "headquarters_address.city": [],
  "headquarters_address.street": [],
  "headquarters_address.zip": [],
  "contact.firstname": [],
  "contact.lastname": [],
  "contact.phone": [],
  tax_id: [],
  payment_target: [],
  vat_id: [],
  bank_code: [],
  bank_name: [],
  iban: [],
  swift: [],
  "billing_address.city": [],
  "billing_address.street": [],
  "billing_address.zip": [],
  password: [],
  email: [],
});

export const getDepartmentKey = (key: DepartmentType) => {
  const departmentKeys = [
    {
      value: DepartmentType.no,
      key: "text.no",
    },
    {
      value: DepartmentType.invoice,
      key: "invoice",
    },
    {
      value: DepartmentType.warning,
      key: "warning",
    },
    {
      value: DepartmentType.miscellaneous,
      key: "miscellaneous",
    },
  ];
  return departmentKeys.find(({ value }) => value === key)?.key || "";
};

export const getLanguageKey = (key: Languages) => {
  const languagesKeys = [
    {
      value: Languages.bulgarian,
      key: "languages.bulgarian",
    },
    {
      value: Languages.croatian,
      key: "languages.croatian",
    },
    {
      value: Languages.czech,
      key: "languages.czech",
    },
    {
      value: Languages.danish,
      key: "languages.danish",
    },
    {
      value: Languages.dutch,
      key: "languages.dutch",
    },
    {
      value: Languages.english,
      key: "languages.english",
    },
    {
      value: Languages.estonian,
      key: "languages.estonian",
    },
    {
      value: Languages.finnish,
      key: "languages.finnish",
    },
    {
      value: Languages.french,
      key: "languages.french",
    },
    {
      value: Languages.german,
      key: "languages.german",
    },
    {
      value: Languages.greek,
      key: "languages.greek",
    },
    {
      value: Languages.hungarian,
      key: "languages.hungarian",
    },
    {
      value: Languages.italian,
      key: "languages.italian",
    },
    {
      value: Languages.latvian,
      key: "languages.latvian",
    },
    {
      value: Languages.lithuanian,
      key: "languages.lithuanian",
    },
    {
      value: Languages.norwegian,
      key: "languages.norwegian",
    },
    {
      value: Languages.polish,
      key: "languages.polish",
    },
    {
      value: Languages.portuguese,
      key: "languages.portuguese",
    },
    {
      value: Languages.romanian,
      key: "languages.romanian",
    },
    {
      value: Languages.russian,
      key: "languages.russian",
    },
    {
      value: Languages.serbian,
      key: "languages.serbian",
    },
    {
      value: Languages.slovak,
      key: "languages.slovak",
    },
    {
      value: Languages.slovenian,
      key: "languages.slovenian",
    },
    {
      value: Languages.spanish,
      key: "languages.spanish",
    },
    {
      value: Languages.swedish,
      key: "languages.swedish",
    },
    {
      value: Languages.ukrainian,
      key: "languages.ukrainian",
    },
  ];

  return languagesKeys.find(({ value }) => value === key)?.key || "";
};
