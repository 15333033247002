<template>
  <div class="bpl-border-gray rounded-lg">
    <v-data-table
      :headers="headers.filter((h) => !h.hidden)"
      :items="users"
      class="bpl-custom-table-footer bpl-table-height-316"
      :items-per-page="perPage"
      :items-per-page-options="[]"
      :page="page"
      :loading="props.loading"
    >
      <template #[`item.actions`]="{ item }">
        <v-menu :close-on-content-click="true">
          <template #activator="{ props: menuProps }">
            <v-btn
              icon="mdi-dots-vertical"
              variant="plain"
              elevation="0"
              v-bind="menuProps"
            ></v-btn>
          </template>
          <v-list min-width="230">
            <v-list-item v-if="props.edited" @click="emit('edit', item.id)">
              <v-list-item-title>{{ $t("button.edit") }}</v-list-item-title>
              <template #append>
                <v-icon icon="mdi-file-edit" color="grey-darken-2"></v-icon>
              </template>
            </v-list-item>
            <v-list-item
              :disabled="props.removedId === item.id"
              @click.stop="emit('remove', item.id)"
            >
              <v-list-item-title>{{ $t("button.delete") }}</v-list-item-title>
              <template #append>
                <v-icon icon="mdi-delete" color="grey-darken-2"></v-icon>
              </template>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

import { calcFetchPage, getRoleName, getUsersByRoles } from "@/utils/other";
import { getDepartmentKey, getLanguageKey } from "@/utils/auth";

import { Pagination } from "@/types/other";
import { ProfileData, DepartmentType } from "@/types/auth";
import { RoleType } from "@/types/roles";

const page = ref(1);

const pagData = computed<Pagination>(() => ({
  per_page: perPage,
  current_page: page.value,
  total: users.value.length,
  total_pages: Math.ceil(users.value.length / perPage),
}));

const { t } = useI18n();

const props = defineProps<{
  items?: ProfileData[];
  roles: RoleType[];
  removedId?: string;
  edited?: boolean;
  loading?: boolean;
}>();

const perPage = 5;

const checkIsHidden = (needRoles: RoleType[]) =>
  !props.roles.some((item) => needRoles.includes(item));

const headers = computed(
  () =>
    [
      {
        title: t("designation"),
        key: "designation",
        sortable: false,
        hidden: checkIsHidden([
          "manager",
          "planner",
          "sub-industry",
          "sub-logistic",
          "sub-forwarder",
        ]),
      },
      {
        title: t("department"),
        key: "department",
        sortable: false,
        hidden: checkIsHidden([
          "accounting-contractor",
          "accounting-client",
          "sub-industry",
          "sub-logistic",
          "sub-forwarder",
        ]),
      },
      {
        title: t("first-name"),
        key: "firstName",
        sortable: false,
      },
      {
        title: t("last-name"),
        key: "lastName",
        sortable: false,
      },
      {
        title: t("language"),
        key: "languages",
        sortable: false,
        hidden: checkIsHidden(["driver"]),
      },
      {
        title: t("email"),
        key: "email",
        sortable: false,
      },
      {
        title: t("phone"),
        key: "phone",
        sortable: false,
      },
      {
        title: t("receipt-recipient"),
        key: "voucherRecipient",
        sortable: false,
        hidden: checkIsHidden([
          "accounting-contractor",
          "accounting-client",
          "sub-industry",
          "sub-logistic",
          "sub-forwarder",
        ]),
      },
      {
        title: t("previous-role"),
        key: "from_role",
        sortable: false,
        hidden: checkIsHidden([
          "sub-industry",
          "sub-logistic",
          "sub-forwarder",
        ]),
      },
      {
        title: "",
        key: "actions",
        width: "48px",
        align: "end",
        sortable: false,
      },
    ] as any[],
);

const getDepartmentText = (
  department: DepartmentType,
  department_miscellaneous: string,
) => {
  switch (department) {
    case DepartmentType.no:
      return "";
    case DepartmentType.miscellaneous:
      return department_miscellaneous;
  }
  return t(getDepartmentKey(department));
};

const users = computed(() => {
  return getUsersByRoles(props.items, props.roles).map(
    ({
      department,
      department_miscellaneous,
      designation,
      firstname,
      lastname,
      email,
      phone,
      languages,
      voucher_recipient,
      from_role,
      id,
    }) => {
      const voucherRecipient = voucher_recipient ? t("text.yes") : t("text.no");
      return {
        department: getDepartmentText(department, department_miscellaneous),
        designation,
        firstName: firstname,
        lastName: lastname,
        languages: languages
          ?.map((language) => t(getLanguageKey(language)))
          .join(", "),
        email,
        phone,
        voucherRecipient,
        from_role: from_role ? t(getRoleName(from_role)) : "",
        id,
      };
    },
  );
});

watch(pagData, (value, oldValue) => {
  if (oldValue && oldValue.total > value.total) {
    page.value = calcFetchPage(oldValue.total - value.total, oldValue);
  }
});

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "remove", data: string): void;
  (e: "edit", data: string): void;
}>();
</script>

<style></style>
