<template>
  <div
    class="text-capitalize py-1 px-3 bpl-chip"
    :style="{
      color: props.color || primary,
      borderColor: props.borderColor || props.color || primary,
      backgroundColor: props.backgroundColor || transparent,
    }"
  >
    <span>
      <slot></slot>
    </span>
  </div>
</template>

<script setup lang="ts">
const primary = "#1982D4";
const transparent = "#00000000";

const props = defineProps<{
  color?: string;
  borderColor?: string;
  backgroundColor?: string;
}>();
</script>

<style scoped>
.bpl-chip {
  border: 1px solid;
  border-radius: 16px;
  width: max-content;
}
</style>
