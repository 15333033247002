<template>
  <v-row class="my-0 pt-6" :class="{ 'pointer-events-none': props.readonly }">
    <v-col cols="6" class="py-0">
      <v-select
        :model-value="documentObject.pallet_exchange_id"
        :error-messages="errors.pallet_exchange_id"
        autocomplete="off"
        :rules="[rules.required]"
        :label="$t('pallet-exchange')"
        :items="pallets"
        item-value="code"
        item-title="name"
        :readonly="props.readonly"
        :variant="props.readonly ? 'filled' : 'outlined'"
        class="pb-2"
        @update:model-value="vModalUpdate('pallet_exchange_id', $event)"
      >
        <template #append>
          <v-icon icon="mdi-information" color="primary"></v-icon
        ></template>
      </v-select>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "@/store";

import { useRules } from "@/utils/rules";
import {
  getLocalDocumentObject,
  getInitialDocumentErrorObject,
} from "@/utils/calculator";

import { DocumentErrorObject, DocumentObject } from "@/types/document";

const rules = useRules();
const store = useStore();

const props = defineProps<{
  modelValue?: DocumentObject;
  readonly?: boolean;
  errors?: DocumentErrorObject;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", data?: DocumentObject): void;
  (e: "update:errors", data: DocumentErrorObject): void;
}>();

const documentObject = computed(
  () => props.modelValue || getLocalDocumentObject(),
);

const errors = computed<DocumentErrorObject>({
  get() {
    return props.errors || getInitialDocumentErrorObject();
  },
  set(value?: DocumentErrorObject): void {
    emit("update:errors", value || getInitialDocumentErrorObject());
  },
});

const pallets = computed(() =>
  store.state.app.pallets.filter(({ active }) => active),
);

const vModalUpdate = (field: keyof DocumentObject, value: any) => {
  errors.value[field as keyof DocumentErrorObject] = [];
  emit("update:modelValue", {
    ...documentObject.value,
    [field]: value,
  });
};
</script>

<style scoped></style>
