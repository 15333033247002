import { onUnmounted, Ref, watch, VNodeRef } from "vue";
import { VForm } from "vuetify/components";
import { useI18n } from "vue-i18n";

export const useValidHelper = (form: Ref<VForm | undefined>) => {
  const { locale } = useI18n();

  watch(locale, () => {
    if (form.value?.errors?.length) {
      const errors = form.value.errors.map((e) => e.id);
      form.value.items.forEach((i) => {
        if (errors.includes(i.id)) i.validate();
      });
    }
  });

  const submitHandler = () => {
    const inputs = (form.value?.items || []).filter((i) => !i.isValid);

    if (inputs.length) {
      const activeId = document.activeElement?.id;
      const isActive = inputs.some((item) => item.id === activeId);

      if (!isActive) {
        const errorInput = document.querySelector(
          `#${inputs.map(({ id }) => id).join(", #")}`,
        );

        const inputWrap = errorInput?.closest(".v-input");
        if (inputWrap) {
          const topOffset = 74;
          const windowHeight = window.innerHeight;
          const { top, bottom } = inputWrap.getBoundingClientRect();

          if (top - topOffset < 0) {
            window.scrollTo({
              top: window.scrollY + top - topOffset,
              behavior: "smooth",
            });
          } else if (bottom > windowHeight) {
            window.scrollTo({
              top: window.scrollY + bottom - windowHeight,
              behavior: "smooth",
            });
          }
        }
      }
    }
  };

  watch(
    form,
    (value: VNodeRef) => {
      if (value) {
        if (value instanceof HTMLElement) {
          value.addEventListener("submit", submitHandler);
        } else {
          (value as any)?.$el?.addEventListener("submit", submitHandler);
        }
      }
    },
    { immediate: true },
  );

  onUnmounted(() => {
    form.value?.$el?.removeEventListener("submit", submitHandler);
    form.value?.removeEventListener("submit", submitHandler);
  });
};

const getIds = (elements: HTMLCollection) => {
  return Array.from(elements || []).map((i) => i.id);
};

export const getAllInputs = (block?: VNodeRef) => {
  if (block) {
    if (block instanceof HTMLElement) {
      return getIds(block.getElementsByTagName("input"));
    } else {
      return getIds((block as any)?.$el?.getElementsByTagName("input"));
    }
  }
  return [];
};

export const isValidCheck = async (
  inputs: (string | number)[],
  form?: VForm,
) => {
  const items = (form?.items || []).filter((i) => inputs.includes(i.id));
  await Promise.all(items.map((i) => i.validate()));
  return !items.some((i) => !i.isValid);
};
