<template>
  <v-sheet width="890" class="mx-auto py-12 px-10 rounded-xl bpl-border-gray">
    <div class="d-flex justify-center">
      <logo-full class="mx-auto"></logo-full>
    </div>
    <div class="text-h4 font-weight-light text-center mt-10">
      {{ $t("registration-role-title") }}
    </div>
    <v-container class="pa-0 mt-10">
      <v-row no-gutters class="role-form-item">
        <v-col>
          <role-btn
            :role="$t('client')"
            :description="$t('registration-client-subtitle')"
            @click="selectRole(NewUserRole.client)"
          />
        </v-col>
        <v-col>
          <role-btn
            :role="$t('contractor')"
            :description="$t('registration-contractor-subtitle')"
            @click="selectRole(NewUserRole.contractor)"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script setup lang="ts">
import LogoFull from "@/components/Auth/Registration/LogoFull.vue";
import RoleBtn from "@/components/Auth/Registration/RoleBtn.vue";
import { NewUserRole } from "@/types/auth";

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "select", role: NewUserRole): void;
}>();

const selectRole = (role: NewUserRole) => {
  emit("select", role);
};
</script>

<style scoped>
.role-form-item {
  gap: 40px;
}
</style>
