<template>
  <v-row no-gutters>
    <v-col cols="8" class="pa-10">
      <v-sheet class="pb-12 pt-11 px-10 rounded-xl bpl-border-gray">
        <div class="d-flex justify-space-between align-center">
          <div class="d-flex align-center">
            <v-btn
              icon="mdi-keyboard-backspace"
              variant="plain"
              :to="{ name: props.isOffer ? 'offers' : 'orders' }"
              class="mr-4"
            />
            <template v-if="documentObject">
              <p class="text-h4 text-primary font-weight-light py-1">
                {{ `${documentPrefix}${documentObject.number}` }}
              </p>
              <status-chip
                :status="{
                  name: documentObject.status,
                  context: props.isOffer
                    ? StatusContext.offer
                    : StatusContext.order,
                }"
                class="ml-4"
              />
              <router-link
                v-if="documentObject.ride"
                :to="{
                  name: 'ride',
                  params: { id: documentObject.ride.data.id },
                }"
                class="ml-4 text-decoration-none"
              >
                <base-chip
                  color="#C2E9FF"
                  border-color="#1982D4"
                  background-color="#1982D4"
                  class="text-capitalize py-1 px-3"
                >
                  <span>{{ $t("ride.text") }}</span>
                  <v-icon
                    icon="mdi-open-in-new"
                    color="#C2E9FF"
                    class="ml-1"
                  ></v-icon>
                </base-chip>
              </router-link>
              <template v-if="isBplAdmin">
                <v-tooltip
                  v-if="documentObject.special_department"
                  :text="$t('status-order.special-department')"
                  location="top"
                  offset="2px"
                >
                  <template #activator="{ props: tooltipProps }">
                    <v-icon
                      v-bind="tooltipProps"
                      color="warning"
                      icon="mdi-alert-outline"
                      class="ml-2"
                    ></v-icon>
                  </template>
                </v-tooltip>
                <router-link
                  v-if="documentObject.marketplace"
                  :to="{
                    name: 'marketplace-item',
                    params: { id: documentObject.id },
                  }"
                  class="ml-4 text-decoration-none"
                >
                  <base-chip
                    color="#C2FFDB"
                    border-color="#27AE60"
                    background-color="#27AE60"
                    class="text-capitalize py-1 px-3"
                  >
                    <span>{{ $t("marketplace") }}</span>
                    <v-icon
                      icon="mdi-open-in-new"
                      color="#C2FFDB"
                      class="ml-1"
                    ></v-icon>
                  </base-chip>
                </router-link>
              </template>
            </template>
          </div>
          <div class="d-flex align-center">
            <export-loader
              v-if="isExported || exportLoading"
              :tooltip="$t('loader.download-prepared')"
            />
            <v-btn
              v-if="
                documentObject &&
                !props.isOffer &&
                isBplAdmin &&
                carrierIsEmpty &&
                isNotCanceled
              "
              color="primary"
              variant="outlined"
              class="ml-2"
              :disable="setMarketplaceLoading"
              :loading="setMarketplaceLoading"
              @click="setMarketplace(!documentObject.marketplace)"
            >
              {{
                documentObject.marketplace
                  ? $t("button.remove-marketplace")
                  : $t("button.show-marketplace")
              }}
            </v-btn>
            <v-menu>
              <template #activator="{ props: menuProps }">
                <v-btn
                  class="ml-2"
                  icon="mdi-dots-vertical"
                  variant="plain"
                  elevation="0"
                  v-bind="menuProps"
                ></v-btn>
              </template>
              <v-list min-width="230">
                <template v-if="!isOffer">
                  <template v-if="isBplAdmin">
                    <v-list-item
                      v-if="isClient || isBplAdmin"
                      @click="exportPdf()"
                    >
                      <v-list-item-title>
                        {{ $t("button.pdf-export-ab") }}
                      </v-list-item-title>
                      <template #append>
                        <v-icon
                          icon="mdi-file-export"
                          color="grey-darken-2"
                        ></v-icon>
                      </template>
                    </v-list-item>
                    <v-list-item
                      v-if="isTransporter || isBplAdmin"
                      @click="exportPdfTa()"
                    >
                      <v-list-item-title>
                        {{ $t("button.pdf-export-ta") }}
                      </v-list-item-title>
                      <template #append>
                        <v-icon
                          icon="mdi-file-export"
                          color="grey-darken-2"
                        ></v-icon>
                      </template>
                    </v-list-item>
                  </template>
                  <template v-else>
                    <v-list-item @click="exportPdf()">
                      <v-list-item-title>
                        {{ $t("button.pdf-export") }}
                      </v-list-item-title>
                      <template #append>
                        <v-icon
                          icon="mdi-file-export"
                          color="grey-darken-2"
                        ></v-icon>
                      </template>
                    </v-list-item>
                  </template>
                </template>
                <v-list-item v-if="!isTransporter" @click="exportCsv()">
                  <v-list-item-title>
                    {{ $t("button.csv-export") }}
                  </v-list-item-title>
                  <template #append>
                    <v-icon
                      icon="mdi-file-table"
                      color="grey-darken-2"
                    ></v-icon>
                  </template>
                </v-list-item>
                <v-list-item
                  v-if="props.isOffer"
                  :disabled="removeLoading"
                  @click.stop="deleteDocument()"
                >
                  <v-list-item-title>
                    {{ $t("button.delete") }}
                  </v-list-item-title>
                  <template #append>
                    <v-icon icon="mdi-delete" color="grey-darken-2"></v-icon>
                  </template>
                </v-list-item>
                <v-list-item
                  v-else-if="(isBplAdmin || isClient) && isNotCanceled"
                  :disabled="cancelLoading"
                  @click.stop="cancelDocument()"
                >
                  <v-list-item-title>
                    {{ $t("button.cancel-order") }}
                  </v-list-item-title>
                  <template #append>
                    <v-icon icon="mdi-cancel" color="grey-darken-2"></v-icon>
                  </template>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <template v-if="documentObject">
          <template v-if="pointsIsEmpty && isNotCanceled">
            <v-alert
              v-if="isPriceOnRequest"
              :text="$t('alert.missing-data-request')"
              color="warning"
              variant="tonal"
              class="mt-10"
              icon="mdi-alert-outline"
              closable
            />
            <v-alert
              v-else
              :text="$t('alert.missing-data', { date: deadlineFillingDate })"
              color="warning"
              variant="tonal"
              class="mt-10"
              icon="mdi-alert-outline"
              closable
            />
          </template>
          <template v-if="!props.isOffer">
            <v-alert
              v-if="isBplAdmin && carrierIsEmpty && isNotCanceled"
              :text="$t('alert.missing-carrier')"
              color="warning"
              variant="tonal"
              class="mt-10"
              icon="mdi-alert-outline"
              closable
            />
            <v-form
              v-if="isBplAdmin || isTransporter"
              ref="formCarrier"
              v-model="validCarrier"
              @submit.prevent="saveCarrierChanges()"
            >
              <editable-block
                v-model="editedCarrier"
                class="mt-10"
                submit
                :loading="updateCarrierLoading"
                @cancel="syncCarrier"
              >
                <template #title>
                  <p class="text-h5 text-primary py-2">
                    {{ isBplAdmin ? $t("transporters") : $t("planner") }}
                  </p>
                </template>
                <template v-if="isNotCanceled" #edit-btn>
                  <v-btn
                    v-if="isBplAdmin"
                    :to="{
                      name: 'transporters',
                      query: {
                        add: 'true',
                      },
                    }"
                    color="primary"
                  >
                    {{ $t("button.register-transporter") }}
                  </v-btn>
                  <v-btn
                    variant="text"
                    elevation="0"
                    color="primary"
                    class="ml-2"
                    icon="mdi-file-edit-outline"
                    @click="editedCarrier = true"
                  />
                </template>
                <template #default="{ readonly }">
                  <div class="pt-4">
                    <async-company-selector
                      v-if="isBplAdmin"
                      v-model="locCarrier.carrier_id"
                      no-selection
                      :title="$t(`find-transporter`)"
                      class="pb-2"
                      :class="{ 'pointer-events-none': readonly }"
                      :readonly="readonly"
                      :type="CompanyQueryType.carrier"
                      @changed="adminCarrierCompanyObject = $event"
                    />
                    <template v-if="carrierCompanyData">
                      <v-select
                        v-model="locCarrier.carrier_contact_id"
                        :label="$t('contact-person')"
                        :items="carrierCompanyProfiles"
                        :variant="readonly ? 'filled' : 'outlined'"
                        :class="{ 'pointer-events-none': readonly }"
                        :readonly="readonly"
                        prepend-inner-icon="mdi-magnify"
                      ></v-select>
                    </template>
                  </div>
                </template>
              </editable-block>
            </v-form>
          </template>
          <v-form
            ref="formPoints"
            v-model="validPoints"
            @submit.prevent="savePointsChanges()"
          >
            <editable-block
              v-model="editedPoints"
              class="mt-7"
              submit
              :loading="updatePointsLoading"
              @cancel="syncPoints"
            >
              <template #title>
                <p class="text-h5 text-primary py-2">
                  {{ $t("route-data") }}
                </p>
              </template>
              <template v-if="isNotCanceled && !isTransporter" #edit-btn>
                <v-btn
                  variant="text"
                  elevation="0"
                  color="primary"
                  icon="mdi-file-edit-outline"
                  @click="editedPoints = true"
                />
              </template>
              <template #default="{ readonly }">
                <div class="pt-6">
                  <v-chip>
                    <span class="text-grey-darken-2">
                      {{ $t("vignette-avoidance") }}:
                      <span class="text-grey-darken-4">
                        {{
                          documentObject.vignette
                            ? $t("text.yes")
                            : $t("text.no")
                        }}
                      </span>
                    </span>
                  </v-chip>
                  <v-chip class="ml-4">
                    <span class="text-grey-darken-2">
                      {{ $t("duration") }}:
                      <span class="text-grey-darken-4">
                        {{ formatDuration }}
                      </span>
                    </span>
                  </v-chip>
                  <v-chip v-if="calcData" class="ml-4">
                    <span class="text-grey-darken-2">
                      {{ $t("route-data") }}:
                      <span class="text-grey-darken-4">
                        {{ calcData.distance }} km
                      </span>
                    </span>
                  </v-chip>
                </div>
                <div
                  v-if="locPoints"
                  :class="{ 'pointer-events-none': readonly }"
                >
                  <route-point
                    v-for="(point, index) in locPoints"
                    :key="point.id"
                    v-model="locPoints[index]"
                    :document="documentObject"
                    :empty-warning="pointWarningsActivator"
                    :readonly="readonly"
                    :index="index"
                    :is-last="index === locPoints.length - 1"
                  />
                </div>
                <div
                  v-if="!isTransporter"
                  class="pt-10 pb-6"
                  :class="{ 'pointer-events-none': readonly }"
                >
                  <p class="text-h5 text-primary mb-8">
                    {{ $t("assignment") }}
                  </p>
                  <v-text-field
                    v-model="locAssignment.reference_number"
                    :error-messages="assignmentErrors.reference_number"
                    :rules="[rules.required, rules.lengthMax(20)]"
                    :label="$t('reference-number')"
                    :readonly="readonly"
                    :variant="readonly ? 'filled' : 'outlined'"
                    clearable
                    class="pb-2"
                    @update:model-value="assignmentErrors.reference_number = []"
                  ></v-text-field>
                </div>
              </template>
            </editable-block>
          </v-form>
          <v-divider />
          <v-form
            ref="formDates"
            v-model="validDates"
            @submit.prevent="saveDatesChanges()"
          >
            <editable-block
              v-model="editedDates"
              class="mt-7"
              submit
              :loading="updateDatesLoading"
              @cancel="syncDates"
            >
              <template #title>
                <p class="text-h5 text-primary py-2">
                  {{ $t("arrival-time") }}
                </p>
              </template>
              <template v-if="isBplAdmin" #edit-btn>
                <v-btn
                  variant="text"
                  elevation="0"
                  color="primary"
                  icon="mdi-file-edit-outline"
                  @click="editedDates = true"
                />
              </template>
              <template #default="{ readonly }">
                <v-row class="my-0 py-6">
                  <v-col
                    :cols="
                      !readonly ? (!flexStart ? 8 : 4) : !flexStart ? 12 : 6
                    "
                    class="py-0"
                  >
                    <calendar-field
                      v-model="locDates.loading_time_start"
                      :error-messages="datesErrors.loading_time_start"
                      :title="$t('loading-time')"
                      :rules="[rules.required]"
                      class="pb-2"
                      :info="!flexStart && !readonly"
                      :max="
                        locDates.loading_time_end ||
                        locDates.unloading_time_start ||
                        locDates.unloading_time_end
                      "
                      :readonly="readonly"
                      :loading="updateDatesLoading"
                      :chip-format="locFormat.yMmDdHhMmSs"
                      @update:model-value="datesErrors.loading_time_start = []"
                    />
                  </v-col>
                  <v-col
                    v-if="flexStart"
                    :cols="!readonly ? 4 : 6"
                    class="bpl-hyphen-col py-0"
                  >
                    <calendar-field
                      v-model="locDates.loading_time_end"
                      :error-messages="datesErrors.loading_time_end"
                      :title="$t('loading-time')"
                      :rules="[
                        rules.required,
                        rules.mustAfter(
                          minDateLoadingEnd,
                          locFormat.yMmDdHhMmSs,
                        ),
                      ]"
                      class="pb-2"
                      :info="!readonly"
                      :min="minDateLoadingEnd"
                      :max="
                        locDates.unloading_time_start ||
                        locDates.unloading_time_end
                      "
                      :readonly="readonly"
                      :loading="updateDatesLoading"
                      :chip-format="locFormat.yMmDdHhMmSs"
                      @update:model-value="datesErrors.loading_time_end = []"
                    />
                  </v-col>
                  <v-col v-if="!readonly" cols="4" class="py-0 pl-12">
                    <v-tooltip
                      text="Lorem Ipsum"
                      location="bottom"
                      offset="-32px"
                    >
                      <template #activator="{ props: tooltipProps }">
                        <div v-bind="tooltipProps" class="d-inline-block">
                          <v-checkbox
                            v-model="flexStart"
                            :label="$t('flexible-arrival-time')"
                            color="primary"
                            class="bpl-underlined-field"
                          ></v-checkbox></div></template
                    ></v-tooltip>
                  </v-col>
                  <v-col
                    :cols="!readonly ? (!flexEnd ? 8 : 4) : !flexEnd ? 12 : 6"
                    class="py-0"
                  >
                    <calendar-field
                      v-model="locDates.unloading_time_start"
                      :error-messages="datesErrors.unloading_time_start"
                      :title="$t('discharge-time')"
                      :rules="[
                        rules.required,
                        rules.mustAfter(
                          minDateDischargeStart,
                          locFormat.yMmDdHhMmSs,
                        ),
                      ]"
                      class="pb-2"
                      :info="!flexEnd && !readonly"
                      :min="minDateDischargeStart"
                      :max="locDates.unloading_time_end"
                      :readonly="readonly"
                      :loading="updateDatesLoading"
                      :chip-format="locFormat.yMmDdHhMmSs"
                      @update:model-value="
                        datesErrors.unloading_time_start = []
                      "
                    />
                  </v-col>
                  <v-col
                    v-if="flexEnd"
                    :cols="!readonly ? 4 : 6"
                    class="bpl-hyphen-col py-0"
                  >
                    <calendar-field
                      v-model="locDates.unloading_time_end"
                      :error-messages="datesErrors.unloading_time_end"
                      :title="$t('discharge-time')"
                      :rules="[
                        rules.required,
                        rules.mustAfter(
                          minDateDischargeEnd,
                          locFormat.yMmDdHhMmSs,
                        ),
                      ]"
                      class="pb-2"
                      :info="!readonly"
                      :min="minDateDischargeEnd"
                      :readonly="readonly"
                      :loading="updateDatesLoading"
                      :chip-format="locFormat.yMmDdHhMmSs"
                      @update:model-value="datesErrors.unloading_time_end = []"
                    />
                  </v-col>
                  <v-col v-if="!readonly" cols="4" class="py-0 pl-12">
                    <v-tooltip
                      text="Lorem Ipsum"
                      location="bottom"
                      offset="-32px"
                    >
                      <template #activator="{ props: tooltipProps }">
                        <div v-bind="tooltipProps" class="d-inline-block">
                          <v-checkbox
                            v-model="flexEnd"
                            :label="$t('flexible-arrival-time')"
                            color="primary"
                            class="bpl-underlined-field"
                          ></v-checkbox></div></template
                    ></v-tooltip>
                  </v-col>
                </v-row>
              </template>
            </editable-block>
          </v-form>
          <v-divider />
          <p class="text-h5 text-primary mt-10 mb-8">
            {{ $t("offer-data") }}
          </p>
          <div>
            <v-chip>
              <span v-if="isTransporter" class="text-grey-darken-2">
                {{ $t("booked-on") }}:
                <span class="text-grey-darken-4">
                  {{ bookedOn }}
                </span>
              </span>
              <span v-else class="text-grey-darken-2">
                {{ $t("date-created") }}:
                <span class="text-grey-darken-4">
                  {{ createdAt }}
                </span>
              </span>
            </v-chip>
            <v-chip v-if="calcData" class="ml-4">
              <span class="text-grey-darken-2">
                {{ $t("number-drivers") }}:
                <span class="text-grey-darken-4">
                  {{ calcData.drivers }}
                </span>
              </span>
            </v-chip>
            <v-chip class="ml-4">
              <span class="text-grey-darken-2">
                {{ $t("pallet-exchange") }}:
                <span class="text-grey-darken-4">
                  {{ documentObject.pallet.data.name }}
                </span>
              </span>
            </v-chip>
          </div>
          <v-row class="my-0 pt-8 pointer-events-none">
            <v-col cols="6" class="py-0">
              <fake-input
                :label="$t('vehicle-type')"
                class="pb-2"
                :value="documentObject.vehicle.data.name"
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <v-combobox
                variant="outlined"
                readonly
                :label="$t('equipment')"
                :model-value="
                  documentObject.vehicle.data.equipment?.split(',') || []
                "
                multiple
                chips
              />
            </v-col>
            <v-col v-if="spCalcList.length" cols="12" class="py-0">
              <v-combobox
                variant="outlined"
                readonly
                :label="$t('special-features')"
                :model-value="spCalcList"
                multiple
                chips
              >
                <template #chip="{ item }">
                  <v-chip>
                    <span class="text-grey-darken-2">
                      {{ item.title ? `${item.title}:` : "" }}
                      <span class="text-grey-darken-4">
                        {{ item.value }}
                      </span>
                    </span>
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
          <template v-if="!isTransporter">
            <p class="text-h5 text-primary mt-4">
              {{ $t("master-data") }}
            </p>
            <v-row class="my-0 pt-8">
              <v-col cols="12" class="py-0">
                <fake-input
                  :label="$t('company')"
                  class="pb-2"
                  :value="companyData"
                />
              </v-col>
              <v-col cols="12" class="py-0">
                <fake-input
                  :label="$t('different-billing-address')"
                  class="pb-2"
                  :value="differentBillingAddress"
                />
              </v-col>
              <v-col cols="12" class="py-0">
                <fake-input
                  :label="$t('contact')"
                  class="pb-2"
                  :value="contactData"
                />
              </v-col>
              <v-col cols="12" class="py-0">
                <fake-input
                  :label="$t('dispatcher')"
                  class="pb-2"
                  :value="dispatcherData"
                /> </v-col
            ></v-row>
          </template>
          <v-divider />
          <p class="text-h5 text-primary mt-10">
            {{ $t("goods") }}
          </p>
          <v-row class="my-0 pt-8">
            <v-col cols="4" class="py-0">
              <fake-input
                :label="$t('type-goods')"
                class="pb-2"
                :value="documentObject.goods_type"
              />
            </v-col>
            <v-col cols="4" class="py-0">
              <fake-input
                :label="$t('value-goods')"
                class="pb-2"
                :value="formatGoodsValue"
                append-inner-icon="mdi-currency-eur"
              />
            </v-col>
            <v-col cols="4" class="py-0">
              <fake-input
                :label="$t('weight-goods')"
                class="pb-2"
                :value="formatGoodsWeight"
                append-inner-icon="mdi-weight-kilogram"
            /></v-col>
            <v-col cols="4" class="py-0">
              <fake-input
                :label="$t('length')"
                class="pb-2"
                :value="formatGoodsLength"
              />
            </v-col>
            <v-col cols="4" class="py-0">
              <fake-input
                :label="$t('width')"
                class="pb-2"
                :value="formatGoodsWidth"
              />
            </v-col>
            <v-col cols="4" class="py-0">
              <fake-input
                :label="$t('height')"
                class="pb-2"
                :value="formatGoodsHeight"
              />
            </v-col>
            <template v-if="!isTransporter">
              <v-col cols="12" class="py-0">
                <v-tooltip text="Lorem Ipsum" location="bottom" offset="-32px">
                  <template #activator="{ props: tooltipProps }">
                    <div v-bind="tooltipProps" class="d-inline-block">
                      <v-checkbox
                        v-model="documentObject.goods_insurance"
                        disabled
                        :label="$t('insurance')"
                        color="primary"
                        class="bpl-underlined-field"
                      ></v-checkbox>
                    </div>
                  </template>
                </v-tooltip>
              </v-col>
              <v-col cols="12" class="pt-0 pb-8">
                <v-alert
                  v-if="documentObject.goods_insurance"
                  :text="$t('obtain-insurance-text')"
                  color="info"
                  variant="tonal"
                />
                <v-alert
                  v-else
                  :text="$t('waived-insurance-text')"
                  color="warning"
                  variant="tonal"
                  icon="mdi-alert-outline"
                />
              </v-col>
              <template v-if="documentObject.goods_insurance">
                <v-col cols="4" class="py-0">
                  <fake-input
                    :label="$t('product-group')"
                    class="pb-2"
                    :value="goodsProductGroupTitle"
                  />
                </v-col>
                <v-col cols="8" class="py-0">
                  <div class="d-flex align-center">
                    <v-icon icon="mdi-information" color="primary"></v-icon>
                    <div class="ml-4">
                      <div>{{ $t("product-group-text") }}</div>
                      <router-link
                        to="/warengruppen"
                        target="_blank"
                        class="text-primary text-decoration-none"
                      >
                        {{ $t("product-group-link") }}
                      </router-link>
                    </div>
                  </div>
                </v-col>
              </template>
            </template>
          </v-row>
          <v-divider />
          <p class="text-h5 text-primary mt-10">
            {{ $t("payment-target") }}
          </p>
          <v-row class="my-0 pt-8">
            <v-col cols="4" class="py-0">
              <fake-input
                :label="$t('payment-target')"
                class="pb-2"
                :value="paymentData"
              />
            </v-col>
          </v-row>
          <v-divider />
        </template>
        <app-loader v-else-if="loading" />
      </v-sheet>
    </v-col>
    <v-col cols="4" class="pa-10 pl-0">
      <v-sheet
        v-if="isTransporter"
        class="py-12 px-10 rounded-xl bpl-border-gray"
      >
        <p class="text-h5 text-primary mb-8">
          {{ $t("order-amount") }}
        </p>
        <app-loader v-if="loading" height="76px" />
        <div v-else>
          <p class="text-h4 font-weight-bold text-primary mb-2">
            {{ formatTransporterPrice }} €
          </p>
          <p class="text-subtitle-1">
            {{ $t("text.calculator-vat") }}
          </p>
        </div>
      </v-sheet>
      <v-sheet v-else class="py-12 px-10 rounded-xl bpl-border-gray">
        <p class="text-h5 text-primary mb-8">
          {{ $t("current-costs") }}
        </p>
        <app-loader v-if="loading" />
        <div v-else>
          <v-row class="my-0">
            <v-col cols="12" class="py-0">
              <fake-input
                class="pb-2"
                :label="$t('base-price')"
                append-inner-icon="mdi-currency-eur"
                :value="formatBasePrice"
              ></fake-input>
            </v-col>
          </v-row>
          <div class="text-subtitle-1 text-grey-darken-2 pb-2">
            <template v-for="item in calcList || []" :key="item.name">
              <div
                v-if="item.calculation_type === FeatureCalcType.priceOnRequest"
                class="d-flex justify-space-between py-3"
                :class="{ 'text-error': isOffer }"
              >
                <span>{{ item.name }}:</span>
                <span>{{ $t("price-on-request") }}</span>
              </div>
              <div v-else class="d-flex justify-space-between py-3">
                <span>{{ item.name }}:</span>
                <span>{{ item.cost }} €</span>
              </div>
            </template>
            <div
              v-if="documentObject?.manual"
              class="d-flex justify-space-between py-3 text-error"
            >
              <span>{{ $t("manual-offer") }}:</span>
              <span>{{ $t("price-on-request") }}</span>
            </div>
            <div
              v-if="totalPriceDiff"
              class="d-flex justify-space-between py-3"
            >
              <span>
                {{ totalPriceDiff > 0 ? $t("surcharge") : $t("deduction") }}:
              </span>
              <span>{{ formatTotalPriceDiff }} €</span>
            </div>
          </div>
          <!-- is price on request offer -->
          <template v-if="isPriceOnRequest">
            <!-- is price on request offer and user have admin role -->
            <template v-if="isBplAdmin">
              <p class="text-h5 text-primary mb-8 mt-4">
                {{ $t("price-without-individual-position") }}
              </p>
              <p class="text-h4 font-weight-bold text-grey-darken-1 mb-2">
                {{ formatTotalPrice }} €
              </p>
              <p class="text-grey-darken-1">
                {{ $t("text.calculator-vat") }}
              </p>
              <v-alert
                v-if="documentObject?.manual"
                :text="$t('notifications.manual-offer')"
                color="warning"
                variant="tonal"
                class="mt-8"
              />
              <v-alert
                v-else
                :text="$t('notifications.price-on-request')"
                color="warning"
                variant="tonal"
                class="mt-8"
              />
              <v-form
                ref="formNewTotalPrice"
                v-model="validNewTotalPrice"
                @submit.prevent="saveNewTotalPrice()"
              >
                <div
                  class="d-flex flex-column justify-space-between align-center mt-8"
                >
                  <div class="w-100">
                    <p class="text-h5 text-primary py-2">
                      {{ $t("new-total") }}
                    </p>
                    <div class="mt-8">
                      <v-text-field
                        v-model="newTotalPrice"
                        :error-messages="newTotalPriceErrors.total_price"
                        :label="$t('individual-price')"
                        :rules="[
                          rules.required,
                          rules.numberOnly,
                          rules.range(1, Infinity),
                        ]"
                        variant="outlined"
                        append-inner-icon="mdi-currency-eur"
                        class="input-no-details"
                        @update:model-value="
                          newTotalPriceErrors.total_price = []
                        "
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <v-checkbox
                  v-model="acceptNewTotalPrice"
                  :disabled="!newTotalPrice"
                  :label="$t('text.vat-include')"
                  color="primary"
                  class="mb-0 mt-6"
                  hide-details
                ></v-checkbox>
                <v-btn
                  type="submit"
                  color="primary"
                  size="x-large"
                  :loading="updateNewTotalPriceLoading"
                  :disabled="!canCreateOrder"
                  class="mt-8 w-100"
                >
                  {{ $t("button.create-order") }}
                </v-btn>
              </v-form>
            </template>
            <!-- is price on request offer and user haven't admin role -->
            <template v-else>
              <p class="text-h5 text-primary mt-4">
                {{ $t("total") }}
              </p>
              <v-alert
                :text="$t('notifications.price-on-request-offer')"
                color="info"
                variant="tonal"
                class="mt-8"
              />
            </template>
          </template>
          <!-- isn't price on request or isn't offer -->
          <template v-else-if="documentObject">
            <!-- is admin -->
            <template v-if="isBplAdmin">
              <v-form
                ref="formTotalPrice"
                v-model="validTotalPrice"
                @submit.prevent="saveTotalPrice()"
              >
                <editable-block
                  v-model="editedTotalPrice"
                  class="mt-10"
                  submit
                  :loading="updateTotalPriceLoading"
                  @cancel="syncTotalPrice"
                >
                  <template #title>
                    <p class="text-h5 text-primary py-2">
                      {{ $t("total") }}
                    </p>
                  </template>
                  <template v-if="!documentObject.marketplace" #edit-btn>
                    <v-btn
                      variant="text"
                      elevation="0"
                      color="primary"
                      class="ml-2"
                      icon="mdi-file-edit-outline"
                      @click="editedTotalPrice = true"
                    />
                  </template>
                  <template #default="{ readonly }">
                    <v-row
                      class="my-0 py-6"
                      :class="{ 'pointer-events-none': readonly }"
                    >
                      <v-col cols="12" class="py-0">
                        <v-text-field
                          v-model="locTotalPrice.total_price"
                          :error-messages="totalPriceErrors.total_price"
                          :rules="[rules.required, rules.floatNumberOnly]"
                          :label="$t('net-value')"
                          type="number"
                          hide-spin-buttons
                          :readonly="readonly"
                          :variant="readonly ? 'filled' : 'outlined'"
                          append-inner-icon="mdi-currency-eur"
                          clearable
                          class="pb-2"
                          @update:model-value="
                            totalPriceErrors.total_price = []
                          "
                        ></v-text-field>
                        <div
                          v-if="formatTotalPriceInitial"
                          class="d-flex justify-space-between text-subtitle-1 text-grey-darken-2"
                        >
                          <span>{{ $t("original-calculation-value") }}:</span>
                          <span>{{ formatTotalPriceInitial }} € </span>
                        </div>
                        <v-alert
                          v-if="totalPriceAlert"
                          :text="$t('alert.adjusted-total-price')"
                          color="warning"
                          variant="tonal"
                          class="mt-8"
                        />
                      </v-col>
                    </v-row>
                  </template>
                </editable-block>
              </v-form>
              <v-form
                ref="formTransporterPrice"
                v-model="validTransporterPrice"
                @submit.prevent="saveTransporterPrice()"
              >
                <editable-block
                  v-model="editedTransporterPrice"
                  submit
                  :loading="updateTransporterPriceLoading"
                  @cancel="syncTransporterPrice"
                >
                  <template #title>
                    <p class="text-h5 text-primary py-2">
                      {{ $t("transporter-price") }}
                    </p>
                  </template>
                  <template v-if="!documentObject.marketplace" #edit-btn>
                    <v-btn
                      variant="text"
                      elevation="0"
                      color="primary"
                      class="ml-2"
                      icon="mdi-file-edit-outline"
                      @click="editedTransporterPrice = true"
                    />
                  </template>
                  <template #default="{ readonly }">
                    <v-row
                      class="my-0 py-6"
                      :class="{ 'pointer-events-none': readonly }"
                    >
                      <v-col cols="12" class="py-0">
                        <v-text-field
                          v-model="locTransporterPrice.transporter_price"
                          :error-messages="
                            transporterPriceErrors.transporter_price
                          "
                          :rules="[rules.required, rules.floatNumberOnly]"
                          :label="$t('net-value')"
                          type="number"
                          hide-spin-buttons
                          :readonly="readonly"
                          :variant="readonly ? 'filled' : 'outlined'"
                          append-inner-icon="mdi-currency-eur"
                          clearable
                          class="pb-2"
                          @update:model-value="
                            transporterPriceErrors.transporter_price = []
                          "
                        ></v-text-field>
                        <div
                          v-if="formatTransporterPriceInitial"
                          class="d-flex justify-space-between text-subtitle-1 text-grey-darken-2"
                        >
                          <span>{{ $t("original-calculation-value") }}:</span>
                          <span> {{ formatTransporterPriceInitial }} € </span>
                        </div>
                        <v-alert
                          v-if="transporterPriceAlert"
                          :text="$t('alert.adjusted-transporter-price')"
                          color="warning"
                          variant="tonal"
                          class="mt-8"
                        />
                      </v-col>
                    </v-row>
                  </template>
                </editable-block>
              </v-form>
            </template>
            <!-- isn't admin -->
            <template v-else>
              <p class="text-h5 text-primary mb-8 mt-4">
                {{ $t("total") }}
              </p>
              <p class="text-h4 font-weight-bold text-primary mb-2">
                {{ formatTotalPrice }} €
              </p>
              <p class="text-subtitle-1">
                {{ $t("text.calculator-vat") }}
              </p>
            </template>
          </template>
        </div>
      </v-sheet>
    </v-col>
    <async-modal :id="cancelConfirm.id" v-slot="{ resolve, cancel, autofocus }">
      <v-card max-width="660" class="px-10 py-12">
        <p class="text-h4 font-weight-light text-primary text-center">
          {{ $t("dialog.document-cancel-order") }}
        </p>
        <div class="text-grey-darken-2 text-center">
          <p class="mt-4">
            {{ $t("dialog.document-cancel-order-text1") }}
          </p>
          <p class="mt-4">
            {{ $t("dialog.document-cancel-order-text2") }}
          </p>
          <p class="mt-4 font-weight-bold">
            {{ $t("dialog.document-cancel-order-text3") }}
            <router-link to="/terms" target="_blank" class="text-primary">
              {{ $t("here") }}
            </router-link>
            .
          </p>
          <div class="mt-8">
            <v-checkbox
              v-model="cancelCheckbox"
              :label="$t('dialog.document-cancel-order-checkbox')"
              color="primary"
              hide-details
              class="bpl-underlined-field"
            ></v-checkbox>
          </div>
        </div>
        <v-btn
          :disabled="!cancelCheckbox"
          color="primary"
          size="large"
          class="mb-4 mt-8"
          @click="resolve(ResolveType.confirm)"
        >
          {{ $t("button.cancel-order") }}
        </v-btn>
        <v-btn
          :ref="autofocus"
          variant="outlined"
          color="primary"
          size="large"
          class="bg-light-blue-lighten-5"
          @click="cancel()"
        >
          {{ $t("button.cancel") }}
        </v-btn>
      </v-card>
    </async-modal>
    <async-modal
      :id="stopoversModal.id"
      v-slot="{ resolve, cancel, autofocus }"
    >
      <v-card class="px-10 py-12">
        <p class="text-h4 font-weight-light text-primary text-center">
          {{ $t("dialog.stopovers-title") }}
        </p>
        <div class="mt-4 text-grey-darken-2 text-center">
          <p>
            {{
              $t("dialog.stopovers-text", { stopovers: emptyPoints.join(", ") })
            }}
          </p>
          <p class="font-weight-bold">
            {{ `${$t("loading-reference")}, ${$t("discharge-reference")}` }}
          </p>
        </div>
        <v-btn
          :ref="autofocus"
          variant="outlined"
          color="primary"
          size="large"
          class="mb-4 mt-10"
          @click="resolve(ResolveType.confirm)"
        >
          {{ $t("button.save-without-entry") }}
        </v-btn>
        <v-btn
          variant="outlined"
          color="primary"
          size="large"
          class="bg-light-blue-lighten-5"
          @click="cancel()"
        >
          {{ $t("button.add-now") }}
        </v-btn>
      </v-card>
    </async-modal>
  </v-row>
</template>

<script setup lang="ts">
import { VForm, VTextField } from "vuetify/components";
import { ref, computed, watch, nextTick } from "vue";
import { useStore } from "@/store";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import moment from "moment";

import { updateDocument, getDocument, removeDocuments } from "@/api/documents";

import { snackbar } from "@/utils/snackbar";
import { useRules } from "@/utils/rules";
import {
  getPaymentTargetName,
  joinWithDots,
  cleanObject,
  useFormat,
} from "@/utils/other";
import { useValidHelper } from "@/utils/validHelper";
import { useLocFormat } from "@/utils/locFormat";
import {
  getInitialDocumentCalcObject,
  getGoodsProductGroupTitle,
} from "@/utils/calculator";
import { useCalcList } from "@/utils/calcList";
import { useConfirm } from "@/utils/confirmHelper";
import { access } from "@/utils/access";
import { getInitialDocumentErrorObject } from "@/utils/calculator";
import { useNumberHelper } from "@/utils/numberHelper";
import { useAsyncModal, ResolveType } from "@/utils/asyncModalHelper";

import {
  EditCarrierObject,
  EditAssignmentObject,
  EditDatesObject,
  EditTotalPriceObject,
  EditTransporterPriceObject,
  DocumentCalcObject,
} from "@/types/calculator";
import { ExportNotificationType } from "@/types/other";
import { FeatureCalcType } from "@/types/settings";
import {
  SavedPointObject,
  EditPointObject,
  DocumentType,
  DocumentSavedData,
  DocumentErrorObject,
  OfferStatusType,
  OrderStatusType,
  StatusContext,
  PointType,
  PointMeta,
} from "@/types/document";
import { CompanyData, CompanyQueryType } from "@/types/company";
import { ProfileData } from "@/types/auth";

import CalendarField from "@/components/CalendarField.vue";
import FakeInput from "@/components/FakeInput.vue";
import ExportLoader from "@/components/ExportLoader.vue";
import AppLoader from "@/components/AppLoader.vue";
import StatusChip from "@/components/StatusChip.vue";
import EditableBlock from "@/components/EditableBlock.vue";
import AsyncCompanySelector from "@/components/AsyncCompanySelector.vue";
import RoutePoint from "@/components/DocumentsView/DocumentView/RoutePoint.vue";
import AsyncModal from "@/components/AsyncModal.vue";
import BaseChip from "@/components/BaseChip.vue";

const { t } = useI18n();
const { locale } = useI18n();
const router = useRouter();
const rules = useRules();
const store = useStore();
const locFormat = useLocFormat();

const deleteConfirm = useConfirm("dialog.delete-item");
const cancelConfirm = useAsyncModal();
const marketTransporterSelectedConfirm = useConfirm(
  "dialog.document-transporter-selected",
  { text: "dialog.document-transporter-selected-text" },
);
const marketShowConfirm = useConfirm("dialog.document-show-marketplace", {
  text: "dialog.document-show-marketplace-text",
});
const marketRemoveConfirm = useConfirm("dialog.document-remove-marketplace", {
  text: "dialog.document-remove-marketplace-text",
});
const stopoversModal = useAsyncModal();

const props = defineProps<{
  id: string;
  isOffer?: boolean;
}>();

const documentObject = ref<DocumentSavedData>();
const loading = ref(true);
const updatePointsLoading = ref(false);
const updateCarrierLoading = ref(false);
const updateDatesLoading = ref(false);
const setMarketplaceLoading = ref(false);
const updateNewTotalPriceLoading = ref(false);
const updateTotalPriceLoading = ref(false);
const updateTransporterPriceLoading = ref(false);
const removeLoading = ref(false);
const cancelLoading = ref(false);
const cancelCheckbox = ref(false);
const exportLoading = ref(false);

const carrierErrors = ref<DocumentErrorObject>(getInitialDocumentErrorObject());
const assignmentErrors = ref<DocumentErrorObject>(
  getInitialDocumentErrorObject(),
);
const datesErrors = ref<DocumentErrorObject>(getInitialDocumentErrorObject());
const newTotalPriceErrors = ref<DocumentErrorObject>(
  getInitialDocumentErrorObject(),
);
const totalPriceErrors = ref<DocumentErrorObject>(
  getInitialDocumentErrorObject(),
);
const transporterPriceErrors = ref<DocumentErrorObject>(
  getInitialDocumentErrorObject(),
);

const editedCarrier = ref(false);
const editedPoints = ref(false);
const editedDates = ref(false);

const editedTotalPrice = ref(false);
const editedTransporterPrice = ref(false);

const validPoints = ref(false);
const formPoints = ref<VForm>();

const validCarrier = ref(false);
const formCarrier = ref<VForm>();

const validDates = ref(false);
const formDates = ref<VForm>();

const validNewTotalPrice = ref(false);
const formNewTotalPrice = ref<VForm>();

const validTotalPrice = ref(false);
const formTotalPrice = ref<VForm>();

const validTransporterPrice = ref(false);
const formTransporterPrice = ref<VForm>();

const flexStart = ref(false);
const flexEnd = ref(false);

const newTotalPrice = useNumberHelper(0);
const acceptNewTotalPrice = ref(false);

const pointWarningsActivator = ref(false);

const locCarrier = ref<EditCarrierObject>({});
const locPoints = ref<EditPointObject[] | null>(null);
const locAssignment = ref<EditAssignmentObject>({});
const locDates = ref<EditDatesObject>({});
const locTotalPrice = ref<EditTotalPriceObject>({});
const locTransporterPrice = ref<EditTransporterPriceObject>({});

const adminCarrierCompanyObject = ref<CompanyData>();

const totalPriceAlert = ref(false);
const transporterPriceAlert = ref(false);

useValidHelper(formPoints);
useValidHelper(formCarrier);
useValidHelper(formDates);
useValidHelper(formNewTotalPrice);
useValidHelper(formTotalPrice);
useValidHelper(formTransporterPrice);

const calcData = computed(() => {
  let result = getInitialDocumentCalcObject();
  try {
    if (documentObject.value)
      result = JSON.parse(documentObject.value.calc) as DocumentCalcObject;
  } catch (e: any) {
    console.error(e.message);
  }
  return result;
});

const palletCode = computed(() => documentObject.value?.pallet.data.code);
const toFormat = useFormat();
const { calcList, spCalcList } = useCalcList(calcData, palletCode);

const formatDuration = computed(() =>
  calcData.value
    ? moment
        .duration(calcData.value?.duration, "hours")
        .locale(locale.value)
        .humanize({ h: 48, d: 7, w: 4 })
    : "",
);

const bookedOn = computed(() =>
  documentObject.value?.transporter_assigned_at
    ? moment(documentObject.value.transporter_assigned_at).format(
        locFormat.value.yMmDd,
      )
    : null,
);

const createdAt = computed(() =>
  documentObject.value?.created_at
    ? moment(documentObject.value.created_at).format(locFormat.value.yMmDd)
    : null,
);

const companyData = computed(() =>
  joinWithDots(
    joinWithDots(
      documentObject.value?.company.data?.name,
      documentObject.value?.company.data?.addendum,
    ),
    documentObject.value?.company.data?.headquarters_address.data.street,
    documentObject.value?.company.data?.headquarters_address.data.country.data.code.toUpperCase(),
    `${documentObject.value?.company.data?.headquarters_address.data.zip} ${documentObject.value?.company.data?.headquarters_address.data.city}`,
  ),
);

const differentBillingAddress = computed(() => {
  const bAddr = documentObject.value?.company.data.billing_address;
  return bAddr
    ? joinWithDots(
        bAddr.data.street,
        bAddr.data.country.data.code.toUpperCase(),
        `${bAddr.data.zip} ${bAddr.data.city}`,
      )
    : undefined;
});

const contactData = computed(() =>
  joinWithDots(
    `${documentObject.value?.contact.data.firstname} ${documentObject.value?.contact.data.lastname}`,
    documentObject.value?.contact.data.phone,
    documentObject.value?.contact.data.email,
  ),
);

const dispatcherData = computed(() =>
  joinWithDots(
    `${documentObject.value?.user.data.firstname} ${documentObject.value?.user.data.lastname}`,
    documentObject.value?.user.data.phone,
    documentObject.value?.user.data.email,
  ),
);

const paymentData = computed(() => {
  const value = documentObject.value?.payment_target;
  return value ? t(getPaymentTargetName(value)) : "";
});

const formatBasePrice = computed(() => toFormat(calcData.value.basePrice, 2));
const formatTotalPrice = computed(() =>
  toFormat(documentObject.value?.total_price || 0, 2),
);
const formatTransporterPrice = computed(() =>
  toFormat(documentObject.value?.transporter_price || 0, 2),
);
const formatTotalPriceInitial = computed(() =>
  toFormat(documentObject.value?.total_price_initial),
);
const formatTransporterPriceInitial = computed(() =>
  toFormat(documentObject.value?.transporter_price_initial),
);
const formatGoodsValue = computed(() =>
  toFormat(documentObject.value?.goods_value),
);
const formatGoodsWeight = computed(() =>
  toFormat(documentObject.value?.goods_weight),
);
const formatGoodsLength = computed(() =>
  toFormat(documentObject.value?.goods_length),
);
const formatGoodsWidth = computed(() =>
  toFormat(documentObject.value?.goods_width),
);
const formatGoodsHeight = computed(() =>
  toFormat(documentObject.value?.goods_height),
);

const totalPriceDiff = computed(() =>
  documentObject.value?.total_price && documentObject.value?.total_price_initial
    ? documentObject.value?.total_price -
      documentObject.value?.total_price_initial
    : 0,
);

const formatTotalPriceDiff = computed(() =>
  toFormat(Math.abs(totalPriceDiff.value), 2),
);

const isPriceOnRequest = computed(
  () => documentObject.value?.status === OfferStatusType.priceOnRequest,
);

const minDateLoadingEnd = computed(
  () => locDates.value?.loading_time_start || "",
);
const minDateDischargeStart = computed(
  () => locDates.value?.loading_time_end || minDateLoadingEnd.value,
);
const minDateDischargeEnd = computed(
  () => locDates.value?.unloading_time_start || minDateDischargeStart.value,
);

const pointsIsEmpty = computed(() => {
  const pointsEmpty = (locPoints.value || []).some(
    ({ company_name, house, street, meta }) =>
      !(company_name && house && street && meta),
  );
  const referenceNumberEmpty = !locAssignment.value.reference_number;

  return pointsEmpty || referenceNumberEmpty;
});

const carrierIsEmpty = computed(() => !locCarrier.value.carrier_id);

const needMarketplace = computed(
  () =>
    documentObject.value?.type === DocumentType.order &&
    carrierIsEmpty.value &&
    !documentObject.value?.manual,
);

const isNotCanceled = computed(
  () => documentObject.value?.status !== OrderStatusType.canceled,
);

const isBplAdmin = computed(() =>
  access.someRoles([
    "admin",
    "sub-admin",
    "bpl-manager",
    "bpl-calculation-manager",
  ]),
);

const isClient = computed(() =>
  access.someRoles([
    "industry",
    "sub-industry",
    "accounting-client",
    "manager",
    "logistic",
    "sub-logistic",
    "pallet-department-client",
  ]),
);

const isTransporter = computed(() =>
  access.someRoles([
    "forwarder",
    "sub-forwarder",
    "planner",
    "accounting-contractor",
    "driver",
    "pallet-department-contractor",
  ]),
);

const isExported = computed(() =>
  store.state.app.exportsQueue
    .map((e) => e.ids)
    .flat()
    .includes(props.id),
);

const emptyPoints = computed(() => {
  const points: number[] = [];
  locPoints.value?.forEach((item, index) => {
    if (
      (item.type === PointType.loadingPoint ||
        item.type === PointType.unloadingPoint) &&
      !item?.meta.reference
    ) {
      points.push(index + 1);
    }
  });

  return points;
});

const userLocale = computed(() => store.state.auth.profile?.locale);

const carrierCompanyData = computed<CompanyData | null>(
  () =>
    (isBplAdmin.value
      ? adminCarrierCompanyObject.value
      : store.getters["auth/company"]) || null,
);

const carrierCompanyProfiles = computed(() => {
  return (
    carrierCompanyData.value?.users?.data
      .filter((user: ProfileData) => {
        return ["manager", "forwarder", "sub-forwarder"].includes(user.role);
      })
      .map((user: ProfileData) => {
        return isBplAdmin.value
          ? {
              title: `${user.firstname} ${user.lastname} (${user.role}) · ${user.phone}`,
              value: user.id,
            }
          : {
              title: `${user.firstname} ${user.lastname}`,
              value: user.id,
            };
      }) || []
  );
});

const deadlineFillingDate = computed(() => {
  return moment(documentObject.value?.created_at)
    .add(15, "minutes")
    .format(locFormat.value.yMmDdHhMmWithDots);
});

const documentPrefix = computed(() => {
  if (props.isOffer) {
    return "";
  } else {
    return isTransporter.value ? "TA" : "AB";
  }
});

const canCreateOrder = computed(
  () =>
    acceptNewTotalPrice.value &&
    newTotalPrice.value &&
    documentObject.value?.filled,
);

const prepLocPoints = computed<SavedPointObject[]>(
  () =>
    locPoints.value?.map((item) => ({
      ...item,
      company_name: item.company_name || undefined,
      meta: JSON.stringify(cleanObject<PointMeta>(item.meta)),
    })) || [],
);

const pointsIsChanged = computed(() => {
  if (documentObject.value) {
    const pointsChanged = prepLocPoints.value.some(
      (point, index) =>
        JSON.stringify(point) !==
        JSON.stringify(documentObject.value?.points.data[index]),
    );
    const referenceNumberChanged =
      locAssignment.value.reference_number !==
      documentObject.value.reference_number;

    return pointsChanged || referenceNumberChanged;
  } else {
    return true;
  }
});

const include = computed(
  () =>
    `points.country,pallet,carrier,carrierContact,ride,vehicle${
      isTransporter.value
        ? ""
        : ",user,company.headquarters_address,company.billing_address,contact"
    }`,
);

const goodsProductGroupTitle = computed(() => {
  const titleObject = getGoodsProductGroupTitle(
    documentObject.value?.goods_product_group || undefined,
  );
  return `${t(titleObject.key)} ${titleObject.number}`;
});

const syncCarrier = () => {
  locCarrier.value.carrier_id = documentObject.value?.carrier?.data.id || "";
  locCarrier.value.carrier_contact_id =
    documentObject.value?.carrierContact?.data.id || "";
  carrierErrors.value = getInitialDocumentErrorObject();
};

const syncPoints = () => {
  try {
    const defMeta =
      '{ "neutral_shipping": {"active": false }, "another_branch": {"active": false } }';
    locPoints.value = (documentObject.value?.points.data || []).map((item) => ({
      ...item,
      company_name: item.company_name || "",
      meta: JSON.parse(item.meta || defMeta),
    }));
    locAssignment.value.reference_number =
      documentObject.value?.reference_number;

    pointWarningsActivator.value = false;
  } catch (e: any) {
    snackbar(e.message);
  }
};

const syncDates = () => {
  locDates.value = {
    loading_time_start: documentObject.value?.loading_time_start,
    loading_time_end: documentObject.value?.loading_time_end,
    unloading_time_start: documentObject.value?.unloading_time_start,
    unloading_time_end: documentObject.value?.unloading_time_end,
  };

  flexStart.value = !!documentObject.value?.loading_time_end;
  flexEnd.value = !!documentObject.value?.unloading_time_end;

  datesErrors.value = getInitialDocumentErrorObject();
};

const syncTotalPrice = () => {
  locTotalPrice.value.total_price = documentObject.value?.total_price;
  totalPriceErrors.value = getInitialDocumentErrorObject();
};

const syncTransporterPrice = () => {
  locTransporterPrice.value.transporter_price =
    documentObject.value?.transporter_price;

  transporterPriceErrors.value = getInitialDocumentErrorObject();
};

const backToList = async (wrongTypeId?: string) => {
  await router.push({
    name: props.isOffer ? "offers" : "orders",
    query: wrongTypeId ? { wrong_type_id: wrongTypeId } : undefined,
  });
};

const fetchDocument = async (id: string) => {
  try {
    loading.value = true;

    const { data } = await getDocument(id, {
      include: include.value,
    });
    const type = props.isOffer ? DocumentType.offer : DocumentType.order;

    if (data.type !== type) await backToList(id);
    documentObject.value = data;
    syncPoints();
    syncDates();
    syncCarrier();
    syncTotalPrice();
    syncTransporterPrice();
  } catch (e: any) {
    snackbar(e.message);
    await backToList();
  } finally {
    loading.value = false;
  }
};

const savePointsChanges = async () => {
  await formPoints.value?.validate();
  if (validPoints.value && locPoints.value) {
    if (pointsIsChanged.value) {
      if (!emptyPoints.value.length || (await stopoversModal.callback())) {
        try {
          updatePointsLoading.value = true;

          const { data } = await updateDocument(
            props.id,
            {
              points: prepLocPoints.value,
              reference_number: locAssignment.value.reference_number,
              marketplace: needMarketplace.value || undefined,
            },
            {
              include: include.value,
            },
          );

          documentObject.value = data;

          syncPoints();

          editedPoints.value = false;
        } catch (e: any) {
          snackbar(e.message);
        } finally {
          updatePointsLoading.value = false;
        }
      } else {
        //activate warning
        pointWarningsActivator.value = true;
        await nextTick();
        //scroll to input
        formPoints.value?.$el
          .querySelector(".point-warning-show input")
          ?.focus();
      }
    } else {
      editedPoints.value = false;
    }
  }
};

const saveCarrierChanges = async () => {
  await formCarrier.value?.validate();
  if (
    validCarrier.value &&
    locCarrier.value &&
    (!documentObject.value?.marketplace ||
      (await marketTransporterSelectedConfirm()))
  ) {
    try {
      updateCarrierLoading.value = true;

      const payload = isBplAdmin.value
        ? {
            carrier_id: locCarrier.value.carrier_id,
            carrier_contact_id:
              locCarrier.value.carrier_contact_id || undefined,
            marketplace: false,
          }
        : {
            carrier_contact_id:
              locCarrier.value.carrier_contact_id || undefined,
          };

      const { data } = await updateDocument(props.id, payload, {
        include: include.value,
      });

      documentObject.value = data;

      syncCarrier();

      editedCarrier.value = false;
    } catch (e: any) {
      carrierErrors.value = e.errors;
      snackbar(e.message);
    } finally {
      updateCarrierLoading.value = false;
    }
  }
};

const saveDatesChanges = async () => {
  await formDates.value?.validate();
  if (validDates.value && locDates.value) {
    try {
      updateDatesLoading.value = true;

      const { data } = await updateDocument(
        props.id,
        { ...locDates.value },
        {
          include: include.value,
        },
      );

      documentObject.value = data;

      syncDates();

      editedDates.value = false;
    } catch (e: any) {
      datesErrors.value = e.errors;
      snackbar(e.message);
    } finally {
      updateDatesLoading.value = false;
    }
  }
};

const setMarketplace = async (value: boolean) => {
  if (value ? await marketShowConfirm() : await marketRemoveConfirm()) {
    try {
      setMarketplaceLoading.value = true;

      const { data } = await updateDocument(
        props.id,
        { marketplace: value },
        {
          include: include.value,
        },
      );

      documentObject.value = data;
    } catch (e: any) {
      snackbar(e.message);
    } finally {
      setMarketplaceLoading.value = false;
    }
  }
};

const saveNewTotalPrice = async () => {
  await formNewTotalPrice.value?.validate();
  if (validNewTotalPrice.value && canCreateOrder.value) {
    try {
      updateNewTotalPriceLoading.value = true;

      const { data } = await updateDocument(
        props.id,
        {
          type: DocumentType.order,
          total_price: Number(newTotalPrice.value),
        },
        {
          include: include.value,
        },
      );

      await router.push({ name: "offers", query: { created_id: data.id } });
    } catch (e: any) {
      newTotalPriceErrors.value = e.errors;
      snackbar(e.message);
    } finally {
      updateNewTotalPriceLoading.value = false;
    }
  }
};

const saveTotalPrice = async () => {
  await formTotalPrice.value?.validate();
  if (validTotalPrice.value) {
    try {
      updateTotalPriceLoading.value = true;
      if (
        locTotalPrice.value.total_price !== documentObject.value?.total_price
      ) {
        const { data } = await updateDocument(
          props.id,
          {
            ...locTotalPrice.value,
          },
          {
            include: include.value,
          },
        );

        documentObject.value = data;
        syncTotalPrice();
        if (!transporterPriceAlert.value) {
          totalPriceAlert.value = true;
        }
      }
      editedTotalPrice.value = false;
      transporterPriceAlert.value = false;
    } catch (e: any) {
      totalPriceErrors.value = e.errors;
      snackbar(e.message);
    } finally {
      updateTotalPriceLoading.value = false;
    }
  }
};

const saveTransporterPrice = async () => {
  await formTransporterPrice.value?.validate();
  if (validTransporterPrice.value) {
    try {
      updateTransporterPriceLoading.value = true;
      if (
        locTransporterPrice.value.transporter_price !==
        documentObject.value?.transporter_price
      ) {
        const { data } = await updateDocument(
          props.id,
          {
            ...locTransporterPrice.value,
          },
          {
            include: include.value,
          },
        );

        documentObject.value = data;
        syncTransporterPrice();
        if (!totalPriceAlert.value) {
          transporterPriceAlert.value = true;
        }
      }
      editedTransporterPrice.value = false;
      totalPriceAlert.value = false;
    } catch (e: any) {
      transporterPriceErrors.value = e.errors;
      snackbar(e.message);
    } finally {
      updateTransporterPriceLoading.value = false;
    }
  }
};

const deleteDocument = async () => {
  if (await deleteConfirm()) {
    try {
      removeLoading.value = true;

      await removeDocuments([props.id]);

      await backToList();
    } catch (e: any) {
      snackbar(e.message);
    } finally {
      removeLoading.value = false;
    }
  }
};

const cancelDocument = async () => {
  if (await cancelConfirm.callback()) {
    try {
      cancelLoading.value = true;

      const { data } = await updateDocument(
        props.id,
        { status: OrderStatusType.canceled },
        {
          include: include.value,
        },
      );

      documentObject.value = data;
    } catch (e: any) {
      snackbar(e.message);
    } finally {
      cancelLoading.value = false;
    }
  }
};

const exportCsv = async () => {
  try {
    exportLoading.value = true;
    const type: ExportNotificationType = props.isOffer
      ? ExportNotificationType.offersCsvExport
      : ExportNotificationType.ordersCsvExport;
    await store.dispatch("app/exportFiles", { type, ids: [props.id] });
  } catch (e: any) {
    snackbar(e.message);
  } finally {
    exportLoading.value = false;
  }
};

const exportPdf = async () => {
  try {
    exportLoading.value = true;
    await store.dispatch("app/exportFiles", {
      type: ExportNotificationType.ordersPdfExport,
      ids: [props.id],
    });
  } catch (e: any) {
    snackbar(e.message);
  } finally {
    exportLoading.value = false;
  }
};

const exportPdfTa = async () => {
  try {
    exportLoading.value = true;
    await store.dispatch("app/exportFiles", {
      type: ExportNotificationType.documentsTransporterPdfExport,
      ids: [props.id],
    });
  } catch (e: any) {
    snackbar(e.message);
  } finally {
    exportLoading.value = false;
  }
};

watch(
  () => ({
    company: locCarrier.value.carrier_id,
    user: locCarrier.value.carrier_contact_id,
  }),
  (value) => {
    const contact = carrierCompanyData.value?.contact?.data?.id;
    if (!value.user && contact) {
      locCarrier.value.carrier_contact_id = contact;
    }
  },
);

watch(adminCarrierCompanyObject, (value) => {
  if (
    !value?.users.data.some(
      ({ id }) => id === locCarrier.value.carrier_contact_id,
    )
  ) {
    locCarrier.value.carrier_contact_id = undefined;
  }
});

watch(
  userLocale,
  () => {
    fetchDocument(props.id);
  },
  { immediate: true },
);
</script>

<style scoped></style>
